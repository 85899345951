import { devLogError } from '../../log'

type FaceRecParams = {
  camWidth: string,
  camHeight: string,
  minFaceSize: Array<number>,
  maxFaceSize: Array<number>,
  roiWidth: number,
  roiHeight: number,
}

type StartCameraArgs = {
  videoEl: HTMLVideoElement
  canvas: HTMLCanvasElement
  faceRecParams?: FaceRecParams
  onStream?: (stream: MediaStream) => void | Promise<void>
  onCameraStart?: () => void | Promise<void>
}

function drawImge(videoEl: HTMLVideoElement, canvas: HTMLCanvasElement, faceRecParams?: FaceRecParams){
  if (!faceRecParams) return null

  const {
    camWidth,
    camHeight,
    roiHeight,
    roiWidth
  } = faceRecParams as FaceRecParams
  
  if(!canvas) return null
  const ctx = canvas.getContext('2d');
  if(!ctx) return null

  const camWidthNum = parseInt(camWidth.replace('px',''), 10)
  const camHeightNum = parseInt(camHeight.replace('px',''), 10)

  const faceWidth = roiWidth * camWidthNum;
  const faceHeight = roiHeight * camHeightNum;

  canvas.width = camWidthNum;
  canvas.height = camHeightNum;

  ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
  
  const pX=camWidthNum/2 - faceWidth/2
  const pY=camHeightNum/2 - faceHeight/2

  ctx.fillRect(pX,pY,faceWidth,faceHeight);

}

export async function startCamera({
  videoEl,
  canvas,
  faceRecParams,
  onStream,
  onCameraStart,
}: StartCameraArgs) {
  if (!videoEl.paused) return

  await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(async (stream) => {
      if (!videoEl) return

      onStream?.(stream)
      videoEl.srcObject = stream

      requestAnimationFrame(async () => {
        if (!videoEl) return
        drawImge(videoEl, canvas, faceRecParams)
        await videoEl
          .play()
          .then(() => onCameraStart?.())
          .catch((err) => {
            devLogError(err)
            startCamera({ videoEl, canvas, onStream, onCameraStart })
          })
      })
    })
    .catch((error) => devLogError('Error accessing camera:', error))
}

export async function stopCamera(stream?: MediaStream) {
  if (stream) stream.getTracks().forEach((track) => track.stop())
}
