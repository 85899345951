import './index.css'
import 'react-responsive-modal/styles.css'
import '@aws-amplify/ui-react/styles.css'

import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Match media replacement
// Ensure only works in landscape
const originalMatchMedia = window.matchMedia
;(window as any).matchMedia = function (query: string) {
  if (query === '(orientation: landscape)') {
    return {
      matches: false,
      addListener: () => {},
      removeListener: () => {},
    }
  } else {
    return originalMatchMedia(query)
  }
}

Object.defineProperty(navigator, 'userAgent', {
  value:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.102 Safari/537.36',
  writable: true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
