import './App.css'

import { useEffect } from 'react'

import { iframeCameraPermission } from './utils'

import { Amplify } from 'aws-amplify'
import CameraComponent from './Components/CameraComponent/CameraComponent'
import { devLogError } from './log'
import { API } from './services/api'

async function tryLoadAwsConfig() {
  try {
    // @ts-ignore
    const config = await import('./aws-exports').then((m) => m.default)
    Amplify.configure(config)
    console.log('Amplify configured')
  } catch (err) {
    console.error('Could not load aws-exports.js')
  }
}

function App() {
  useEffect(() => {
    tryLoadAwsConfig()
    iframeCameraPermission()
  }, [])

  return (
    <CameraComponent
      onImageCaptured={async ({ image, userId, type }) => {
        if (!image) {
          devLogError('No image captured')
          return
        }

        await API.findByFace({ userId, image, type })
      }}
    />
  )
}

export default App
