import { devLog, devLogError } from './log'
import { xmldata } from './xml/haarscade_frontalface_default'
import { CascadeClassifier } from '@techstark/opencv-js/src/types/opencv'

export function iframeMessage(message: { type: string; data?: any, errorCategory?: string }) {
  if (!window || !window.parent) {
    return devLogError('[iframeMessage] Not running inside an iframe.')
  }
  devLog('[iframeMessage] Posting message: ', message)
  window.parent.postMessage(message, '*')
}

export async function iframeCameraPermission() {
  if (window.self !== window.top) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        devLog('Camera access granted')
        return true
      })
      .catch((error) => {
        devLogError('Error accessing camera:', error)
        if (error.name === 'NotAllowedError') {
          devLogError(
            'Permission to access the camera was denied. Please enable camera access to use this feature.',
          )
        } else {
          devLogError(
            'Error accessing camera. Please try again later or check your camera settings.',
          )
        }

        return false
      })
  }
}

export const loadHaar = async function (classifier: CascadeClassifier) {
  await loadDataFile()
  classifier.load('xmldata')
}

export async function loadDataFile() {
  if (window.haarloaded) return

  while (!window?.cv?.FS_createDataFile) {
    await new Promise((r) => setTimeout(r, 10))
  }

  const enc = new TextEncoder()
  const data = enc.encode(xmldata)
  try {
    window.cv.FS_createDataFile('/', 'xmldata', data, true, false, false)
  } catch (error: any) {
    if (error?.code !== 'EEXIST') throw error
  }

  window.haarloaded = true
}
