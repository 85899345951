export const xmldata = `<?xml version="1.0"?>
<!--
    Stump-based 24x24 discrete(?) adaboost frontal face detector.
    Created by Rainer Lienhart.

////////////////////////////////////////////////////////////////////////////////////////

  IMPORTANT: READ BEFORE DOWNLOADING, COPYING, INSTALLING OR USING.

  By downloading, copying, installing or using the software you agree to this license.
  If you do not agree to this license, do not download, install,
  copy or use the software.


                        Intel License Agreement
                For Open Source Computer Vision Library

 Copyright (C) 2000, Intel Corporation, all rights reserved.
 Third party copyrights are property of their respective owners.

 Redistribution and use in source and binary forms, with or without modification,
 are permitted provided that the following conditions are met:

   * Redistribution's of source code must retain the above copyright notice,
     this list of conditions and the following disclaimer.

   * Redistribution's in binary form must reproduce the above copyright notice,
     this list of conditions and the following disclaimer in the documentation
     and/or other materials provided with the distribution.

   * The name of Intel Corporation may not be used to endorse or promote products
     derived from this software without specific prior written permission.

 This software is provided by the copyright holders and contributors "as is" and
 any express or implied warranties, including, but not limited to, the implied
 warranties of merchantability and fitness for a particular purpose are disclaimed.
 In no event shall the Intel Corporation or contributors be liable for any direct,
 indirect, incidental, special, exemplary, or consequential damages
 (including, but not limited to, procurement of substitute goods or services;
 loss of use, data, or profits; or business interruption) however caused
 and on any theory of liability, whether in contract, strict liability,
 or tort (including negligence or otherwise) arising in any way out of
 the use of this software, even if advised of the possibility of such damage.
-->
<opencv_storage>
<cascade type_id="opencv-cascade-classifier"><stageType>BOOST</stageType>
  <featureType>HAAR</featureType>
  <height>24</height>
  <width>24</width>
  <stageParams>
    <maxWeakCount>211</maxWeakCount></stageParams>
  <featureParams>
    <maxCatCount>0</maxCatCount></featureParams>
  <stageNum>25</stageNum>
  <stages>
    <_>
      <maxWeakCount>9</maxWeakCount>
      <stageThreshold>-5.0425500869750977e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 0 -3.1511999666690826e-02</internalNodes>
          <leafValues>
            2.0875380039215088e+00 -2.2172100543975830e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1 1.2396000325679779e-02</internalNodes>
          <leafValues>
            -1.8633940219879150e+00 1.3272049427032471e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2 2.1927999332547188e-02</internalNodes>
          <leafValues>
            -1.5105249881744385e+00 1.0625729560852051e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 3 5.7529998011887074e-03</internalNodes>
          <leafValues>
            -8.7463897466659546e-01 1.1760339736938477e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 4 1.5014000236988068e-02</internalNodes>
          <leafValues>
            -7.7945697307586670e-01 1.2608419656753540e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 5 9.9371001124382019e-02</internalNodes>
          <leafValues>
            5.5751299858093262e-01 -1.8743000030517578e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 6 2.7340000960975885e-03</internalNodes>
          <leafValues>
            -1.6911929845809937e+00 4.4009700417518616e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 7 -1.8859000876545906e-02</internalNodes>
          <leafValues>
            -1.4769539833068848e+00 4.4350099563598633e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 8 5.9739998541772366e-03</internalNodes>
          <leafValues>
            -8.5909199714660645e-01 8.5255599021911621e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>16</maxWeakCount>
      <stageThreshold>-4.9842400550842285e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 9 -2.1110000088810921e-02</internalNodes>
          <leafValues>
            1.2435649633407593e+00 -1.5713009834289551e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 10 2.0355999469757080e-02</internalNodes>
          <leafValues>
            -1.6204780340194702e+00 1.1817760467529297e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 11 2.1308999508619308e-02</internalNodes>
          <leafValues>
            -1.9415930509567261e+00 7.0069098472595215e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 12 9.1660000383853912e-02</internalNodes>
          <leafValues>
            -5.5670100450515747e-01 1.7284419536590576e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 13 3.6288000643253326e-02</internalNodes>
          <leafValues>
            2.6763799786567688e-01 -2.1831810474395752e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 14 -1.9109999760985374e-02</internalNodes>
          <leafValues>
            -2.6730210781097412e+00 4.5670801401138306e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 15 8.2539999857544899e-03</internalNodes>
          <leafValues>
            -1.0852910280227661e+00 5.3564202785491943e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 16 1.8355000764131546e-02</internalNodes>
          <leafValues>
            -3.5200199484825134e-01 9.3339198827743530e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 17 -7.0569999516010284e-03</internalNodes>
          <leafValues>
            9.2782098054885864e-01 -6.6349899768829346e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 18 -9.8770000040531158e-03</internalNodes>
          <leafValues>
            1.1577470302581787e+00 -2.9774799942970276e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 19 1.5814000740647316e-02</internalNodes>
          <leafValues>
            -4.1960600018501282e-01 1.3576040267944336e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 20 -2.0700000226497650e-02</internalNodes>
          <leafValues>
            1.4590020179748535e+00 -1.9739399850368500e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 21 -1.3760800659656525e-01</internalNodes>
          <leafValues>
            1.1186759471893311e+00 -5.2915501594543457e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 22 1.4318999834358692e-02</internalNodes>
          <leafValues>
            -3.5127198696136475e-01 1.1440860033035278e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 23 1.0253000073134899e-02</internalNodes>
          <leafValues>
            -6.0850602388381958e-01 7.7098500728607178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 24 9.1508001089096069e-02</internalNodes>
          <leafValues>
            3.8817799091339111e-01 -1.5122940540313721e+00</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>27</maxWeakCount>
      <stageThreshold>-4.6551899909973145e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 25 6.9747000932693481e-02</internalNodes>
          <leafValues>
            -1.0130879878997803e+00 1.4687349796295166e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 26 3.1502999365329742e-02</internalNodes>
          <leafValues>
            -1.6463639736175537e+00 1.0000629425048828e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 27 1.4260999858379364e-02</internalNodes>
          <leafValues>
            4.6480301022529602e-01 -1.5959889888763428e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 28 1.4453000389039516e-02</internalNodes>
          <leafValues>
            -6.5511900186538696e-01 8.3021801710128784e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 29 -3.0509999487549067e-03</internalNodes>
          <leafValues>
            -1.3982310295104980e+00 4.2550599575042725e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 30 3.2722998410463333e-02</internalNodes>
          <leafValues>
            -5.0702601671218872e-01 1.0526109933853149e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 31 -7.2960001416504383e-03</internalNodes>
          <leafValues>
            3.6356899142265320e-01 -1.3464889526367188e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 32 5.0425000488758087e-02</internalNodes>
          <leafValues>
            -3.0461400747299194e-01 1.4504129886627197e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 33 4.6879000961780548e-02</internalNodes>
          <leafValues>
            -4.0286201238632202e-01 1.2145609855651855e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 34 -6.9358997046947479e-02</internalNodes>
          <leafValues>
            1.0539360046386719e+00 -4.5719701051712036e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 35 -4.9033999443054199e-02</internalNodes>
          <leafValues>
            -1.6253089904785156e+00 1.5378999710083008e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 36 8.4827996790409088e-02</internalNodes>
          <leafValues>
            2.8402999043464661e-01 -1.5662059783935547e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 37 -1.7229999648407102e-03</internalNodes>
          <leafValues>
            -1.0147459506988525e+00 2.3294800519943237e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 38 1.1562199890613556e-01</internalNodes>
          <leafValues>
            -1.6732899844646454e-01 1.2804069519042969e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 39 -5.1279999315738678e-02</internalNodes>
          <leafValues>
            1.5162390470504761e+00 -3.0271100997924805e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 40 -4.2706999927759171e-02</internalNodes>
          <leafValues>
            1.7631920576095581e+00 -5.1832001656293869e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 41 3.7178099155426025e-01</internalNodes>
          <leafValues>
            -3.1389200687408447e-01 1.5357979536056519e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 42 1.9412999972701073e-02</internalNodes>
          <leafValues>
            -1.0017599910497665e-01 9.3655401468276978e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 43 1.7439000308513641e-02</internalNodes>
          <leafValues>
            -4.0379899740219116e-01 9.6293002367019653e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 44 3.9638999849557877e-02</internalNodes>
          <leafValues>
            1.7039099335670471e-01 -2.9602990150451660e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 45 -9.1469995677471161e-03</internalNodes>
          <leafValues>
            8.8786798715591431e-01 -4.3818700313568115e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 46 1.7219999572262168e-03</internalNodes>
          <leafValues>
            -3.7218600511550903e-01 4.0018901228904724e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 47 3.0231000855565071e-02</internalNodes>
          <leafValues>
            6.5924003720283508e-02 -2.6469180583953857e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 48 -7.8795999288558960e-02</internalNodes>
          <leafValues>
            -1.7491459846496582e+00 2.8475299477577209e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 49 2.1110000088810921e-03</internalNodes>
          <leafValues>
            -9.3908101320266724e-01 2.3205199837684631e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 50 2.7091000229120255e-02</internalNodes>
          <leafValues>
            -5.2664000540971756e-02 1.0756820440292358e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 51 -4.4964998960494995e-02</internalNodes>
          <leafValues>
            -1.8294479846954346e+00 9.9561996757984161e-02</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>32</maxWeakCount>
      <stageThreshold>-4.4531588554382324e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 52 -6.5701000392436981e-02</internalNodes>
          <leafValues>
            1.1558510065078735e+00 -1.0716359615325928e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 53 1.5839999541640282e-02</internalNodes>
          <leafValues>
            -1.5634720325469971e+00 7.6877099275588989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 54 1.4570899307727814e-01</internalNodes>
          <leafValues>
            -5.7450097799301147e-01 1.3808720111846924e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 55 6.1389999464154243e-03</internalNodes>
          <leafValues>
            -1.4570560455322266e+00 5.1610302925109863e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 56 6.7179999314248562e-03</internalNodes>
          <leafValues>
            -8.3533602952957153e-01 5.8522200584411621e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 57 1.8518000841140747e-02</internalNodes>
          <leafValues>
            -3.1312099099159241e-01 1.1696679592132568e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 58 1.9958000630140305e-02</internalNodes>
          <leafValues>
            -4.3442600965499878e-01 9.5446902513504028e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 59 -2.7755001187324524e-01</internalNodes>
          <leafValues>
            1.4906179904937744e+00 -1.3815900683403015e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 60 9.1859996318817139e-03</internalNodes>
          <leafValues>
            -9.6361500024795532e-01 2.7665498852729797e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 61 -3.7737999111413956e-02</internalNodes>
          <leafValues>
            -2.4464108943939209e+00 2.3619599640369415e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 62 1.8463000655174255e-02</internalNodes>
          <leafValues>
            1.7539200186729431e-01 -1.3423130512237549e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 63 -1.1114999651908875e-02</internalNodes>
          <leafValues>
            4.8710799217224121e-01 -8.9851897954940796e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 64 3.3927999436855316e-02</internalNodes>
          <leafValues>
            1.7874200642108917e-01 -1.6342279911041260e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 65 -3.5649001598358154e-02</internalNodes>
          <leafValues>
            -1.9607399702072144e+00 1.8102499842643738e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 66 -1.1438000015914440e-02</internalNodes>
          <leafValues>
            9.9010699987411499e-01 -3.8103199005126953e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 67 -6.5236002206802368e-02</internalNodes>
          <leafValues>
            -2.5794160366058350e+00 2.4753600358963013e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 68 -4.2272001504898071e-02</internalNodes>
          <leafValues>
            1.4411840438842773e+00 -2.9508298635482788e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 69 1.9219999667257071e-03</internalNodes>
          <leafValues>
            -4.9608600139617920e-01 6.3173598051071167e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 70 -1.2921799719333649e-01</internalNodes>
          <leafValues>
            -2.3314270973205566e+00 5.4496999830007553e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 71 2.2931000217795372e-02</internalNodes>
          <leafValues>
            -8.4447097778320312e-01 3.8738098740577698e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 72 -3.4120000898838043e-02</internalNodes>
          <leafValues>
            -1.4431500434875488e+00 9.8422996699810028e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 73 2.6223000138998032e-02</internalNodes>
          <leafValues>
            1.8223099410533905e-01 -1.2586519718170166e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 74 2.2236999124288559e-02</internalNodes>
          <leafValues>
            6.9807998836040497e-02 -2.3820950984954834e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 75 -5.8240001089870930e-03</internalNodes>
          <leafValues>
            3.9332500100135803e-01 -2.7542799711227417e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 76 4.3653000146150589e-02</internalNodes>
          <leafValues>
            1.4832699298858643e-01 -1.1368780136108398e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 77 5.7266999036073685e-02</internalNodes>
          <leafValues>
            2.4628099799156189e-01 -1.2687400579452515e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 78 2.3409998975694180e-03</internalNodes>
          <leafValues>
            -7.5448900461196899e-01 2.7163800597190857e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 79 1.2996000237762928e-02</internalNodes>
          <leafValues>
            -3.6394900083541870e-01 7.0959198474884033e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 80 -2.6517000049352646e-02</internalNodes>
          <leafValues>
            -2.3221859931945801e+00 3.5744000226259232e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 81 -5.8400002308189869e-03</internalNodes>
          <leafValues>
            4.2194300889968872e-01 -4.8184998333454132e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 82 -1.6568999737501144e-02</internalNodes>
          <leafValues>
            1.1099940538406372e+00 -3.4849700331687927e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 83 -6.8157002329826355e-02</internalNodes>
          <leafValues>
            -3.3269989490509033e+00 2.1299000084400177e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>52</maxWeakCount>
      <stageThreshold>-4.3864588737487793e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 84 3.9974000304937363e-02</internalNodes>
          <leafValues>
            -1.2173449993133545e+00 1.0826710462570190e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 85 1.8819500505924225e-01</internalNodes>
          <leafValues>
            -4.8289400339126587e-01 1.4045250415802002e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 86 7.8027002513408661e-02</internalNodes>
          <leafValues>
            -1.0782150030136108e+00 7.4040299654006958e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 87 1.1899999663000926e-04</internalNodes>
          <leafValues>
            -1.2019979953765869e+00 3.7749201059341431e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 88 8.5056997835636139e-02</internalNodes>
          <leafValues>
            -4.3939098715782166e-01 1.2647340297698975e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 89 8.9720003306865692e-03</internalNodes>
          <leafValues>
            -1.8440499901771545e-01 4.5726400613784790e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 90 8.8120000436902046e-03</internalNodes>
          <leafValues>
            3.0396699905395508e-01 -9.5991098880767822e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 91 -2.3507999256253242e-02</internalNodes>
          <leafValues>
            1.2487529516220093e+00 4.6227999031543732e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 92 7.0039997808635235e-03</internalNodes>
          <leafValues>
            -5.9442102909088135e-01 5.3963297605514526e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 93 3.3851999789476395e-02</internalNodes>
          <leafValues>
            2.8496098518371582e-01 -1.4895249605178833e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 94 -3.2530000898987055e-03</internalNodes>
          <leafValues>
            4.8120799660682678e-01 -5.2712398767471313e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 95 2.9097000136971474e-02</internalNodes>
          <leafValues>
            2.6743900775909424e-01 -1.6007850170135498e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 96 -8.4790000692009926e-03</internalNodes>
          <leafValues>
            -1.3107639551162720e+00 1.5243099629878998e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 97 -1.0795000009238720e-02</internalNodes>
          <leafValues>
            4.5613598823547363e-01 -7.2050899267196655e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 98 -2.4620000272989273e-02</internalNodes>
          <leafValues>
            -1.7320619821548462e+00 6.8363003432750702e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 99 3.7380000576376915e-03</internalNodes>
          <leafValues>
            -1.9303299486637115e-01 6.8243497610092163e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 100 -1.2264000251889229e-02</internalNodes>
          <leafValues>
            -1.6095290184020996e+00 7.5268000364303589e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 101 -4.8670000396668911e-03</internalNodes>
          <leafValues>
            7.4286502599716187e-01 -2.1510200202465057e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 102 7.6725997030735016e-02</internalNodes>
          <leafValues>
            -2.6835098862648010e-01 1.3094140291213989e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 103 2.8578000143170357e-02</internalNodes>
          <leafValues>
            -5.8793000876903534e-02 1.2196329832077026e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 104 1.9694000482559204e-02</internalNodes>
          <leafValues>
            -3.5142898559570312e-01 8.4926998615264893e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 105 -2.9093999415636063e-02</internalNodes>
          <leafValues>
            -1.0507299900054932e+00 2.9806300997734070e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 106 -2.9144000262022018e-02</internalNodes>
          <leafValues>
            8.2547801733016968e-01 -3.2687199115753174e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 107 1.9741000607609749e-02</internalNodes>
          <leafValues>
            2.0452600717544556e-01 -8.3760201930999756e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 108 4.3299999088048935e-03</internalNodes>
          <leafValues>
            2.0577900111675262e-01 -6.6829800605773926e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 109 -3.5500999540090561e-02</internalNodes>
          <leafValues>
            -1.2969900369644165e+00 1.3897499442100525e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 110 -1.6172999516129494e-02</internalNodes>
          <leafValues>
            -1.3110569715499878e+00 7.5751997530460358e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 111 -2.2151000797748566e-02</internalNodes>
          <leafValues>
            -1.0524389743804932e+00 1.9241100549697876e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 112 -2.2707000374794006e-02</internalNodes>
          <leafValues>
            -1.3735309839248657e+00 6.6780999302864075e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 113 1.6607999801635742e-02</internalNodes>
          <leafValues>
            -3.7135999649763107e-02 7.7846401929855347e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 114 -1.3309000059962273e-02</internalNodes>
          <leafValues>
            -9.9850702285766602e-01 1.2248100340366364e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 115 -3.3732000738382339e-02</internalNodes>
          <leafValues>
            1.4461359977722168e+00 1.3151999562978745e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 116 1.6935000196099281e-02</internalNodes>
          <leafValues>
            -3.7121298909187317e-01 5.2842199802398682e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 117 3.3259999472647905e-03</internalNodes>
          <leafValues>
            -5.7568502426147461e-01 3.9261901378631592e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 118 8.3644002676010132e-02</internalNodes>
          <leafValues>
            1.6116000711917877e-02 -2.1173279285430908e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 119 2.5785198807716370e-01</internalNodes>
          <leafValues>
            -8.1609003245830536e-02 9.8782497644424438e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 120 -3.6566998809576035e-02</internalNodes>
          <leafValues>
            -1.1512110233306885e+00 9.6459001302719116e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 121 -1.6445999965071678e-02</internalNodes>
          <leafValues>
            3.7315499782562256e-01 -1.4585399627685547e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 122 -3.7519999314099550e-03</internalNodes>
          <leafValues>
            2.6179298758506775e-01 -5.8156698942184448e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 123 -6.3660000450909138e-03</internalNodes>
          <leafValues>
            7.5477397441864014e-01 -1.7055200040340424e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 124 -3.8499999791383743e-03</internalNodes>
          <leafValues>
            2.2653999924659729e-01 -6.3876402378082275e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 125 -4.5494001358747482e-02</internalNodes>
          <leafValues>
            -1.2640299797058105e+00 2.5260698795318604e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 126 -2.3941000923514366e-02</internalNodes>
          <leafValues>
            8.7068402767181396e-01 -2.7104699611663818e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 127 -7.7558003365993500e-02</internalNodes>
          <leafValues>
            -1.3901610374450684e+00 2.3612299561500549e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 128 2.3614000529050827e-02</internalNodes>
          <leafValues>
            6.6140003502368927e-02 -1.2645419836044312e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 129 -2.5750000495463610e-03</internalNodes>
          <leafValues>
            -5.3841698169708252e-01 3.0379098653793335e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 130 1.2010800093412399e-01</internalNodes>
          <leafValues>
            -3.5343000292778015e-01 5.2866202592849731e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 131 2.2899999748915434e-03</internalNodes>
          <leafValues>
            -5.8701997995376587e-01 2.4061000347137451e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 132 6.9716997444629669e-02</internalNodes>
          <leafValues>
            -3.3348900079727173e-01 5.1916301250457764e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 133 -4.6670001000165939e-02</internalNodes>
          <leafValues>
            6.9795399904251099e-01 -1.4895999804139137e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 134 -5.0129000097513199e-02</internalNodes>
          <leafValues>
            8.6146199703216553e-01 -2.5986000895500183e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 135 3.0147999525070190e-02</internalNodes>
          <leafValues>
            1.9332799315452576e-01 -5.9131097793579102e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>53</maxWeakCount>
      <stageThreshold>-4.1299300193786621e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 136 9.1085001826286316e-02</internalNodes>
          <leafValues>
            -8.9233100414276123e-01 1.0434230566024780e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 137 1.2818999588489532e-02</internalNodes>
          <leafValues>
            -1.2597670555114746e+00 5.5317097902297974e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 138 1.5931999310851097e-02</internalNodes>
          <leafValues>
            -8.6254400014877319e-01 6.3731801509857178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 139 2.2780001163482666e-03</internalNodes>
          <leafValues>
            -7.4639201164245605e-01 5.3155601024627686e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 140 3.1840998679399490e-02</internalNodes>
          <leafValues>
            -1.2650489807128906e+00 3.6153900623321533e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 141 2.6960000395774841e-03</internalNodes>
          <leafValues>
            -9.8290401697158813e-01 3.6013001203536987e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 142 -1.2055000290274620e-02</internalNodes>
          <leafValues>
            6.4068400859832764e-01 -5.0125002861022949e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 143 2.1324999630451202e-02</internalNodes>
          <leafValues>
            -2.4034999310970306e-01 8.5448002815246582e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 144 3.0486000701785088e-02</internalNodes>
          <leafValues>
            -3.4273600578308105e-01 1.1428849697113037e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 145 -4.5079998672008514e-02</internalNodes>
          <leafValues>
            1.0976949930191040e+00 -1.7974600195884705e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 146 -7.1700997650623322e-02</internalNodes>
          <leafValues>
            1.5735000371932983e+00 -3.1433498859405518e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 147 5.9218000620603561e-02</internalNodes>
          <leafValues>
            -2.7582401037216187e-01 1.0448570251464844e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 148 6.7010000348091125e-03</internalNodes>
          <leafValues>
            -1.0974019765853882e+00 1.9801199436187744e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 149 4.1046999394893646e-02</internalNodes>
          <leafValues>
            3.0547699332237244e-01 -1.3287999629974365e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 150 -8.5499999113380909e-04</internalNodes>
          <leafValues>
            2.5807100534439087e-01 -7.0052897930145264e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 151 -3.0360000208020210e-02</internalNodes>
          <leafValues>
            -1.2306419610977173e+00 2.2609399259090424e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 152 -1.2930000200867653e-02</internalNodes>
          <leafValues>
            4.0758600831031799e-01 -5.1234501600265503e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 153 3.7367999553680420e-02</internalNodes>
          <leafValues>
            -9.4755001366138458e-02 6.1765098571777344e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 154 2.4434000253677368e-02</internalNodes>
          <leafValues>
            -4.1100600361824036e-01 4.7630500793457031e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 155 5.7007998228073120e-02</internalNodes>
          <leafValues>
            2.5249299407005310e-01 -6.8669801950454712e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 156 -1.6313999891281128e-02</internalNodes>
          <leafValues>
            -9.3928402662277222e-01 1.1448100209236145e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 157 -1.7648899555206299e-01</internalNodes>
          <leafValues>
            1.2451089620590210e+00 -5.6519001722335815e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 158 1.7614600062370300e-01</internalNodes>
          <leafValues>
            -3.2528200745582581e-01 8.2791501283645630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 159 -7.3910001665353775e-03</internalNodes>
          <leafValues>
            3.4783700108528137e-01 -1.7929099500179291e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 160 6.0890998691320419e-02</internalNodes>
          <leafValues>
            5.5098000913858414e-02 -1.5480779409408569e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 161 -2.9123000800609589e-02</internalNodes>
          <leafValues>
            -1.0255639553070068e+00 2.4106900393962860e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 162 -4.5648999512195587e-02</internalNodes>
          <leafValues>
            1.0301599502563477e+00 -3.1672099232673645e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 163 3.7333000451326370e-02</internalNodes>
          <leafValues>
            2.1620599925518036e-01 -8.2589900493621826e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 164 -2.4411000311374664e-02</internalNodes>
          <leafValues>
            -1.5957959890365601e+00 5.1139000803232193e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 165 -5.9806998819112778e-02</internalNodes>
          <leafValues>
            -1.0312290191650391e+00 1.3092300295829773e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 166 -3.0106000602245331e-02</internalNodes>
          <leafValues>
            -1.4781630039215088e+00 3.7211999297142029e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 167 7.4209999293088913e-03</internalNodes>
          <leafValues>
            -2.4024100601673126e-01 4.9333998560905457e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 168 -2.1909999195486307e-03</internalNodes>
          <leafValues>
            2.8941500186920166e-01 -5.7259601354598999e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 169 2.0860999822616577e-02</internalNodes>
          <leafValues>
            -2.3148399591445923e-01 6.3765901327133179e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 170 -6.6990000195801258e-03</internalNodes>
          <leafValues>
            -1.2107750177383423e+00 6.4018003642559052e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 171 1.8758000805974007e-02</internalNodes>
          <leafValues>
            2.4461300671100616e-01 -9.9786698818206787e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 172 -4.4323001056909561e-02</internalNodes>
          <leafValues>
            -1.3699189424514771e+00 3.6051999777555466e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 173 2.2859999909996986e-02</internalNodes>
          <leafValues>
            2.1288399398326874e-01 -1.0397620201110840e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 174 -9.8600005730986595e-04</internalNodes>
          <leafValues>
            3.2443600893020630e-01 -5.4291802644729614e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 175 1.7239000648260117e-02</internalNodes>
          <leafValues>
            -2.8323900699615479e-01 4.4468200206756592e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 176 -3.4531001001596451e-02</internalNodes>
          <leafValues>
            -2.3107020854949951e+00 -3.1399999279528856e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 177 6.7006997764110565e-02</internalNodes>
          <leafValues>
            2.8715699911117554e-01 -6.4481002092361450e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 178 2.3776899278163910e-01</internalNodes>
          <leafValues>
            -2.7174800634384155e-01 8.0219101905822754e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 179 -1.2903000228106976e-02</internalNodes>
          <leafValues>
            -1.5317620038986206e+00 2.1423600614070892e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 180 1.0514999739825726e-02</internalNodes>
          <leafValues>
            7.7037997543811798e-02 -1.0581140518188477e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 181 1.6969000920653343e-02</internalNodes>
          <leafValues>
            1.4306700229644775e-01 -8.5828399658203125e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 182 -7.2460002265870571e-03</internalNodes>
          <leafValues>
            -1.1020129919052124e+00 6.4906999468803406e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 183 1.0556999593973160e-02</internalNodes>
          <leafValues>
            1.3964000158011913e-02 6.3601499795913696e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 184 6.1380001716315746e-03</internalNodes>
          <leafValues>
            -3.4545901417732239e-01 5.6296801567077637e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 185 1.3158000074326992e-02</internalNodes>
          <leafValues>
            1.9927300512790680e-01 -1.5040320158004761e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 186 3.1310000922530890e-03</internalNodes>
          <leafValues>
            -4.0903699398040771e-01 3.7796398997306824e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 187 -1.0920699685811996e-01</internalNodes>
          <leafValues>
            -2.2227079868316650e+00 1.2178199738264084e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 188 8.1820003688335419e-03</internalNodes>
          <leafValues>
            -2.8652000427246094e-01 6.7890799045562744e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>62</maxWeakCount>
      <stageThreshold>-4.0218091011047363e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 189 3.1346999108791351e-02</internalNodes>
          <leafValues>
            -8.8884598016738892e-01 9.4936800003051758e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 190 3.1918000429868698e-02</internalNodes>
          <leafValues>
            -1.1146880388259888e+00 4.8888999223709106e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 191 6.5939999185502529e-03</internalNodes>
          <leafValues>
            -1.0097689628601074e+00 4.9723801016807556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 192 2.6148000732064247e-02</internalNodes>
          <leafValues>
            2.5991299748420715e-01 -1.2537480592727661e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 193 1.2845000252127647e-02</internalNodes>
          <leafValues>
            -5.7138597965240479e-01 5.9659498929977417e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 194 2.6344999670982361e-02</internalNodes>
          <leafValues>
            -5.5203199386596680e-01 3.0217400193214417e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 195 -1.5083000063896179e-02</internalNodes>
          <leafValues>
            -1.2871240377426147e+00 2.2354200482368469e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 196 -3.8887001574039459e-02</internalNodes>
          <leafValues>
            1.7425049543380737e+00 -9.9747002124786377e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 197 -5.7029998861253262e-03</internalNodes>
          <leafValues>
            -1.0523240566253662e+00 1.8362599611282349e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 198 -1.4860000228509307e-03</internalNodes>
          <leafValues>
            5.6784200668334961e-01 -4.6742001175880432e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 199 -2.8486000373959541e-02</internalNodes>
          <leafValues>
            1.3082909584045410e+00 -2.6460900902748108e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 200 6.6224999725818634e-02</internalNodes>
          <leafValues>
            -4.6210700273513794e-01 4.1749599575996399e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 201 8.8569996878504753e-03</internalNodes>
          <leafValues>
            -4.1474899649620056e-01 5.9204798936843872e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 202 1.1355999857187271e-02</internalNodes>
          <leafValues>
            3.6103099584579468e-01 -4.5781201124191284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 203 -2.7679998893290758e-03</internalNodes>
          <leafValues>
            -8.9238899946212769e-01 1.4199000597000122e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 204 1.1246999725699425e-02</internalNodes>
          <leafValues>
            2.9353401064872742e-01 -9.7330600023269653e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 205 7.1970000863075256e-03</internalNodes>
          <leafValues>
            -7.9334902763366699e-01 1.8313400447368622e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 206 3.1768999993801117e-02</internalNodes>
          <leafValues>
            1.5523099899291992e-01 -1.3245639801025391e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 207 2.5173999369144440e-02</internalNodes>
          <leafValues>
            3.4214999526739120e-02 -2.0948131084442139e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 208 7.5360001064836979e-03</internalNodes>
          <leafValues>
            -3.9450600743293762e-01 5.1333999633789062e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 209 3.2873000949621201e-02</internalNodes>
          <leafValues>
            8.8372997939586639e-02 -1.2814120054244995e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 210 -2.7379998937249184e-03</internalNodes>
          <leafValues>
            5.5286502838134766e-01 -4.6384999155998230e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 211 -3.8075000047683716e-02</internalNodes>
          <leafValues>
            -1.8497270345687866e+00 4.5944001525640488e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 212 -3.8984000682830811e-02</internalNodes>
          <leafValues>
            -4.8223701119422913e-01 3.4760600328445435e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 213 2.8029999230057001e-03</internalNodes>
          <leafValues>
            -4.5154699683189392e-01 4.2806300520896912e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 214 -5.4145999252796173e-02</internalNodes>
          <leafValues>
            -8.4520798921585083e-01 1.6674900054931641e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 215 -8.3280000835657120e-03</internalNodes>
          <leafValues>
            3.5348299145698547e-01 -4.7163200378417969e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 216 3.3778000622987747e-02</internalNodes>
          <leafValues>
            1.8463100492954254e-01 -1.6686669588088989e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 217 -1.1238099634647369e-01</internalNodes>
          <leafValues>
            -1.2521569728851318e+00 3.5992000252008438e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 218 -1.0408000089228153e-02</internalNodes>
          <leafValues>
            -8.1620401144027710e-01 2.3428599536418915e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 219 -4.9439999274909496e-03</internalNodes>
          <leafValues>
            -9.2584699392318726e-01 1.0034800320863724e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 220 -9.3029998242855072e-03</internalNodes>
          <leafValues>
            5.6499302387237549e-01 -1.8881900608539581e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 221 -1.1749999597668648e-02</internalNodes>
          <leafValues>
            8.0302399396896362e-01 -3.8277000188827515e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 222 -2.3217000067234039e-02</internalNodes>
          <leafValues>
            -8.4926998615264893e-01 1.9671200215816498e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 223 1.6866000369191170e-02</internalNodes>
          <leafValues>
            -4.0591898560523987e-01 5.0695300102233887e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 224 -2.4031000211834908e-02</internalNodes>
          <leafValues>
            -1.5297520160675049e+00 2.3344999551773071e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 225 -3.6945998668670654e-02</internalNodes>
          <leafValues>
            6.3007700443267822e-01 -3.1780400872230530e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 226 -6.1563998460769653e-02</internalNodes>
          <leafValues>
            5.8627897500991821e-01 -1.2107999995350838e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 227 2.1661000326275826e-02</internalNodes>
          <leafValues>
            -2.5623700022697449e-01 1.0409849882125854e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 228 -3.6710000131279230e-03</internalNodes>
          <leafValues>
            2.9171100258827209e-01 -8.3287298679351807e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 229 4.4849000871181488e-02</internalNodes>
          <leafValues>
            -3.9633199572563171e-01 4.5662000775337219e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 230 5.7195000350475311e-02</internalNodes>
          <leafValues>
            2.1023899316787720e-01 -1.5004800558090210e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 231 -1.1342000216245651e-02</internalNodes>
          <leafValues>
            4.4071298837661743e-01 -3.8653799891471863e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 232 -1.2004000134766102e-02</internalNodes>
          <leafValues>
            9.3954598903656006e-01 -1.0589499771595001e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 233 2.2515999153256416e-02</internalNodes>
          <leafValues>
            9.4480002298951149e-03 -1.6799509525299072e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 234 -1.9809000194072723e-02</internalNodes>
          <leafValues>
            -1.0133639574050903e+00 2.4146600067615509e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 235 1.5891000628471375e-02</internalNodes>
          <leafValues>
            -3.7507599592208862e-01 4.6614098548889160e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 236 -9.1420002281665802e-03</internalNodes>
          <leafValues>
            -8.0484098196029663e-01 1.7816999554634094e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 237 -4.4740000739693642e-03</internalNodes>
          <leafValues>
            -1.0562069416046143e+00 7.3305003345012665e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 238 1.2742500007152557e-01</internalNodes>
          <leafValues>
            2.0165599882602692e-01 -1.5467929840087891e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 239 4.7703001648187637e-02</internalNodes>
          <leafValues>
            -3.7937799096107483e-01 3.7885999679565430e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 240 5.3608000278472900e-02</internalNodes>
          <leafValues>
            2.1220499277114868e-01 -1.2399710416793823e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 241 -3.9680998772382736e-02</internalNodes>
          <leafValues>
            -1.0257550477981567e+00 5.1282998174428940e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 242 -6.7327000200748444e-02</internalNodes>
          <leafValues>
            -1.0304750204086304e+00 2.3005299270153046e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 243 1.3337600231170654e-01</internalNodes>
          <leafValues>
            -2.0869000256061554e-01 1.2272510528564453e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 244 -2.0919300615787506e-01</internalNodes>
          <leafValues>
            8.7929898500442505e-01 -4.4254999607801437e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 245 -6.5589003264904022e-02</internalNodes>
          <leafValues>
            1.0443429946899414e+00 -2.1682099997997284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 246 6.1882998794317245e-02</internalNodes>
          <leafValues>
            1.3798199594020844e-01 -1.9009059667587280e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 247 -2.5578999891877174e-02</internalNodes>
          <leafValues>
            -1.6607600450515747e+00 5.8439997956156731e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 248 -3.4827001392841339e-02</internalNodes>
          <leafValues>
            7.9940402507781982e-01 -8.2406997680664062e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 249 -1.8209999427199364e-02</internalNodes>
          <leafValues>
            -9.6073997020721436e-01 6.6320002079010010e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 250 1.5070999972522259e-02</internalNodes>
          <leafValues>
            1.9899399578571320e-01 -7.6433002948760986e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>72</maxWeakCount>
      <stageThreshold>-3.8832089900970459e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 251 4.6324998140335083e-02</internalNodes>
          <leafValues>
            -1.0362670421600342e+00 8.2201498746871948e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 252 1.5406999737024307e-02</internalNodes>
          <leafValues>
            -1.2327589988708496e+00 2.9647698998451233e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 253 1.2808999978005886e-02</internalNodes>
          <leafValues>
            -7.5852298736572266e-01 5.7985502481460571e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 254 4.9150999635457993e-02</internalNodes>
          <leafValues>
            -3.8983899354934692e-01 8.9680302143096924e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 255 1.2621000409126282e-02</internalNodes>
          <leafValues>
            -7.1799302101135254e-01 5.0440901517868042e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 256 -1.8768999725580215e-02</internalNodes>
          <leafValues>
            5.5147600173950195e-01 -7.0555400848388672e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 257 4.1965000331401825e-02</internalNodes>
          <leafValues>
            -4.4782099127769470e-01 7.0985502004623413e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 258 -5.1401998847723007e-02</internalNodes>
          <leafValues>
            -1.0932120084762573e+00 2.6701900362968445e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 259 -7.0960998535156250e-02</internalNodes>
          <leafValues>
            8.3618402481079102e-01 -3.8318100571632385e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 260 1.6745999455451965e-02</internalNodes>
          <leafValues>
            -2.5733101367950439e-01 2.5966501235961914e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 261 -6.2400000169873238e-03</internalNodes>
          <leafValues>
            3.1631499528884888e-01 -5.8796900510787964e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 262 -3.9397999644279480e-02</internalNodes>
          <leafValues>
            -1.0491210222244263e+00 1.6822400689125061e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 263 0.</internalNodes>
          <leafValues>
            1.6144199669361115e-01 -8.7876898050308228e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 264 -2.2307999432086945e-02</internalNodes>
          <leafValues>
            -6.9053500890731812e-01 2.3607000708580017e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 265 1.8919999711215496e-03</internalNodes>
          <leafValues>
            2.4989199638366699e-01 -5.6583297252655029e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 266 1.0730000212788582e-03</internalNodes>
          <leafValues>
            -5.0415802001953125e-01 3.8374501466751099e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 267 3.9230998605489731e-02</internalNodes>
          <leafValues>
            4.2619001120328903e-02 -1.3875889778137207e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 268 6.2238000333309174e-02</internalNodes>
          <leafValues>
            1.4119400084018707e-01 -1.0688860416412354e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 269 2.1399999968707561e-03</internalNodes>
          <leafValues>
            -8.9622402191162109e-01 1.9796399772167206e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 270 9.1800000518560410e-04</internalNodes>
          <leafValues>
            -4.5337298512458801e-01 4.3532699346542358e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 271 -6.9169998168945312e-03</internalNodes>
          <leafValues>
            3.3822798728942871e-01 -4.4793000817298889e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 272 -2.3866999894380569e-02</internalNodes>
          <leafValues>
            -7.8908598423004150e-01 2.2511799633502960e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 273 -1.0262800008058548e-01</internalNodes>
          <leafValues>
            -2.2831439971923828e+00 -5.3960001096129417e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 274 -9.5239998772740364e-03</internalNodes>
          <leafValues>
            3.9346700906753540e-01 -5.2242201566696167e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 275 3.9877001196146011e-02</internalNodes>
          <leafValues>
            3.2799001783132553e-02 -1.5079489946365356e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 276 -1.3144999742507935e-02</internalNodes>
          <leafValues>
            -1.0839990377426147e+00 1.8482400476932526e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 277 -5.0590999424457550e-02</internalNodes>
          <leafValues>
            -1.8822289705276489e+00 -2.2199999075382948e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 278 2.4917000904679298e-02</internalNodes>
          <leafValues>
            1.4593400061130524e-01 -2.2196519374847412e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 279 -7.6370001770555973e-03</internalNodes>
          <leafValues>
            -1.0164569616317749e+00 5.8797001838684082e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 280 4.2911998927593231e-02</internalNodes>
          <leafValues>
            1.5443000197410583e-01 -1.1843889951705933e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 281 2.3000000510364771e-04</internalNodes>
          <leafValues>
            -7.7305799722671509e-01 1.2189900130033493e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 282 9.0929996222257614e-03</internalNodes>
          <leafValues>
            -1.1450099945068359e-01 7.1091300249099731e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 283 1.1145000346004963e-02</internalNodes>
          <leafValues>
            7.0000998675823212e-02 -1.0534820556640625e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 284 -5.2453000098466873e-02</internalNodes>
          <leafValues>
            -1.7594360113143921e+00 1.9523799419403076e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 285 -2.3020699620246887e-01</internalNodes>
          <leafValues>
            9.5840299129486084e-01 -2.5045698881149292e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 286 -1.6365999355912209e-02</internalNodes>
          <leafValues>
            4.6731901168823242e-01 -2.1108399331569672e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 287 -1.7208000645041466e-02</internalNodes>
          <leafValues>
            7.0835697650909424e-01 -2.8018298745155334e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 288 -3.6648001521825790e-02</internalNodes>
          <leafValues>
            -1.1013339757919312e+00 2.4341100454330444e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 289 -1.0304999537765980e-02</internalNodes>
          <leafValues>
            -1.0933129787445068e+00 5.6258998811244965e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 290 -1.3713000342249870e-02</internalNodes>
          <leafValues>
            -2.6438099145889282e-01 1.9821000099182129e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 291 2.9308000579476357e-02</internalNodes>
          <leafValues>
            -2.2142399847507477e-01 1.0525950193405151e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 292 2.4077000096440315e-02</internalNodes>
          <leafValues>
            1.8485699594020844e-01 -1.7203969955444336e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 293 6.1280000954866409e-03</internalNodes>
          <leafValues>
            -9.2721498012542725e-01 5.8752998709678650e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 294 -2.2377999499440193e-02</internalNodes>
          <leafValues>
            1.9646559953689575e+00 2.7785999700427055e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 295 -7.0440000854432583e-03</internalNodes>
          <leafValues>
            2.1427600085735321e-01 -4.8407599329948425e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 296 -4.0603000670671463e-02</internalNodes>
          <leafValues>
            -1.1754349470138550e+00 1.6061200201511383e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 297 -2.4466000497341156e-02</internalNodes>
          <leafValues>
            -1.1239900588989258e+00 4.1110001504421234e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 298 2.5309999473392963e-03</internalNodes>
          <leafValues>
            -1.7169700562953949e-01 3.2178801298141479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 299 -1.9588999450206757e-02</internalNodes>
          <leafValues>
            8.2720202207565308e-01 -2.6376700401306152e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 300 -2.9635999351739883e-02</internalNodes>
          <leafValues>
            -1.1524770259857178e+00 1.4999300241470337e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 301 -1.5030000358819962e-02</internalNodes>
          <leafValues>
            -1.0491830110549927e+00 4.0160998702049255e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 302 -6.0715001076459885e-02</internalNodes>
          <leafValues>
            -1.0903840065002441e+00 1.5330800414085388e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 303 -1.2790000066161156e-02</internalNodes>
          <leafValues>
            4.2248600721359253e-01 -4.2399200797080994e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 304 -2.0247999578714371e-02</internalNodes>
          <leafValues>
            -9.1866999864578247e-01 1.8485699594020844e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 305 -3.0683999881148338e-02</internalNodes>
          <leafValues>
            -1.5958670377731323e+00 2.5760000571608543e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 306 -2.0718000829219818e-02</internalNodes>
          <leafValues>
            -6.6299998760223389e-01 3.1037199497222900e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 307 -1.7290000105276704e-03</internalNodes>
          <leafValues>
            1.9183400273323059e-01 -6.5084999799728394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 308 -3.1394001096487045e-02</internalNodes>
          <leafValues>
            -6.3643002510070801e-01 1.5408399701118469e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 309 1.9003000110387802e-02</internalNodes>
          <leafValues>
            -1.8919399380683899e-01 1.5294510126113892e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 310 6.1769997701048851e-03</internalNodes>
          <leafValues>
            -1.0597900301218033e-01 6.4859598875045776e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 311 -1.0165999643504620e-02</internalNodes>
          <leafValues>
            -1.0802700519561768e+00 3.7176001816987991e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 312 -1.4169999631121755e-03</internalNodes>
          <leafValues>
            3.4157499670982361e-01 -9.7737997770309448e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 313 -4.0799998678267002e-03</internalNodes>
          <leafValues>
            4.7624599933624268e-01 -3.4366300702095032e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 314 -4.4096998870372772e-02</internalNodes>
          <leafValues>
            9.7634297609329224e-01 -1.9173000007867813e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 315 -6.0669999569654465e-02</internalNodes>
          <leafValues>
            -2.1752851009368896e+00 -2.8925999999046326e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 316 -3.2931998372077942e-02</internalNodes>
          <leafValues>
            -6.4383101463317871e-01 1.6494099795818329e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 317 -1.4722800254821777e-01</internalNodes>
          <leafValues>
            -1.4745830297470093e+00 2.5839998852461576e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 318 -1.1930000036954880e-02</internalNodes>
          <leafValues>
            4.2441400885581970e-01 -1.7712600529193878e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 319 1.4517900347709656e-01</internalNodes>
          <leafValues>
            2.5444999337196350e-02 -1.2779400348663330e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 320 5.1447998732328415e-02</internalNodes>
          <leafValues>
            1.5678399801254272e-01 -1.5188430547714233e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 321 3.1479999888688326e-03</internalNodes>
          <leafValues>
            -4.0424400568008423e-01 3.2429701089859009e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 322 -4.3600000441074371e-02</internalNodes>
          <leafValues>
            -1.9932260513305664e+00 1.5018600225448608e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>83</maxWeakCount>
      <stageThreshold>-3.8424909114837646e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 323 1.2899599969387054e-01</internalNodes>
          <leafValues>
            -6.2161999940872192e-01 1.1116520166397095e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 324 -9.1261997818946838e-02</internalNodes>
          <leafValues>
            1.0143059492111206e+00 -6.1335200071334839e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 325 1.4271999709308147e-02</internalNodes>
          <leafValues>
            -1.0261659622192383e+00 3.9779999852180481e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 326 3.2889999449253082e-02</internalNodes>
          <leafValues>
            -1.1386079788208008e+00 2.8690800070762634e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 327 1.2590000405907631e-02</internalNodes>
          <leafValues>
            -5.6645601987838745e-01 4.5172399282455444e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 328 1.4661000110208988e-02</internalNodes>
          <leafValues>
            3.0505999922752380e-01 -6.8129599094390869e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 329 -3.3555999398231506e-02</internalNodes>
          <leafValues>
            -1.7208939790725708e+00 6.1439000070095062e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 330 1.4252699911594391e-01</internalNodes>
          <leafValues>
            2.3192200064659119e-01 -1.7297149896621704e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 331 -6.2079997733235359e-03</internalNodes>
          <leafValues>
            -1.2163300514221191e+00 1.2160199880599976e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 332 1.8178999423980713e-02</internalNodes>
          <leafValues>
            3.2553699612617493e-01 -8.1003999710083008e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 333 2.5036999955773354e-02</internalNodes>
          <leafValues>
            -3.1698799133300781e-01 6.7361402511596680e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 334 4.6560999006032944e-02</internalNodes>
          <leafValues>
            -1.1089800298213959e-01 8.4082502126693726e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 335 -8.9999996125698090e-03</internalNodes>
          <leafValues>
            3.9574500918388367e-01 -4.7624599933624268e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 336 4.0805999189615250e-02</internalNodes>
          <leafValues>
            -1.8000000272877514e-04 9.4570702314376831e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 337 -3.4221999347209930e-02</internalNodes>
          <leafValues>
            7.5206297636032104e-01 -3.1531500816345215e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 338 -3.9716001600027084e-02</internalNodes>
          <leafValues>
            -8.3139598369598389e-01 1.7744399607181549e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 339 2.5170000735670328e-03</internalNodes>
          <leafValues>
            -5.9377998113632202e-01 2.4657000601291656e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 340 2.7428999543190002e-02</internalNodes>
          <leafValues>
            1.5998399257659912e-01 -4.2781999707221985e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 341 3.4986000508069992e-02</internalNodes>
          <leafValues>
            3.5055998712778091e-02 -1.5988600254058838e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 342 4.4970000162720680e-03</internalNodes>
          <leafValues>
            -5.2034300565719604e-01 3.7828299403190613e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 343 2.7699999045580626e-03</internalNodes>
          <leafValues>
            -5.3182601928710938e-01 2.4951000511646271e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 344 3.5174001008272171e-02</internalNodes>
          <leafValues>
            1.9983400404453278e-01 -1.4446129798889160e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 345 2.5970999151468277e-02</internalNodes>
          <leafValues>
            4.4426999986171722e-02 -1.3622980117797852e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 346 -1.5783999115228653e-02</internalNodes>
          <leafValues>
            -9.1020399332046509e-01 2.7190300822257996e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 347 -7.5880000367760658e-03</internalNodes>
          <leafValues>
            9.2064999043941498e-02 -8.1628900766372681e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 348 2.0754000172019005e-02</internalNodes>
          <leafValues>
            2.1185700595378876e-01 -7.4729001522064209e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 349 5.9829000383615494e-02</internalNodes>
          <leafValues>
            -2.7301099896430969e-01 8.0923300981521606e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 350 3.9039000868797302e-02</internalNodes>
          <leafValues>
            -1.0432299971580505e-01 8.6226201057434082e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 351 2.1665999665856361e-02</internalNodes>
          <leafValues>
            6.2709003686904907e-02 -9.8894298076629639e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 352 -2.7496999129652977e-02</internalNodes>
          <leafValues>
            -9.2690998315811157e-01 1.5586300194263458e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 353 1.0462000034749508e-02</internalNodes>
          <leafValues>
            1.3418099284172058e-01 -7.0386397838592529e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 354 2.4870999157428741e-02</internalNodes>
          <leafValues>
            1.9706700742244720e-01 -4.0263301134109497e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 355 -1.6036000102758408e-02</internalNodes>
          <leafValues>
            -1.1409829854965210e+00 7.3997996747493744e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 356 4.8627000302076340e-02</internalNodes>
          <leafValues>
            1.6990399360656738e-01 -7.2152197360992432e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 357 1.2619999470189214e-03</internalNodes>
          <leafValues>
            -4.7389799356460571e-01 2.6254999637603760e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 358 -8.8035002350807190e-02</internalNodes>
          <leafValues>
            -2.1606519222259521e+00 1.4554800093173981e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 359 1.8356999382376671e-02</internalNodes>
          <leafValues>
            4.4750999659299850e-02 -1.0766370296478271e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 360 3.5275001078844070e-02</internalNodes>
          <leafValues>
            -3.2919000834226608e-02 1.2153890132904053e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 361 -2.0392900705337524e-01</internalNodes>
          <leafValues>
            -1.3187999725341797e+00 1.5503999777138233e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 362 -1.6619000583887100e-02</internalNodes>
          <leafValues>
            3.6850199103355408e-01 -1.5283699333667755e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 363 3.7739001214504242e-02</internalNodes>
          <leafValues>
            -2.5727799534797668e-01 7.0655298233032227e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 364 2.2720000706613064e-03</internalNodes>
          <leafValues>
            -7.7602997422218323e-02 3.3367800712585449e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 365 -1.4802999794483185e-02</internalNodes>
          <leafValues>
            -7.8524798154830933e-01 7.6934002339839935e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 366 -4.8319000750780106e-02</internalNodes>
          <leafValues>
            1.7022320032119751e+00 4.9722000956535339e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 367 -2.9539000242948532e-02</internalNodes>
          <leafValues>
            7.7670699357986450e-01 -2.4534299969673157e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 368 -4.6169001609086990e-02</internalNodes>
          <leafValues>
            -1.4922779798507690e+00 1.2340000271797180e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 369 -2.8064999729394913e-02</internalNodes>
          <leafValues>
            -2.1345369815826416e+00 -2.5797000154852867e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 370 -5.7339998893439770e-03</internalNodes>
          <leafValues>
            5.6982600688934326e-01 -1.2056600302457809e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 371 -1.0111000388860703e-02</internalNodes>
          <leafValues>
            6.7911398410797119e-01 -2.6638001203536987e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 372 1.1359999887645245e-02</internalNodes>
          <leafValues>
            2.4789799749851227e-01 -6.4493000507354736e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 373 5.1809001713991165e-02</internalNodes>
          <leafValues>
            1.4716000296175480e-02 -1.2395579814910889e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 374 3.3291999250650406e-02</internalNodes>
          <leafValues>
            -8.2559995353221893e-03 1.0168470144271851e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 375 -1.4494000002741814e-02</internalNodes>
          <leafValues>
            4.5066800713539124e-01 -3.6250999569892883e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 376 -3.4221999347209930e-02</internalNodes>
          <leafValues>
            -9.5292502641677856e-01 2.0684599876403809e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 377 -8.0654002726078033e-02</internalNodes>
          <leafValues>
            -2.0139501094818115e+00 -2.3084999993443489e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 378 -8.9399999706074595e-04</internalNodes>
          <leafValues>
            3.9572000503540039e-01 -2.9351300001144409e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 379 9.7162000834941864e-02</internalNodes>
          <leafValues>
            -2.4980300664901733e-01 1.0859220027923584e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 380 3.6614000797271729e-02</internalNodes>
          <leafValues>
            -5.7844001799821854e-02 1.2162159681320190e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 381 5.1693998277187347e-02</internalNodes>
          <leafValues>
            4.3062999844551086e-02 -1.0636160373687744e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 382 -2.4557000026106834e-02</internalNodes>
          <leafValues>
            -4.8946800827980042e-01 1.7182900011539459e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 383 3.2736799120903015e-01</internalNodes>
          <leafValues>
            -2.9688599705696106e-01 5.1798301935195923e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 384 7.6959999278187752e-03</internalNodes>
          <leafValues>
            -5.9805899858474731e-01 2.4803200364112854e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 385 1.6172200441360474e-01</internalNodes>
          <leafValues>
            -2.9613999649882317e-02 -2.3162529468536377e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 386 -4.7889999113976955e-03</internalNodes>
          <leafValues>
            3.7457901239395142e-01 -3.2779198884963989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 387 -1.8402999266982079e-02</internalNodes>
          <leafValues>
            -9.9692702293395996e-01 7.2948001325130463e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 388 7.7665001153945923e-02</internalNodes>
          <leafValues>
            1.4175699651241302e-01 -1.7238730192184448e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 389 1.8921000882983208e-02</internalNodes>
          <leafValues>
            -2.1273100376129150e-01 1.0165189504623413e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 390 -7.9397998750209808e-02</internalNodes>
          <leafValues>
            -1.3164349794387817e+00 1.4981999993324280e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 391 -6.8037003278732300e-02</internalNodes>
          <leafValues>
            4.9421998858451843e-01 -2.9091000556945801e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 392 -6.1010001227259636e-03</internalNodes>
          <leafValues>
            4.2430499196052551e-01 -3.3899301290512085e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 393 3.1927000731229782e-02</internalNodes>
          <leafValues>
            -3.1046999618411064e-02 -2.3459999561309814e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 394 -2.9843999072909355e-02</internalNodes>
          <leafValues>
            -7.8989601135253906e-01 1.5417699515819550e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 395 -8.0541998147964478e-02</internalNodes>
          <leafValues>
            -2.2509229183197021e+00 -3.0906999483704567e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 396 3.8109999150037766e-03</internalNodes>
          <leafValues>
            -2.5577300786972046e-01 2.3785500228404999e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 397 3.3647000789642334e-02</internalNodes>
          <leafValues>
            -2.2541399300098419e-01 9.2307400703430176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 398 8.2809999585151672e-03</internalNodes>
          <leafValues>
            -2.8896200656890869e-01 3.1046199798583984e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 399 1.0104399919509888e-01</internalNodes>
          <leafValues>
            -3.4864000976085663e-02 -2.7102620601654053e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 400 -1.0009000077843666e-02</internalNodes>
          <leafValues>
            5.9715402126312256e-01 -3.3831000328063965e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 401 7.1919998154044151e-03</internalNodes>
          <leafValues>
            -4.7738000750541687e-01 2.2686000168323517e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 402 2.4969000369310379e-02</internalNodes>
          <leafValues>
            2.2877700626850128e-01 -1.0435529947280884e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 403 2.7908000349998474e-01</internalNodes>
          <leafValues>
            -2.5818100571632385e-01 7.6780498027801514e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 404 -4.4213000684976578e-02</internalNodes>
          <leafValues>
            -5.9798002243041992e-01 2.8039899468421936e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 405 -1.4136999845504761e-02</internalNodes>
          <leafValues>
            7.0987302064895630e-01 -2.5645199418067932e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>91</maxWeakCount>
      <stageThreshold>-3.6478610038757324e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 406 1.3771200180053711e-01</internalNodes>
          <leafValues>
            -5.5870598554611206e-01 1.0953769683837891e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 407 3.4460999071598053e-02</internalNodes>
          <leafValues>
            -7.1171897649765015e-01 5.2899599075317383e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 408 1.8580000847578049e-02</internalNodes>
          <leafValues>
            -1.1157519817352295e+00 4.0593999624252319e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 409 2.5041999295353889e-02</internalNodes>
          <leafValues>
            -4.0892499685287476e-01 7.4129998683929443e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 410 5.7179000228643417e-02</internalNodes>
          <leafValues>
            -3.8054299354553223e-01 7.3647701740264893e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 411 1.4932000078260899e-02</internalNodes>
          <leafValues>
            -6.9945502281188965e-01 3.7950998544692993e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 412 8.8900001719594002e-03</internalNodes>
          <leafValues>
            -5.4558598995208740e-01 3.6332499980926514e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 413 3.0435999855399132e-02</internalNodes>
          <leafValues>
            -1.0124599933624268e-01 7.9585897922515869e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 414 -4.4160000979900360e-02</internalNodes>
          <leafValues>
            8.4410899877548218e-01 -3.2976400852203369e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 415 1.8461000174283981e-02</internalNodes>
          <leafValues>
            2.6326599717140198e-01 -9.6736502647399902e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 416 1.0614999569952488e-02</internalNodes>
          <leafValues>
            1.5251900255680084e-01 -1.0589870214462280e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 417 -4.5974001288414001e-02</internalNodes>
          <leafValues>
            -1.9918340444564819e+00 1.3629099726676941e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 418 8.2900002598762512e-02</internalNodes>
          <leafValues>
            -3.2037198543548584e-01 6.0304200649261475e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 419 -8.9130001142621040e-03</internalNodes>
          <leafValues>
            5.9586602449417114e-01 -2.1139599382877350e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 420 4.2814001441001892e-02</internalNodes>
          <leafValues>
            2.2925000637769699e-02 -1.4679330587387085e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 421 -8.7139997631311417e-03</internalNodes>
          <leafValues>
            -4.3989500403404236e-01 2.0439699292182922e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 422 -4.3390002101659775e-03</internalNodes>
          <leafValues>
            -8.9066797494888306e-01 1.0469999909400940e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 423 8.0749997869133949e-03</internalNodes>
          <leafValues>
            2.1164199709892273e-01 -4.0231600403785706e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 424 9.6739001572132111e-02</internalNodes>
          <leafValues>
            1.3319999910891056e-02 -1.6085360050201416e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 425 -3.0536999925971031e-02</internalNodes>
          <leafValues>
            1.0063740015029907e+00 -1.3413299620151520e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 426 -6.0855999588966370e-02</internalNodes>
          <leafValues>
            -1.4689979553222656e+00 9.4240000471472740e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 427 -3.8162000477313995e-02</internalNodes>
          <leafValues>
            -8.1636399030685425e-01 2.6171201467514038e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 428 -9.6960002556443214e-03</internalNodes>
          <leafValues>
            1.1561699956655502e-01 -7.1693199872970581e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 429 4.8902999609708786e-02</internalNodes>
          <leafValues>
            1.3050499558448792e-01 -1.6448370218276978e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 430 -4.1611999273300171e-02</internalNodes>
          <leafValues>
            -1.1795840263366699e+00 2.5017000734806061e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 431 -2.0188000053167343e-02</internalNodes>
          <leafValues>
            6.3188201189041138e-01 -1.0490400344133377e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 432 -9.7900000400841236e-04</internalNodes>
          <leafValues>
            1.8507799506187439e-01 -5.3565901517868042e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 433 -3.3622000366449356e-02</internalNodes>
          <leafValues>
            -9.3127602338790894e-01 2.0071500539779663e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 434 1.9455999135971069e-02</internalNodes>
          <leafValues>
            3.8029000163078308e-02 -1.0112210512161255e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 435 -3.1800000579096377e-04</internalNodes>
          <leafValues>
            3.6457699537277222e-01 -2.7610900998115540e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 436 -3.8899999344721437e-04</internalNodes>
          <leafValues>
            1.9665899872779846e-01 -5.3410500288009644e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 437 -9.3496002256870270e-02</internalNodes>
          <leafValues>
            -1.6772350072860718e+00 2.0727099478244781e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 438 -7.7877998352050781e-02</internalNodes>
          <leafValues>
            -3.0760629177093506e+00 -3.5803999751806259e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 439 1.6947999596595764e-02</internalNodes>
          <leafValues>
            2.1447399258613586e-01 -7.1376299858093262e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 440 -2.1459000185132027e-02</internalNodes>
          <leafValues>
            -1.1468060016632080e+00 1.5855999663472176e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 441 -1.2865999713540077e-02</internalNodes>
          <leafValues>
            8.3812397718429565e-01 -6.5944001078605652e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 442 7.8220004215836525e-03</internalNodes>
          <leafValues>
            -2.8026801347732544e-01 7.9376900196075439e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 443 1.0294400155544281e-01</internalNodes>
          <leafValues>
            1.7832300066947937e-01 -6.8412202596664429e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 444 -3.7487998604774475e-02</internalNodes>
          <leafValues>
            9.6189999580383301e-01 -2.1735599637031555e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 445 2.5505999103188515e-02</internalNodes>
          <leafValues>
            1.0103999637067318e-02 1.2461110353469849e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 446 6.6700001480057836e-04</internalNodes>
          <leafValues>
            -5.3488200902938843e-01 1.4746299386024475e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 447 -2.8867900371551514e-01</internalNodes>
          <leafValues>
            8.2172799110412598e-01 -1.4948000200092793e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 448 9.1294996440410614e-02</internalNodes>
          <leafValues>
            -1.9605399668216705e-01 1.0803170204162598e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 449 1.2056600302457809e-01</internalNodes>
          <leafValues>
            -2.3848999291658401e-02 1.1392610073089600e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 450 -7.3775000870227814e-02</internalNodes>
          <leafValues>
            -1.3583840131759644e+00 -4.2039998807013035e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 451 -3.3128000795841217e-02</internalNodes>
          <leafValues>
            -6.4483201503753662e-01 2.4142199754714966e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 452 -4.3937001377344131e-02</internalNodes>
          <leafValues>
            8.4285402297973633e-01 -2.0624800026416779e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 453 1.8110199272632599e-01</internalNodes>
          <leafValues>
            1.9212099909782410e-01 -1.2222139835357666e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 454 -1.1850999668240547e-02</internalNodes>
          <leafValues>
            -7.2677397727966309e-01 5.2687998861074448e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 455 4.5920000411570072e-03</internalNodes>
          <leafValues>
            -3.6305201053619385e-01 2.9223799705505371e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 456 7.0620002225041389e-03</internalNodes>
          <leafValues>
            5.8116000145673752e-02 -6.7161601781845093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 457 -2.3715000599622726e-02</internalNodes>
          <leafValues>
            4.7142100334167480e-01 1.8580000847578049e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 458 -6.7171998322010040e-02</internalNodes>
          <leafValues>
            -1.1331889629364014e+00 2.3780999705195427e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 459 -6.5310001373291016e-02</internalNodes>
          <leafValues>
            9.8253500461578369e-01 2.8362000361084938e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 460 2.2791000083088875e-02</internalNodes>
          <leafValues>
            -2.8213700652122498e-01 5.8993399143218994e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 461 -1.9037999212741852e-02</internalNodes>
          <leafValues>
            -6.3711500167846680e-01 2.6514598727226257e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 462 -6.8689999170601368e-03</internalNodes>
          <leafValues>
            3.7487301230430603e-01 -3.3232098817825317e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 463 -4.0146000683307648e-02</internalNodes>
          <leafValues>
            -1.3048729896545410e+00 1.5724299848079681e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 464 -4.0530998259782791e-02</internalNodes>
          <leafValues>
            -2.0458049774169922e+00 -2.6925999671220779e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 465 -1.2253999710083008e-02</internalNodes>
          <leafValues>
            7.7649402618408203e-01 -4.2971000075340271e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 466 -2.7219999581575394e-02</internalNodes>
          <leafValues>
            1.7424400150775909e-01 -4.4600901007652283e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 467 -8.8366001844406128e-02</internalNodes>
          <leafValues>
            -1.5036419630050659e+00 1.4289900660514832e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 468 -7.9159997403621674e-03</internalNodes>
          <leafValues>
            2.8666698932647705e-01 -3.7923699617385864e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 469 -4.1960000991821289e-02</internalNodes>
          <leafValues>
            1.3846950531005859e+00 6.5026998519897461e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 470 4.5662999153137207e-02</internalNodes>
          <leafValues>
            -2.2452299296855927e-01 7.9521000385284424e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 471 -1.4090600609779358e-01</internalNodes>
          <leafValues>
            -1.5879319906234741e+00 1.1359000205993652e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 472 -5.9216000139713287e-02</internalNodes>
          <leafValues>
            -1.1945960521697998e+00 -7.1640000678598881e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 473 4.3390002101659775e-03</internalNodes>
          <leafValues>
            -1.5528699755668640e-01 4.0664499998092651e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 474 -2.0369999110698700e-03</internalNodes>
          <leafValues>
            2.5927901268005371e-01 -3.8368299603462219e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 475 2.7516499161720276e-01</internalNodes>
          <leafValues>
            -8.8497996330261230e-02 7.6787501573562622e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 476 -2.6601999998092651e-02</internalNodes>
          <leafValues>
            7.5024497509002686e-01 -2.2621999680995941e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 477 4.0906000882387161e-02</internalNodes>
          <leafValues>
            1.2158600240945816e-01 -1.4566910266876221e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 478 5.5320002138614655e-03</internalNodes>
          <leafValues>
            -3.6611500382423401e-01 2.5968599319458008e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 479 3.1879000365734100e-02</internalNodes>
          <leafValues>
            -7.5019001960754395e-02 4.8484799265861511e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 480 -4.1482001543045044e-02</internalNodes>
          <leafValues>
            7.8220397233963013e-01 -2.1992200613021851e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 481 -9.6130996942520142e-02</internalNodes>
          <leafValues>
            -8.9456301927566528e-01 1.4680700004100800e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 482 -1.1568999849259853e-02</internalNodes>
          <leafValues>
            8.2714098691940308e-01 -2.0275600254535675e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 483 1.8312999978661537e-02</internalNodes>
          <leafValues>
            1.6367999836802483e-02 2.7306801080703735e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 484 -3.4166000783443451e-02</internalNodes>
          <leafValues>
            1.1307320594787598e+00 -1.8810899555683136e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 485 -2.4476999416947365e-02</internalNodes>
          <leafValues>
            -5.7791298627853394e-01 1.5812499821186066e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 486 4.8957001417875290e-02</internalNodes>
          <leafValues>
            -2.2564999759197235e-02 -1.6373280286788940e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 487 -2.0702999085187912e-02</internalNodes>
          <leafValues>
            -5.4512101411819458e-01 2.4086999893188477e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 488 -2.3002000525593758e-02</internalNodes>
          <leafValues>
            -1.2236540317535400e+00 -7.3440000414848328e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 489 6.4585000276565552e-02</internalNodes>
          <leafValues>
            1.4695599675178528e-01 -4.4967499375343323e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 490 1.2666000053286552e-02</internalNodes>
          <leafValues>
            -2.7873900532722473e-01 4.3876600265502930e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 491 -1.2002999894320965e-02</internalNodes>
          <leafValues>
            -2.4289099872112274e-01 2.5350099802017212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 492 -2.6443999260663986e-02</internalNodes>
          <leafValues>
            -8.5864800214767456e-01 2.6025999337434769e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 493 -2.5547999888658524e-02</internalNodes>
          <leafValues>
            6.9287902116775513e-01 -2.1160000469535589e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 494 3.9115000516176224e-02</internalNodes>
          <leafValues>
            -1.6589100658893585e-01 1.5209139585494995e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 495 -6.0330000706017017e-03</internalNodes>
          <leafValues>
            4.3856900930404663e-01 -2.1613700687885284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 496 -3.3936999738216400e-02</internalNodes>
          <leafValues>
            -9.7998398542404175e-01 2.2133000195026398e-02</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>99</maxWeakCount>
      <stageThreshold>-3.8700489997863770e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 497 4.0672998875379562e-02</internalNodes>
          <leafValues>
            -9.0474700927734375e-01 6.4410597085952759e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 498 2.5609999895095825e-02</internalNodes>
          <leafValues>
            -7.9216998815536499e-01 5.7489997148513794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 499 1.9959500432014465e-01</internalNodes>
          <leafValues>
            -3.0099600553512573e-01 1.3143850564956665e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 500 1.2404999695718288e-02</internalNodes>
          <leafValues>
            -8.9882999658584595e-01 2.9205799102783203e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 501 3.9207998663187027e-02</internalNodes>
          <leafValues>
            -4.1955199837684631e-01 5.3463298082351685e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 502 -3.0843999236822128e-02</internalNodes>
          <leafValues>
            4.5793399214744568e-01 -4.4629099965095520e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 503 -3.5523001104593277e-02</internalNodes>
          <leafValues>
            9.1310501098632812e-01 -2.7373200654983521e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 504 -6.1650000512599945e-02</internalNodes>
          <leafValues>
            -1.4697799682617188e+00 2.0364099740982056e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 505 -1.1739999987185001e-02</internalNodes>
          <leafValues>
            -1.0482879877090454e+00 6.7801997065544128e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 506 6.6933996975421906e-02</internalNodes>
          <leafValues>
            2.9274499416351318e-01 -5.2282899618148804e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 507 -2.0631000399589539e-02</internalNodes>
          <leafValues>
            -1.2855139970779419e+00 4.4550999999046326e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 508 -2.2357000038027763e-02</internalNodes>
          <leafValues>
            -8.5753798484802246e-01 1.8434000015258789e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 509 1.1500000255182385e-03</internalNodes>
          <leafValues>
            1.6405500471591949e-01 -6.9125002622604370e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 510 3.5872999578714371e-02</internalNodes>
          <leafValues>
            1.5756499767303467e-01 -8.4262597560882568e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 511 3.0659999698400497e-02</internalNodes>
          <leafValues>
            2.1637000143527985e-02 -1.3634690046310425e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 512 5.5559999309480190e-03</internalNodes>
          <leafValues>
            -1.6737000644207001e-01 2.5888401269912720e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 513 -6.1160000041127205e-03</internalNodes>
          <leafValues>
            -9.7271800041198730e-01 6.6100001335144043e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 514 -3.0316999182105064e-02</internalNodes>
          <leafValues>
            9.8474198579788208e-01 -1.6448000445961952e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 515 -9.7200004383921623e-03</internalNodes>
          <leafValues>
            4.7604700922966003e-01 -3.2516700029373169e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 516 -5.7126998901367188e-02</internalNodes>
          <leafValues>
            -9.5920699834823608e-01 1.9938200712203979e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 517 4.0059997700154781e-03</internalNodes>
          <leafValues>
            -5.2612501382827759e-01 2.2428700327873230e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 518 3.3734001219272614e-02</internalNodes>
          <leafValues>
            1.7070099711418152e-01 -1.0737580060958862e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 519 -3.4641999751329422e-02</internalNodes>
          <leafValues>
            -1.1343129873275757e+00 3.6540001630783081e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 520 4.6923000365495682e-02</internalNodes>
          <leafValues>
            2.5832301378250122e-01 -7.1535801887512207e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 521 -8.7660001590847969e-03</internalNodes>
          <leafValues>
            1.9640900194644928e-01 -5.3355097770690918e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 522 6.5627999603748322e-02</internalNodes>
          <leafValues>
            -5.1194999366998672e-02 9.7610700130462646e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 523 -4.4165000319480896e-02</internalNodes>
          <leafValues>
            1.0631920099258423e+00 -2.3462599515914917e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 524 1.7304999753832817e-02</internalNodes>
          <leafValues>
            -1.8582899868488312e-01 4.5889899134635925e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 525 3.3135998994112015e-02</internalNodes>
          <leafValues>
            -2.9381999745965004e-02 -2.6651329994201660e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 526 -2.1029999479651451e-02</internalNodes>
          <leafValues>
            9.9979901313781738e-01 2.4937000125646591e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 527 2.9783999547362328e-02</internalNodes>
          <leafValues>
            -2.9605999588966370e-02 -2.1695868968963623e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 528 5.5291999131441116e-02</internalNodes>
          <leafValues>
            -7.5599999399855733e-04 7.4651998281478882e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 529 -3.3597998321056366e-02</internalNodes>
          <leafValues>
            -1.5274159908294678e+00 1.1060000397264957e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 530 1.9602999091148376e-02</internalNodes>
          <leafValues>
            3.3574998378753662e-02 9.9526202678680420e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 531 -2.0787000656127930e-02</internalNodes>
          <leafValues>
            7.6612901687622070e-01 -2.4670800566673279e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 532 3.2536000013351440e-02</internalNodes>
          <leafValues>
            1.6263400018215179e-01 -6.1134302616119385e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 533 -1.0788000188767910e-02</internalNodes>
          <leafValues>
            -9.7839701175689697e-01 2.8969999402761459e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 534 -9.9560003727674484e-03</internalNodes>
          <leafValues>
            4.6145799756050110e-01 -1.3510499894618988e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 535 -3.7489999085664749e-03</internalNodes>
          <leafValues>
            2.5458198785781860e-01 -5.1955598592758179e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 536 -4.1779998689889908e-02</internalNodes>
          <leafValues>
            -8.0565100908279419e-01 1.5208500623703003e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 537 -3.4221000969409943e-02</internalNodes>
          <leafValues>
            -1.3137799501419067e+00 -3.5800000187009573e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 538 1.0130000300705433e-02</internalNodes>
          <leafValues>
            2.0175799727439880e-01 -6.1339598894119263e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 539 -8.9849002659320831e-02</internalNodes>
          <leafValues>
            9.7632801532745361e-01 -2.0884799957275391e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 540 2.6097999885678291e-02</internalNodes>
          <leafValues>
            -1.8807999789714813e-01 4.7705799341201782e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 541 -3.7539999466389418e-03</internalNodes>
          <leafValues>
            -6.7980402708053589e-01 1.1288800090551376e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 542 3.1973000615835190e-02</internalNodes>
          <leafValues>
            1.8951700627803802e-01 -1.4967479705810547e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 543 1.9332999363541603e-02</internalNodes>
          <leafValues>
            -2.3609900474548340e-01 8.1320500373840332e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 544 1.9490000559017062e-03</internalNodes>
          <leafValues>
            2.4830399453639984e-01 -6.9211997091770172e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 545 -4.4146999716758728e-02</internalNodes>
          <leafValues>
            -1.0418920516967773e+00 4.8053000122308731e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 546 -4.4681999832391739e-02</internalNodes>
          <leafValues>
            5.1346302032470703e-01 -7.3799998499453068e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 547 -1.0757499933242798e-01</internalNodes>
          <leafValues>
            1.6202019453048706e+00 -1.8667599558830261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 548 -1.2846800684928894e-01</internalNodes>
          <leafValues>
            2.9869480133056641e+00 9.5427997410297394e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 549 -4.4757999479770660e-02</internalNodes>
          <leafValues>
            6.0405302047729492e-01 -2.7058699727058411e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 550 -4.3990999460220337e-02</internalNodes>
          <leafValues>
            -6.1790502071380615e-01 1.5997199714183807e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 551 -1.2268999963998795e-01</internalNodes>
          <leafValues>
            6.6327202320098877e-01 -2.3636999726295471e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 552 -1.9982999190688133e-02</internalNodes>
          <leafValues>
            -1.1228660345077515e+00 1.9616700708866119e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 553 -1.5527999959886074e-02</internalNodes>
          <leafValues>
            -1.0770269632339478e+00 2.0693000406026840e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 554 -4.8971001058816910e-02</internalNodes>
          <leafValues>
            8.1168299913406372e-01 -1.7252000048756599e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 555 5.5975999683141708e-02</internalNodes>
          <leafValues>
            -2.2529000416398048e-02 -1.7356760501861572e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 556 -9.8580000922083855e-03</internalNodes>
          <leafValues>
            6.7881399393081665e-01 -5.8180000633001328e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 557 1.3481000438332558e-02</internalNodes>
          <leafValues>
            5.7847999036312103e-02 -7.7255302667617798e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 558 6.5609999001026154e-03</internalNodes>
          <leafValues>
            -1.3146899640560150e-01 6.7055797576904297e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 559 7.1149999275803566e-03</internalNodes>
          <leafValues>
            -3.7880599498748779e-01 3.0978998541831970e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 560 4.8159998841583729e-03</internalNodes>
          <leafValues>
            -5.8470398187637329e-01 2.5602099299430847e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 561 9.5319999381899834e-03</internalNodes>
          <leafValues>
            -3.0217000842094421e-01 4.1253298521041870e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 562 -2.7474999427795410e-02</internalNodes>
          <leafValues>
            5.9154701232910156e-01 1.7963999882340431e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 563 -3.9519999176263809e-02</internalNodes>
          <leafValues>
            9.6913498640060425e-01 -2.1020300686359406e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 564 -3.0658999457955360e-02</internalNodes>
          <leafValues>
            9.1155898571014404e-01 4.0550000965595245e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 565 -1.4680000022053719e-03</internalNodes>
          <leafValues>
            -6.0489797592163086e-01 1.6960899531841278e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 566 1.9077600538730621e-01</internalNodes>
          <leafValues>
            4.3515000492334366e-02 8.1892901659011841e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 567 5.1790000870823860e-03</internalNodes>
          <leafValues>
            -9.3617302179336548e-01 2.4937000125646591e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 568 2.4126000702381134e-02</internalNodes>
          <leafValues>
            1.8175500631332397e-01 -3.4185901284217834e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 569 -2.6383999735116959e-02</internalNodes>
          <leafValues>
            -1.2912579774856567e+00 -3.4280000254511833e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 570 5.4139997810125351e-03</internalNodes>
          <leafValues>
            -4.6291999518871307e-02 2.5269600749015808e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 571 5.4216001182794571e-02</internalNodes>
          <leafValues>
            -1.2848000042140484e-02 -1.4304540157318115e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 572 2.3799999326001853e-04</internalNodes>
          <leafValues>
            -2.6676699519157410e-01 3.3588299155235291e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 573 1.5216999687254429e-02</internalNodes>
          <leafValues>
            -5.1367300748825073e-01 1.3005100190639496e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 574 1.7007999122142792e-02</internalNodes>
          <leafValues>
            4.1575899720191956e-01 -3.1241199374198914e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 575 3.0496999621391296e-02</internalNodes>
          <leafValues>
            -2.4820999801158905e-01 7.0828497409820557e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 576 6.5430002287030220e-03</internalNodes>
          <leafValues>
            -2.2637000679969788e-01 1.9184599816799164e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 577 1.4163999259471893e-01</internalNodes>
          <leafValues>
            6.5227001905441284e-02 -8.8809502124786377e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 578 1.9338000565767288e-02</internalNodes>
          <leafValues>
            1.8891200423240662e-01 -2.7397701144218445e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 579 -1.7324000597000122e-02</internalNodes>
          <leafValues>
            -9.4866698980331421e-01 2.4196999147534370e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 580 -6.2069999985396862e-03</internalNodes>
          <leafValues>
            3.6938399076461792e-01 -1.7494900524616241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 581 -1.6109000891447067e-02</internalNodes>
          <leafValues>
            9.6159499883651733e-01 -2.0005300641059875e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 582 -1.0122500360012054e-01</internalNodes>
          <leafValues>
            -3.0699110031127930e+00 1.1363799870014191e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 583 -7.5509999878704548e-03</internalNodes>
          <leafValues>
            2.2921000421047211e-01 -4.5645099878311157e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 584 4.4247999787330627e-02</internalNodes>
          <leafValues>
            -3.1599999056197703e-04 3.9225301146507263e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 585 -1.1636000126600266e-01</internalNodes>
          <leafValues>
            9.5233702659606934e-01 -2.0201599597930908e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 586 4.7360002063214779e-03</internalNodes>
          <leafValues>
            -9.9177002906799316e-02 2.0370499789714813e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 587 2.2459000349044800e-02</internalNodes>
          <leafValues>
            8.7280003353953362e-03 -1.0217070579528809e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 588 -1.2109000235795975e-02</internalNodes>
          <leafValues>
            6.4812600612640381e-01 -9.0149000287055969e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 589 5.6120000779628754e-02</internalNodes>
          <leafValues>
            -3.6759998649358749e-02 -1.9275590181350708e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 590 -8.7379999458789825e-03</internalNodes>
          <leafValues>
            6.9261300563812256e-01 -6.8374998867511749e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 591 6.6399998031556606e-03</internalNodes>
          <leafValues>
            -4.0569800138473511e-01 1.8625700473785400e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 592 -1.8131999298930168e-02</internalNodes>
          <leafValues>
            -6.4518201351165771e-01 2.1976399421691895e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 593 -2.2718999534845352e-02</internalNodes>
          <leafValues>
            9.7776198387145996e-01 -1.8654300272464752e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 594 1.2705000117421150e-02</internalNodes>
          <leafValues>
            -1.0546600073575974e-01 3.7404099106788635e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 595 -1.3682999648153782e-02</internalNodes>
          <leafValues>
            6.1064100265502930e-01 -2.6881098747253418e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>115</maxWeakCount>
      <stageThreshold>-3.7160909175872803e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 596 3.1357999891042709e-02</internalNodes>
          <leafValues>
            -1.0183910131454468e+00 5.7528597116470337e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 597 9.3050003051757812e-02</internalNodes>
          <leafValues>
            -4.1297501325607300e-01 1.0091199874877930e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 598 2.5949999690055847e-02</internalNodes>
          <leafValues>
            -5.8587902784347534e-01 5.6606197357177734e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 599 1.6472000628709793e-02</internalNodes>
          <leafValues>
            -9.2857497930526733e-01 3.0924499034881592e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 600 -1.8779999809339643e-03</internalNodes>
          <leafValues>
            1.1951000243425369e-01 -1.1180130243301392e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 601 -9.0129999443888664e-03</internalNodes>
          <leafValues>
            -5.7849502563476562e-01 3.3154401183128357e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 602 2.2547999396920204e-02</internalNodes>
          <leafValues>
            -3.8325101137161255e-01 5.2462202310562134e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 603 -3.7780001759529114e-02</internalNodes>
          <leafValues>
            1.1790670156478882e+00 -3.4166999161243439e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 604 -5.3799999877810478e-03</internalNodes>
          <leafValues>
            -8.6265897750854492e-01 1.1867900192737579e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 605 -2.3893000558018684e-02</internalNodes>
          <leafValues>
            -7.4950599670410156e-01 2.1011400222778320e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 606 -2.6521999388933182e-02</internalNodes>
          <leafValues>
            9.2128598690032959e-01 -2.8252801299095154e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 607 1.2280000373721123e-02</internalNodes>
          <leafValues>
            2.6662799715995789e-01 -7.0013600587844849e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 608 9.6594996750354767e-02</internalNodes>
          <leafValues>
            -2.8453999757766724e-01 7.3168998956680298e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 609 -2.7414999902248383e-02</internalNodes>
          <leafValues>
            -6.1492699384689331e-01 1.5576200187206268e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 610 -1.5767000615596771e-02</internalNodes>
          <leafValues>
            5.7551199197769165e-01 -3.4362199902534485e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 611 -2.1100000012665987e-03</internalNodes>
          <leafValues>
            3.2599699497222900e-01 -1.3008299469947815e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 612 1.2006999924778938e-02</internalNodes>
          <leafValues>
            8.9322999119758606e-02 -9.6025598049163818e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 613 -1.5421999618411064e-02</internalNodes>
          <leafValues>
            3.4449499845504761e-01 -4.6711999177932739e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 614 -4.1579999960958958e-03</internalNodes>
          <leafValues>
            2.3696300387382507e-01 -5.2563297748565674e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 615 -2.1185999736189842e-02</internalNodes>
          <leafValues>
            -7.4267697334289551e-01 2.1702000498771667e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 616 -1.7077000811696053e-02</internalNodes>
          <leafValues>
            -9.0471798181533813e-01 6.6012002527713776e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 617 -4.0849998593330383e-02</internalNodes>
          <leafValues>
            -3.4446600079536438e-01 2.1503700315952301e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 618 -8.1930002197623253e-03</internalNodes>
          <leafValues>
            -9.3388599157333374e-01 5.0471000373363495e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 619 -1.9238000735640526e-02</internalNodes>
          <leafValues>
            -5.3203701972961426e-01 1.7240600287914276e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 620 -4.4192001223564148e-02</internalNodes>
          <leafValues>
            9.2075002193450928e-01 -2.2148500382900238e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 621 -6.2392000108957291e-02</internalNodes>
          <leafValues>
            -7.1053802967071533e-01 1.8323899805545807e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 622 -1.0079999919980764e-03</internalNodes>
          <leafValues>
            -8.7063097953796387e-01 5.5330000817775726e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 623 2.3870000615715981e-02</internalNodes>
          <leafValues>
            -2.2854200005531311e-01 5.2415597438812256e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 624 2.1391000598669052e-02</internalNodes>
          <leafValues>
            -3.0325898528099060e-01 5.5860602855682373e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 625 2.0254999399185181e-02</internalNodes>
          <leafValues>
            2.6901501417160034e-01 -7.0261800289154053e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 626 -2.8772000223398209e-02</internalNodes>
          <leafValues>
            -1.1835030317306519e+00 4.6512000262737274e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 627 3.4199999645352364e-03</internalNodes>
          <leafValues>
            -5.4652100801467896e-01 2.5962498784065247e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 628 5.6983001530170441e-02</internalNodes>
          <leafValues>
            -2.6982900500297546e-01 5.8170700073242188e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 629 -9.3892000615596771e-02</internalNodes>
          <leafValues>
            -9.1046398878097534e-01 1.9677700102329254e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 630 1.7699999734759331e-02</internalNodes>
          <leafValues>
            -4.4003298878669739e-01 2.1349500119686127e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 631 2.2844199836254120e-01</internalNodes>
          <leafValues>
            2.3605000227689743e-02 7.7171599864959717e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 632 -1.8287500739097595e-01</internalNodes>
          <leafValues>
            7.9228597879409790e-01 -2.4644799530506134e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 633 -6.9891996681690216e-02</internalNodes>
          <leafValues>
            8.0267798900604248e-01 -3.6072000861167908e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 634 1.5297000296413898e-02</internalNodes>
          <leafValues>
            -2.0072300732135773e-01 1.1030600070953369e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 635 6.7500001750886440e-03</internalNodes>
          <leafValues>
            -4.5967999845743179e-02 7.2094500064849854e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 636 -1.5983000397682190e-02</internalNodes>
          <leafValues>
            -9.0357202291488647e-01 4.4987998902797699e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 637 1.3088000006973743e-02</internalNodes>
          <leafValues>
            3.5297098755836487e-01 -3.7710601091384888e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 638 1.3061000034213066e-02</internalNodes>
          <leafValues>
            -1.9583599269390106e-01 1.1198940277099609e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 639 -3.9907000958919525e-02</internalNodes>
          <leafValues>
            -1.3998429775238037e+00 1.9145099818706512e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 640 1.5026999637484550e-02</internalNodes>
          <leafValues>
            2.3600000422447920e-03 -1.1611249446868896e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 641 -2.0517999306321144e-02</internalNodes>
          <leafValues>
            -4.8908099532127380e-01 1.6743400692939758e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 642 -2.2359000518918037e-02</internalNodes>
          <leafValues>
            -1.2202980518341064e+00 -1.1975999921560287e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 643 -7.9150004312396049e-03</internalNodes>
          <leafValues>
            3.7228098511695862e-01 -8.5063003003597260e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 644 1.5258000232279301e-02</internalNodes>
          <leafValues>
            -2.9412600398063660e-01 5.9406399726867676e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 645 -3.1665999442338943e-02</internalNodes>
          <leafValues>
            -1.4395569562911987e+00 1.3578799366950989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 646 -3.0773999169468880e-02</internalNodes>
          <leafValues>
            -2.2545371055603027e+00 -3.3971000462770462e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 647 -1.5483000315725803e-02</internalNodes>
          <leafValues>
            3.7700700759887695e-01 1.5847999602556229e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 648 3.5167001187801361e-02</internalNodes>
          <leafValues>
            -2.9446101188659668e-01 5.3159099817276001e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 649 -1.7906000837683678e-02</internalNodes>
          <leafValues>
            -9.9788200855255127e-01 1.6235999763011932e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 650 -3.1799999997019768e-03</internalNodes>
          <leafValues>
            4.7657001763582230e-02 -7.5249898433685303e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 651 1.5720000490546227e-02</internalNodes>
          <leafValues>
            1.4873799681663513e-01 -6.5375399589538574e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 652 2.9864000156521797e-02</internalNodes>
          <leafValues>
            -1.4952000230550766e-02 -1.2275190353393555e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 653 2.9899999499320984e-03</internalNodes>
          <leafValues>
            -1.4263699948787689e-01 4.3272799253463745e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 654 8.4749996662139893e-02</internalNodes>
          <leafValues>
            -1.9280999898910522e-02 -1.1946409940719604e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 655 -5.8724999427795410e-02</internalNodes>
          <leafValues>
            -1.7328219413757324e+00 1.4374700188636780e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 656 4.4755998998880386e-02</internalNodes>
          <leafValues>
            -2.4140599370002747e-01 5.4019999504089355e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 657 4.0369000285863876e-02</internalNodes>
          <leafValues>
            5.7680001482367516e-03 5.6578099727630615e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 658 3.7735998630523682e-02</internalNodes>
          <leafValues>
            3.8180999457836151e-02 -7.9370397329330444e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 659 6.0752999037504196e-02</internalNodes>
          <leafValues>
            7.6453000307083130e-02 1.4813209772109985e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 660 -1.9832000136375427e-02</internalNodes>
          <leafValues>
            -1.6971720457077026e+00 -2.7370000258088112e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 661 -1.6592699289321899e-01</internalNodes>
          <leafValues>
            6.2976002693176270e-01 3.1762998551130295e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 662 6.9014996290206909e-02</internalNodes>
          <leafValues>
            -3.3463200926780701e-01 3.0076700448989868e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 663 1.1358000338077545e-02</internalNodes>
          <leafValues>
            2.2741499543190002e-01 -3.8224700093269348e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 664 1.7000000225380063e-03</internalNodes>
          <leafValues>
            1.9223800301551819e-01 -5.2735102176666260e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 665 7.9769000411033630e-02</internalNodes>
          <leafValues>
            9.1491997241973877e-02 2.1049048900604248e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 666 -5.7144001126289368e-02</internalNodes>
          <leafValues>
            -1.7452130317687988e+00 -4.0910001844167709e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 667 7.3830001056194305e-03</internalNodes>
          <leafValues>
            -2.4214799702167511e-01 3.5577800869941711e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 668 -1.8040999770164490e-02</internalNodes>
          <leafValues>
            1.1779999732971191e+00 -1.7676700651645660e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 669 9.4503000378608704e-02</internalNodes>
          <leafValues>
            1.3936099410057068e-01 -1.2993700504302979e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 670 5.4210000671446323e-03</internalNodes>
          <leafValues>
            -5.4608601331710815e-01 1.3916400074958801e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 671 7.0290002040565014e-03</internalNodes>
          <leafValues>
            -2.1597200632095337e-01 3.9258098602294922e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 672 3.4515999257564545e-02</internalNodes>
          <leafValues>
            6.3188999891281128e-02 -7.2108101844787598e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 673 -5.1924999803304672e-02</internalNodes>
          <leafValues>
            6.8667602539062500e-01 6.3272997736930847e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 674 -6.9162003695964813e-02</internalNodes>
          <leafValues>
            1.7411810159683228e+00 -1.6619299352169037e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 675 -5.5229999125003815e-03</internalNodes>
          <leafValues>
            3.0694699287414551e-01 -1.6662900149822235e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 676 6.8599998950958252e-02</internalNodes>
          <leafValues>
            -2.1405400335788727e-01 7.3185002803802490e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 677 -6.7038998007774353e-02</internalNodes>
          <leafValues>
            -7.9360598325729370e-01 2.0525799691677094e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 678 -2.1005000919103622e-02</internalNodes>
          <leafValues>
            3.7344399094581604e-01 -2.9618600010871887e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 679 2.0278999581933022e-02</internalNodes>
          <leafValues>
            -1.5200000256299973e-02 4.0555301308631897e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 680 -4.7107998281717300e-02</internalNodes>
          <leafValues>
            1.2116849422454834e+00 -1.7464299499988556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 681 1.8768499791622162e-01</internalNodes>
          <leafValues>
            -2.2909000515937805e-02 6.9645798206329346e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 682 -4.3228998780250549e-02</internalNodes>
          <leafValues>
            -1.0602480173110962e+00 -5.5599998449906707e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 683 2.0004000514745712e-02</internalNodes>
          <leafValues>
            -3.2751001417636871e-02 5.3805100917816162e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 684 8.0880001187324524e-03</internalNodes>
          <leafValues>
            3.7548001855611801e-02 -7.4768900871276855e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 685 2.7101000770926476e-02</internalNodes>
          <leafValues>
            -8.1790000200271606e-02 3.3387100696563721e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 686 -9.1746002435684204e-02</internalNodes>
          <leafValues>
            -1.9213509559631348e+00 -3.8952998816967010e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 687 -1.2454999610781670e-02</internalNodes>
          <leafValues>
            4.8360601067543030e-01 1.8168000504374504e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 688 1.4649000018835068e-02</internalNodes>
          <leafValues>
            -1.9906699657440186e-01 7.2815400362014771e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 689 2.9101999476552010e-02</internalNodes>
          <leafValues>
            1.9871099293231964e-01 -4.9216800928115845e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 690 8.7799998000264168e-03</internalNodes>
          <leafValues>
            -1.9499599933624268e-01 7.7317398786544800e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 691 -5.4740000516176224e-02</internalNodes>
          <leafValues>
            1.8087190389633179e+00 6.8323001265525818e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 692 -1.4798000454902649e-02</internalNodes>
          <leafValues>
            7.8064900636672974e-01 -1.8709599971771240e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 693 2.5012999773025513e-02</internalNodes>
          <leafValues>
            1.5285299718379974e-01 -1.6021020412445068e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 694 4.6548001468181610e-02</internalNodes>
          <leafValues>
            -1.6738200187683105e-01 1.1902060508728027e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 695 1.7624000087380409e-02</internalNodes>
          <leafValues>
            -1.0285499691963196e-01 3.9175900816917419e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 696 1.6319599747657776e-01</internalNodes>
          <leafValues>
            -3.5624001175165176e-02 -1.6098170280456543e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 697 1.3137999922037125e-02</internalNodes>
          <leafValues>
            -5.6359000504016876e-02 5.4158902168273926e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 698 -1.5665000304579735e-02</internalNodes>
          <leafValues>
            2.8063100576400757e-01 -3.1708601117134094e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 699 8.0554001033306122e-02</internalNodes>
          <leafValues>
            1.2640400230884552e-01 -1.0297529697418213e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 700 3.5363998264074326e-02</internalNodes>
          <leafValues>
            2.0752999931573868e-02 -7.9105597734451294e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 701 3.2986998558044434e-02</internalNodes>
          <leafValues>
            1.9057099521160126e-01 -8.3839899301528931e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 702 1.2195000424981117e-02</internalNodes>
          <leafValues>
            7.3729000985622406e-02 -6.2780702114105225e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 703 4.3065998703241348e-02</internalNodes>
          <leafValues>
            4.7384999692440033e-02 1.5712939500808716e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 704 3.0326999723911285e-02</internalNodes>
          <leafValues>
            -2.7314600348472595e-01 3.8572001457214355e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 705 3.5493001341819763e-02</internalNodes>
          <leafValues>
            5.4593998938798904e-02 5.2583402395248413e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 706 -1.4596999622881413e-02</internalNodes>
          <leafValues>
            3.8152599334716797e-01 -2.8332400321960449e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 707 1.2606999836862087e-02</internalNodes>
          <leafValues>
            1.5455099940299988e-01 -3.0501499772071838e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 708 1.0172000154852867e-02</internalNodes>
          <leafValues>
            2.3637000471353531e-02 -8.7217897176742554e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 709 2.8843000531196594e-02</internalNodes>
          <leafValues>
            1.6090999543666840e-01 -2.0277599990367889e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 710 5.5100000463426113e-04</internalNodes>
          <leafValues>
            -6.1545401811599731e-01 8.0935999751091003e-02</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>127</maxWeakCount>
      <stageThreshold>-3.5645289421081543e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 711 4.8344001173973083e-02</internalNodes>
          <leafValues>
            -8.4904599189758301e-01 5.6974399089813232e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 712 3.2460000365972519e-02</internalNodes>
          <leafValues>
            -8.1417298316955566e-01 4.4781699776649475e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 713 3.3339999616146088e-02</internalNodes>
          <leafValues>
            -3.6423799395561218e-01 6.7937397956848145e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 714 6.4019998535513878e-03</internalNodes>
          <leafValues>
            -1.1885459423065186e+00 1.9238699972629547e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 715 -5.6889997795224190e-03</internalNodes>
          <leafValues>
            3.3085298538208008e-01 -7.1334099769592285e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 716 1.2698000296950340e-02</internalNodes>
          <leafValues>
            -5.0990802049636841e-01 1.1376299709081650e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 717 6.0549997724592686e-03</internalNodes>
          <leafValues>
            -1.0470550060272217e+00 2.0222599804401398e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 718 2.6420000940561295e-03</internalNodes>
          <leafValues>
            -5.0559401512145996e-01 3.6441200971603394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 719 -1.6925999894738197e-02</internalNodes>
          <leafValues>
            -9.9541902542114258e-01 1.2602199614048004e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 720 2.8235999867320061e-02</internalNodes>
          <leafValues>
            -9.4137996435165405e-02 5.7780402898788452e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 721 1.0428999550640583e-02</internalNodes>
          <leafValues>
            2.3272900283336639e-01 -5.2569699287414551e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 722 9.8860003054141998e-03</internalNodes>
          <leafValues>
            -1.0316299647092819e-01 4.7657600045204163e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 723 2.6015000417828560e-02</internalNodes>
          <leafValues>
            -1.0920000495389104e-03 -1.5581729412078857e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 724 -2.5537999346852303e-02</internalNodes>
          <leafValues>
            -6.5451401472091675e-01 1.8843199312686920e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 725 -3.5310001112520695e-03</internalNodes>
          <leafValues>
            2.8140598535537720e-01 -4.4575300812721252e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 726 9.2449998483061790e-03</internalNodes>
          <leafValues>
            1.5612000226974487e-01 -2.1370999515056610e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 727 2.1030999720096588e-02</internalNodes>
          <leafValues>
            -2.9170298576354980e-01 5.2234101295471191e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 728 -5.1063001155853271e-02</internalNodes>
          <leafValues>
            1.3661290407180786e+00 3.0465999618172646e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 729 -6.2330000102519989e-02</internalNodes>
          <leafValues>
            1.2207020521163940e+00 -2.2434400022029877e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 730 -3.2963000237941742e-02</internalNodes>
          <leafValues>
            -8.2016801834106445e-01 1.4531899988651276e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 731 -3.7418000400066376e-02</internalNodes>
          <leafValues>
            -1.2218099832534790e+00 1.9448999315500259e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 732 1.2402799725532532e-01</internalNodes>
          <leafValues>
            1.2082300335168839e-01 -9.8729300498962402e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 733 -8.9229997247457504e-03</internalNodes>
          <leafValues>
            -1.1688489913940430e+00 2.1105000749230385e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 734 -5.9879999607801437e-02</internalNodes>
          <leafValues>
            -1.0689330101013184e+00 1.9860200583934784e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 735 6.2620001845061779e-03</internalNodes>
          <leafValues>
            -3.6229598522186279e-01 3.8000801205635071e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 736 -1.7673000693321228e-02</internalNodes>
          <leafValues>
            4.9094098806381226e-01 -1.4606699347496033e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 737 1.7579000443220139e-02</internalNodes>
          <leafValues>
            5.8728098869323730e-01 -2.7774399518966675e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 738 5.1560001447796822e-03</internalNodes>
          <leafValues>
            -7.5194999575614929e-02 6.0193097591400146e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 739 -1.0599999688565731e-02</internalNodes>
          <leafValues>
            2.7637401223182678e-01 -3.7794300913810730e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 740 2.0884099602699280e-01</internalNodes>
          <leafValues>
            -5.3599998354911804e-03 1.0317809581756592e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 741 -2.6412999257445335e-02</internalNodes>
          <leafValues>
            8.2336401939392090e-01 -2.2480599582195282e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 742 5.8892000466585159e-02</internalNodes>
          <leafValues>
            1.3098299503326416e-01 -1.1853699684143066e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 743 -1.1579000391066074e-02</internalNodes>
          <leafValues>
            -9.0667802095413208e-01 4.4126998633146286e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 744 4.5988000929355621e-02</internalNodes>
          <leafValues>
            1.0143999941647053e-02 1.0740900039672852e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 745 -2.2838000208139420e-02</internalNodes>
          <leafValues>
            1.7791990041732788e+00 -1.7315499484539032e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 746 -8.1709995865821838e-03</internalNodes>
          <leafValues>
            5.7386302947998047e-01 -7.4106000363826752e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 747 3.5359999164938927e-03</internalNodes>
          <leafValues>
            -3.2072898745536804e-01 4.0182501077651978e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 748 4.9444999545812607e-02</internalNodes>
          <leafValues>
            1.9288000464439392e-01 -1.2166700363159180e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 749 3.5139999818056822e-03</internalNodes>
          <leafValues>
            6.9568000733852386e-02 -7.1323698759078979e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 750 -3.0996000394225121e-02</internalNodes>
          <leafValues>
            -3.8862198591232300e-01 1.8098799884319305e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 751 8.6452998220920563e-02</internalNodes>
          <leafValues>
            -2.5792999193072319e-02 -1.5453219413757324e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 752 -1.3652600347995758e-01</internalNodes>
          <leafValues>
            -1.9199420213699341e+00 1.6613300144672394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 753 -5.7689999230206013e-03</internalNodes>
          <leafValues>
            -1.2822589874267578e+00 -1.5907999128103256e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 754 -1.7899999395012856e-02</internalNodes>
          <leafValues>
            -4.0409898757934570e-01 2.3591600358486176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 755 -1.9969999790191650e-02</internalNodes>
          <leafValues>
            -7.2891902923583984e-01 5.6235000491142273e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 756 -5.7493001222610474e-02</internalNodes>
          <leafValues>
            5.7830798625946045e-01 -1.5796000137925148e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 757 -8.3056002855300903e-02</internalNodes>
          <leafValues>
            9.1511601209640503e-01 -2.1121400594711304e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 758 -5.3771000355482101e-02</internalNodes>
          <leafValues>
            -5.1931297779083252e-01 1.8576000630855560e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 759 -8.3670001477003098e-03</internalNodes>
          <leafValues>
            2.4109700322151184e-01 -3.9648601412773132e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 760 5.5406998842954636e-02</internalNodes>
          <leafValues>
            1.6771200299263000e-01 -2.5664970874786377e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 761 -6.7180998623371124e-02</internalNodes>
          <leafValues>
            -1.3658570051193237e+00 -1.4232000336050987e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 762 -2.3900000378489494e-02</internalNodes>
          <leafValues>
            -1.7084569931030273e+00 1.6507799923419952e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 763 5.5949999950826168e-03</internalNodes>
          <leafValues>
            -3.1373998522758484e-01 3.2837900519371033e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 764 2.1294999867677689e-02</internalNodes>
          <leafValues>
            1.4953400194644928e-01 -4.8579800128936768e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 765 -2.4613000452518463e-02</internalNodes>
          <leafValues>
            7.4346399307250977e-01 -2.2305199503898621e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 766 -1.9626000896096230e-02</internalNodes>
          <leafValues>
            -4.0918299555778503e-01 1.8893200159072876e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 767 -5.3266000002622604e-02</internalNodes>
          <leafValues>
            8.1381601095199585e-01 -2.0853699743747711e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 768 7.1290000341832638e-03</internalNodes>
          <leafValues>
            3.2996100187301636e-01 -5.9937399625778198e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 769 -2.2486999630928040e-02</internalNodes>
          <leafValues>
            -1.2551610469818115e+00 -2.0413000136613846e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 770 -8.2310996949672699e-02</internalNodes>
          <leafValues>
            1.3821430206298828e+00 5.9308998286724091e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 771 1.3097000122070312e-01</internalNodes>
          <leafValues>
            -3.5843998193740845e-02 -1.5396369695663452e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 772 1.4293000102043152e-02</internalNodes>
          <leafValues>
            -1.8475200235843658e-01 3.7455001473426819e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 773 6.3479999080300331e-03</internalNodes>
          <leafValues>
            -4.4901099801063538e-01 1.3876999914646149e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 774 -4.6055000275373459e-02</internalNodes>
          <leafValues>
            6.7832601070404053e-01 -1.7071999609470367e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 775 5.7693999260663986e-02</internalNodes>
          <leafValues>
            -1.1955999769270420e-02 -1.2261159420013428e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 776 -6.0609998181462288e-03</internalNodes>
          <leafValues>
            3.3958598971366882e-01 6.2800000887364149e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 777 -5.2163001149892807e-02</internalNodes>
          <leafValues>
            -1.0621069669723511e+00 -1.3779999688267708e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 778 4.6572998166084290e-02</internalNodes>
          <leafValues>
            1.4538800716400146e-01 -1.2384550571441650e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 779 7.5309998355805874e-03</internalNodes>
          <leafValues>
            -2.4467700719833374e-01 5.1377099752426147e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 780 2.1615000441670418e-02</internalNodes>
          <leafValues>
            1.3072599470615387e-01 -7.0996797084808350e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 781 -1.7864000052213669e-02</internalNodes>
          <leafValues>
            -1.0474660396575928e+00 4.9599999329075217e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 782 -3.7195000797510147e-02</internalNodes>
          <leafValues>
            -1.5126730203628540e+00 1.4801399409770966e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 783 -3.1100001069717109e-04</internalNodes>
          <leafValues>
            1.3971500098705292e-01 -4.6867498755455017e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 784 2.5042999535799026e-02</internalNodes>
          <leafValues>
            2.8632000088691711e-01 -4.1794699430465698e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 785 9.3449996784329414e-03</internalNodes>
          <leafValues>
            -2.7336201071739197e-01 4.3444699048995972e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 786 3.2363999634981155e-02</internalNodes>
          <leafValues>
            1.8438899517059326e-01 -9.5019298791885376e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 787 -6.2299999408423901e-03</internalNodes>
          <leafValues>
            3.2581999897956848e-01 -3.0815601348876953e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 788 5.1488999277353287e-02</internalNodes>
          <leafValues>
            1.1416000127792358e-01 -1.9795479774475098e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 789 -2.6449000462889671e-02</internalNodes>
          <leafValues>
            -1.1067299842834473e+00 -8.5519999265670776e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 790 -1.5420000068843365e-02</internalNodes>
          <leafValues>
            8.0138701200485229e-01 -3.2035000622272491e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 791 1.9456999376416206e-02</internalNodes>
          <leafValues>
            -2.6449498534202576e-01 3.8753899931907654e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 792 3.3620998263359070e-02</internalNodes>
          <leafValues>
            1.6052000224590302e-02 5.8840900659561157e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 793 2.8906000778079033e-02</internalNodes>
          <leafValues>
            1.5216000378131866e-02 -9.4723600149154663e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 794 2.0300000323913991e-04</internalNodes>
          <leafValues>
            -3.0766001343727112e-01 2.1235899627208710e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 795 -4.9141999334096909e-02</internalNodes>
          <leafValues>
            -1.6058609485626221e+00 -3.1094999983906746e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 796 7.6425999402999878e-02</internalNodes>
          <leafValues>
            7.4758999049663544e-02 1.1639410257339478e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 797 2.3897999897599220e-02</internalNodes>
          <leafValues>
            -6.4320000819861889e-03 -1.1150749921798706e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 798 3.8970001041889191e-03</internalNodes>
          <leafValues>
            -2.4105699360370636e-01 2.0858900249004364e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 799 -8.9445002377033234e-02</internalNodes>
          <leafValues>
            1.9157789945602417e+00 -1.5721100568771362e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 800 -1.5008999966084957e-02</internalNodes>
          <leafValues>
            -2.5174099206924438e-01 1.8179899454116821e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 801 -1.1145999655127525e-02</internalNodes>
          <leafValues>
            -6.9349497556686401e-01 4.4927999377250671e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 802 9.4578996300697327e-02</internalNodes>
          <leafValues>
            1.8102100491523743e-01 -7.4978601932525635e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 803 5.5038899183273315e-01</internalNodes>
          <leafValues>
            -3.0974000692367554e-02 -1.6746139526367188e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 804 4.1381001472473145e-02</internalNodes>
          <leafValues>
            6.3910000026226044e-02 7.6561200618743896e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 805 2.4771999567747116e-02</internalNodes>
          <leafValues>
            1.1380000039935112e-02 -8.8559401035308838e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 806 5.0999000668525696e-02</internalNodes>
          <leafValues>
            1.4890299737453461e-01 -2.4634211063385010e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 807 -1.6893999651074409e-02</internalNodes>
          <leafValues>
            3.8870999217033386e-01 -2.9880300164222717e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 808 -1.2162300199270248e-01</internalNodes>
          <leafValues>
            -1.5542800426483154e+00 1.6300800442695618e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 809 -3.6049999762326479e-03</internalNodes>
          <leafValues>
            2.1842800080776215e-01 -3.7312099337577820e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 810 1.1575400084257126e-01</internalNodes>
          <leafValues>
            -4.7061000019311905e-02 5.9403699636459351e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 811 3.6903999745845795e-02</internalNodes>
          <leafValues>
            -2.5508600473403931e-01 5.5397301912307739e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 812 1.1483999900519848e-02</internalNodes>
          <leafValues>
            -1.8129499256610870e-01 4.0682798624038696e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 813 -2.0233999937772751e-02</internalNodes>
          <leafValues>
            5.4311197996139526e-01 -2.3822399973869324e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 814 -2.8765000402927399e-02</internalNodes>
          <leafValues>
            -6.9172298908233643e-01 1.5943300724029541e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 815 -5.8320001699030399e-03</internalNodes>
          <leafValues>
            2.9447799921035767e-01 -3.4005999565124512e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 816 -5.5468998849391937e-02</internalNodes>
          <leafValues>
            9.2200797796249390e-01 9.4093002378940582e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 817 -1.4801000244915485e-02</internalNodes>
          <leafValues>
            -7.9539698362350464e-01 3.1521998345851898e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 818 -7.0940000005066395e-03</internalNodes>
          <leafValues>
            3.3096000552177429e-01 -5.0886999815702438e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 819 -4.5124001801013947e-02</internalNodes>
          <leafValues>
            -1.3719749450683594e+00 -2.1408999338746071e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 820 6.4377002418041229e-02</internalNodes>
          <leafValues>
            6.3901998102664948e-02 9.1478300094604492e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 821 -1.4727000147104263e-02</internalNodes>
          <leafValues>
            3.6050599813461304e-01 -2.8614500164985657e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 822 4.5007001608610153e-02</internalNodes>
          <leafValues>
            -1.5619699656963348e-01 5.3160297870635986e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 823 -1.1330000124871731e-03</internalNodes>
          <leafValues>
            1.3422900438308716e-01 -4.4358900189399719e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 824 4.9451000988483429e-02</internalNodes>
          <leafValues>
            1.0571800172328949e-01 -2.5589139461517334e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 825 2.9102999716997147e-02</internalNodes>
          <leafValues>
            -1.0088000446557999e-02 -1.1073939800262451e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 826 3.4786000847816467e-02</internalNodes>
          <leafValues>
            -2.7719999197870493e-03 5.6700998544692993e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 827 -6.1309998854994774e-03</internalNodes>
          <leafValues>
            -4.6889400482177734e-01 1.2636399269104004e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 828 1.5525000169873238e-02</internalNodes>
          <leafValues>
            -8.4279999136924744e-03 8.7469202280044556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 829 2.9249999206513166e-03</internalNodes>
          <leafValues>
            -3.4434300661087036e-01 2.0851600170135498e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 830 -5.3571000695228577e-02</internalNodes>
          <leafValues>
            1.4982949495315552e+00 5.7328000664710999e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 831 -1.9217999652028084e-02</internalNodes>
          <leafValues>
            -9.9234098196029663e-01 -9.3919998034834862e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 832 -5.5282998830080032e-02</internalNodes>
          <leafValues>
            -5.7682299613952637e-01 1.6860599815845490e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 833 5.6336000561714172e-02</internalNodes>
          <leafValues>
            -3.3775001764297485e-02 -1.3889650106430054e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 834 -2.3824000731110573e-02</internalNodes>
          <leafValues>
            4.0182098746299744e-01 1.8360000103712082e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 835 1.7810000572353601e-03</internalNodes>
          <leafValues>
            1.8145999312400818e-01 -4.1743400692939758e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 836 -3.7689000368118286e-02</internalNodes>
          <leafValues>
            5.4683101177215576e-01 1.8219999969005585e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 837 -2.4144999682903290e-02</internalNodes>
          <leafValues>
            6.8352097272872925e-01 -1.9650200009346008e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>135</maxWeakCount>
      <stageThreshold>-3.7025990486145020e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 838 2.7444999665021896e-02</internalNodes>
          <leafValues>
            -8.9984202384948730e-01 5.1876497268676758e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 839 1.1554100364446640e-01</internalNodes>
          <leafValues>
            -5.6524401903152466e-01 7.0551300048828125e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 840 -2.2297000512480736e-02</internalNodes>
          <leafValues>
            3.6079999804496765e-01 -6.6864597797393799e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 841 1.3325000181794167e-02</internalNodes>
          <leafValues>
            -5.5573397874832153e-01 3.5789999365806580e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 842 -3.8060001097619534e-03</internalNodes>
          <leafValues>
            -1.0713000297546387e+00 1.8850000202655792e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 843 -2.6819999329745770e-03</internalNodes>
          <leafValues>
            -7.1584302186965942e-01 2.6344498991966248e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 844 3.3819999080151320e-03</internalNodes>
          <leafValues>
            -4.6930798888206482e-01 2.6658400893211365e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 845 3.7643000483512878e-02</internalNodes>
          <leafValues>
            2.1098700165748596e-01 -1.0804339647293091e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 846 -1.3861999846994877e-02</internalNodes>
          <leafValues>
            6.6912001371383667e-01 -2.7942800521850586e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 847 -2.7350001037120819e-03</internalNodes>
          <leafValues>
            -9.5332300662994385e-01 2.4051299691200256e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 848 -3.8336999714374542e-02</internalNodes>
          <leafValues>
            8.1432801485061646e-01 -2.4919399619102478e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 849 -3.4697998315095901e-02</internalNodes>
          <leafValues>
            1.2330100536346436e+00 6.8600000813603401e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 850 2.3360999301075935e-02</internalNodes>
          <leafValues>
            -3.0794700980186462e-01 7.0714497566223145e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 851 3.5057999193668365e-02</internalNodes>
          <leafValues>
            2.1205900609493256e-01 -1.4399830102920532e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 852 -1.3256999664008617e-02</internalNodes>
          <leafValues>
            -9.0260702371597290e-01 4.8610001802444458e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 853 1.2740000151097775e-02</internalNodes>
          <leafValues>
            2.2655199468135834e-01 -4.4643801450729370e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 854 3.6400000099092722e-03</internalNodes>
          <leafValues>
            -3.9817899465560913e-01 3.4665399789810181e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 855 1.0064700245857239e-01</internalNodes>
          <leafValues>
            1.8383599817752838e-01 -1.3410769701004028e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 856 0.</internalNodes>
          <leafValues>
            1.5536400675773621e-01 -5.1582497358322144e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 857 1.1708999983966351e-02</internalNodes>
          <leafValues>
            2.1651400625705719e-01 -7.2705197334289551e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 858 -3.5964999347925186e-02</internalNodes>
          <leafValues>
            -1.4789500236511230e+00 -2.4317000061273575e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 859 -2.1236000582575798e-02</internalNodes>
          <leafValues>
            -1.6844099760055542e-01 1.9526599347591400e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 860 1.4874000102281570e-02</internalNodes>
          <leafValues>
            3.7335999310016632e-02 -8.7557297945022583e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 861 -5.1409997977316380e-03</internalNodes>
          <leafValues>
            3.3466500043869019e-01 -2.4109700322151184e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 862 2.3450000211596489e-02</internalNodes>
          <leafValues>
            5.5320002138614655e-03 -1.2509720325469971e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 863 -2.5062000378966331e-02</internalNodes>
          <leafValues>
            4.5212399959564209e-01 -8.4469996392726898e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 864 -7.7400001464411616e-04</internalNodes>
          <leafValues>
            1.5249900519847870e-01 -4.8486500978469849e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 865 -4.0483999997377396e-02</internalNodes>
          <leafValues>
            -1.3024920225143433e+00 1.7983500659465790e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 866 2.8170999139547348e-02</internalNodes>
          <leafValues>
            -2.4410900473594666e-01 6.2271100282669067e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 867 4.5692998915910721e-02</internalNodes>
          <leafValues>
            2.8122000396251678e-02 9.2394399642944336e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 868 3.9707001298666000e-02</internalNodes>
          <leafValues>
            -2.2332799434661865e-01 7.7674001455307007e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 869 5.0517000257968903e-02</internalNodes>
          <leafValues>
            2.0319999754428864e-01 -1.0895930528640747e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 870 -1.7266999930143356e-02</internalNodes>
          <leafValues>
            6.8598401546478271e-01 -2.3304499685764313e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 871 8.0186001956462860e-02</internalNodes>
          <leafValues>
            -1.0292000137269497e-02 6.1881101131439209e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 872 9.7676001489162445e-02</internalNodes>
          <leafValues>
            -2.0070299506187439e-01 1.0088349580764771e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 873 -1.5572000294923782e-02</internalNodes>
          <leafValues>
            4.7615298628807068e-01 4.5623999089002609e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 874 -1.5305000357329845e-02</internalNodes>
          <leafValues>
            -1.1077369451522827e+00 4.5239999890327454e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 875 -1.6485000029206276e-02</internalNodes>
          <leafValues>
            1.0152939558029175e+00 1.6327999532222748e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 876 -2.6141999289393425e-02</internalNodes>
          <leafValues>
            4.1723299026489258e-01 -2.8645500540733337e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 877 8.8679995387792587e-03</internalNodes>
          <leafValues>
            2.1404999494552612e-01 -1.6772800683975220e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 878 -2.6886999607086182e-02</internalNodes>
          <leafValues>
            -1.1564220190048218e+00 -1.0324000380933285e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 879 7.7789998613297939e-03</internalNodes>
          <leafValues>
            3.5359498858451843e-01 -2.9611301422119141e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 880 -1.5974000096321106e-02</internalNodes>
          <leafValues>
            -1.5374109745025635e+00 -2.9958000406622887e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 881 2.0866999402642250e-02</internalNodes>
          <leafValues>
            2.0244100689888000e-01 -7.1270197629928589e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 882 8.5482001304626465e-02</internalNodes>
          <leafValues>
            -2.5932999327778816e-02 -1.5156569480895996e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 883 2.3872999474406242e-02</internalNodes>
          <leafValues>
            1.6803400218486786e-01 -3.8806200027465820e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 884 -3.9105001837015152e-02</internalNodes>
          <leafValues>
            -1.1958349943161011e+00 -2.0361000671982765e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 885 -7.7946998178958893e-02</internalNodes>
          <leafValues>
            -1.0898950099945068e+00 1.4530299603939056e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 886 -1.6876000910997391e-02</internalNodes>
          <leafValues>
            2.8049701452255249e-01 -4.1336300969123840e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 887 1.1875600367784500e-01</internalNodes>
          <leafValues>
            -4.3490998446941376e-02 4.1263699531555176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 888 1.5624199807643890e-01</internalNodes>
          <leafValues>
            -2.6429599523544312e-01 5.5127799510955811e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 889 -4.5908000320196152e-02</internalNodes>
          <leafValues>
            6.0189199447631836e-01 1.8921000882983208e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 890 -1.0309999808669090e-02</internalNodes>
          <leafValues>
            3.8152998685836792e-01 -2.9507899284362793e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 891 9.5769003033638000e-02</internalNodes>
          <leafValues>
            1.3246500492095947e-01 -4.6266800165176392e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 892 1.3686999678611755e-02</internalNodes>
          <leafValues>
            1.1738699674606323e-01 -5.1664102077484131e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 893 2.3990001063793898e-03</internalNodes>
          <leafValues>
            -3.4007599949836731e-01 2.0953500270843506e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 894 3.3264998346567154e-02</internalNodes>
          <leafValues>
            -1.7052799463272095e-01 1.4366799592971802e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 895 -3.3206000924110413e-02</internalNodes>
          <leafValues>
            6.1295700073242188e-01 -4.1549999266862869e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 896 2.7979998849332333e-03</internalNodes>
          <leafValues>
            -4.8554301261901855e-01 1.3372699916362762e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 897 -6.5792001783847809e-02</internalNodes>
          <leafValues>
            -4.0257668495178223e+00 1.0876700282096863e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 898 2.1430000197142363e-03</internalNodes>
          <leafValues>
            -3.9179998636245728e-01 2.2427099943161011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 899 2.2363999858498573e-02</internalNodes>
          <leafValues>
            -8.6429998278617859e-02 3.7785199284553528e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 900 -5.7410001754760742e-02</internalNodes>
          <leafValues>
            1.1454069614410400e+00 -1.9736599922180176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 901 6.6550001502037048e-03</internalNodes>
          <leafValues>
            -2.1105000749230385e-02 5.8453398942947388e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 902 1.2326999567449093e-02</internalNodes>
          <leafValues>
            3.7817001342773438e-02 -6.6987001895904541e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 903 -8.1869997084140778e-03</internalNodes>
          <leafValues>
            5.6366002559661865e-01 -7.6877996325492859e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 904 3.6681000143289566e-02</internalNodes>
          <leafValues>
            -1.7343300580978394e-01 1.1670149564743042e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 905 -4.0220400691032410e-01</internalNodes>
          <leafValues>
            1.2640819549560547e+00 4.3398998677730560e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 906 -2.2126000374555588e-02</internalNodes>
          <leafValues>
            6.6978102922439575e-01 -2.1605299413204193e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 907 -1.3156999833881855e-02</internalNodes>
          <leafValues>
            -4.1198599338531494e-01 2.0215000212192535e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 908 -1.2860000133514404e-02</internalNodes>
          <leafValues>
            -9.1582697629928589e-01 3.9232999086380005e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 909 2.1627999842166901e-02</internalNodes>
          <leafValues>
            3.8719999138265848e-03 3.5668200254440308e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 910 1.1896000243723392e-02</internalNodes>
          <leafValues>
            -3.7303900718688965e-01 1.9235099852085114e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 911 -1.9548999145627022e-02</internalNodes>
          <leafValues>
            -4.2374899983406067e-01 2.4429599940776825e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 912 6.4444996416568756e-02</internalNodes>
          <leafValues>
            -1.6558900475502014e-01 1.2697030305862427e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 913 1.0898499935865402e-01</internalNodes>
          <leafValues>
            1.4894300699234009e-01 -2.1534640789031982e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 914 -3.4077998250722885e-02</internalNodes>
          <leafValues>
            1.3779460191726685e+00 -1.6198499500751495e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 915 -3.7489999085664749e-03</internalNodes>
          <leafValues>
            -3.3828601241111755e-01 2.1152900159358978e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 916 -1.0971999727189541e-02</internalNodes>
          <leafValues>
            7.6517897844314575e-01 -1.9692599773406982e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 917 -1.1485000140964985e-02</internalNodes>
          <leafValues>
            -6.9271200895309448e-01 2.1657100319862366e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 918 2.5984000414609909e-02</internalNodes>
          <leafValues>
            -1.1983999982476234e-02 -9.9697297811508179e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 919 4.2159999720752239e-03</internalNodes>
          <leafValues>
            -1.0205700248479843e-01 4.8884400725364685e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 920 -4.7697000205516815e-02</internalNodes>
          <leafValues>
            1.0666010379791260e+00 -1.7576299607753754e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 921 4.0300001273863018e-04</internalNodes>
          <leafValues>
            1.8524800240993500e-01 -7.4790000915527344e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 922 1.1539600044488907e-01</internalNodes>
          <leafValues>
            -2.2019700706005096e-01 5.4509997367858887e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 923 1.6021000221371651e-02</internalNodes>
          <leafValues>
            2.5487500429153442e-01 -5.0740098953247070e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 924 5.6632000952959061e-02</internalNodes>
          <leafValues>
            -1.1256000027060509e-02 -9.5968097448348999e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 925 -1.0726000182330608e-02</internalNodes>
          <leafValues>
            -2.8544700145721436e-01 1.6994799673557281e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 926 1.2420000135898590e-01</internalNodes>
          <leafValues>
            -3.6139998584985733e-02 -1.3132710456848145e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 927 -5.3799999877810478e-03</internalNodes>
          <leafValues>
            3.3092701435089111e-01 1.3307999819517136e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 928 1.1908000335097313e-02</internalNodes>
          <leafValues>
            -3.4830299019813538e-01 2.4041900038719177e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 929 -4.3007999658584595e-02</internalNodes>
          <leafValues>
            -1.4390469789505005e+00 1.5599599480628967e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 930 -3.3149998635053635e-02</internalNodes>
          <leafValues>
            -1.1805850267410278e+00 -1.2347999960184097e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 931 -2.1341999992728233e-02</internalNodes>
          <leafValues>
            2.2119441032409668e+00 6.2737002968788147e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 932 -1.2218999676406384e-02</internalNodes>
          <leafValues>
            -1.8709750175476074e+00 -4.5499999076128006e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 933 -1.6860999166965485e-02</internalNodes>
          <leafValues>
            -7.6912701129913330e-01 1.5330000221729279e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 934 -2.4999999441206455e-03</internalNodes>
          <leafValues>
            -6.2987399101257324e-01 5.1600001752376556e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 935 -4.5037999749183655e-02</internalNodes>
          <leafValues>
            8.5428899526596069e-01 6.2600001692771912e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 936 3.9057999849319458e-02</internalNodes>
          <leafValues>
            -3.2458998262882233e-02 -1.3325669765472412e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 937 6.6720000468194485e-03</internalNodes>
          <leafValues>
            -1.9423599541187286e-01 3.7328699231147766e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 938 -1.6361000016331673e-02</internalNodes>
          <leafValues>
            2.0605869293212891e+00 -1.5042699873447418e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 939 6.1719999648630619e-03</internalNodes>
          <leafValues>
            -1.1610999703407288e-01 2.5455400347709656e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 940 4.5722000300884247e-02</internalNodes>
          <leafValues>
            -1.6340000554919243e-02 -1.0449140071868896e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 941 4.1209999471902847e-03</internalNodes>
          <leafValues>
            -4.1997998952865601e-02 3.9680999517440796e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 942 -1.7800000205170363e-04</internalNodes>
          <leafValues>
            -6.6422599554061890e-01 3.3443000167608261e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 943 7.1109998971223831e-03</internalNodes>
          <leafValues>
            -5.8231998234987259e-02 3.7857300043106079e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 944 -4.9864001572132111e-02</internalNodes>
          <leafValues>
            6.1019402742385864e-01 -2.1005700528621674e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 945 -2.5011999532580376e-02</internalNodes>
          <leafValues>
            -5.7100099325180054e-01 1.7848399281501770e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 946 3.0939999967813492e-02</internalNodes>
          <leafValues>
            5.6363001465797424e-02 -6.4731001853942871e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 947 4.6271000057458878e-02</internalNodes>
          <leafValues>
            1.7482399940490723e-01 -9.8909401893615723e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 948 -3.1870000530034304e-03</internalNodes>
          <leafValues>
            -6.6804802417755127e-01 3.2267000526189804e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 949 -2.4351999163627625e-02</internalNodes>
          <leafValues>
            2.9444900155067444e-01 -1.3599999947473407e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 950 1.1974000371992588e-02</internalNodes>
          <leafValues>
            -2.8345099091529846e-01 4.7171199321746826e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 951 1.3070000335574150e-02</internalNodes>
          <leafValues>
            -1.0834600031375885e-01 5.7193297147750854e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 952 5.9163000434637070e-02</internalNodes>
          <leafValues>
            -5.0939001142978668e-02 -1.9059720039367676e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 953 -4.1094999760389328e-02</internalNodes>
          <leafValues>
            4.5104598999023438e-01 -9.7599998116493225e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 954 -8.3989001810550690e-02</internalNodes>
          <leafValues>
            -2.0349199771881104e+00 -5.1019001752138138e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 955 4.4619001448154449e-02</internalNodes>
          <leafValues>
            1.7041100561618805e-01 -1.2278720140457153e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 956 2.4419000372290611e-02</internalNodes>
          <leafValues>
            -2.1796999499201775e-02 -1.0822949409484863e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 957 -4.3870001100003719e-03</internalNodes>
          <leafValues>
            3.0466699600219727e-01 -3.7066599726676941e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 958 2.4607999250292778e-02</internalNodes>
          <leafValues>
            -3.1169500946998596e-01 2.3657299578189850e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 959 -8.5182003676891327e-02</internalNodes>
          <leafValues>
            -1.7982350587844849e+00 1.5254299342632294e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 960 2.1844999864697456e-02</internalNodes>
          <leafValues>
            -5.1888000220060349e-02 -1.9017189741134644e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 961 -1.6829000785946846e-02</internalNodes>
          <leafValues>
            2.1025900542736053e-01 2.1656999364495277e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 962 3.2547999173402786e-02</internalNodes>
          <leafValues>
            -2.0292599499225616e-01 6.0944002866744995e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 963 2.4709999561309814e-03</internalNodes>
          <leafValues>
            -9.5371198654174805e-01 1.8568399548530579e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 964 5.5415999144315720e-02</internalNodes>
          <leafValues>
            -1.4405299723148346e-01 2.1506340503692627e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 965 -1.0635499656200409e-01</internalNodes>
          <leafValues>
            -1.0911970138549805e+00 1.3228000700473785e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 966 -7.9889995977282524e-03</internalNodes>
          <leafValues>
            1.0253400355577469e-01 -5.1744902133941650e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 967 7.5567997992038727e-02</internalNodes>
          <leafValues>
            5.8965001255273819e-02 1.2354209423065186e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 968 -9.2805996537208557e-02</internalNodes>
          <leafValues>
            -1.3431650400161743e+00 -3.4462999552488327e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 969 4.9431998282670975e-02</internalNodes>
          <leafValues>
            4.9601998180150986e-02 1.6054730415344238e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 970 -1.1772999539971352e-02</internalNodes>
          <leafValues>
            -1.0261050462722778e+00 -4.1559999808669090e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 971 8.5886001586914062e-02</internalNodes>
          <leafValues>
            8.4642998874187469e-02 9.5220798254013062e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 972 8.1031002104282379e-02</internalNodes>
          <leafValues>
            -1.4687100052833557e-01 1.9359990358352661e+00</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>136</maxWeakCount>
      <stageThreshold>-3.4265899658203125e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 973 -3.3840999007225037e-02</internalNodes>
          <leafValues>
            6.5889501571655273e-01 -6.9755297899246216e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 974 1.5410000458359718e-02</internalNodes>
          <leafValues>
            -9.0728402137756348e-01 3.0478599667549133e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 975 5.4905999451875687e-02</internalNodes>
          <leafValues>
            -4.9774798750877380e-01 5.7132601737976074e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 976 2.1390000358223915e-02</internalNodes>
          <leafValues>
            -4.2565199732780457e-01 5.8096802234649658e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 977 7.8849997371435165e-03</internalNodes>
          <leafValues>
            -4.7905999422073364e-01 4.3016499280929565e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 978 -3.7544999271631241e-02</internalNodes>
          <leafValues>
            5.0861597061157227e-01 -1.9985899329185486e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 979 1.5925799310207367e-01</internalNodes>
          <leafValues>
            -2.3263600468635559e-01 1.0993319749832153e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 980 -6.8939998745918274e-02</internalNodes>
          <leafValues>
            4.0569001436233521e-01 5.6855000555515289e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 981 -3.3695001155138016e-02</internalNodes>
          <leafValues>
            4.5132800936698914e-01 -3.3332800865173340e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 982 -6.3314996659755707e-02</internalNodes>
          <leafValues>
            -8.5015702247619629e-01 2.2341699898242950e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 983 7.3699997738003731e-03</internalNodes>
          <leafValues>
            -9.3082201480865479e-01 5.9216998517513275e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 984 -9.5969997346401215e-03</internalNodes>
          <leafValues>
            -1.2794899940490723e+00 1.8447299301624298e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 985 -1.3067999482154846e-01</internalNodes>
          <leafValues>
            5.8426898717880249e-01 -2.6007199287414551e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 986 5.7402998208999634e-02</internalNodes>
          <leafValues>
            -5.3789000958204269e-02 7.1175599098205566e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 987 -7.2340001352131367e-03</internalNodes>
          <leafValues>
            -8.6962199211120605e-01 7.5214996933937073e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 988 3.1098999083042145e-02</internalNodes>
          <leafValues>
            -7.5006999075412750e-02 9.0781599283218384e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 989 3.5854000598192215e-02</internalNodes>
          <leafValues>
            -2.4795499444007874e-01 7.2272098064422607e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 990 -3.1534999608993530e-02</internalNodes>
          <leafValues>
            -1.1238329410552979e+00 2.0988300442695618e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 991 -1.9437000155448914e-02</internalNodes>
          <leafValues>
            -1.4499390125274658e+00 -1.5100000426173210e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 992 -7.2420001961290836e-03</internalNodes>
          <leafValues>
            5.3864902257919312e-01 -1.1375399678945541e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 993 8.1639997661113739e-03</internalNodes>
          <leafValues>
            6.6889002919197083e-02 -7.6872897148132324e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 994 -4.3653000146150589e-02</internalNodes>
          <leafValues>
            1.1413530111312866e+00 4.0217000991106033e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 995 2.6569999754428864e-02</internalNodes>
          <leafValues>
            -2.4719099700450897e-01 5.9295099973678589e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 996 3.2216999679803848e-02</internalNodes>
          <leafValues>
            -4.0024999529123306e-02 3.2688000798225403e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 997 -7.2236001491546631e-02</internalNodes>
          <leafValues>
            5.8729398250579834e-01 -2.5396001338958740e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 998 3.1424999237060547e-02</internalNodes>
          <leafValues>
            1.5315100550651550e-01 -5.6042098999023438e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 999 -4.7699999413453043e-04</internalNodes>
          <leafValues>
            1.6958899796009064e-01 -5.2626699209213257e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1000 2.7189999818801880e-03</internalNodes>
          <leafValues>
            -1.4944599568843842e-01 2.9658699035644531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1001 3.2875001430511475e-02</internalNodes>
          <leafValues>
            -3.9943501353263855e-01 2.5156599283218384e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1002 -1.4553000219166279e-02</internalNodes>
          <leafValues>
            2.7972599864006042e-01 -4.7203800082206726e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1003 3.8017999380826950e-02</internalNodes>
          <leafValues>
            -2.9200001154094934e-03 -1.1300059556961060e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1004 2.8659999370574951e-03</internalNodes>
          <leafValues>
            4.1111800074577332e-01 -2.6220801472663879e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1005 -4.1606999933719635e-02</internalNodes>
          <leafValues>
            -1.4293819665908813e+00 -1.9132999703288078e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1006 -2.4802999570965767e-02</internalNodes>
          <leafValues>
            -2.5013598799705505e-01 1.5978699922561646e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1007 1.0098000057041645e-02</internalNodes>
          <leafValues>
            4.3738998472690582e-02 -6.9986099004745483e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1008 -2.0947000011801720e-02</internalNodes>
          <leafValues>
            -9.4137799739837646e-01 2.3204000294208527e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1009 2.2458000108599663e-02</internalNodes>
          <leafValues>
            -2.7185800671577454e-01 4.5319199562072754e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1010 -3.7110999226570129e-02</internalNodes>
          <leafValues>
            -1.0314660072326660e+00 1.4421799778938293e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1011 -1.0648000054061413e-02</internalNodes>
          <leafValues>
            6.3107001781463623e-01 -2.5520798563957214e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1012 5.5422998964786530e-02</internalNodes>
          <leafValues>
            1.6206599771976471e-01 -1.7722640037536621e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1013 2.1601999178528786e-02</internalNodes>
          <leafValues>
            -2.5016099214553833e-01 5.4119801521301270e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1014 8.7000000348780304e-05</internalNodes>
          <leafValues>
            -2.9008901119232178e-01 3.3507999777793884e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1015 1.4406000263988972e-02</internalNodes>
          <leafValues>
            -7.8840004280209541e-03 -1.1677219867706299e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1016 1.0777399688959122e-01</internalNodes>
          <leafValues>
            1.1292000114917755e-01 -2.4940319061279297e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1017 3.5943999886512756e-02</internalNodes>
          <leafValues>
            -1.9480599462985992e-01 9.5757502317428589e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1018 -3.9510000497102737e-03</internalNodes>
          <leafValues>
            3.0927801132202148e-01 -2.5530201196670532e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1019 2.0942000672221184e-02</internalNodes>
          <leafValues>
            -7.6319999061524868e-03 -1.0086350440979004e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1020 -2.9877999797463417e-02</internalNodes>
          <leafValues>
            -4.6027699112892151e-01 1.9507199525833130e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1021 2.5971999391913414e-02</internalNodes>
          <leafValues>
            -1.2187999673187733e-02 -1.0035500526428223e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1022 1.0603000409901142e-02</internalNodes>
          <leafValues>
            -7.5969003140926361e-02 4.1669899225234985e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1023 8.5819996893405914e-03</internalNodes>
          <leafValues>
            -2.6648598909378052e-01 3.9111500978469849e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1024 2.1270999684929848e-02</internalNodes>
          <leafValues>
            1.8273900449275970e-01 -3.6052298545837402e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1025 7.4518002569675446e-02</internalNodes>
          <leafValues>
            -1.8938399851322174e-01 9.2658001184463501e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1026 4.6569998376071453e-03</internalNodes>
          <leafValues>
            -1.4506199955940247e-01 3.3294600248336792e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1027 1.7119999974966049e-03</internalNodes>
          <leafValues>
            -5.2464002370834351e-01 8.9879997074604034e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1028 9.8500004969537258e-04</internalNodes>
          <leafValues>
            -3.8381999731063843e-01 2.4392999708652496e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1029 2.8233999386429787e-02</internalNodes>
          <leafValues>
            -5.7879998348653316e-03 -1.2617139816284180e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1030 -3.2678000628948212e-02</internalNodes>
          <leafValues>
            -5.7953298091888428e-01 1.6955299675464630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1031 2.2536000236868858e-02</internalNodes>
          <leafValues>
            2.2281000390648842e-02 -8.7869602441787720e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1032 -2.1657999604940414e-02</internalNodes>
          <leafValues>
            -6.5108501911163330e-01 1.2966899573802948e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1033 7.6799998059868813e-03</internalNodes>
          <leafValues>
            -3.3965200185775757e-01 2.2013300657272339e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1034 1.4592000283300877e-02</internalNodes>
          <leafValues>
            1.5077300369739532e-01 -5.0452399253845215e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1035 2.7868000790476799e-02</internalNodes>
          <leafValues>
            -2.5045299530029297e-01 4.5741999149322510e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1036 5.6940000504255295e-03</internalNodes>
          <leafValues>
            -1.0948500037193298e-01 5.5757802724838257e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1037 -1.0002999566495419e-02</internalNodes>
          <leafValues>
            -9.7366297245025635e-01 1.8467999994754791e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1038 -4.0719998069107533e-03</internalNodes>
          <leafValues>
            3.8222199678421021e-01 -1.6921100020408630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1039 -2.2593999281525612e-02</internalNodes>
          <leafValues>
            -1.0391089916229248e+00 5.1839998923242092e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1040 -3.9579998701810837e-02</internalNodes>
          <leafValues>
            -5.5109229087829590e+00 1.1163999885320663e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1041 -1.7537999898195267e-02</internalNodes>
          <leafValues>
            9.5485800504684448e-01 -1.8584500253200531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1042 9.0300003066658974e-03</internalNodes>
          <leafValues>
            1.0436000302433968e-02 8.2114797830581665e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1043 -7.9539995640516281e-03</internalNodes>
          <leafValues>
            2.2632899880409241e-01 -3.4568199515342712e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1044 2.7091000229120255e-02</internalNodes>
          <leafValues>
            1.6430099308490753e-01 -1.3926379680633545e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1045 -2.0625999197363853e-02</internalNodes>
          <leafValues>
            -8.6366099119186401e-01 2.3880000226199627e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1046 -7.1989998221397400e-02</internalNodes>
          <leafValues>
            -2.8192629814147949e+00 1.1570499837398529e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1047 -2.6964999735355377e-02</internalNodes>
          <leafValues>
            -1.2946130037307739e+00 -2.4661000818014145e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1048 -4.7377999871969223e-02</internalNodes>
          <leafValues>
            -8.1306397914886475e-01 1.1831399798393250e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1049 -1.0895600169897079e-01</internalNodes>
          <leafValues>
            6.5937900543212891e-01 -2.0843900740146637e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1050 1.3574000447988510e-02</internalNodes>
          <leafValues>
            7.4240001849830151e-03 5.3152197599411011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1051 -6.6920001991093159e-03</internalNodes>
          <leafValues>
            3.0655801296234131e-01 -3.1084299087524414e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1052 -3.9070001803338528e-03</internalNodes>
          <leafValues>
            2.5576499104499817e-01 -5.2932001650333405e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1053 -3.7613000720739365e-02</internalNodes>
          <leafValues>
            -1.4350049495697021e+00 -1.5448000282049179e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1054 8.6329998448491096e-03</internalNodes>
          <leafValues>
            -1.6884399950504303e-01 4.2124900221824646e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1055 -3.2097000628709793e-02</internalNodes>
          <leafValues>
            -6.4979398250579834e-01 4.1110001504421234e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1056 5.8495998382568359e-02</internalNodes>
          <leafValues>
            -5.2963998168706894e-02 6.3368302583694458e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1057 -4.0901999920606613e-02</internalNodes>
          <leafValues>
            -9.2101097106933594e-01 9.0640000998973846e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1058 -1.9925000146031380e-02</internalNodes>
          <leafValues>
            5.3759998083114624e-01 -6.2996998429298401e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1059 -4.6020001173019409e-03</internalNodes>
          <leafValues>
            -5.4333502054214478e-01 8.4104999899864197e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1060 1.6824999824166298e-02</internalNodes>
          <leafValues>
            1.5563699603080750e-01 -4.0171200037002563e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1061 9.4790002331137657e-03</internalNodes>
          <leafValues>
            -2.4245299398899078e-01 5.1509499549865723e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1062 -1.9534999504685402e-02</internalNodes>
          <leafValues>
            -5.1118397712707520e-01 1.3831999897956848e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1063 1.0746000334620476e-02</internalNodes>
          <leafValues>
            -2.1854999661445618e-01 6.2828701734542847e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1064 3.7927001714706421e-02</internalNodes>
          <leafValues>
            1.1640299856662750e-01 -2.7301959991455078e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1065 1.6390999779105186e-02</internalNodes>
          <leafValues>
            -1.4635999687016010e-02 -1.0797250270843506e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1066 -1.9785000011324883e-02</internalNodes>
          <leafValues>
            1.2166420221328735e+00 3.3275000751018524e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1067 1.1067000217735767e-02</internalNodes>
          <leafValues>
            -2.5388300418853760e-01 4.4038599729537964e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1068 5.2479999139904976e-03</internalNodes>
          <leafValues>
            2.2496800124645233e-01 -2.4216499924659729e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1069 -1.1141999624669552e-02</internalNodes>
          <leafValues>
            2.5018098950386047e-01 -3.0811500549316406e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1070 -1.0666999965906143e-02</internalNodes>
          <leafValues>
            -3.2729101181030273e-01 2.6168298721313477e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1071 1.0545299947261810e-01</internalNodes>
          <leafValues>
            -5.5750001221895218e-02 -1.9605729579925537e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1072 5.4827999323606491e-02</internalNodes>
          <leafValues>
            -1.9519999623298645e-03 7.3866099119186401e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1073 1.7760999500751495e-02</internalNodes>
          <leafValues>
            -3.0647200345993042e-01 2.6346999406814575e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1074 -3.1185999512672424e-02</internalNodes>
          <leafValues>
            -2.4600900709629059e-01 1.7082199454307556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1075 -5.7296000421047211e-02</internalNodes>
          <leafValues>
            4.7033500671386719e-01 -2.6048299670219421e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1076 -1.1312000453472137e-02</internalNodes>
          <leafValues>
            3.8628900051116943e-01 -2.8817000985145569e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1077 3.0592000111937523e-02</internalNodes>
          <leafValues>
            -4.8826001584529877e-02 -1.7638969421386719e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1078 1.8489999929443002e-03</internalNodes>
          <leafValues>
            2.1099899709224701e-01 -2.5940999388694763e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1079 1.1419000104069710e-02</internalNodes>
          <leafValues>
            -1.6829599440097809e-01 1.0278660058975220e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1080 8.1403002142906189e-02</internalNodes>
          <leafValues>
            1.1531999707221985e-01 -1.2482399940490723e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1081 5.3495999425649643e-02</internalNodes>
          <leafValues>
            -4.6303998678922653e-02 -1.7165969610214233e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1082 -2.3948000743985176e-02</internalNodes>
          <leafValues>
            -4.0246599912643433e-01 2.0562100410461426e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1083 6.7690000869333744e-03</internalNodes>
          <leafValues>
            -3.3152300119400024e-01 2.0683400332927704e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1084 -3.2343998551368713e-02</internalNodes>
          <leafValues>
            -7.2632801532745361e-01 2.0073500275611877e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1085 3.7863001227378845e-02</internalNodes>
          <leafValues>
            -1.5631000697612762e-01 1.6697460412979126e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1086 1.5440000221133232e-02</internalNodes>
          <leafValues>
            1.9487400352954865e-01 -3.5384199023246765e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1087 -4.4376000761985779e-02</internalNodes>
          <leafValues>
            8.2093602418899536e-01 -1.8193599581718445e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1088 -2.3102000355720520e-02</internalNodes>
          <leafValues>
            -4.3044099211692810e-01 1.2375400215387344e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1089 1.9400000572204590e-02</internalNodes>
          <leafValues>
            -2.9726000502705574e-02 -1.1597590446472168e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1090 1.0385700315237045e-01</internalNodes>
          <leafValues>
            1.1149899661540985e-01 -4.6835222244262695e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1091 -1.8964000046253204e-02</internalNodes>
          <leafValues>
            2.1773819923400879e+00 -1.4544400572776794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1092 3.8750998675823212e-02</internalNodes>
          <leafValues>
            -4.9446001648902893e-02 3.4018298983573914e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1093 2.2766999900341034e-02</internalNodes>
          <leafValues>
            -3.2802999019622803e-01 3.0531400442123413e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1094 -3.1357001513242722e-02</internalNodes>
          <leafValues>
            1.1520819664001465e+00 2.7305999770760536e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1095 9.6909999847412109e-03</internalNodes>
          <leafValues>
            -3.8799500465393066e-01 2.1512599289417267e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1096 -4.9284998327493668e-02</internalNodes>
          <leafValues>
            -1.6774909496307373e+00 1.5774199366569519e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1097 -3.9510998874902725e-02</internalNodes>
          <leafValues>
            -9.7647899389266968e-01 -1.0552000254392624e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1098 4.7997999936342239e-02</internalNodes>
          <leafValues>
            2.0843900740146637e-01 -6.8992799520492554e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1099 5.1422998309135437e-02</internalNodes>
          <leafValues>
            -1.6665300726890564e-01 1.2149239778518677e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1100 1.4279999770224094e-02</internalNodes>
          <leafValues>
            2.3627699911594391e-01 -4.1396799683570862e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1101 -9.1611996293067932e-02</internalNodes>
          <leafValues>
            -9.2830902338027954e-01 -1.8345000222325325e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1102 6.5080001950263977e-03</internalNodes>
          <leafValues>
            -7.3647201061248779e-01 1.9497099518775940e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1103 3.5723000764846802e-02</internalNodes>
          <leafValues>
            1.4197799563407898e-01 -4.2089301347732544e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1104 5.0638001412153244e-02</internalNodes>
          <leafValues>
            1.1644000187516212e-02 7.8486597537994385e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1105 -1.4613999985158443e-02</internalNodes>
          <leafValues>
            -1.1909500360488892e+00 -3.5128001123666763e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1106 -3.8662999868392944e-02</internalNodes>
          <leafValues>
            2.4314730167388916e+00 6.5647996962070465e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1107 -4.0346998721361160e-02</internalNodes>
          <leafValues>
            7.1755301952362061e-01 -1.9108299911022186e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1108 2.3902000859379768e-02</internalNodes>
          <leafValues>
            1.5646199882030487e-01 -7.9294800758361816e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>137</maxWeakCount>
      <stageThreshold>-3.5125269889831543e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1109 8.5640000179409981e-03</internalNodes>
          <leafValues>
            -8.1450700759887695e-01 5.8875298500061035e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1110 -1.3292600214481354e-01</internalNodes>
          <leafValues>
            9.3213397264480591e-01 -2.9367300868034363e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1111 9.8400004208087921e-03</internalNodes>
          <leafValues>
            -5.6462901830673218e-01 4.1647699475288391e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1112 5.0889998674392700e-03</internalNodes>
          <leafValues>
            -7.9232800006866455e-01 1.6975000500679016e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1113 -6.1039000749588013e-02</internalNodes>
          <leafValues>
            -1.4169000387191772e+00 2.5020999833941460e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1114 -4.6599999768659472e-04</internalNodes>
          <leafValues>
            3.7982499599456787e-01 -4.1567099094390869e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1115 3.3889999613165855e-03</internalNodes>
          <leafValues>
            -4.0768599510192871e-01 3.5548499226570129e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1116 2.1006999537348747e-02</internalNodes>
          <leafValues>
            -2.4080100655555725e-01 8.6112701892852783e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1117 7.5559997931122780e-03</internalNodes>
          <leafValues>
            -8.7467199563980103e-01 9.8572000861167908e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1118 2.4779999628663063e-02</internalNodes>
          <leafValues>
            1.5566200017929077e-01 -6.9229799509048462e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1119 -3.5620000213384628e-02</internalNodes>
          <leafValues>
            -1.1472270488739014e+00 3.6359999328851700e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1120 1.9810000434517860e-02</internalNodes>
          <leafValues>
            1.5516200661659241e-01 -6.9520097970962524e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1121 1.5019999817013741e-02</internalNodes>
          <leafValues>
            4.1990000754594803e-02 -9.6622800827026367e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1122 -2.3137999698519707e-02</internalNodes>
          <leafValues>
            4.3396899104118347e-01 2.4160000029951334e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1123 -1.8743000924587250e-02</internalNodes>
          <leafValues>
            4.3481099605560303e-01 -3.2522499561309814e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1124 4.5080000162124634e-01</internalNodes>
          <leafValues>
            -9.4573996961116791e-02 7.2421300411224365e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1125 1.1854999698698521e-02</internalNodes>
          <leafValues>
            -3.8133099675178528e-01 3.0098399519920349e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1126 -2.4830000475049019e-02</internalNodes>
          <leafValues>
            8.9300602674484253e-01 -1.0295899957418442e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1127 -4.4743001461029053e-02</internalNodes>
          <leafValues>
            8.6280298233032227e-01 -2.1716499328613281e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1128 -1.4600000344216824e-02</internalNodes>
          <leafValues>
            6.0069400072097778e-01 -1.5906299650669098e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1129 -2.4527000263333321e-02</internalNodes>
          <leafValues>
            -1.5872869491577148e+00 -2.1817000582814217e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1130 2.3024000227451324e-02</internalNodes>
          <leafValues>
            1.6853399574756622e-01 -3.8106900453567505e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1131 -2.4917000904679298e-02</internalNodes>
          <leafValues>
            5.0810897350311279e-01 -2.7279898524284363e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1132 1.0130000300705433e-03</internalNodes>
          <leafValues>
            -4.3138799071311951e-01 2.6438099145889282e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1133 1.5603000298142433e-02</internalNodes>
          <leafValues>
            -3.1624200940132141e-01 5.5715900659561157e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1134 -2.6685999706387520e-02</internalNodes>
          <leafValues>
            1.0553920269012451e+00 2.9074000194668770e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1135 1.3940000208094716e-03</internalNodes>
          <leafValues>
            -7.1873801946640015e-01 6.5390996634960175e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1136 -6.4799998654052615e-04</internalNodes>
          <leafValues>
            2.4884399771690369e-01 -2.0978200435638428e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1137 -3.1888000667095184e-02</internalNodes>
          <leafValues>
            -6.8844497203826904e-01 6.3589997589588165e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1138 -4.9290000461041927e-03</internalNodes>
          <leafValues>
            -5.9152501821517944e-01 2.7943599224090576e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1139 3.1168000772595406e-02</internalNodes>
          <leafValues>
            4.5223999768495560e-02 -8.8639199733734131e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1140 -3.3663000911474228e-02</internalNodes>
          <leafValues>
            -6.1590200662612915e-01 1.5749299526214600e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1141 1.1966999620199203e-02</internalNodes>
          <leafValues>
            -3.0606698989868164e-01 4.2293301224708557e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1142 -3.4680001437664032e-02</internalNodes>
          <leafValues>
            -1.3734940290451050e+00 1.5908700227737427e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1143 9.9290004000067711e-03</internalNodes>
          <leafValues>
            -5.5860197544097900e-01 1.2119200080633163e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1144 5.9574998915195465e-02</internalNodes>
          <leafValues>
            4.9720001406967640e-03 8.2055401802062988e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1145 -6.5428003668785095e-02</internalNodes>
          <leafValues>
            1.5651429891586304e+00 -1.6817499697208405e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1146 -9.2895999550819397e-02</internalNodes>
          <leafValues>
            -1.5794529914855957e+00 1.4661799371242523e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1147 -4.1184000670909882e-02</internalNodes>
          <leafValues>
            -1.5518720149993896e+00 -2.9969999566674232e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1148 2.1447999402880669e-02</internalNodes>
          <leafValues>
            1.7196300625801086e-01 -6.9343197345733643e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1149 -2.5569999590516090e-02</internalNodes>
          <leafValues>
            -1.3061310052871704e+00 -2.4336999282240868e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1150 -4.1200999170541763e-02</internalNodes>
          <leafValues>
            -1.3821059465408325e+00 1.4801800251007080e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1151 -1.7668999731540680e-02</internalNodes>
          <leafValues>
            -7.0889997482299805e-01 3.6524001508951187e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1152 9.0060001239180565e-03</internalNodes>
          <leafValues>
            -4.0913999080657959e-02 8.0373102426528931e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1153 -1.1652999557554722e-02</internalNodes>
          <leafValues>
            5.7546800374984741e-01 -2.4991700053215027e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1154 -7.4780001305043697e-03</internalNodes>
          <leafValues>
            -4.9280899763107300e-01 1.9810900092124939e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1155 8.5499999113380909e-04</internalNodes>
          <leafValues>
            -4.8858100175857544e-01 1.3563099503517151e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1156 -3.0538000166416168e-02</internalNodes>
          <leafValues>
            -6.0278397798538208e-01 1.8522000312805176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1157 -1.8846999853849411e-02</internalNodes>
          <leafValues>
            2.3565599322319031e-01 -3.5136300325393677e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1158 -8.1129996106028557e-03</internalNodes>
          <leafValues>
            -8.1304997205734253e-02 2.1069599688053131e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1159 -3.4830000251531601e-02</internalNodes>
          <leafValues>
            -1.2065670490264893e+00 -1.4251999557018280e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1160 1.9021000713109970e-02</internalNodes>
          <leafValues>
            2.3349900543689728e-01 -4.5664900541305542e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1161 -1.9004000350832939e-02</internalNodes>
          <leafValues>
            -8.1075799465179443e-01 1.3140000402927399e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1162 -8.9057996869087219e-02</internalNodes>
          <leafValues>
            6.1542397737503052e-01 3.2983001321554184e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1163 6.8620000965893269e-03</internalNodes>
          <leafValues>
            -2.9583099484443665e-01 2.7003699541091919e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1164 -2.8240999206900597e-02</internalNodes>
          <leafValues>
            -6.1102700233459473e-01 1.7357499897480011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1165 -3.2099999953061342e-04</internalNodes>
          <leafValues>
            -5.3322899341583252e-01 6.8539001047611237e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1166 -1.0829100012779236e-01</internalNodes>
          <leafValues>
            -1.2879559993743896e+00 1.1801700294017792e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1167 1.5878999605774879e-02</internalNodes>
          <leafValues>
            -1.7072600126266479e-01 1.1103910207748413e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1168 8.6859995499253273e-03</internalNodes>
          <leafValues>
            -1.0995099693536758e-01 4.6010500192642212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1169 -2.5234999135136604e-02</internalNodes>
          <leafValues>
            1.0220669507980347e+00 -1.8694299459457397e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1170 -1.3508999720215797e-02</internalNodes>
          <leafValues>
            -7.8316599130630493e-01 1.4202600717544556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1171 -7.7149998396635056e-03</internalNodes>
          <leafValues>
            -8.8060700893402100e-01 1.1060000397264957e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1172 7.1580000221729279e-02</internalNodes>
          <leafValues>
            1.1369399726390839e-01 -1.1032789945602417e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1173 -1.3554000295698643e-02</internalNodes>
          <leafValues>
            -8.1096500158309937e-01 3.4080001059919596e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1174 2.9450000729411840e-03</internalNodes>
          <leafValues>
            -7.2879999876022339e-02 3.4998100996017456e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1175 -5.0833001732826233e-02</internalNodes>
          <leafValues>
            -1.2868590354919434e+00 -2.8842000290751457e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1176 -8.7989997118711472e-03</internalNodes>
          <leafValues>
            4.7613599896430969e-01 -1.4690400660037994e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1177 2.1424399316310883e-01</internalNodes>
          <leafValues>
            -5.9702001512050629e-02 -2.4802260398864746e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1178 1.3962999917566776e-02</internalNodes>
          <leafValues>
            1.7420299351215363e-01 -4.3911001086235046e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1179 4.2502000927925110e-02</internalNodes>
          <leafValues>
            -1.9965299963951111e-01 7.0654797554016113e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1180 1.9827999174594879e-02</internalNodes>
          <leafValues>
            -6.9136001169681549e-02 6.1643397808074951e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1181 -3.3560000360012054e-02</internalNodes>
          <leafValues>
            -1.2740780115127563e+00 -2.5673000141978264e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1182 6.3542999327182770e-02</internalNodes>
          <leafValues>
            1.2403500080108643e-01 -1.0776289701461792e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1183 2.1933000534772873e-02</internalNodes>
          <leafValues>
            1.4952000230550766e-02 -7.1023499965667725e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1184 -7.8424997627735138e-02</internalNodes>
          <leafValues>
            6.2033998966217041e-01 3.3610999584197998e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1185 1.4390000142157078e-02</internalNodes>
          <leafValues>
            -3.6324599385261536e-01 1.7308300733566284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1186 -6.7309997975826263e-02</internalNodes>
          <leafValues>
            5.2374100685119629e-01 1.2799999676644802e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1187 1.3047499954700470e-01</internalNodes>
          <leafValues>
            -1.7122499644756317e-01 1.1235200166702271e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1188 -4.6245999634265900e-02</internalNodes>
          <leafValues>
            -1.1908329725265503e+00 1.7425599694252014e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1189 -2.9842000454664230e-02</internalNodes>
          <leafValues>
            8.3930599689483643e-01 -1.8064199388027191e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1190 -3.8099999073892832e-04</internalNodes>
          <leafValues>
            3.5532799363136292e-01 -2.3842300474643707e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1191 -2.2378999739885330e-02</internalNodes>
          <leafValues>
            -8.7943899631500244e-01 -7.8399997437372804e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1192 -1.5569999814033508e-03</internalNodes>
          <leafValues>
            -1.4253300428390503e-01 2.5876200199127197e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1193 1.2013000436127186e-02</internalNodes>
          <leafValues>
            -2.9015499353408813e-01 2.6051101088523865e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1194 2.4384999647736549e-02</internalNodes>
          <leafValues>
            -3.1438998878002167e-02 5.8695900440216064e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1195 -4.7180999070405960e-02</internalNodes>
          <leafValues>
            6.9430100917816162e-01 -2.1816100180149078e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1196 -2.4893999099731445e-02</internalNodes>
          <leafValues>
            -6.4599299430847168e-01 1.5611599385738373e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1197 2.1944999694824219e-02</internalNodes>
          <leafValues>
            -2.7742000296711922e-02 -1.1346880197525024e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1198 1.8809899687767029e-01</internalNodes>
          <leafValues>
            -1.0076000355184078e-02 1.2429029941558838e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1199 -7.7872000634670258e-02</internalNodes>
          <leafValues>
            8.5008001327514648e-01 -1.9015499949455261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1200 -4.8769000917673111e-02</internalNodes>
          <leafValues>
            -2.0763080120086670e+00 1.2179400026798248e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1201 -1.7115000635385513e-02</internalNodes>
          <leafValues>
            -8.5687297582626343e-01 7.8760003671050072e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1202 -2.7499999850988388e-03</internalNodes>
          <leafValues>
            3.8645499944686890e-01 -1.1391499638557434e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1203 -9.8793998360633850e-02</internalNodes>
          <leafValues>
            -1.7233899831771851e+00 -5.6063000112771988e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1204 -2.1936999633908272e-02</internalNodes>
          <leafValues>
            5.4749399423599243e-01 -4.2481999844312668e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1205 6.1096999794244766e-02</internalNodes>
          <leafValues>
            -3.8945000618696213e-02 -1.0807880163192749e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1206 -2.4563999846577644e-02</internalNodes>
          <leafValues>
            5.8311098814010620e-01 -9.7599998116493225e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1207 3.3752001821994781e-02</internalNodes>
          <leafValues>
            -1.3795999810099602e-02 -8.4730297327041626e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1208 3.8199000060558319e-02</internalNodes>
          <leafValues>
            1.5114299952983856e-01 -7.9473400115966797e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1209 -2.0117999985814095e-02</internalNodes>
          <leafValues>
            5.1579099893569946e-01 -2.1445399522781372e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1210 2.4734999984502792e-02</internalNodes>
          <leafValues>
            -2.2105000913143158e-02 4.2917698621749878e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1211 -2.4357000365853310e-02</internalNodes>
          <leafValues>
            -8.6201298236846924e-01 -3.6760000512003899e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1212 -2.6442000642418861e-02</internalNodes>
          <leafValues>
            -4.5397499203681946e-01 2.2462800145149231e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1213 -3.4429999068379402e-03</internalNodes>
          <leafValues>
            1.3073000311851501e-01 -3.8622701168060303e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1214 1.0701700299978256e-01</internalNodes>
          <leafValues>
            1.3158600032329559e-01 -7.9306900501251221e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1215 4.5152999460697174e-02</internalNodes>
          <leafValues>
            -2.5296801328659058e-01 4.0672400593757629e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1216 4.4349998235702515e-02</internalNodes>
          <leafValues>
            2.2613000124692917e-02 7.9618102312088013e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1217 1.0839999886229634e-03</internalNodes>
          <leafValues>
            -3.9158400893211365e-01 1.1639100313186646e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1218 7.1433000266551971e-02</internalNodes>
          <leafValues>
            8.2466997206211090e-02 1.2530590295791626e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1219 3.5838000476360321e-02</internalNodes>
          <leafValues>
            -1.8203300237655640e-01 7.7078700065612793e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1220 -2.0839000120759010e-02</internalNodes>
          <leafValues>
            -6.1744397878646851e-01 1.5891399979591370e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1221 4.2525801062583923e-01</internalNodes>
          <leafValues>
            -4.8978000879287720e-02 -1.8422030210494995e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1222 1.1408000253140926e-02</internalNodes>
          <leafValues>
            1.7918199300765991e-01 -1.5383499860763550e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1223 -1.5364999882876873e-02</internalNodes>
          <leafValues>
            -8.4016501903533936e-01 -1.0280000278726220e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1224 -1.5212000347673893e-02</internalNodes>
          <leafValues>
            -1.8995699286460876e-01 1.7130999267101288e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1225 -1.8972000107169151e-02</internalNodes>
          <leafValues>
            -7.9541999101638794e-01 6.6800001077353954e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1226 -3.3330000005662441e-03</internalNodes>
          <leafValues>
            -2.3530800640583038e-01 2.4730099737644196e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1227 9.3248002231121063e-02</internalNodes>
          <leafValues>
            -5.4758001118898392e-02 -1.8324300050735474e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1228 -1.2555000372231007e-02</internalNodes>
          <leafValues>
            2.6385200023651123e-01 -3.8526400923728943e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1229 -2.7070000767707825e-02</internalNodes>
          <leafValues>
            -6.6929799318313599e-01 2.0340999588370323e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1230 -2.3677000775933266e-02</internalNodes>
          <leafValues>
            6.7265301942825317e-01 -1.4344000257551670e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1231 -1.4275000430643559e-02</internalNodes>
          <leafValues>
            3.0186399817466736e-01 -2.8514400124549866e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1232 2.8096999973058701e-02</internalNodes>
          <leafValues>
            1.4766000211238861e-01 -1.4078520536422729e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1233 5.0840001553297043e-02</internalNodes>
          <leafValues>
            -1.8613600730895996e-01 7.9953002929687500e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1234 1.1505999602377415e-02</internalNodes>
          <leafValues>
            1.9118399918079376e-01 -8.5035003721714020e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1235 -1.4661000110208988e-02</internalNodes>
          <leafValues>
            4.5239299535751343e-01 -2.2205199301242828e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1236 2.2842499613761902e-01</internalNodes>
          <leafValues>
            1.3488399982452393e-01 -1.2894610166549683e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1237 1.1106900125741959e-01</internalNodes>
          <leafValues>
            -2.0753799378871918e-01 5.4561597108840942e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1238 3.2450000289827585e-03</internalNodes>
          <leafValues>
            3.2053700089454651e-01 -1.6403500735759735e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1239 8.5309997200965881e-02</internalNodes>
          <leafValues>
            -2.0210500061511993e-01 5.3296798467636108e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1240 2.2048000246286392e-02</internalNodes>
          <leafValues>
            1.5698599815368652e-01 -1.7014099657535553e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1241 -1.5676999464631081e-02</internalNodes>
          <leafValues>
            -6.2863498926162720e-01 4.0761999785900116e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1242 3.3112901449203491e-01</internalNodes>
          <leafValues>
            1.6609300673007965e-01 -1.0326379537582397e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1243 8.8470000773668289e-03</internalNodes>
          <leafValues>
            -2.5076198577880859e-01 3.1660598516464233e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1244 4.6080000698566437e-02</internalNodes>
          <leafValues>
            1.5352100133895874e-01 -1.6333500146865845e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1245 -3.7703000009059906e-02</internalNodes>
          <leafValues>
            5.6873798370361328e-01 -2.0102599263191223e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>159</maxWeakCount>
      <stageThreshold>-3.5939640998840332e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1246 -8.1808999180793762e-02</internalNodes>
          <leafValues>
            5.7124799489974976e-01 -6.7438799142837524e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1247 2.1761199831962585e-01</internalNodes>
          <leafValues>
            -3.8610199093818665e-01 9.0343999862670898e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1248 1.4878000132739544e-02</internalNodes>
          <leafValues>
            2.2241599857807159e-01 -1.2779350280761719e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1249 5.2434999495744705e-02</internalNodes>
          <leafValues>
            -2.8690400719642639e-01 7.5742298364639282e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1250 9.1429995372891426e-03</internalNodes>
          <leafValues>
            -6.4880400896072388e-01 2.2268800437450409e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1251 7.9169999808073044e-03</internalNodes>
          <leafValues>
            -2.9253599047660828e-01 3.1030198931694031e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1252 -2.6084000244736671e-02</internalNodes>
          <leafValues>
            4.5532700419425964e-01 -3.8500601053237915e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1253 -2.9400000348687172e-03</internalNodes>
          <leafValues>
            -5.1264399290084839e-01 2.7432298660278320e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1254 5.7130001485347748e-02</internalNodes>
          <leafValues>
            1.5788000077009201e-02 -1.2133100032806396e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1255 -6.1309998854994774e-03</internalNodes>
          <leafValues>
            3.9174601435661316e-01 -3.0866798758506775e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1256 -4.0405001491308212e-02</internalNodes>
          <leafValues>
            1.1901949644088745e+00 -2.0347100496292114e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1257 -2.0297000184655190e-02</internalNodes>
          <leafValues>
            -6.8239498138427734e-01 2.0458699762821198e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1258 -1.7188999801874161e-02</internalNodes>
          <leafValues>
            -8.4939897060394287e-01 3.8433000445365906e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1259 -2.4215999990701675e-02</internalNodes>
          <leafValues>
            -1.1039420366287231e+00 1.5975099802017212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1260 5.6869000196456909e-02</internalNodes>
          <leafValues>
            -1.9595299661159515e-01 1.1806850433349609e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1261 3.6199999158270657e-04</internalNodes>
          <leafValues>
            -4.0847799181938171e-01 3.2938599586486816e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1262 9.9790003150701523e-03</internalNodes>
          <leafValues>
            -2.9673001170158386e-01 4.1547900438308716e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1263 -5.2625000476837158e-02</internalNodes>
          <leafValues>
            -1.3069299459457397e+00 1.7862600088119507e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1264 -1.3748999685049057e-02</internalNodes>
          <leafValues>
            2.3665800690650940e-01 -4.4536599516868591e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1265 -3.0517000705003738e-02</internalNodes>
          <leafValues>
            2.9018300771713257e-01 -1.1210100352764130e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1266 -3.0037501454353333e-01</internalNodes>
          <leafValues>
            -2.4237680435180664e+00 -4.2830999940633774e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1267 -3.5990998148918152e-02</internalNodes>
          <leafValues>
            8.8206499814987183e-01 -4.7012999653816223e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1268 -5.5112000554800034e-02</internalNodes>
          <leafValues>
            8.0119001865386963e-01 -2.0490999519824982e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1269 3.3762000501155853e-02</internalNodes>
          <leafValues>
            1.4617599546909332e-01 -1.1349489688873291e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1270 -8.2710003480315208e-03</internalNodes>
          <leafValues>
            -8.1604897975921631e-01 1.8988000229001045e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1271 -5.4399999789893627e-03</internalNodes>
          <leafValues>
            -7.0980900526046753e-01 2.2343699634075165e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1272 3.1059999018907547e-03</internalNodes>
          <leafValues>
            -7.2808599472045898e-01 4.0224999189376831e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1273 5.3651999682188034e-02</internalNodes>
          <leafValues>
            1.7170900106430054e-01 -1.1163710355758667e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1274 -1.2541399896144867e-01</internalNodes>
          <leafValues>
            2.7680370807647705e+00 -1.4611500501632690e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1275 9.2542000114917755e-02</internalNodes>
          <leafValues>
            1.1609800159931183e-01 -3.9635529518127441e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1276 3.8513999432325363e-02</internalNodes>
          <leafValues>
            -7.6399999670684338e-03 -9.8780900239944458e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1277 -2.0200000144541264e-03</internalNodes>
          <leafValues>
            2.3059999942779541e-01 -7.4970299005508423e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1278 9.7599998116493225e-03</internalNodes>
          <leafValues>
            -3.1137999892234802e-01 3.0287799239158630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1279 2.4095000699162483e-02</internalNodes>
          <leafValues>
            -4.9529999494552612e-02 5.2690100669860840e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1280 -1.7982000485062599e-02</internalNodes>
          <leafValues>
            -1.1610640287399292e+00 -5.7000000961124897e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1281 -1.0555000044405460e-02</internalNodes>
          <leafValues>
            -2.7189099788665771e-01 2.3597699403762817e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1282 -7.2889998555183411e-03</internalNodes>
          <leafValues>
            -5.4219102859497070e-01 8.1914000213146210e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1283 2.3939000442624092e-02</internalNodes>
          <leafValues>
            1.7975799739360809e-01 -6.7049497365951538e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1284 -1.8365999683737755e-02</internalNodes>
          <leafValues>
            6.2664300203323364e-01 -2.0970100164413452e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1285 1.5715999528765678e-02</internalNodes>
          <leafValues>
            2.4193699657917023e-01 -1.0444309711456299e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1286 -4.8804000020027161e-02</internalNodes>
          <leafValues>
            -9.4060599803924561e-01 -3.7519999314099550e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1287 6.7130001261830330e-03</internalNodes>
          <leafValues>
            -7.5432002544403076e-02 6.1575299501419067e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1288 9.7770001739263535e-03</internalNodes>
          <leafValues>
            3.9285000413656235e-02 -8.4810298681259155e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1289 1.4744999818503857e-02</internalNodes>
          <leafValues>
            1.6968999803066254e-01 -5.0906401872634888e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1290 9.7079001367092133e-02</internalNodes>
          <leafValues>
            -3.3103000372648239e-02 -1.2706379890441895e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1291 4.8285998404026031e-02</internalNodes>
          <leafValues>
            9.4329997897148132e-02 2.7203190326690674e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1292 9.7810002043843269e-03</internalNodes>
          <leafValues>
            -3.9533400535583496e-01 1.5363800525665283e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1293 -3.9893999695777893e-02</internalNodes>
          <leafValues>
            -2.2767400741577148e-01 1.3913999497890472e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1294 2.2848000749945641e-02</internalNodes>
          <leafValues>
            -2.7391999959945679e-01 3.4199500083923340e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1295 6.7179999314248562e-03</internalNodes>
          <leafValues>
            -1.0874299705028534e-01 4.8125401139259338e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1296 5.9599999338388443e-02</internalNodes>
          <leafValues>
            -4.9522001296281815e-02 -2.0117089748382568e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1297 6.9340001791715622e-03</internalNodes>
          <leafValues>
            1.5037499368190765e-01 -1.1271899938583374e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1298 1.5757000073790550e-02</internalNodes>
          <leafValues>
            -2.0885000005364418e-02 -1.1651979684829712e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1299 -4.9690000712871552e-02</internalNodes>
          <leafValues>
            -8.0213499069213867e-01 1.4372299611568451e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1300 5.2347000688314438e-02</internalNodes>
          <leafValues>
            -2.0836700499057770e-01 6.1677598953247070e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1301 2.2430999204516411e-02</internalNodes>
          <leafValues>
            2.0305900275707245e-01 -7.5326198339462280e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1302 4.1142001748085022e-02</internalNodes>
          <leafValues>
            -1.8118199706077576e-01 1.0033359527587891e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1303 -2.1632000803947449e-02</internalNodes>
          <leafValues>
            4.9998998641967773e-01 -3.4662999212741852e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1304 -8.2808002829551697e-02</internalNodes>
          <leafValues>
            1.1711900234222412e+00 -1.8433600664138794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1305 8.5060000419616699e-03</internalNodes>
          <leafValues>
            -6.3225001096725464e-02 2.9024899005889893e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1306 7.8905001282691956e-02</internalNodes>
          <leafValues>
            -2.3274500668048859e-01 5.9695798158645630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1307 -9.0207003057003021e-02</internalNodes>
          <leafValues>
            -8.2211899757385254e-01 1.7772200703620911e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1308 -2.9269000515341759e-02</internalNodes>
          <leafValues>
            6.0860699415206909e-01 -2.1468900144100189e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1309 6.9499998353421688e-03</internalNodes>
          <leafValues>
            -4.2665999382734299e-02 6.0512101650238037e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1310 -8.0629996955394745e-03</internalNodes>
          <leafValues>
            -1.1508270502090454e+00 -2.7286000549793243e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1311 1.9595999270677567e-02</internalNodes>
          <leafValues>
            -9.1880001127719879e-03 5.6857800483703613e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1312 -1.4884999953210354e-02</internalNodes>
          <leafValues>
            3.7658798694610596e-01 -2.7149501442909241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1313 2.5217000395059586e-02</internalNodes>
          <leafValues>
            -9.9991001188755035e-02 2.4664700031280518e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1314 -1.5855999663472176e-02</internalNodes>
          <leafValues>
            6.6826701164245605e-01 -2.0614700019359589e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1315 2.9441000893712044e-02</internalNodes>
          <leafValues>
            1.5832200646400452e-01 -7.6060897111892700e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1316 -8.5279997438192368e-03</internalNodes>
          <leafValues>
            3.8212299346923828e-01 -2.5407800078392029e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1317 2.4421999230980873e-02</internalNodes>
          <leafValues>
            1.5105099976062775e-01 -2.8752899169921875e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1318 -3.3886998891830444e-02</internalNodes>
          <leafValues>
            -6.8002802133560181e-01 3.4327000379562378e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1319 -2.0810000132769346e-03</internalNodes>
          <leafValues>
            2.5413900613784790e-01 -2.6859098672866821e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1320 3.0358999967575073e-02</internalNodes>
          <leafValues>
            -3.0842000618577003e-02 -1.1476809978485107e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1321 4.0210001170635223e-03</internalNodes>
          <leafValues>
            -3.5253798961639404e-01 2.9868099093437195e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1322 2.7681000530719757e-02</internalNodes>
          <leafValues>
            -3.8148999214172363e-02 -1.3262039422988892e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1323 7.9039996489882469e-03</internalNodes>
          <leafValues>
            -2.3737000301480293e-02 7.0503002405166626e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1324 4.4031001627445221e-02</internalNodes>
          <leafValues>
            1.0674899816513062e-01 -4.5261201262474060e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1325 -3.2370999455451965e-02</internalNodes>
          <leafValues>
            4.6674901247024536e-01 -6.1546999961137772e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1326 2.0933000370860100e-02</internalNodes>
          <leafValues>
            -2.8447899222373962e-01 4.3845599889755249e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1327 2.5227999314665794e-02</internalNodes>
          <leafValues>
            -2.2537000477313995e-02 7.0389097929000854e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1328 6.5520000644028187e-03</internalNodes>
          <leafValues>
            -3.2554900646209717e-01 2.4023699760437012e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1329 -5.8557998389005661e-02</internalNodes>
          <leafValues>
            -1.2227720022201538e+00 1.1668799817562103e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1330 3.1899999827146530e-02</internalNodes>
          <leafValues>
            -1.9305000081658363e-02 -1.0973169803619385e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1331 -3.0445000156760216e-02</internalNodes>
          <leafValues>
            6.5582501888275146e-01 7.5090996921062469e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1332 1.4933000318706036e-02</internalNodes>
          <leafValues>
            -5.2155798673629761e-01 1.1523099988698959e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1333 -4.9008000642061234e-02</internalNodes>
          <leafValues>
            -7.8303998708724976e-01 1.6657200455665588e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1334 8.3158999681472778e-02</internalNodes>
          <leafValues>
            -2.6879999786615372e-03 -8.5282301902770996e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1335 2.3902999237179756e-02</internalNodes>
          <leafValues>
            -5.1010999828577042e-02 4.1999098658561707e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1336 1.6428999602794647e-02</internalNodes>
          <leafValues>
            1.9232999533414841e-02 -6.5049099922180176e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1337 -1.1838000267744064e-02</internalNodes>
          <leafValues>
            -6.2409800291061401e-01 1.5411199629306793e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1338 -1.6799999866634607e-04</internalNodes>
          <leafValues>
            1.7589199542999268e-01 -3.4338700771331787e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1339 1.9193999469280243e-02</internalNodes>
          <leafValues>
            4.3418999761343002e-02 7.9069197177886963e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1340 -1.0032000020146370e-02</internalNodes>
          <leafValues>
            4.5648899674415588e-01 -2.2494800388813019e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1341 -1.4004000462591648e-02</internalNodes>
          <leafValues>
            3.3570998907089233e-01 -4.8799999058246613e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1342 -1.0319899767637253e-01</internalNodes>
          <leafValues>
            -2.3378000259399414e+00 -5.8933001011610031e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1343 -9.5697000622749329e-02</internalNodes>
          <leafValues>
            -6.6153901815414429e-01 2.0098599791526794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1344 -4.1480999439954758e-02</internalNodes>
          <leafValues>
            4.5939201116561890e-01 -2.2314099967479706e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1345 2.4099999573081732e-03</internalNodes>
          <leafValues>
            -2.6898598670959473e-01 2.4922999739646912e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1346 1.0724999755620956e-01</internalNodes>
          <leafValues>
            -1.8640199303627014e-01 7.2769802808761597e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1347 3.1870000530034304e-03</internalNodes>
          <leafValues>
            -2.4608999490737915e-02 2.8643900156021118e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1348 2.9167000204324722e-02</internalNodes>
          <leafValues>
            -3.4683000296354294e-02 -1.1162580251693726e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1349 1.1287000030279160e-02</internalNodes>
          <leafValues>
            6.3760001212358475e-03 6.6632097959518433e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1350 -1.2001000344753265e-02</internalNodes>
          <leafValues>
            4.2420101165771484e-01 -2.6279801130294800e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1351 -1.2695999816060066e-02</internalNodes>
          <leafValues>
            -2.1957000717520714e-02 1.8936799466609955e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1352 2.4597000330686569e-02</internalNodes>
          <leafValues>
            -3.4963998943567276e-02 -1.0989320278167725e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1353 4.5953001827001572e-02</internalNodes>
          <leafValues>
            1.1109799891710281e-01 -2.9306049346923828e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1354 -2.7241000905632973e-02</internalNodes>
          <leafValues>
            2.9101699590682983e-01 -2.7407899498939514e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1355 4.0063999593257904e-02</internalNodes>
          <leafValues>
            1.1877900362014771e-01 -6.2801802158355713e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1356 2.3055000230669975e-02</internalNodes>
          <leafValues>
            1.4813800156116486e-01 -3.7007498741149902e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1357 -2.3737000301480293e-02</internalNodes>
          <leafValues>
            -5.3724801540374756e-01 1.9358199834823608e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1358 7.7522002160549164e-02</internalNodes>
          <leafValues>
            -6.0194000601768494e-02 -1.9489669799804688e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1359 -1.3345000334084034e-02</internalNodes>
          <leafValues>
            -4.5229598879814148e-01 1.8741500377655029e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1360 -2.1719999611377716e-02</internalNodes>
          <leafValues>
            1.2144249677658081e+00 -1.5365800261497498e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1361 -7.1474999189376831e-02</internalNodes>
          <leafValues>
            -2.3047130107879639e+00 1.0999900102615356e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1362 -5.4999999701976776e-03</internalNodes>
          <leafValues>
            -7.1855199337005615e-01 2.0100999623537064e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1363 2.6740999892354012e-02</internalNodes>
          <leafValues>
            7.3545001447200775e-02 9.8786002397537231e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1364 -3.9407998323440552e-02</internalNodes>
          <leafValues>
            -1.2227380275726318e+00 -4.3506998568773270e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1365 2.5888999924063683e-02</internalNodes>
          <leafValues>
            1.3409300148487091e-01 -1.1770780086517334e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1366 4.8925001174211502e-02</internalNodes>
          <leafValues>
            -3.0810000374913216e-02 -9.3479502201080322e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1367 3.6892998963594437e-02</internalNodes>
          <leafValues>
            1.3333700597286224e-01 -1.4998290538787842e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1368 7.8929997980594635e-02</internalNodes>
          <leafValues>
            -1.4538800716400146e-01 1.5631790161132812e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1369 2.9006000608205795e-02</internalNodes>
          <leafValues>
            1.9383700191974640e-01 -6.7642802000045776e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1370 6.3089998438954353e-03</internalNodes>
          <leafValues>
            -3.7465399503707886e-01 1.0857500135898590e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1371 -6.5830998122692108e-02</internalNodes>
          <leafValues>
            8.1059402227401733e-01 3.0201999470591545e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1372 -6.8965002894401550e-02</internalNodes>
          <leafValues>
            8.3772599697113037e-01 -1.7140999436378479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1373 -1.1669100075960159e-01</internalNodes>
          <leafValues>
            -9.4647198915481567e-01 1.3123199343681335e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1374 -1.3060000492259860e-03</internalNodes>
          <leafValues>
            4.6007998287677765e-02 -5.2011597156524658e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1375 -4.4558998197317123e-02</internalNodes>
          <leafValues>
            -1.9423669576644897e+00 1.3200700283050537e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1376 5.1033001393079758e-02</internalNodes>
          <leafValues>
            -2.1480999886989594e-01 4.8673900961875916e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1377 -3.1578000634908676e-02</internalNodes>
          <leafValues>
            5.9989798069000244e-01 7.9159997403621674e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1378 2.1020000800490379e-02</internalNodes>
          <leafValues>
            -2.2069500386714935e-01 5.4046201705932617e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1379 -1.3824200630187988e-01</internalNodes>
          <leafValues>
            6.2957501411437988e-01 -2.1712999790906906e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1380 5.2228998392820358e-02</internalNodes>
          <leafValues>
            -2.3360900580883026e-01 4.9760800600051880e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1381 2.5884000584483147e-02</internalNodes>
          <leafValues>
            1.8041999638080597e-01 -2.2039200365543365e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1382 -1.2138999998569489e-02</internalNodes>
          <leafValues>
            -6.9731897115707397e-01 1.5712000429630280e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1383 -2.4237999692559242e-02</internalNodes>
          <leafValues>
            3.4593299031257629e-01 7.1469999849796295e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1384 -2.5272000581026077e-02</internalNodes>
          <leafValues>
            -8.7583297491073608e-01 -9.8240002989768982e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1385 1.2597000226378441e-02</internalNodes>
          <leafValues>
            2.3649999499320984e-01 -2.8731200098991394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1386 5.7330999523401260e-02</internalNodes>
          <leafValues>
            -6.1530999839305878e-02 -2.2326040267944336e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1387 1.6671000048518181e-02</internalNodes>
          <leafValues>
            -1.9850100576877594e-01 4.0810701251029968e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1388 -2.2818999364972115e-02</internalNodes>
          <leafValues>
            9.6487599611282349e-01 -2.0245699584484100e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1389 3.7000001611886546e-05</internalNodes>
          <leafValues>
            -5.8908998966217041e-02 2.7055400609970093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1390 -7.6700001955032349e-03</internalNodes>
          <leafValues>
            -4.5317101478576660e-01 8.9628003537654877e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1391 9.4085998833179474e-02</internalNodes>
          <leafValues>
            1.1604599654674530e-01 -1.0951169729232788e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1392 -6.2267001718282700e-02</internalNodes>
          <leafValues>
            1.8096530437469482e+00 -1.4773200452327728e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1393 1.7416000366210938e-02</internalNodes>
          <leafValues>
            2.3068200051784515e-01 -4.2417600750923157e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1394 -2.2066000849008560e-02</internalNodes>
          <leafValues>
            4.9270299077033997e-01 -2.0630900561809540e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1395 -1.0404000058770180e-02</internalNodes>
          <leafValues>
            6.0924297571182251e-01 2.8130000457167625e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1396 -9.3670003116130829e-03</internalNodes>
          <leafValues>
            4.0171200037002563e-01 -2.1681700646877289e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1397 -2.9039999470114708e-02</internalNodes>
          <leafValues>
            -8.4876501560211182e-01 1.4246800541877747e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1398 -2.1061999723315239e-02</internalNodes>
          <leafValues>
            -7.9198300838470459e-01 -1.2595999985933304e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1399 -3.7000998854637146e-02</internalNodes>
          <leafValues>
            -6.7488902807235718e-01 1.2830400466918945e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1400 1.0735999792814255e-02</internalNodes>
          <leafValues>
            3.6779999732971191e-02 -6.3393002748489380e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1401 1.6367599368095398e-01</internalNodes>
          <leafValues>
            1.3803899288177490e-01 -4.7189000248908997e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1402 9.4917997717857361e-02</internalNodes>
          <leafValues>
            -1.3855700194835663e-01 1.9492419958114624e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1403 3.5261999815702438e-02</internalNodes>
          <leafValues>
            1.3721899688243866e-01 -2.1186530590057373e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1404 1.2811000458896160e-02</internalNodes>
          <leafValues>
            -2.0008100569248199e-01 4.9507799744606018e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>155</maxWeakCount>
      <stageThreshold>-3.3933560848236084e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1405 1.3904400169849396e-01</internalNodes>
          <leafValues>
            -4.6581199765205383e-01 7.6431602239608765e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1406 1.1916999705135822e-02</internalNodes>
          <leafValues>
            -9.4398999214172363e-01 3.9726299047470093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1407 -1.0006999596953392e-02</internalNodes>
          <leafValues>
            3.2718798518180847e-01 -6.3367402553558350e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1408 -6.0479999519884586e-03</internalNodes>
          <leafValues>
            2.7427899837493896e-01 -5.7446998357772827e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1409 -1.2489999644458294e-03</internalNodes>
          <leafValues>
            2.3629300296306610e-01 -6.8593502044677734e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1410 3.2382000237703323e-02</internalNodes>
          <leafValues>
            -5.7630199193954468e-01 2.7492699027061462e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1411 -1.3957999646663666e-02</internalNodes>
          <leafValues>
            -6.1061501502990723e-01 2.4541600048542023e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1412 1.1159999994561076e-03</internalNodes>
          <leafValues>
            -5.6539100408554077e-01 2.7179300785064697e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1413 2.7000000045518391e-05</internalNodes>
          <leafValues>
            -8.0235999822616577e-01 1.1509100347757339e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1414 -2.5700000696815550e-04</internalNodes>
          <leafValues>
            -8.1205898523330688e-01 2.3844699561595917e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1415 4.0460000745952129e-03</internalNodes>
          <leafValues>
            1.3909600675106049e-01 -6.6163200139999390e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1416 1.4356000348925591e-02</internalNodes>
          <leafValues>
            -1.6485199332237244e-01 4.1901698708534241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1417 -5.5374998599290848e-02</internalNodes>
          <leafValues>
            1.4425870180130005e+00 -1.8820199370384216e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1418 9.3594998121261597e-02</internalNodes>
          <leafValues>
            1.3548299670219421e-01 -9.1636097431182861e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1419 2.6624999940395355e-02</internalNodes>
          <leafValues>
            -3.3748298883438110e-01 3.9233601093292236e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1420 3.7469998933374882e-03</internalNodes>
          <leafValues>
            -1.1615400016307831e-01 4.4399300217628479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1421 -3.1886000186204910e-02</internalNodes>
          <leafValues>
            -9.9498301744461060e-01 1.6120000509545207e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1422 -2.2600000724196434e-02</internalNodes>
          <leafValues>
            -4.8067399859428406e-01 1.7007300257682800e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1423 2.5202000513672829e-02</internalNodes>
          <leafValues>
            3.5580001771450043e-02 -8.0215400457382202e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1424 -3.1036999076604843e-02</internalNodes>
          <leafValues>
            -1.0895340442657471e+00 1.8081900477409363e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1425 -2.6475999504327774e-02</internalNodes>
          <leafValues>
            9.5671200752258301e-01 -2.1049399673938751e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1426 -1.3853999786078930e-02</internalNodes>
          <leafValues>
            -1.0370320081710815e+00 2.2166700661182404e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1427 -6.2925003468990326e-02</internalNodes>
          <leafValues>
            9.0199398994445801e-01 -1.9085299968719482e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1428 -4.4750999659299850e-02</internalNodes>
          <leafValues>
            -1.0119110345840454e+00 1.4691199362277985e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1429 -2.0428000018000603e-02</internalNodes>
          <leafValues>
            6.1624497175216675e-01 -2.3552699387073517e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1430 -8.0329999327659607e-03</internalNodes>
          <leafValues>
            -8.3279997110366821e-02 2.1728700399398804e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1431 8.7280003353953362e-03</internalNodes>
          <leafValues>
            6.5458998084068298e-02 -6.0318702459335327e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1432 -2.7202000841498375e-02</internalNodes>
          <leafValues>
            -9.3447399139404297e-01 1.5270000696182251e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1433 -1.6471000388264656e-02</internalNodes>
          <leafValues>
            -8.4177100658416748e-01 1.3332000002264977e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1434 -1.3744000345468521e-02</internalNodes>
          <leafValues>
            6.0567200183868408e-01 -9.2021003365516663e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1435 2.9164999723434448e-02</internalNodes>
          <leafValues>
            -2.8114000335335732e-02 -1.4014569520950317e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1436 3.7457000464200974e-02</internalNodes>
          <leafValues>
            1.3080599904060364e-01 -4.9382498860359192e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1437 -2.5070000439882278e-02</internalNodes>
          <leafValues>
            -1.1289390325546265e+00 -1.4600000344216824e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1438 -6.3812002539634705e-02</internalNodes>
          <leafValues>
            7.5871598720550537e-01 -1.8200000049546361e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1439 -9.3900002539157867e-03</internalNodes>
          <leafValues>
            2.9936400055885315e-01 -2.9487800598144531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1440 -7.6000002445653081e-04</internalNodes>
          <leafValues>
            1.9725000485777855e-02 1.9993899762630463e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1441 -2.1740999072790146e-02</internalNodes>
          <leafValues>
            -8.5247898101806641e-01 4.9169998615980148e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1442 -1.7869999632239342e-02</internalNodes>
          <leafValues>
            -5.9985999017953873e-02 1.5222500264644623e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1443 -2.4831000715494156e-02</internalNodes>
          <leafValues>
            3.5603401064872742e-01 -2.6259899139404297e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1444 1.5715500712394714e-01</internalNodes>
          <leafValues>
            1.5599999460391700e-04 1.0428730249404907e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1445 6.9026999175548553e-02</internalNodes>
          <leafValues>
            -3.3006999641656876e-02 -1.1796669960021973e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1446 -1.1021999642252922e-02</internalNodes>
          <leafValues>
            5.8987700939178467e-01 -5.7647999376058578e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1447 -1.3834999874234200e-02</internalNodes>
          <leafValues>
            5.9502798318862915e-01 -2.4418599903583527e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1448 -3.0941000208258629e-02</internalNodes>
          <leafValues>
            -1.1723799705505371e+00 1.6907000541687012e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1449 2.1258000284433365e-02</internalNodes>
          <leafValues>
            -1.8900999799370766e-02 -1.0684759616851807e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1450 9.3079999089241028e-02</internalNodes>
          <leafValues>
            1.6305600106716156e-01 -1.3375270366668701e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1451 2.9635999351739883e-02</internalNodes>
          <leafValues>
            -2.2524799406528473e-01 4.5400100946426392e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1452 -1.2199999764561653e-04</internalNodes>
          <leafValues>
            2.7409100532531738e-01 -3.7371399998664856e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1453 -4.2098000645637512e-02</internalNodes>
          <leafValues>
            -7.5828802585601807e-01 1.7137000337243080e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1454 -2.2505000233650208e-02</internalNodes>
          <leafValues>
            -2.2759300470352173e-01 2.3698699474334717e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1455 -1.2862999923527241e-02</internalNodes>
          <leafValues>
            1.9252400100231171e-01 -3.2127100229263306e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1456 2.7860000729560852e-02</internalNodes>
          <leafValues>
            1.6723699867725372e-01 -1.0209059715270996e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1457 -2.7807999402284622e-02</internalNodes>
          <leafValues>
            1.2824759483337402e+00 -1.7225299775600433e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1458 -6.1630001291632652e-03</internalNodes>
          <leafValues>
            -5.4072898626327515e-01 2.3885700106620789e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1459 -2.0436000078916550e-02</internalNodes>
          <leafValues>
            6.3355398178100586e-01 -2.1090599894523621e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1460 -1.2307999655604362e-02</internalNodes>
          <leafValues>
            -4.9778199195861816e-01 1.7402599751949310e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1461 -4.0493998676538467e-02</internalNodes>
          <leafValues>
            -1.1848740577697754e+00 -3.3890999853610992e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1462 2.9657000675797462e-02</internalNodes>
          <leafValues>
            2.1740999072790146e-02 1.0069919824600220e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1463 6.8379999138414860e-03</internalNodes>
          <leafValues>
            2.9217999428510666e-02 -5.9906297922134399e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1464 1.6164999455213547e-02</internalNodes>
          <leafValues>
            -2.1000799536705017e-01 3.7637299299240112e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1465 5.0193000584840775e-02</internalNodes>
          <leafValues>
            2.5319999549537897e-03 -7.1668201684951782e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1466 1.9680000841617584e-03</internalNodes>
          <leafValues>
            -2.1921400725841522e-01 3.2298699021339417e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1467 2.4979999288916588e-02</internalNodes>
          <leafValues>
            -9.6840001642704010e-03 -7.7572900056838989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1468 -1.5809999778866768e-02</internalNodes>
          <leafValues>
            4.4637501239776611e-01 -6.1760000884532928e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1469 3.7206999957561493e-02</internalNodes>
          <leafValues>
            -2.0495399832725525e-01 5.7722198963165283e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1470 -7.9264998435974121e-02</internalNodes>
          <leafValues>
            -7.6745402812957764e-01 1.2550400197505951e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1471 -1.7152000218629837e-02</internalNodes>
          <leafValues>
            -1.4121830463409424e+00 -5.1704000681638718e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1472 3.2740000635385513e-02</internalNodes>
          <leafValues>
            1.9334000349044800e-01 -6.3633698225021362e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1473 -1.1756999790668488e-01</internalNodes>
          <leafValues>
            8.4325402975082397e-01 -1.8018600344657898e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1474 1.2057200074195862e-01</internalNodes>
          <leafValues>
            1.2530000507831573e-01 -2.1213600635528564e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1475 4.2779999785125256e-03</internalNodes>
          <leafValues>
            -4.6604400873184204e-01 8.9643999934196472e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1476 -7.2544999420642853e-02</internalNodes>
          <leafValues>
            5.1826500892639160e-01 1.6823999583721161e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1477 1.7710599303245544e-01</internalNodes>
          <leafValues>
            -3.0910000205039978e-02 -1.1046639680862427e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1478 8.4229996427893639e-03</internalNodes>
          <leafValues>
            2.4445800483226776e-01 -3.8613098859786987e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1479 -1.3035000301897526e-02</internalNodes>
          <leafValues>
            9.8004400730133057e-01 -1.7016500234603882e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1480 1.8912000581622124e-02</internalNodes>
          <leafValues>
            2.0248499512672424e-01 -3.8545900583267212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1481 2.1447999402880669e-02</internalNodes>
          <leafValues>
            -2.5717198848724365e-01 3.5181200504302979e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1482 6.3357003033161163e-02</internalNodes>
          <leafValues>
            1.6994799673557281e-01 -9.1383802890777588e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1483 -3.2435998320579529e-02</internalNodes>
          <leafValues>
            -8.5681599378585815e-01 -2.1680999547243118e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1484 -2.3564999923110008e-02</internalNodes>
          <leafValues>
            5.6115597486495972e-01 -2.2400000307243317e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1485 1.8789000809192657e-02</internalNodes>
          <leafValues>
            -2.5459799170494080e-01 3.4512901306152344e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1486 3.1042000278830528e-02</internalNodes>
          <leafValues>
            7.5719999149441719e-03 3.4800198674201965e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1487 -1.1226999573409557e-02</internalNodes>
          <leafValues>
            -6.0219800472259521e-01 4.2814999818801880e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1488 -1.2845999561250210e-02</internalNodes>
          <leafValues>
            4.2020401358604431e-01 -5.3801000118255615e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1489 -1.2791999615728855e-02</internalNodes>
          <leafValues>
            2.2724500298500061e-01 -3.2398000359535217e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1490 6.8651996552944183e-02</internalNodes>
          <leafValues>
            9.3532003462314606e-02 10.</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1491 5.2789999172091484e-03</internalNodes>
          <leafValues>
            -2.6926299929618835e-01 3.3303201198577881e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1492 -3.8779001682996750e-02</internalNodes>
          <leafValues>
            -7.2365301847457886e-01 1.7806500196456909e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1493 6.1820000410079956e-03</internalNodes>
          <leafValues>
            -3.5119399428367615e-01 1.6586300730705261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1494 1.7515200376510620e-01</internalNodes>
          <leafValues>
            1.1623100191354752e-01 -1.5419290065765381e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1495 1.1627999693155289e-01</internalNodes>
          <leafValues>
            -9.1479998081922531e-03 -9.9842602014541626e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1496 -2.2964000701904297e-02</internalNodes>
          <leafValues>
            2.0565399527549744e-01 1.5432000160217285e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1497 -5.1410000771284103e-02</internalNodes>
          <leafValues>
            5.8072400093078613e-01 -2.0118400454521179e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1498 2.2474199533462524e-01</internalNodes>
          <leafValues>
            1.8728999421000481e-02 1.0829299688339233e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1499 9.4860000535845757e-03</internalNodes>
          <leafValues>
            -3.3171299099922180e-01 1.9902999699115753e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1500 -1.1846300214529037e-01</internalNodes>
          <leafValues>
            1.3711010217666626e+00 6.8926997482776642e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1501 3.7810999900102615e-02</internalNodes>
          <leafValues>
            -9.3600002583116293e-04 -8.3996999263763428e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1502 2.2202000021934509e-02</internalNodes>
          <leafValues>
            -1.1963999830186367e-02 3.6673998832702637e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1503 -3.6366000771522522e-02</internalNodes>
          <leafValues>
            3.7866500020027161e-01 -2.7714800834655762e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1504 -1.3184699416160583e-01</internalNodes>
          <leafValues>
            -2.7481179237365723e+00 1.0666900128126144e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1505 -4.1655998677015305e-02</internalNodes>
          <leafValues>
            4.7524300217628479e-01 -2.3249800503253937e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1506 -3.3151999115943909e-02</internalNodes>
          <leafValues>
            -5.7929402589797974e-01 1.7434400320053101e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1507 1.5769999474287033e-02</internalNodes>
          <leafValues>
            -1.1284000240266323e-02 -8.3701401948928833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1508 -3.9363000541925430e-02</internalNodes>
          <leafValues>
            3.4821599721908569e-01 -1.7455400526523590e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1509 -6.7849002778530121e-02</internalNodes>
          <leafValues>
            1.4225699901580811e+00 -1.4765599370002747e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1510 -2.6775000616908073e-02</internalNodes>
          <leafValues>
            2.3947000503540039e-01 1.3271999545395374e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1511 3.9919000118970871e-02</internalNodes>
          <leafValues>
            -8.9999996125698090e-03 -7.5938898324966431e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1512 1.0065600275993347e-01</internalNodes>
          <leafValues>
            -1.8685000017285347e-02 7.6245301961898804e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1513 -8.1022001802921295e-02</internalNodes>
          <leafValues>
            -9.0439099073410034e-01 -8.5880002006888390e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1514 -2.1258000284433365e-02</internalNodes>
          <leafValues>
            -2.1319599449634552e-01 2.1919700503349304e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1515 -1.0630999691784382e-02</internalNodes>
          <leafValues>
            1.9598099589347839e-01 -3.5768100619316101e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1516 8.1300002057105303e-04</internalNodes>
          <leafValues>
            -9.2794999480247498e-02 2.6145899295806885e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1517 3.4650000743567944e-03</internalNodes>
          <leafValues>
            -5.5336099863052368e-01 2.7386000379920006e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1518 1.8835999071598053e-02</internalNodes>
          <leafValues>
            1.8446099758148193e-01 -6.6934299468994141e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1519 -2.5631999596953392e-02</internalNodes>
          <leafValues>
            1.9382879734039307e+00 -1.4708900451660156e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1520 -4.0939999744296074e-03</internalNodes>
          <leafValues>
            -2.6451599597930908e-01 2.0733200013637543e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1521 -8.9199998183175921e-04</internalNodes>
          <leafValues>
            -5.5031597614288330e-01 5.0374999642372131e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1522 -4.9518000334501266e-02</internalNodes>
          <leafValues>
            -2.5615389347076416e+00 1.3141700625419617e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1523 1.1680999770760536e-02</internalNodes>
          <leafValues>
            -2.4819800257682800e-01 3.9982700347900391e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1524 3.4563999623060226e-02</internalNodes>
          <leafValues>
            1.6178800165653229e-01 -7.1418899297714233e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1525 -8.2909995689988136e-03</internalNodes>
          <leafValues>
            2.2180099785327911e-01 -2.9181700944900513e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1526 -2.2358000278472900e-02</internalNodes>
          <leafValues>
            3.1044098734855652e-01 -2.7280000504106283e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1527 -3.0801000073552132e-02</internalNodes>
          <leafValues>
            -9.5672702789306641e-01 -8.3400001749396324e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1528 4.3779000639915466e-02</internalNodes>
          <leafValues>
            1.2556900084018707e-01 -1.1759619712829590e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1529 4.3046001344919205e-02</internalNodes>
          <leafValues>
            -5.8876998722553253e-02 -1.8568470478057861e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1530 2.7188999578356743e-02</internalNodes>
          <leafValues>
            4.2858000844717026e-02 3.9036700129508972e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1531 9.4149997457861900e-03</internalNodes>
          <leafValues>
            -4.3567001819610596e-02 -1.1094470024108887e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1532 9.4311997294425964e-02</internalNodes>
          <leafValues>
            4.0256999433040619e-02 9.8442298173904419e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1533 1.7025099694728851e-01</internalNodes>
          <leafValues>
            2.9510000720620155e-02 -6.9509297609329224e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1534 -4.7148000448942184e-02</internalNodes>
          <leafValues>
            1.0338569879531860e+00 6.7602001130580902e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1535 1.1186300218105316e-01</internalNodes>
          <leafValues>
            -6.8682998418807983e-02 -2.4985830783843994e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1536 -1.4353999868035316e-02</internalNodes>
          <leafValues>
            -5.9481900930404663e-01 1.5001699328422546e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1537 3.4024000167846680e-02</internalNodes>
          <leafValues>
            -6.4823001623153687e-02 -2.1382639408111572e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1538 2.1601999178528786e-02</internalNodes>
          <leafValues>
            5.5309999734163284e-02 7.8292900323867798e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1539 2.1771999076008797e-02</internalNodes>
          <leafValues>
            -7.1279997937381268e-03 -7.2148102521896362e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1540 8.2416996359825134e-02</internalNodes>
          <leafValues>
            1.4609499275684357e-01 -1.3636670112609863e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1541 8.4671996533870697e-02</internalNodes>
          <leafValues>
            -1.7784699797630310e-01 7.2857701778411865e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1542 -5.5128000676631927e-02</internalNodes>
          <leafValues>
            -5.9402400255203247e-01 1.9357800483703613e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1543 -6.4823001623153687e-02</internalNodes>
          <leafValues>
            -1.0783840417861938e+00 -4.0734000504016876e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1544 -2.2769000381231308e-02</internalNodes>
          <leafValues>
            7.7900201082229614e-01 3.4960000775754452e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1545 5.4756000638008118e-02</internalNodes>
          <leafValues>
            -6.5683998167514801e-02 -1.8188409805297852e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1546 -8.9000001025851816e-05</internalNodes>
          <leafValues>
            -1.7891999334096909e-02 2.0768299698829651e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1547 9.8361998796463013e-02</internalNodes>
          <leafValues>
            -5.5946998298168182e-02 -1.4153920412063599e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1548 -7.0930002257227898e-03</internalNodes>
          <leafValues>
            3.4135299921035767e-01 -1.2089899927377701e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1549 5.0278000533580780e-02</internalNodes>
          <leafValues>
            -2.6286700367927551e-01 2.5797298550605774e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1550 -5.7870000600814819e-03</internalNodes>
          <leafValues>
            -1.3178600370883942e-01 1.7350199818611145e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1551 1.3973999768495560e-02</internalNodes>
          <leafValues>
            2.8518000617623329e-02 -6.1152201890945435e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1552 2.1449999883770943e-02</internalNodes>
          <leafValues>
            2.6181999593973160e-02 3.0306598544120789e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1553 -2.9214000329375267e-02</internalNodes>
          <leafValues>
            4.4940599799156189e-01 -2.2803099453449249e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1554 4.8099999548867345e-04</internalNodes>
          <leafValues>
            -1.9879999756813049e-01 2.0744499564170837e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1555 1.7109999898821115e-03</internalNodes>
          <leafValues>
            -5.4037201404571533e-01 6.7865997552871704e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1556 8.6660003289580345e-03</internalNodes>
          <leafValues>
            -1.3128000311553478e-02 5.2297902107238770e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1557 6.3657999038696289e-02</internalNodes>
          <leafValues>
            6.8299002945423126e-02 -4.9235099554061890e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1558 -2.7968000620603561e-02</internalNodes>
          <leafValues>
            6.8183898925781250e-01 7.8781001269817352e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1559 4.8953998833894730e-02</internalNodes>
          <leafValues>
            -2.0622399449348450e-01 5.0388097763061523e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>169</maxWeakCount>
      <stageThreshold>-3.2396929264068604e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1560 -2.9312999919056892e-02</internalNodes>
          <leafValues>
            7.1284699440002441e-01 -5.8230698108673096e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1561 1.2415099889039993e-01</internalNodes>
          <leafValues>
            -3.6863499879837036e-01 6.0067200660705566e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1562 7.9349996522068977e-03</internalNodes>
          <leafValues>
            -8.6008298397064209e-01 2.1724699437618256e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1563 3.0365999788045883e-02</internalNodes>
          <leafValues>
            -2.7186998724937439e-01 6.1247897148132324e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1564 2.5218000635504723e-02</internalNodes>
          <leafValues>
            -3.4748300909996033e-01 5.0427699089050293e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1565 1.0014000348746777e-02</internalNodes>
          <leafValues>
            -3.1898999214172363e-01 4.1376799345016479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1566 -1.6775000840425491e-02</internalNodes>
          <leafValues>
            -6.9048100709915161e-01 9.4830997288227081e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1567 -2.6950000319629908e-03</internalNodes>
          <leafValues>
            -2.0829799771308899e-01 2.3737199604511261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1568 4.2257998138666153e-02</internalNodes>
          <leafValues>
            -4.9366700649261475e-01 1.8170599639415741e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1569 -4.8505000770092010e-02</internalNodes>
          <leafValues>
            1.3429640531539917e+00 3.9769001305103302e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1570 2.8992999345064163e-02</internalNodes>
          <leafValues>
            4.6496000140905380e-02 -8.1643497943878174e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1571 -4.0089000016450882e-02</internalNodes>
          <leafValues>
            -7.1197801828384399e-01 2.2553899884223938e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1572 -4.1021998971700668e-02</internalNodes>
          <leafValues>
            1.0057929754257202e+00 -1.9690200686454773e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1573 1.1838000267744064e-02</internalNodes>
          <leafValues>
            -1.2600000016391277e-02 8.0767101049423218e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1574 -2.1328000351786613e-02</internalNodes>
          <leafValues>
            -8.2023900747299194e-01 2.0524999126791954e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1575 -2.3904999718070030e-02</internalNodes>
          <leafValues>
            5.4210501909255981e-01 -7.4767000973224640e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1576 1.8008999526500702e-02</internalNodes>
          <leafValues>
            -3.3827701210975647e-01 4.2358601093292236e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1577 -4.3614000082015991e-02</internalNodes>
          <leafValues>
            -1.1983489990234375e+00 1.5566200017929077e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1578 -9.2449998483061790e-03</internalNodes>
          <leafValues>
            -8.9029997587203979e-01 1.1003999970853329e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1579 4.7485001385211945e-02</internalNodes>
          <leafValues>
            1.6664099693298340e-01 -9.0764498710632324e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1580 -1.4233999885618687e-02</internalNodes>
          <leafValues>
            6.2695199251174927e-01 -2.5791200995445251e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1581 3.8010000716894865e-03</internalNodes>
          <leafValues>
            -2.8229999542236328e-01 2.6624599099159241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1582 3.4330000635236502e-03</internalNodes>
          <leafValues>
            -6.3771998882293701e-01 9.8422996699810028e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1583 -2.9221000149846077e-02</internalNodes>
          <leafValues>
            -7.6769900321960449e-01 2.2634500265121460e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1584 -6.4949998632073402e-03</internalNodes>
          <leafValues>
            4.5600101351737976e-01 -2.6528900861740112e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1585 -3.0034000054001808e-02</internalNodes>
          <leafValues>
            -7.6551097631454468e-01 1.4009299874305725e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1586 7.8360000625252724e-03</internalNodes>
          <leafValues>
            4.6755999326705933e-02 -7.2356200218200684e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1587 8.8550001382827759e-03</internalNodes>
          <leafValues>
            -4.9141999334096909e-02 5.1472699642181396e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1588 9.5973998308181763e-02</internalNodes>
          <leafValues>
            -2.0068999379873276e-02 -1.0850950479507446e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1589 -3.2876998186111450e-02</internalNodes>
          <leafValues>
            -9.5875298976898193e-01 1.4543600380420685e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1590 -1.3384000398218632e-02</internalNodes>
          <leafValues>
            -7.0013600587844849e-01 2.9157999902963638e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1591 1.5235999599099159e-02</internalNodes>
          <leafValues>
            -2.8235700726509094e-01 2.5367999076843262e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1592 1.2054000049829483e-02</internalNodes>
          <leafValues>
            -2.5303399562835693e-01 4.6526700258255005e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1593 -7.6295003294944763e-02</internalNodes>
          <leafValues>
            -6.9915801286697388e-01 1.3217200338840485e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1594 -1.2040000408887863e-02</internalNodes>
          <leafValues>
            4.5894598960876465e-01 -2.3856499791145325e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1595 2.1916000172495842e-02</internalNodes>
          <leafValues>
            1.8268600106239319e-01 -6.1629700660705566e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1596 -2.7330000884830952e-03</internalNodes>
          <leafValues>
            -6.3257902860641479e-01 3.4219000488519669e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1597 -4.8652000725269318e-02</internalNodes>
          <leafValues>
            -1.0297729969024658e+00 1.7386500537395477e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1598 -1.0463999584317207e-02</internalNodes>
          <leafValues>
            3.4757301211357117e-01 -2.7464100718498230e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1599 -6.6550001502037048e-03</internalNodes>
          <leafValues>
            -2.8980299830436707e-01 2.4037900567054749e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1600 8.5469996556639671e-03</internalNodes>
          <leafValues>
            -4.4340500235557556e-01 1.4267399907112122e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1601 1.9913999363780022e-02</internalNodes>
          <leafValues>
            1.7740400135517120e-01 -2.4096299707889557e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1602 2.2012999281287193e-02</internalNodes>
          <leafValues>
            -1.0812000371515751e-02 -9.4690799713134766e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1603 -5.2179001271724701e-02</internalNodes>
          <leafValues>
            1.6547499895095825e+00 9.6487000584602356e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1604 1.9698999822139740e-02</internalNodes>
          <leafValues>
            -6.7560002207756042e-03 -8.6311501264572144e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1605 2.3040000349283218e-02</internalNodes>
          <leafValues>
            -2.3519999813288450e-03 3.8531300425529480e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1606 -1.5038000419735909e-02</internalNodes>
          <leafValues>
            -6.1905699968338013e-01 3.1077999621629715e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1607 -4.9956001341342926e-02</internalNodes>
          <leafValues>
            7.0657497644424438e-01 4.7880999743938446e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1608 -6.9269999861717224e-02</internalNodes>
          <leafValues>
            3.9212900400161743e-01 -2.3848000168800354e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1609 4.7399997711181641e-03</internalNodes>
          <leafValues>
            -2.4309000000357628e-02 2.5386300683021545e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1610 -3.3923998475074768e-02</internalNodes>
          <leafValues>
            4.6930399537086487e-01 -2.3321899771690369e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1611 -1.6231000423431396e-02</internalNodes>
          <leafValues>
            3.2319200038909912e-01 -2.0545600354671478e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1612 -5.0193000584840775e-02</internalNodes>
          <leafValues>
            -1.2277870178222656e+00 -4.0798000991344452e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1613 5.6944001466035843e-02</internalNodes>
          <leafValues>
            4.5184001326560974e-02 6.0197502374649048e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1614 4.0936999022960663e-02</internalNodes>
          <leafValues>
            -1.6772800683975220e-01 8.9819300174713135e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1615 -3.0839999672025442e-03</internalNodes>
          <leafValues>
            3.3716198801994324e-01 -2.7240800857543945e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1616 -3.2600000500679016e-02</internalNodes>
          <leafValues>
            -8.5446500778198242e-01 1.9664999097585678e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1617 9.8480999469757080e-02</internalNodes>
          <leafValues>
            5.4742000997066498e-02 6.3827300071716309e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1618 -3.8185000419616699e-02</internalNodes>
          <leafValues>
            5.2274698019027710e-01 -2.3384800553321838e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1619 -4.5917000621557236e-02</internalNodes>
          <leafValues>
            6.2829202413558960e-01 3.2859001308679581e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1620 -1.1955499649047852e-01</internalNodes>
          <leafValues>
            -6.1572700738906860e-01 3.4680001437664032e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1621 -1.2044399976730347e-01</internalNodes>
          <leafValues>
            -8.4380000829696655e-01 1.6530700027942657e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1622 7.0619001984596252e-02</internalNodes>
          <leafValues>
            -6.3261002302169800e-02 -1.9863929748535156e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1623 8.4889996796846390e-03</internalNodes>
          <leafValues>
            -1.7663399875164032e-01 3.8011199235916138e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1624 2.2710999473929405e-02</internalNodes>
          <leafValues>
            -2.7605999261140823e-02 -9.1921401023864746e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1625 4.9700000090524554e-04</internalNodes>
          <leafValues>
            -2.4293200671672821e-01 2.2878900170326233e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1626 3.4651998430490494e-02</internalNodes>
          <leafValues>
            -2.3705999553203583e-01 5.4010999202728271e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1627 -4.4700000435113907e-03</internalNodes>
          <leafValues>
            3.9078998565673828e-01 -1.2693800032138824e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1628 2.3643000051379204e-02</internalNodes>
          <leafValues>
            -2.6663699746131897e-01 3.2312598824501038e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1629 1.2813000008463860e-02</internalNodes>
          <leafValues>
            1.7540800571441650e-01 -6.0787999629974365e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1630 -1.1250999756157398e-02</internalNodes>
          <leafValues>
            -1.0852589607238770e+00 -2.8046000748872757e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1631 -4.1535001248121262e-02</internalNodes>
          <leafValues>
            7.1887397766113281e-01 2.7982000261545181e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1632 -9.3470998108386993e-02</internalNodes>
          <leafValues>
            -1.1906319856643677e+00 -4.4810999184846878e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1633 -2.7249999344348907e-02</internalNodes>
          <leafValues>
            6.2942498922348022e-01 9.5039997249841690e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1634 -2.1759999915957451e-02</internalNodes>
          <leafValues>
            1.3233649730682373e+00 -1.5027000010013580e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1635 -9.6890004351735115e-03</internalNodes>
          <leafValues>
            -3.3947101235389709e-01 1.7085799574851990e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1636 6.9395996630191803e-02</internalNodes>
          <leafValues>
            -2.5657799839973450e-01 4.7652098536491394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1637 3.1208999454975128e-02</internalNodes>
          <leafValues>
            1.4154000580310822e-01 -3.4942001104354858e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1638 -4.9727000296115875e-02</internalNodes>
          <leafValues>
            -1.1675560474395752e+00 -4.0757998824119568e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1639 -2.0301999524235725e-02</internalNodes>
          <leafValues>
            -3.9486399292945862e-01 1.5814900398254395e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1640 -1.5367000363767147e-02</internalNodes>
          <leafValues>
            4.9300000071525574e-01 -2.0092099905014038e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1641 -5.0735000520944595e-02</internalNodes>
          <leafValues>
            1.8736059665679932e+00 8.6730003356933594e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1642 -2.0726000890135765e-02</internalNodes>
          <leafValues>
            -8.8938397169113159e-01 -7.3199998587369919e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1643 -3.0993999913334846e-02</internalNodes>
          <leafValues>
            -1.1664899587631226e+00 1.4274600148200989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1644 -4.4269999489188194e-03</internalNodes>
          <leafValues>
            -6.6815102100372314e-01 4.4120000675320625e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1645 -4.5743998140096664e-02</internalNodes>
          <leafValues>
            -4.7955200076103210e-01 1.5121999382972717e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1646 1.6698999330401421e-02</internalNodes>
          <leafValues>
            1.2048599869012833e-01 -4.5235899090766907e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1647 3.2210000790655613e-03</internalNodes>
          <leafValues>
            -7.7615000307559967e-02 2.7846598625183105e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1648 2.4434000253677368e-02</internalNodes>
          <leafValues>
            -1.9987100362777710e-01 6.7253702878952026e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1649 -7.9677999019622803e-02</internalNodes>
          <leafValues>
            9.2222398519515991e-01 9.2557996511459351e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1650 4.4530000537633896e-02</internalNodes>
          <leafValues>
            -2.6690500974655151e-01 3.3320501446723938e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1651 -1.2528300285339355e-01</internalNodes>
          <leafValues>
            -5.4253101348876953e-01 1.3976299762725830e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1652 1.7971999943256378e-02</internalNodes>
          <leafValues>
            1.8219999969005585e-02 -6.8048501014709473e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1653 1.9184000790119171e-02</internalNodes>
          <leafValues>
            -1.2583999894559383e-02 5.4126697778701782e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1654 4.0024001151323318e-02</internalNodes>
          <leafValues>
            -1.7638799548149109e-01 7.8810399770736694e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1655 1.3558999635279179e-02</internalNodes>
          <leafValues>
            2.0737600326538086e-01 -4.7744300961494446e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1656 1.6220999881625175e-02</internalNodes>
          <leafValues>
            2.3076999932527542e-02 -6.1182099580764771e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1657 1.1229000054299831e-02</internalNodes>
          <leafValues>
            -1.7728000879287720e-02 4.1764199733734131e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1658 3.9193000644445419e-02</internalNodes>
          <leafValues>
            -1.8948499858379364e-01 7.4019300937652588e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1659 -9.5539996400475502e-03</internalNodes>
          <leafValues>
            4.0947100520133972e-01 -1.3508899509906769e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1660 2.7878999710083008e-02</internalNodes>
          <leafValues>
            -2.0350700616836548e-01 6.1625397205352783e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1661 -2.3600999265909195e-02</internalNodes>
          <leafValues>
            -1.6967060565948486e+00 1.4633199572563171e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1662 2.6930000633001328e-02</internalNodes>
          <leafValues>
            -3.0401999130845070e-02 -1.0909470319747925e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1663 2.8999999631196260e-04</internalNodes>
          <leafValues>
            -2.0076000690460205e-01 2.2314099967479706e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1664 -4.1124999523162842e-02</internalNodes>
          <leafValues>
            -4.5242199301719666e-01 5.7392001152038574e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1665 6.6789998672902584e-03</internalNodes>
          <leafValues>
            2.3824900388717651e-01 -2.1262100338935852e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1666 4.7864999622106552e-02</internalNodes>
          <leafValues>
            -1.8194800615310669e-01 6.1918401718139648e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1667 -3.1679999083280563e-03</internalNodes>
          <leafValues>
            -2.7393200993537903e-01 2.5017300248146057e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1668 -8.6230002343654633e-03</internalNodes>
          <leafValues>
            -4.6280300617218018e-01 4.2397998273372650e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1669 -7.4350000359117985e-03</internalNodes>
          <leafValues>
            4.1796800494194031e-01 -1.7079999670386314e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1670 -1.8769999733194709e-03</internalNodes>
          <leafValues>
            1.4602300524711609e-01 -3.3721101284027100e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1671 -8.6226001381874084e-02</internalNodes>
          <leafValues>
            7.5143402814865112e-01 1.0711999610066414e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1672 4.6833999454975128e-02</internalNodes>
          <leafValues>
            -1.9119599461555481e-01 4.8414900898933411e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1673 -9.2000002041459084e-05</internalNodes>
          <leafValues>
            3.5220399498939514e-01 -1.7333300411701202e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1674 -1.6343999654054642e-02</internalNodes>
          <leafValues>
            -6.4397698640823364e-01 9.0680001303553581e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1675 4.5703999698162079e-02</internalNodes>
          <leafValues>
            1.8216000869870186e-02 3.1970798969268799e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1676 -2.7382999658584595e-02</internalNodes>
          <leafValues>
            1.0564049482345581e+00 -1.7276400327682495e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1677 -2.7602000162005424e-02</internalNodes>
          <leafValues>
            2.9715499281883240e-01 -9.4600003212690353e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1678 7.6939999125897884e-03</internalNodes>
          <leafValues>
            -2.1660299599170685e-01 4.7385200858116150e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1679 -7.0500001311302185e-04</internalNodes>
          <leafValues>
            2.4048799276351929e-01 -2.6776000857353210e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1680 1.1054199934005737e-01</internalNodes>
          <leafValues>
            -3.3539000898599625e-02 -1.0233880281448364e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1681 6.8765997886657715e-02</internalNodes>
          <leafValues>
            -4.3239998631179333e-03 5.7153397798538208e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1682 1.7999999690800905e-03</internalNodes>
          <leafValues>
            7.7574998140335083e-02 -4.2092698812484741e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1683 1.9232000410556793e-01</internalNodes>
          <leafValues>
            8.2021996378898621e-02 2.8810169696807861e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1684 1.5742099285125732e-01</internalNodes>
          <leafValues>
            -1.3708199560642242e-01 2.0890059471130371e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1685 -4.9387000501155853e-02</internalNodes>
          <leafValues>
            -1.8610910177230835e+00 1.4332099258899689e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1686 5.1929000765085220e-02</internalNodes>
          <leafValues>
            -1.8737000226974487e-01 5.4231601953506470e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1687 4.9965001642704010e-02</internalNodes>
          <leafValues>
            1.4175300300121307e-01 -1.5625779628753662e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1688 -4.2633000761270523e-02</internalNodes>
          <leafValues>
            1.6059479713439941e+00 -1.4712899923324585e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1689 -3.7553999572992325e-02</internalNodes>
          <leafValues>
            -8.0974900722503662e-01 1.3256999850273132e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1690 -3.7174999713897705e-02</internalNodes>
          <leafValues>
            -1.3945020437240601e+00 -5.7055000215768814e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1691 1.3945999555289745e-02</internalNodes>
          <leafValues>
            3.3427000045776367e-02 5.7474797964096069e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1692 -4.4800000614486635e-04</internalNodes>
          <leafValues>
            -5.5327498912811279e-01 2.1952999755740166e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1693 3.1993001699447632e-02</internalNodes>
          <leafValues>
            2.0340999588370323e-02 3.7459200620651245e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1694 -4.2799999937415123e-03</internalNodes>
          <leafValues>
            4.4428700208663940e-01 -2.2999699413776398e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1695 9.8550003021955490e-03</internalNodes>
          <leafValues>
            1.8315799534320831e-01 -4.0964999794960022e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1696 9.3356996774673462e-02</internalNodes>
          <leafValues>
            -6.3661001622676849e-02 -1.6929290294647217e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1697 1.7209999263286591e-02</internalNodes>
          <leafValues>
            2.0153899490833282e-01 -4.6061098575592041e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1698 8.4319999441504478e-03</internalNodes>
          <leafValues>
            -3.2003998756408691e-01 1.5312199294567108e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1699 -1.4054999686777592e-02</internalNodes>
          <leafValues>
            8.6882400512695312e-01 3.2575000077486038e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1700 -7.7180000953376293e-03</internalNodes>
          <leafValues>
            6.3686698675155640e-01 -1.8425500392913818e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1701 2.8005000203847885e-02</internalNodes>
          <leafValues>
            1.7357499897480011e-01 -4.7883599996566772e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1702 -1.8884999677538872e-02</internalNodes>
          <leafValues>
            2.4101600050926208e-01 -2.6547598838806152e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1703 -1.8585000187158585e-02</internalNodes>
          <leafValues>
            5.4232501983642578e-01 5.3633000701665878e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1704 -3.6437001079320908e-02</internalNodes>
          <leafValues>
            2.3908898830413818e+00 -1.3634699583053589e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1705 3.2455001026391983e-02</internalNodes>
          <leafValues>
            1.5910699963569641e-01 -6.7581498622894287e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1706 5.9781998395919800e-02</internalNodes>
          <leafValues>
            -2.3479999508708715e-03 -7.3053699731826782e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1707 9.8209995776414871e-03</internalNodes>
          <leafValues>
            -1.1444099992513657e-01 3.0570301413536072e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1708 -3.5163998603820801e-02</internalNodes>
          <leafValues>
            -1.0511469841003418e+00 -3.3103000372648239e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1709 2.7429999317973852e-03</internalNodes>
          <leafValues>
            -2.0135399699211121e-01 3.2754099369049072e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1710 8.1059997901320457e-03</internalNodes>
          <leafValues>
            -2.1383500099182129e-01 4.3362098932266235e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1711 8.8942997157573700e-02</internalNodes>
          <leafValues>
            1.0940899699926376e-01 -4.7609338760375977e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1712 -3.0054999515414238e-02</internalNodes>
          <leafValues>
            -1.7169300317764282e+00 -6.0919001698493958e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1713 -2.1734999492764473e-02</internalNodes>
          <leafValues>
            6.4778900146484375e-01 -3.2830998301506042e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1714 3.7648998200893402e-02</internalNodes>
          <leafValues>
            -1.0060000233352184e-02 -7.6569098234176636e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1715 2.7189999818801880e-03</internalNodes>
          <leafValues>
            1.9888900220394135e-01 -8.2479000091552734e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1716 -1.0548000223934650e-02</internalNodes>
          <leafValues>
            -8.6613601446151733e-01 -2.5986000895500183e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1717 1.2966300547122955e-01</internalNodes>
          <leafValues>
            1.3911999762058258e-01 -2.2271950244903564e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1718 -1.7676999792456627e-02</internalNodes>
          <leafValues>
            3.3967700600624084e-01 -2.3989599943161011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1719 -7.7051997184753418e-02</internalNodes>
          <leafValues>
            -2.5017969608306885e+00 1.2841999530792236e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1720 -1.9230000674724579e-02</internalNodes>
          <leafValues>
            5.0641202926635742e-01 -1.9751599431037903e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1721 -5.1222998648881912e-02</internalNodes>
          <leafValues>
            -2.9333369731903076e+00 1.3858500123023987e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1722 2.0830000285059214e-03</internalNodes>
          <leafValues>
            -6.0043597221374512e-01 2.9718000441789627e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1723 2.5418000295758247e-02</internalNodes>
          <leafValues>
            3.3915799856185913e-01 -1.4392000436782837e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1724 -2.3905999958515167e-02</internalNodes>
          <leafValues>
            -1.1082680225372314e+00 -4.7377001494169235e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1725 -6.3740001060068607e-03</internalNodes>
          <leafValues>
            4.4533699750900269e-01 -6.7052997648715973e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1726 -3.7698999047279358e-02</internalNodes>
          <leafValues>
            -1.0406579971313477e+00 -4.1790001094341278e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1727 2.1655100584030151e-01</internalNodes>
          <leafValues>
            3.3863000571727753e-02 8.2017302513122559e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1728 -1.3400999829173088e-02</internalNodes>
          <leafValues>
            5.2903497219085693e-01 -1.9133000075817108e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>196</maxWeakCount>
      <stageThreshold>-3.2103500366210938e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1729 7.1268998086452484e-02</internalNodes>
          <leafValues>
            -5.3631198406219482e-01 6.0715299844741821e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1730 5.6111000478267670e-02</internalNodes>
          <leafValues>
            -5.0141602754592896e-01 4.3976101279258728e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1731 4.0463998913764954e-02</internalNodes>
          <leafValues>
            -3.2922199368476868e-01 5.4834699630737305e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1732 6.3155002892017365e-02</internalNodes>
          <leafValues>
            -3.1701698899269104e-01 4.6152999997138977e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1733 1.0320999659597874e-02</internalNodes>
          <leafValues>
            1.0694999992847443e-01 -9.8243898153305054e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1734 6.2606997787952423e-02</internalNodes>
          <leafValues>
            -1.4329700171947479e-01 7.1095001697540283e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1735 -3.9416000247001648e-02</internalNodes>
          <leafValues>
            9.4380199909210205e-01 -2.1572099626064301e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1736 -5.3960001096129417e-03</internalNodes>
          <leafValues>
            -5.4611998796463013e-01 2.5303798913955688e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1737 1.0773199796676636e-01</internalNodes>
          <leafValues>
            1.2496000155806541e-02 -1.0809199810028076e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1738 1.6982000321149826e-02</internalNodes>
          <leafValues>
            -3.1536400318145752e-01 5.1239997148513794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1739 3.1216999515891075e-02</internalNodes>
          <leafValues>
            -4.5199999585747719e-03 -1.2443480491638184e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1740 -2.3106999695301056e-02</internalNodes>
          <leafValues>
            -7.6492899656295776e-01 2.0640599727630615e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1741 -1.1203999631106853e-02</internalNodes>
          <leafValues>
            2.4092699587345123e-01 -3.5142099857330322e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1742 -4.7479998320341110e-03</internalNodes>
          <leafValues>
            -9.7007997334003448e-02 2.0638099312782288e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1743 -1.7358999699354172e-02</internalNodes>
          <leafValues>
            -7.9020297527313232e-01 2.1852999925613403e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1744 1.8851999193429947e-02</internalNodes>
          <leafValues>
            -1.0394600033760071e-01 5.4844200611114502e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1745 7.2249998338520527e-03</internalNodes>
          <leafValues>
            -4.0409401059150696e-01 2.6763799786567688e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1746 1.8915999680757523e-02</internalNodes>
          <leafValues>
            2.0508000254631042e-01 -1.0206340551376343e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1747 3.1156999990344048e-02</internalNodes>
          <leafValues>
            1.2400000123307109e-03 -8.7293499708175659e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1748 2.0951999351382256e-02</internalNodes>
          <leafValues>
            -5.5559999309480190e-03 8.0356198549270630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1749 1.1291000060737133e-02</internalNodes>
          <leafValues>
            -3.6478400230407715e-01 2.2767899930477142e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1750 -5.7011000812053680e-02</internalNodes>
          <leafValues>
            -1.4295619726181030e+00 1.4322000741958618e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1751 7.2194002568721771e-02</internalNodes>
          <leafValues>
            -4.1850000619888306e-02 -1.9111829996109009e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1752 -1.9874000921845436e-02</internalNodes>
          <leafValues>
            2.6425498723983765e-01 -3.2617700099945068e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1753 -1.6692999750375748e-02</internalNodes>
          <leafValues>
            -8.3907800912857056e-01 4.0799999260343611e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1754 -3.9834998548030853e-02</internalNodes>
          <leafValues>
            -4.8858499526977539e-01 1.6436100006103516e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1755 2.7009999379515648e-02</internalNodes>
          <leafValues>
            -1.8862499296665192e-01 8.3419400453567505e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1756 -3.9420002140104771e-03</internalNodes>
          <leafValues>
            2.3231500387191772e-01 -7.2360001504421234e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1757 2.2833000868558884e-02</internalNodes>
          <leafValues>
            -3.5884000360965729e-02 -1.1549400091171265e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1758 -6.8888001143932343e-02</internalNodes>
          <leafValues>
            -1.7837309837341309e+00 1.5159000456333160e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1759 4.3097000569105148e-02</internalNodes>
          <leafValues>
            -2.1608099341392517e-01 5.0624102354049683e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1760 8.6239995434880257e-03</internalNodes>
          <leafValues>
            -1.7795599997043610e-01 2.8957900404930115e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1761 1.4561000280082226e-02</internalNodes>
          <leafValues>
            -1.1408000253140926e-02 -8.9402002096176147e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1762 -1.1501000262796879e-02</internalNodes>
          <leafValues>
            3.0171999335289001e-01 -4.3659001588821411e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1763 -1.0971499979496002e-01</internalNodes>
          <leafValues>
            -9.5147097110748291e-01 -1.9973000511527061e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1764 4.5228000730276108e-02</internalNodes>
          <leafValues>
            3.3110998570919037e-02 9.6619802713394165e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1765 -2.7047999203205109e-02</internalNodes>
          <leafValues>
            9.7963601350784302e-01 -1.7261900007724762e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1766 1.8030999228358269e-02</internalNodes>
          <leafValues>
            -2.0801000297069550e-02 2.7385899424552917e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1767 5.0524998456239700e-02</internalNodes>
          <leafValues>
            -5.6802999228239059e-02 -1.7775089740753174e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1768 -2.9923999682068825e-02</internalNodes>
          <leafValues>
            6.5329200029373169e-01 -2.3537000641226768e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1769 3.8058001548051834e-02</internalNodes>
          <leafValues>
            2.6317000389099121e-02 -7.0665699243545532e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1770 1.8563899397850037e-01</internalNodes>
          <leafValues>
            -5.6039998307824135e-03 3.2873699069023132e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1771 -4.0670000016689301e-03</internalNodes>
          <leafValues>
            3.4204798936843872e-01 -3.0171599984169006e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1772 1.0108999907970428e-02</internalNodes>
          <leafValues>
            -7.3600001633167267e-03 5.7981598377227783e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1773 -1.1567000299692154e-02</internalNodes>
          <leafValues>
            -5.2722197771072388e-01 4.6447999775409698e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1774 -6.5649999305605888e-03</internalNodes>
          <leafValues>
            -5.8529102802276611e-01 1.9101899862289429e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1775 1.0582000017166138e-02</internalNodes>
          <leafValues>
            2.1073000505566597e-02 -6.8892598152160645e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1776 -2.0304000005125999e-02</internalNodes>
          <leafValues>
            -3.6400699615478516e-01 1.5338799357414246e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1777 2.3529999889433384e-03</internalNodes>
          <leafValues>
            3.6164000630378723e-02 -5.9825098514556885e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1778 -1.4690000098198652e-03</internalNodes>
          <leafValues>
            -1.4707699418067932e-01 3.7507998943328857e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1779 8.6449999362230301e-03</internalNodes>
          <leafValues>
            -2.1708500385284424e-01 5.1936799287796021e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1780 -2.4326000362634659e-02</internalNodes>
          <leafValues>
            -1.0846769809722900e+00 1.4084799587726593e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1781 7.4418999254703522e-02</internalNodes>
          <leafValues>
            -1.5513800084590912e-01 1.1822769641876221e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1782 1.7077999189496040e-02</internalNodes>
          <leafValues>
            4.4231001287698746e-02 9.1561102867126465e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1783 -2.4577999487519264e-02</internalNodes>
          <leafValues>
            -1.5504100322723389e+00 -5.4745998233556747e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1784 3.0205000191926956e-02</internalNodes>
          <leafValues>
            1.6662800312042236e-01 -1.0001239776611328e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1785 1.2136000208556652e-02</internalNodes>
          <leafValues>
            -7.7079099416732788e-01 -4.8639997839927673e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1786 8.6717002093791962e-02</internalNodes>
          <leafValues>
            1.1061699688434601e-01 -1.6857999563217163e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1787 -4.2309001088142395e-02</internalNodes>
          <leafValues>
            1.1075930595397949e+00 -1.5438599884510040e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1788 -2.6420000940561295e-03</internalNodes>
          <leafValues>
            2.7451899647712708e-01 -1.8456199765205383e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1789 -5.6662000715732574e-02</internalNodes>
          <leafValues>
            -8.0625599622726440e-01 -1.6928000375628471e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1790 2.3475000634789467e-02</internalNodes>
          <leafValues>
            1.4187699556350708e-01 -2.5500899553298950e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1791 -2.0803000777959824e-02</internalNodes>
          <leafValues>
            1.9826300442218781e-01 -3.1171199679374695e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1792 7.2599998675286770e-03</internalNodes>
          <leafValues>
            -5.0590999424457550e-02 4.1923800110816956e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1793 3.4160000085830688e-01</internalNodes>
          <leafValues>
            -1.6674900054931641e-01 9.2748600244522095e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1794 6.2029999680817127e-03</internalNodes>
          <leafValues>
            -1.2625899910926819e-01 4.0445300936698914e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1795 3.2692000269889832e-02</internalNodes>
          <leafValues>
            -3.2634999603033066e-02 -9.8939800262451172e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1796 2.1100000594742596e-04</internalNodes>
          <leafValues>
            -6.4534001052379608e-02 2.5473698973655701e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1797 7.2100001852959394e-04</internalNodes>
          <leafValues>
            -3.6618599295616150e-01 1.1973100155591965e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1798 5.4490998387336731e-02</internalNodes>
          <leafValues>
            1.2073499709367752e-01 -1.0291390419006348e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1799 -1.0141000151634216e-02</internalNodes>
          <leafValues>
            -5.2177202701568604e-01 3.3734999597072601e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1800 -1.8815999850630760e-02</internalNodes>
          <leafValues>
            6.5181797742843628e-01 1.3399999588727951e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1801 -5.3480002097785473e-03</internalNodes>
          <leafValues>
            1.7370699346065521e-01 -3.4132000803947449e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1802 -1.0847000405192375e-02</internalNodes>
          <leafValues>
            -1.9699899852275848e-01 1.5045499801635742e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1803 -4.9926001578569412e-02</internalNodes>
          <leafValues>
            -5.0888502597808838e-01 3.0762000009417534e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1804 1.2160000391304493e-02</internalNodes>
          <leafValues>
            -6.9251999258995056e-02 1.8745499849319458e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1805 -2.2189998999238014e-03</internalNodes>
          <leafValues>
            -4.0849098563194275e-01 7.9954996705055237e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1806 3.1580000650137663e-03</internalNodes>
          <leafValues>
            -2.1124599874019623e-01 2.2366400063037872e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1807 4.1439998894929886e-03</internalNodes>
          <leafValues>
            -4.9900299310684204e-01 6.2917001545429230e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1808 -7.3730000294744968e-03</internalNodes>
          <leafValues>
            -2.0553299784660339e-01 2.2096699476242065e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1809 5.1812000572681427e-02</internalNodes>
          <leafValues>
            1.8096800148487091e-01 -4.3495801091194153e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1810 1.8340000882744789e-02</internalNodes>
          <leafValues>
            1.5200000256299973e-02 3.7991699576377869e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1811 1.7490799725055695e-01</internalNodes>
          <leafValues>
            -2.0920799672603607e-01 4.0013000369071960e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1812 5.3993999958038330e-02</internalNodes>
          <leafValues>
            2.4751600623130798e-01 -2.6712900400161743e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1813 -3.2033199071884155e-01</internalNodes>
          <leafValues>
            -1.9094380140304565e+00 -6.6960997879505157e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1814 -2.7060000225901604e-02</internalNodes>
          <leafValues>
            -7.1371299028396606e-01 1.5904599428176880e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1815 7.7463999390602112e-02</internalNodes>
          <leafValues>
            -1.6970199346542358e-01 7.7552998065948486e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1816 2.3771999403834343e-02</internalNodes>
          <leafValues>
            1.9021899998188019e-01 -6.0162097215652466e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1817 1.1501000262796879e-02</internalNodes>
          <leafValues>
            7.7039999887347221e-03 -6.1730301380157471e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1818 3.2616000622510910e-02</internalNodes>
          <leafValues>
            1.7159199714660645e-01 -7.0978200435638428e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1819 -4.4383000582456589e-02</internalNodes>
          <leafValues>
            -2.2606229782104492e+00 -7.3276996612548828e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1820 -5.8476001024246216e-02</internalNodes>
          <leafValues>
            2.4087750911712646e+00 8.3091996610164642e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1821 1.9303999841213226e-02</internalNodes>
          <leafValues>
            -2.7082300186157227e-01 2.7369999885559082e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1822 -4.4705998152494431e-02</internalNodes>
          <leafValues>
            3.1355598568916321e-01 -6.2492001801729202e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1823 -6.0334999114274979e-02</internalNodes>
          <leafValues>
            -1.4515119791030884e+00 -5.8761000633239746e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1824 1.1667000129818916e-02</internalNodes>
          <leafValues>
            -1.8084999173879623e-02 5.0479698181152344e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1825 2.8009999543428421e-02</internalNodes>
          <leafValues>
            -2.3302899301052094e-01 3.0708700418472290e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1826 6.5397001802921295e-02</internalNodes>
          <leafValues>
            1.4135900139808655e-01 -5.0010901689529419e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1827 9.6239997074007988e-03</internalNodes>
          <leafValues>
            -2.2054600715637207e-01 3.9191201329231262e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1828 2.5510000996291637e-03</internalNodes>
          <leafValues>
            -1.1381500214338303e-01 2.0032300055027008e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1829 3.1847000122070312e-02</internalNodes>
          <leafValues>
            2.5476999580860138e-02 -5.3326398134231567e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1830 3.3055000007152557e-02</internalNodes>
          <leafValues>
            1.7807699739933014e-01 -6.2793898582458496e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1831 4.7600999474525452e-02</internalNodes>
          <leafValues>
            -1.4747899770736694e-01 1.4204180240631104e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1832 -1.9571999087929726e-02</internalNodes>
          <leafValues>
            -5.2693498134613037e-01 1.5838600695133209e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1833 -5.4730001837015152e-02</internalNodes>
          <leafValues>
            8.8231599330902100e-01 -1.6627800464630127e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1834 -2.2686000913381577e-02</internalNodes>
          <leafValues>
            -4.8386898636817932e-01 1.5000100433826447e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1835 1.0713200271129608e-01</internalNodes>
          <leafValues>
            -2.1336199343204498e-01 4.2333900928497314e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1836 -3.6380000412464142e-02</internalNodes>
          <leafValues>
            -7.4198000133037567e-02 1.4589400589466095e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1837 1.3935999944806099e-02</internalNodes>
          <leafValues>
            -2.4911600351333618e-01 2.6771199703216553e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1838 2.0991999655961990e-02</internalNodes>
          <leafValues>
            8.7959999218583107e-03 4.3064999580383301e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1839 4.9118999391794205e-02</internalNodes>
          <leafValues>
            -1.7591999471187592e-01 6.9282901287078857e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1840 3.6315999925136566e-02</internalNodes>
          <leafValues>
            1.3145299255847931e-01 -3.3597299456596375e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1841 4.1228000074625015e-02</internalNodes>
          <leafValues>
            -4.5692000538110733e-02 -1.3515930175781250e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1842 1.5672000125050545e-02</internalNodes>
          <leafValues>
            1.7544099688529968e-01 -6.0550000518560410e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1843 -1.6286000609397888e-02</internalNodes>
          <leafValues>
            -1.1308189630508423e+00 -3.9533000439405441e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1844 -3.0229999683797359e-03</internalNodes>
          <leafValues>
            -2.2454300522804260e-01 2.3628099262714386e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1845 -1.3786299526691437e-01</internalNodes>
          <leafValues>
            4.5376899838447571e-01 -2.1098700165748596e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1846 -9.6760001033544540e-03</internalNodes>
          <leafValues>
            -1.5105099976062775e-01 2.0781700313091278e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1847 -2.4839999154210091e-02</internalNodes>
          <leafValues>
            -6.8350297212600708e-01 -8.0040004104375839e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1848 -1.3964399695396423e-01</internalNodes>
          <leafValues>
            6.5011298656463623e-01 4.6544000506401062e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1849 -8.2153998315334320e-02</internalNodes>
          <leafValues>
            4.4887199997901917e-01 -2.3591999709606171e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1850 3.8449999410659075e-03</internalNodes>
          <leafValues>
            -8.8173002004623413e-02 2.7346798777580261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1851 -6.6579999402165413e-03</internalNodes>
          <leafValues>
            -4.6866598725318909e-01 7.7001996338367462e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1852 -1.5898000448942184e-02</internalNodes>
          <leafValues>
            2.9268398880958557e-01 -2.1941000595688820e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1853 -5.0946000963449478e-02</internalNodes>
          <leafValues>
            -1.2093789577484131e+00 -4.2109999805688858e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1854 1.6837999224662781e-02</internalNodes>
          <leafValues>
            -4.5595999807119370e-02 5.0180697441101074e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1855 1.5918999910354614e-02</internalNodes>
          <leafValues>
            -2.6904299855232239e-01 2.6516300439834595e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1856 3.6309999413788319e-03</internalNodes>
          <leafValues>
            -1.3046100735664368e-01 3.1807100772857666e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1857 -8.6144998669624329e-02</internalNodes>
          <leafValues>
            1.9443659782409668e+00 -1.3978299498558044e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1858 3.3140998333692551e-02</internalNodes>
          <leafValues>
            1.5266799926757812e-01 -3.0866000801324844e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1859 -3.9679999463260174e-03</internalNodes>
          <leafValues>
            -7.1202301979064941e-01 -1.3844000175595284e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1860 -2.4008000269532204e-02</internalNodes>
          <leafValues>
            9.2007797956466675e-01 4.6723999083042145e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1861 8.7320003658533096e-03</internalNodes>
          <leafValues>
            -2.2567300498485565e-01 3.1931799650192261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1862 -2.7786999940872192e-02</internalNodes>
          <leafValues>
            -7.2337102890014648e-01 1.7018599808216095e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1863 -1.9455300271511078e-01</internalNodes>
          <leafValues>
            1.2461860179901123e+00 -1.4736199378967285e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1864 -1.0869699716567993e-01</internalNodes>
          <leafValues>
            -1.4465179443359375e+00 1.2145300209522247e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1865 -1.9494999200105667e-02</internalNodes>
          <leafValues>
            -7.8153097629547119e-01 -2.3732999339699745e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1866 3.0650000553578138e-03</internalNodes>
          <leafValues>
            -8.5471397638320923e-01 1.6686999797821045e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1867 5.9193998575210571e-02</internalNodes>
          <leafValues>
            -1.4853699505329132e-01 1.1273469924926758e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1868 -5.4207999259233475e-02</internalNodes>
          <leafValues>
            5.4726999998092651e-01 3.5523999482393265e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1869 -3.9324998855590820e-02</internalNodes>
          <leafValues>
            3.6642599105834961e-01 -2.0543999969959259e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1870 8.2278996706008911e-02</internalNodes>
          <leafValues>
            -3.5007998347282410e-02 5.3994202613830566e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1871 -7.4479999020695686e-03</internalNodes>
          <leafValues>
            -6.1537498235702515e-01 -3.5319998860359192e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1872 7.3770000599324703e-03</internalNodes>
          <leafValues>
            -6.5591000020503998e-02 4.1961398720741272e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1873 7.0779998786747456e-03</internalNodes>
          <leafValues>
            -3.4129500389099121e-01 1.2536799907684326e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1874 -1.5581999905407429e-02</internalNodes>
          <leafValues>
            -3.0240398645401001e-01 2.1511000394821167e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1875 -2.7399999089539051e-03</internalNodes>
          <leafValues>
            7.6553001999855042e-02 -4.1060501337051392e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1876 -7.0600003004074097e-02</internalNodes>
          <leafValues>
            -9.7356200218200684e-01 1.1241800338029861e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1877 -1.1706000193953514e-02</internalNodes>
          <leafValues>
            1.8560700118541718e-01 -2.9755198955535889e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1878 7.1499997284263372e-04</internalNodes>
          <leafValues>
            -5.9650000184774399e-02 2.4824699759483337e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1879 -3.6866001784801483e-02</internalNodes>
          <leafValues>
            3.2751700282096863e-01 -2.3059600591659546e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1880 -3.2526999711990356e-02</internalNodes>
          <leafValues>
            -2.9320299625396729e-01 1.5427699685096741e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1881 -7.4813999235630035e-02</internalNodes>
          <leafValues>
            -1.2143570184707642e+00 -5.2244000136852264e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1882 4.1469998657703400e-02</internalNodes>
          <leafValues>
            1.3062499463558197e-01 -2.3274369239807129e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1883 -2.8880000114440918e-02</internalNodes>
          <leafValues>
            -6.6074597835540771e-01 -9.0960003435611725e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1884 4.6381998807191849e-02</internalNodes>
          <leafValues>
            1.6630199551582336e-01 -6.6949498653411865e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1885 2.5424998998641968e-01</internalNodes>
          <leafValues>
            -5.4641999304294586e-02 -1.2676080465316772e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1886 2.4000001139938831e-03</internalNodes>
          <leafValues>
            2.0276799798011780e-01 1.4667999930679798e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1887 -8.2805998623371124e-02</internalNodes>
          <leafValues>
            -7.8713601827621460e-01 -2.4468999356031418e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1888 -1.1438000015914440e-02</internalNodes>
          <leafValues>
            2.8623399138450623e-01 -3.0894000083208084e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1889 -1.2913399934768677e-01</internalNodes>
          <leafValues>
            1.7292929887771606e+00 -1.4293900132179260e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1890 3.8552999496459961e-02</internalNodes>
          <leafValues>
            1.9232999533414841e-02 3.7732601165771484e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1891 1.0191400349140167e-01</internalNodes>
          <leafValues>
            -7.4533998966217041e-02 -3.3868899345397949e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1892 -1.9068000838160515e-02</internalNodes>
          <leafValues>
            3.1814101338386536e-01 1.9261000677943230e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1893 -6.0775000602006912e-02</internalNodes>
          <leafValues>
            7.6936298608779907e-01 -1.7644000053405762e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1894 2.4679999798536301e-02</internalNodes>
          <leafValues>
            1.8396499752998352e-01 -3.0868801474571228e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1895 2.6759000495076180e-02</internalNodes>
          <leafValues>
            -2.3454900085926056e-01 3.3056598901748657e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1896 1.4969999901950359e-02</internalNodes>
          <leafValues>
            1.7213599383831024e-01 -1.8248899281024933e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1897 2.6142999529838562e-02</internalNodes>
          <leafValues>
            -4.6463999897241592e-02 -1.1318379640579224e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1898 -3.7512000650167465e-02</internalNodes>
          <leafValues>
            8.0404001474380493e-01 6.9660000503063202e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1899 -5.3229997865855694e-03</internalNodes>
          <leafValues>
            -8.1884402036666870e-01 -1.8224999308586121e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1900 1.7813000828027725e-02</internalNodes>
          <leafValues>
            1.4957800507545471e-01 -1.8667200207710266e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1901 -3.4010000526905060e-02</internalNodes>
          <leafValues>
            -7.2852301597595215e-01 -1.6615999862551689e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1902 -1.5953000634908676e-02</internalNodes>
          <leafValues>
            5.6944000720977783e-01 1.3832000084221363e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1903 1.9743999466300011e-02</internalNodes>
          <leafValues>
            4.0525000542402267e-02 -4.1773399710655212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1904 -1.0374800115823746e-01</internalNodes>
          <leafValues>
            -1.9825149774551392e+00 1.1960200220346451e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1905 -1.9285000860691071e-02</internalNodes>
          <leafValues>
            5.0230598449707031e-01 -1.9745899736881256e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1906 -1.2780000455677509e-02</internalNodes>
          <leafValues>
            4.0195000171661377e-01 -2.6957999914884567e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1907 -1.6352999955415726e-02</internalNodes>
          <leafValues>
            -7.6608800888061523e-01 -2.4209000170230865e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1908 -1.2763699889183044e-01</internalNodes>
          <leafValues>
            8.6578500270843506e-01 6.4205996692180634e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1909 1.9068999215960503e-02</internalNodes>
          <leafValues>
            -5.5929797887802124e-01 -1.6880000475794077e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1910 3.2480999827384949e-02</internalNodes>
          <leafValues>
            4.0722001343965530e-02 4.8925098776817322e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1911 9.4849998131394386e-03</internalNodes>
          <leafValues>
            -1.9231900572776794e-01 5.1139700412750244e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1912 5.0470000132918358e-03</internalNodes>
          <leafValues>
            1.8706800043582916e-01 -1.6113600134849548e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1913 4.1267998516559601e-02</internalNodes>
          <leafValues>
            -4.8817999660968781e-02 -1.1326299905776978e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1914 -7.6358996331691742e-02</internalNodes>
          <leafValues>
            1.4169390201568604e+00 8.7319999933242798e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1915 -7.2834998369216919e-02</internalNodes>
          <leafValues>
            1.3189860582351685e+00 -1.4819100499153137e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1916 5.9576999396085739e-02</internalNodes>
          <leafValues>
            4.8376999795436859e-02 8.5611802339553833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1917 2.0263999700546265e-02</internalNodes>
          <leafValues>
            -2.1044099330902100e-01 3.3858999609947205e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1918 -8.0301001667976379e-02</internalNodes>
          <leafValues>
            -1.2464400529861450e+00 1.1857099831104279e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1919 -1.7835000529885292e-02</internalNodes>
          <leafValues>
            2.5782299041748047e-01 -2.4564799666404724e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1920 1.1431000195443630e-02</internalNodes>
          <leafValues>
            2.2949799895286560e-01 -2.9497599601745605e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1921 -2.5541000068187714e-02</internalNodes>
          <leafValues>
            -8.6252999305725098e-01 -7.0400000549852848e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1922 -7.6899997657164931e-04</internalNodes>
          <leafValues>
            3.1511399149894714e-01 -1.4349000155925751e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1923 -1.4453999698162079e-02</internalNodes>
          <leafValues>
            2.5148499011993408e-01 -2.8232899308204651e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1924 8.6730001494288445e-03</internalNodes>
          <leafValues>
            2.6601400971412659e-01 -2.8190800547599792e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>197</maxWeakCount>
      <stageThreshold>-3.2772979736328125e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 1925 5.4708998650312424e-02</internalNodes>
          <leafValues>
            -5.4144299030303955e-01 6.1043000221252441e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1926 -1.0838799923658371e-01</internalNodes>
          <leafValues>
            7.1739900112152100e-01 -4.1196098923683167e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1927 2.2996999323368073e-02</internalNodes>
          <leafValues>
            -5.8269798755645752e-01 2.9645600914955139e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1928 2.7540000155568123e-03</internalNodes>
          <leafValues>
            -7.4243897199630737e-01 1.4183300733566284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1929 -2.1520000882446766e-03</internalNodes>
          <leafValues>
            1.7879900336265564e-01 -6.8548601865768433e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1930 -2.2559000179171562e-02</internalNodes>
          <leafValues>
            -1.0775549411773682e+00 1.2388999760150909e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1931 8.3025000989437103e-02</internalNodes>
          <leafValues>
            2.4500999599695206e-02 -1.0251879692077637e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1932 -6.6740000620484352e-03</internalNodes>
          <leafValues>
            -4.5283100008964539e-01 2.1230199933052063e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1933 7.6485000550746918e-02</internalNodes>
          <leafValues>
            -2.6972699165344238e-01 4.8580199480056763e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1934 5.4910001344978809e-03</internalNodes>
          <leafValues>
            -4.8871201276779175e-01 3.1616398692131042e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1935 -1.0414999909698963e-02</internalNodes>
          <leafValues>
            4.1512900590896606e-01 -3.0044800043106079e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1936 2.7607999742031097e-02</internalNodes>
          <leafValues>
            1.6203799843788147e-01 -9.9868500232696533e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1937 -2.3272000253200531e-02</internalNodes>
          <leafValues>
            -1.1024399995803833e+00 2.1124999970197678e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1938 -5.5619999766349792e-02</internalNodes>
          <leafValues>
            6.5033102035522461e-01 -2.7938000857830048e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1939 -4.0631998330354691e-02</internalNodes>
          <leafValues>
            4.2117300629615784e-01 -2.6763799786567688e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1940 -7.3560001328587532e-03</internalNodes>
          <leafValues>
            3.5277798771858215e-01 -3.7854000926017761e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1941 1.7007000744342804e-02</internalNodes>
          <leafValues>
            -2.9189500212669373e-01 4.1053798794746399e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1942 -3.7034001201391220e-02</internalNodes>
          <leafValues>
            -1.3216309547424316e+00 1.2966500222682953e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1943 -1.9633000716567039e-02</internalNodes>
          <leafValues>
            -8.7702298164367676e-01 1.0799999581649899e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1944 -2.3546999320387840e-02</internalNodes>
          <leafValues>
            2.6106101274490356e-01 -2.1481400728225708e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1945 -4.3352998793125153e-02</internalNodes>
          <leafValues>
            -9.9089699983596802e-01 -9.9560003727674484e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1946 -2.2183999419212341e-02</internalNodes>
          <leafValues>
            6.3454401493072510e-01 -5.6547001004219055e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1947 1.6530999913811684e-02</internalNodes>
          <leafValues>
            2.4664999917149544e-02 -7.3326802253723145e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1948 -3.2744001597166061e-02</internalNodes>
          <leafValues>
            -5.6297200918197632e-01 1.6640299558639526e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1949 7.1415998041629791e-02</internalNodes>
          <leafValues>
            -3.0000001424923539e-04 -9.3286401033401489e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1950 8.0999999772757292e-04</internalNodes>
          <leafValues>
            -9.5380000770092010e-02 2.5184699892997742e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1951 -8.4090000018477440e-03</internalNodes>
          <leafValues>
            -6.5496802330017090e-01 6.7300997674465179e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1952 -1.7254000529646873e-02</internalNodes>
          <leafValues>
            -4.6492999792098999e-01 1.6070899367332458e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1953 -1.8641000613570213e-02</internalNodes>
          <leafValues>
            -1.0594010353088379e+00 -1.9617000594735146e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1954 -9.1979997232556343e-03</internalNodes>
          <leafValues>
            5.0716197490692139e-01 -1.5339200198650360e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1955 1.8538000062108040e-02</internalNodes>
          <leafValues>
            -3.0498200654983521e-01 7.3506200313568115e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1956 -5.0335001200437546e-02</internalNodes>
          <leafValues>
            -1.1140480041503906e+00 1.8000100553035736e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1957 -2.3529000580310822e-02</internalNodes>
          <leafValues>
            -8.6907899379730225e-01 -1.2459999881684780e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1958 -2.7100000530481339e-02</internalNodes>
          <leafValues>
            6.5942901372909546e-01 -3.5323999822139740e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1959 6.5879998728632927e-03</internalNodes>
          <leafValues>
            -2.2953400015830994e-01 4.2425099015235901e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1960 2.3360000923275948e-02</internalNodes>
          <leafValues>
            1.8356199562549591e-01 -9.8587298393249512e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1961 1.2946999631822109e-02</internalNodes>
          <leafValues>
            -3.3147400617599487e-01 2.1323199570178986e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1962 -6.6559999249875546e-03</internalNodes>
          <leafValues>
            -1.1951400339603424e-01 2.9752799868583679e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1963 -2.2570999339222908e-02</internalNodes>
          <leafValues>
            3.8499400019645691e-01 -2.4434499442577362e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1964 -6.3813999295234680e-02</internalNodes>
          <leafValues>
            -8.9383500814437866e-01 1.4217500388622284e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1965 -4.9945000559091568e-02</internalNodes>
          <leafValues>
            5.3864401578903198e-01 -2.0485299825668335e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1966 6.8319998681545258e-03</internalNodes>
          <leafValues>
            -5.6678999215364456e-02 3.9970999956130981e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1967 -5.5835999548435211e-02</internalNodes>
          <leafValues>
            -1.5239470005035400e+00 -5.1183000206947327e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1968 3.1957000494003296e-01</internalNodes>
          <leafValues>
            7.4574001133441925e-02 1.2447799444198608e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1969 8.0955997109413147e-02</internalNodes>
          <leafValues>
            -1.9665500521659851e-01 5.9889698028564453e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1970 -1.4911999925971031e-02</internalNodes>
          <leafValues>
            -6.4020597934722900e-01 1.5807600319385529e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1971 4.6709001064300537e-02</internalNodes>
          <leafValues>
            8.5239000618457794e-02 -4.5487201213836670e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1972 6.0539999976754189e-03</internalNodes>
          <leafValues>
            -4.3184000253677368e-01 2.2452600300312042e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1973 -3.4375999122858047e-02</internalNodes>
          <leafValues>
            4.0202501416206360e-01 -2.3903599381446838e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1974 -3.4924000501632690e-02</internalNodes>
          <leafValues>
            5.2870100736618042e-01 3.9709001779556274e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1975 3.0030000489205122e-03</internalNodes>
          <leafValues>
            -3.8754299283027649e-01 1.4192600548267365e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1976 -1.4132999815046787e-02</internalNodes>
          <leafValues>
            8.7528401613235474e-01 8.5507996380329132e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1977 -6.7940000444650650e-03</internalNodes>
          <leafValues>
            -1.1649219989776611e+00 -3.3943001180887222e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1978 -5.2886001765727997e-02</internalNodes>
          <leafValues>
            1.0930680036544800e+00 5.1187001168727875e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1979 -2.1079999860376120e-03</internalNodes>
          <leafValues>
            1.3696199655532837e-01 -3.3849999308586121e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1980 1.8353000283241272e-02</internalNodes>
          <leafValues>
            1.3661600649356842e-01 -4.0777799487113953e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1981 1.2671999633312225e-02</internalNodes>
          <leafValues>
            -1.4936000108718872e-02 -8.1707501411437988e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1982 1.2924999929964542e-02</internalNodes>
          <leafValues>
            1.7625099420547485e-01 -3.2491698861122131e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1983 -1.7921000719070435e-02</internalNodes>
          <leafValues>
            -5.2745401859283447e-01 4.4443000108003616e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1984 1.9160000374540687e-03</internalNodes>
          <leafValues>
            -1.0978599637746811e-01 2.2067500650882721e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1985 -1.4697999693453312e-02</internalNodes>
          <leafValues>
            3.9067798852920532e-01 -2.2224999964237213e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1986 -1.4972999691963196e-02</internalNodes>
          <leafValues>
            -2.5450900197029114e-01 1.7790000140666962e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1987 1.4636999927461147e-02</internalNodes>
          <leafValues>
            -2.5125000625848770e-02 -8.7121301889419556e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1988 -1.0974000208079815e-02</internalNodes>
          <leafValues>
            7.9082798957824707e-01 2.0121000707149506e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1989 -9.1599998995661736e-03</internalNodes>
          <leafValues>
            -4.7906899452209473e-01 5.2232000976800919e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1990 4.6179997734725475e-03</internalNodes>
          <leafValues>
            -1.7244599759578705e-01 3.4527799487113953e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1991 2.3476999253034592e-02</internalNodes>
          <leafValues>
            3.7760001141577959e-03 -6.5333700180053711e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1992 3.1766999512910843e-02</internalNodes>
          <leafValues>
            1.6364000737667084e-02 5.8723700046539307e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1993 -1.8419999629259109e-02</internalNodes>
          <leafValues>
            1.9993899762630463e-01 -3.2056498527526855e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1994 1.9543999806046486e-02</internalNodes>
          <leafValues>
            1.8450200557708740e-01 -2.3793600499629974e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1995 4.1159498691558838e-01</internalNodes>
          <leafValues>
            -6.0382001101970673e-02 -1.6072119474411011e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1996 -4.1595999151468277e-02</internalNodes>
          <leafValues>
            -3.2756200432777405e-01 1.5058000385761261e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1997 -1.0335999540984631e-02</internalNodes>
          <leafValues>
            -6.2394398450851440e-01 1.3112000189721584e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1998 1.2392999604344368e-02</internalNodes>
          <leafValues>
            -3.3114999532699585e-02 5.5579900741577148e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 1999 -8.7270000949501991e-03</internalNodes>
          <leafValues>
            1.9883200526237488e-01 -3.7635600566864014e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2000 1.6295000910758972e-02</internalNodes>
          <leafValues>
            2.0373000204563141e-01 -4.2800799012184143e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2001 -1.0483999736607075e-02</internalNodes>
          <leafValues>
            -5.6847000122070312e-01 4.4199001044034958e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2002 -1.2431999668478966e-02</internalNodes>
          <leafValues>
            7.4641901254653931e-01 4.3678998947143555e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2003 -5.0374999642372131e-02</internalNodes>
          <leafValues>
            8.5090100765228271e-01 -1.7773799598217010e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2004 4.9548000097274780e-02</internalNodes>
          <leafValues>
            1.6784900426864624e-01 -2.9877498745918274e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2005 -4.1085001081228256e-02</internalNodes>
          <leafValues>
            -1.3302919864654541e+00 -4.9182001501321793e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2006 1.0069999843835831e-03</internalNodes>
          <leafValues>
            -6.0538999736309052e-02 1.8483200669288635e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2007 -5.0142999738454819e-02</internalNodes>
          <leafValues>
            7.6447701454162598e-01 -1.8356999754905701e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2008 -8.7879998609423637e-03</internalNodes>
          <leafValues>
            2.2655999660491943e-01 -6.3156999647617340e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2009 -5.0170999020338058e-02</internalNodes>
          <leafValues>
            -1.5899070501327515e+00 -6.1255000531673431e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2010 1.0216099768877029e-01</internalNodes>
          <leafValues>
            1.2071800231933594e-01 -1.4120110273361206e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2011 -1.4372999779880047e-02</internalNodes>
          <leafValues>
            -1.3116970062255859e+00 -5.1936000585556030e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2012 1.0281999595463276e-02</internalNodes>
          <leafValues>
            -2.1639999467879534e-03 4.4247201085090637e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2013 -1.1814000084996223e-02</internalNodes>
          <leafValues>
            6.5378099679946899e-01 -1.8723699450492859e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2014 7.2114996612071991e-02</internalNodes>
          <leafValues>
            7.1846999228000641e-02 8.1496298313140869e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2015 -1.9001999869942665e-02</internalNodes>
          <leafValues>
            -6.7427200078964233e-01 -4.3200000072829425e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2016 -4.6990001574158669e-03</internalNodes>
          <leafValues>
            3.3311501145362854e-01 5.5794000625610352e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2017 -5.8157000690698624e-02</internalNodes>
          <leafValues>
            4.5572298765182495e-01 -2.0305100083351135e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2018 1.1360000353306532e-03</internalNodes>
          <leafValues>
            -4.4686999171972275e-02 2.2681899368762970e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2019 -4.9414999783039093e-02</internalNodes>
          <leafValues>
            2.6694598793983459e-01 -2.6116999983787537e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2020 -1.1913800239562988e-01</internalNodes>
          <leafValues>
            -8.3017998933792114e-01 1.3248500227928162e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2021 -1.8303999677300453e-02</internalNodes>
          <leafValues>
            -6.7499202489852905e-01 1.7092000693082809e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2022 -7.9199997708201408e-03</internalNodes>
          <leafValues>
            -7.2287000715732574e-02 1.4425800740718842e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2023 5.1925998181104660e-02</internalNodes>
          <leafValues>
            3.0921999365091324e-02 -5.5860602855682373e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2024 6.6724002361297607e-02</internalNodes>
          <leafValues>
            1.3666400313377380e-01 -2.9411000013351440e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2025 -1.3778000138700008e-02</internalNodes>
          <leafValues>
            -5.9443902969360352e-01 1.5300000086426735e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2026 -1.7760999500751495e-02</internalNodes>
          <leafValues>
            4.0496501326560974e-01 -3.3559999428689480e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2027 -4.2234998196363449e-02</internalNodes>
          <leafValues>
            -1.0897940397262573e+00 -4.0224999189376831e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2028 -1.3524999842047691e-02</internalNodes>
          <leafValues>
            2.8921899199485779e-01 -2.5194799900054932e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2029 -1.1106000281870365e-02</internalNodes>
          <leafValues>
            6.5312802791595459e-01 -1.8053700029850006e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2030 -1.2284599989652634e-01</internalNodes>
          <leafValues>
            -1.9570649862289429e+00 1.4815400540828705e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2031 4.7715999186038971e-02</internalNodes>
          <leafValues>
            -2.2875599563121796e-01 3.4233701229095459e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2032 3.1817000359296799e-02</internalNodes>
          <leafValues>
            1.5976299345493317e-01 -1.0091969966888428e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2033 4.2570000514388084e-03</internalNodes>
          <leafValues>
            -3.8881298899650574e-01 8.4210000932216644e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2034 -6.1372999101877213e-02</internalNodes>
          <leafValues>
            1.7152810096740723e+00 5.9324998408555984e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2035 -2.7030000928789377e-03</internalNodes>
          <leafValues>
            -3.8161700963973999e-01 8.5127003490924835e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2036 -6.8544000387191772e-02</internalNodes>
          <leafValues>
            -3.0925889015197754e+00 1.1788000166416168e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2037 1.0372500121593475e-01</internalNodes>
          <leafValues>
            -1.3769300282001495e-01 1.9009410142898560e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2038 1.5799000859260559e-02</internalNodes>
          <leafValues>
            -6.2660001218318939e-02 2.5917699933052063e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2039 -9.8040001466870308e-03</internalNodes>
          <leafValues>
            -5.6291598081588745e-01 4.3923001736402512e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2040 -9.0229995548725128e-03</internalNodes>
          <leafValues>
            2.5287100672721863e-01 -4.1225999593734741e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2041 -6.3754998147487640e-02</internalNodes>
          <leafValues>
            -2.6178569793701172e+00 -7.4005998671054840e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2042 3.8954999297857285e-02</internalNodes>
          <leafValues>
            5.9032998979091644e-02 8.5945600271224976e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2043 -3.9802998304367065e-02</internalNodes>
          <leafValues>
            9.3600499629974365e-01 -1.5639400482177734e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2044 5.0301998853683472e-02</internalNodes>
          <leafValues>
            1.3725900650024414e-01 -2.5549728870391846e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2045 4.6250000596046448e-02</internalNodes>
          <leafValues>
            -1.3964000158011913e-02 -7.1026200056076050e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2046 6.2196001410484314e-02</internalNodes>
          <leafValues>
            5.9526000171899796e-02 1.6509100198745728e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2047 -6.4776003360748291e-02</internalNodes>
          <leafValues>
            7.1368998289108276e-01 -1.7270000278949738e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2048 2.7522999793291092e-02</internalNodes>
          <leafValues>
            1.4631600677967072e-01 -8.1428997218608856e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2049 3.9900001138448715e-04</internalNodes>
          <leafValues>
            -3.7144500017166138e-01 1.0152699798345566e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2050 -4.3299999088048935e-03</internalNodes>
          <leafValues>
            -2.3756299912929535e-01 2.6798400282859802e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2051 4.7297000885009766e-02</internalNodes>
          <leafValues>
            -2.7682000771164894e-02 -8.4910297393798828e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2052 1.2508999556303024e-02</internalNodes>
          <leafValues>
            1.8730199337005615e-01 -5.6001102924346924e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2053 4.5899000018835068e-02</internalNodes>
          <leafValues>
            -1.5601199865341187e-01 9.7073000669479370e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2054 1.9853399693965912e-01</internalNodes>
          <leafValues>
            1.4895500242710114e-01 -1.1015529632568359e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2055 1.6674999147653580e-02</internalNodes>
          <leafValues>
            -1.6615299880504608e-01 8.2210999727249146e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2056 1.9829999655485153e-03</internalNodes>
          <leafValues>
            -7.1249999105930328e-02 2.8810900449752808e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2057 2.2447999566793442e-02</internalNodes>
          <leafValues>
            -2.0981000736355782e-02 -7.8416502475738525e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2058 -1.3913000002503395e-02</internalNodes>
          <leafValues>
            -1.8165799975395203e-01 2.0491799712181091e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2059 -7.7659999951720238e-03</internalNodes>
          <leafValues>
            -4.5595899224281311e-01 6.3576996326446533e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2060 -1.3209000229835510e-02</internalNodes>
          <leafValues>
            2.6632300019264221e-01 -1.7795999348163605e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2061 4.9052998423576355e-02</internalNodes>
          <leafValues>
            -1.5476800501346588e-01 1.1069979667663574e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2062 2.0263999700546265e-02</internalNodes>
          <leafValues>
            6.8915002048015594e-02 6.9867497682571411e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2063 -1.6828000545501709e-02</internalNodes>
          <leafValues>
            2.7607199549674988e-01 -2.5139200687408447e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2064 -1.6939499974250793e-01</internalNodes>
          <leafValues>
            -3.0767529010772705e+00 1.1617500334978104e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2065 -1.1336100101470947e-01</internalNodes>
          <leafValues>
            -1.4639229774475098e+00 -5.1447000354528427e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2066 -7.7685996890068054e-02</internalNodes>
          <leafValues>
            8.8430202007293701e-01 4.3306998908519745e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2067 -1.5568000264465809e-02</internalNodes>
          <leafValues>
            1.3672499358654022e-01 -3.4505501389503479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2068 -6.6018998622894287e-02</internalNodes>
          <leafValues>
            -1.0300110578536987e+00 1.1601399630308151e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2069 8.3699999377131462e-03</internalNodes>
          <leafValues>
            7.6429001986980438e-02 -4.4002500176429749e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2070 3.5402998328208923e-02</internalNodes>
          <leafValues>
            1.1979500204324722e-01 -7.2668302059173584e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2071 -3.9051000028848648e-02</internalNodes>
          <leafValues>
            6.7375302314758301e-01 -1.8196000158786774e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2072 -9.7899995744228363e-03</internalNodes>
          <leafValues>
            2.1264599263668060e-01 3.6756001412868500e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2073 -2.3047000169754028e-02</internalNodes>
          <leafValues>
            4.4742199778556824e-01 -2.0986700057983398e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2074 3.1169999856501818e-03</internalNodes>
          <leafValues>
            3.7544000893831253e-02 2.7808201313018799e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2075 1.3136000372469425e-02</internalNodes>
          <leafValues>
            -1.9842399656772614e-01 5.4335701465606689e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2076 1.4782000333070755e-02</internalNodes>
          <leafValues>
            1.3530600070953369e-01 -1.1153600364923477e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2077 -6.0139000415802002e-02</internalNodes>
          <leafValues>
            8.4039300680160522e-01 -1.6711600124835968e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2078 5.1998998969793320e-02</internalNodes>
          <leafValues>
            1.7372000217437744e-01 -7.8547602891921997e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2079 2.4792000651359558e-02</internalNodes>
          <leafValues>
            -1.7739200592041016e-01 6.6752600669860840e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2080 -1.2014999985694885e-02</internalNodes>
          <leafValues>
            -1.4263699948787689e-01 1.6070500016212463e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2081 -9.8655998706817627e-02</internalNodes>
          <leafValues>
            1.0429769754409790e+00 -1.5770199894905090e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2082 1.1758299916982651e-01</internalNodes>
          <leafValues>
            1.0955700278282166e-01 -4.4920377731323242e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2083 -1.8922999501228333e-02</internalNodes>
          <leafValues>
            -7.8543400764465332e-01 1.2984000146389008e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2084 -2.8390999883413315e-02</internalNodes>
          <leafValues>
            -6.0569900274276733e-01 1.2903499603271484e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2085 1.3182999566197395e-02</internalNodes>
          <leafValues>
            -1.4415999874472618e-02 -7.3210501670837402e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2086 -1.1653000116348267e-01</internalNodes>
          <leafValues>
            -2.0442469120025635e+00 1.4053100347518921e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2087 -3.8880000356584787e-03</internalNodes>
          <leafValues>
            -4.1861599683761597e-01 7.8704997897148132e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2088 3.1229000538587570e-02</internalNodes>
          <leafValues>
            2.4632999673485756e-02 4.1870400309562683e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2089 2.5198999792337418e-02</internalNodes>
          <leafValues>
            -1.7557799816131592e-01 6.4710599184036255e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2090 -2.8124000877141953e-02</internalNodes>
          <leafValues>
            -2.2005599737167358e-01 1.4121000468730927e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2091 3.6499001085758209e-02</internalNodes>
          <leafValues>
            -6.8426996469497681e-02 -2.3410849571228027e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2092 -7.2292998433113098e-02</internalNodes>
          <leafValues>
            1.2898750305175781e+00 8.4875002503395081e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2093 -4.1671000421047211e-02</internalNodes>
          <leafValues>
            -1.1630970239639282e+00 -5.3752999752759933e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2094 4.7703001648187637e-02</internalNodes>
          <leafValues>
            7.0101000368595123e-02 7.3676502704620361e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2095 6.5793000161647797e-02</internalNodes>
          <leafValues>
            -1.7755299806594849e-01 6.9780498743057251e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2096 1.3904999941587448e-02</internalNodes>
          <leafValues>
            2.1936799585819244e-01 -2.0390799641609192e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2097 -2.7730999514460564e-02</internalNodes>
          <leafValues>
            6.1867898702621460e-01 -1.7804099619388580e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2098 -1.5879999846220016e-02</internalNodes>
          <leafValues>
            -4.6484100818634033e-01 1.8828600645065308e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2099 7.4128001928329468e-02</internalNodes>
          <leafValues>
            -1.2858100235462189e-01 3.2792479991912842e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2100 -8.9000002481043339e-04</internalNodes>
          <leafValues>
            -3.0117601156234741e-01 2.3818799853324890e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2101 1.7965000122785568e-02</internalNodes>
          <leafValues>
            -2.2284999489784241e-01 2.9954001307487488e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2102 -2.5380000006407499e-03</internalNodes>
          <leafValues>
            2.5064399838447571e-01 -1.3665600121021271e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2103 -9.0680001303553581e-03</internalNodes>
          <leafValues>
            2.9017499089241028e-01 -2.8929701447486877e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2104 4.9169998615980148e-02</internalNodes>
          <leafValues>
            1.9156399369239807e-01 -6.8328702449798584e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2105 -3.0680999159812927e-02</internalNodes>
          <leafValues>
            -7.5677001476287842e-01 -1.3279999606311321e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2106 1.0017400234937668e-01</internalNodes>
          <leafValues>
            8.4453999996185303e-02 1.0888710021972656e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2107 3.1950001139193773e-03</internalNodes>
          <leafValues>
            -2.6919400691986084e-01 1.9537900388240814e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2108 3.5503000020980835e-02</internalNodes>
          <leafValues>
            1.3632300496101379e-01 -5.6917202472686768e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2109 4.5900000259280205e-04</internalNodes>
          <leafValues>
            -4.0443998575210571e-01 1.4074799418449402e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2110 2.5258999317884445e-02</internalNodes>
          <leafValues>
            1.6243200004100800e-01 -5.5741798877716064e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2111 -5.1549999043345451e-03</internalNodes>
          <leafValues>
            3.1132599711418152e-01 -2.2756099700927734e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2112 1.5869999770075083e-03</internalNodes>
          <leafValues>
            -2.6867699623107910e-01 1.9565400481224060e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2113 -1.6204999759793282e-02</internalNodes>
          <leafValues>
            1.5486499667167664e-01 -3.4057798981666565e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2114 -2.9624000191688538e-02</internalNodes>
          <leafValues>
            1.1466799974441528e+00 9.0557999908924103e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2115 -1.5930000226944685e-03</internalNodes>
          <leafValues>
            -7.1257501840591431e-01 -7.0400000549852848e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2116 -5.4019000381231308e-02</internalNodes>
          <leafValues>
            4.1537499427795410e-01 2.7246000245213509e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2117 -6.6211000084877014e-02</internalNodes>
          <leafValues>
            -1.3340090513229370e+00 -4.7352999448776245e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2118 2.7940999716520309e-02</internalNodes>
          <leafValues>
            1.4446300268173218e-01 -5.1518398523330688e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2119 2.8957000002264977e-02</internalNodes>
          <leafValues>
            -4.9966000020503998e-02 -1.1929039955139160e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2120 -2.0424999296665192e-02</internalNodes>
          <leafValues>
            6.3881301879882812e-01 3.8141001015901566e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2121 1.2416999787092209e-02</internalNodes>
          <leafValues>
            -2.1547000110149384e-01 4.9477699398994446e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>181</maxWeakCount>
      <stageThreshold>-3.3196411132812500e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 2122 4.3274000287055969e-02</internalNodes>
          <leafValues>
            -8.0494397878646851e-01 3.9897298812866211e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2123 1.8615500628948212e-01</internalNodes>
          <leafValues>
            -3.1655299663543701e-01 6.8877297639846802e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2124 3.1860999763011932e-02</internalNodes>
          <leafValues>
            -6.4266198873519897e-01 2.5550898909568787e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2125 1.4022000133991241e-02</internalNodes>
          <leafValues>
            -4.5926600694656372e-01 3.1171199679374695e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2126 -6.3029997982084751e-03</internalNodes>
          <leafValues>
            4.6026900410652161e-01 -2.7438500523567200e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2127 -5.4310001432895660e-03</internalNodes>
          <leafValues>
            3.6608600616455078e-01 -2.7205801010131836e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2128 1.6822999343276024e-02</internalNodes>
          <leafValues>
            2.3476999253034592e-02 -8.8443797826766968e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2129 2.6039000600576401e-02</internalNodes>
          <leafValues>
            1.7488799989223480e-01 -5.4564702510833740e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2130 -2.6720000430941582e-02</internalNodes>
          <leafValues>
            -9.6396499872207642e-01 2.3524999618530273e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2131 -1.7041999846696854e-02</internalNodes>
          <leafValues>
            -7.0848798751831055e-01 2.1468099951744080e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2132 5.9569999575614929e-03</internalNodes>
          <leafValues>
            7.3601000010967255e-02 -6.8225598335266113e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2133 -2.8679999522864819e-03</internalNodes>
          <leafValues>
            -7.4935001134872437e-01 2.3803399503231049e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2134 -4.3774999678134918e-02</internalNodes>
          <leafValues>
            6.8323302268981934e-01 -2.1380299329757690e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2135 5.1633000373840332e-02</internalNodes>
          <leafValues>
            -1.2566499412059784e-01 6.7523801326751709e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2136 8.1780003383755684e-03</internalNodes>
          <leafValues>
            7.0689998567104340e-02 -8.0665898323059082e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2137 -5.2841998636722565e-02</internalNodes>
          <leafValues>
            9.5433902740478516e-01 1.6548000276088715e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2138 5.2583999931812286e-02</internalNodes>
          <leafValues>
            -2.8414401412010193e-01 4.7129800915718079e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2139 -1.2659000232815742e-02</internalNodes>
          <leafValues>
            3.8445401191711426e-01 -6.2288001179695129e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2140 1.1694000102579594e-02</internalNodes>
          <leafValues>
            5.6000000768108293e-05 -1.0173139572143555e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2141 -2.3918999359011650e-02</internalNodes>
          <leafValues>
            8.4921300411224365e-01 5.7399999350309372e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2142 -6.1673998832702637e-02</internalNodes>
          <leafValues>
            -9.2571401596069336e-01 -1.7679999582469463e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2143 -1.8279999494552612e-03</internalNodes>
          <leafValues>
            -5.4372298717498779e-01 2.4932399392127991e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2144 3.5257998853921890e-02</internalNodes>
          <leafValues>
            -7.3719997890293598e-03 -9.3963998556137085e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2145 -1.8438000231981277e-02</internalNodes>
          <leafValues>
            7.2136700153350830e-01 1.0491999797523022e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2146 -3.8389001041650772e-02</internalNodes>
          <leafValues>
            1.9272600114345551e-01 -3.5832101106643677e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2147 9.9720999598503113e-02</internalNodes>
          <leafValues>
            1.1354199796915054e-01 -1.6304190158843994e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2148 8.4462001919746399e-02</internalNodes>
          <leafValues>
            -5.3420998156070709e-02 -1.6981120109558105e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2149 4.0270000696182251e-02</internalNodes>
          <leafValues>
            -1.0783199965953827e-01 5.1926600933074951e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2150 5.8935999870300293e-02</internalNodes>
          <leafValues>
            -1.8053700029850006e-01 9.5119798183441162e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2151 1.4957000315189362e-01</internalNodes>
          <leafValues>
            1.6785299777984619e-01 -1.1591869592666626e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2152 6.9399998756125569e-04</internalNodes>
          <leafValues>
            2.0491400361061096e-01 -3.3118200302124023e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2153 -3.3369001001119614e-02</internalNodes>
          <leafValues>
            9.3468099832534790e-01 -2.9639999847859144e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2154 9.3759996816515923e-03</internalNodes>
          <leafValues>
            3.7000000011175871e-03 -7.7549797296524048e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2155 4.3193999677896500e-02</internalNodes>
          <leafValues>
            -2.2040000185370445e-03 7.4589699506759644e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2156 -6.7555002868175507e-02</internalNodes>
          <leafValues>
            7.2292101383209229e-01 -1.8404200673103333e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2157 -3.1168600916862488e-01</internalNodes>
          <leafValues>
            1.0014270544052124e+00 3.4003000706434250e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2158 2.9743999242782593e-02</internalNodes>
          <leafValues>
            -4.6356000006198883e-02 -1.2781809568405151e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2159 1.0737000033259392e-02</internalNodes>
          <leafValues>
            1.4812000095844269e-02 6.6649997234344482e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2160 -2.8841000050306320e-02</internalNodes>
          <leafValues>
            -9.4222599267959595e-01 -2.0796999335289001e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2161 -5.7649998925626278e-03</internalNodes>
          <leafValues>
            -4.3541899323463440e-01 2.3386000096797943e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2162 2.8410999104380608e-02</internalNodes>
          <leafValues>
            -1.7615799605846405e-01 8.5765302181243896e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2163 -2.9007999226450920e-02</internalNodes>
          <leafValues>
            5.7978099584579468e-01 2.8565999120473862e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2164 2.4965999647974968e-02</internalNodes>
          <leafValues>
            -2.2729000076651573e-02 -9.6773099899291992e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2165 1.2036000378429890e-02</internalNodes>
          <leafValues>
            -1.4214700460433960e-01 5.1687997579574585e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2166 -4.2514000087976456e-02</internalNodes>
          <leafValues>
            9.7273802757263184e-01 -1.8119800090789795e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2167 1.0276000015437603e-02</internalNodes>
          <leafValues>
            -8.3099998533725739e-02 3.1762799620628357e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2168 -6.9191999733448029e-02</internalNodes>
          <leafValues>
            -2.0668580532073975e+00 -6.0173999518156052e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2169 -4.6769999898970127e-03</internalNodes>
          <leafValues>
            4.4131800532341003e-01 2.3209000006318092e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2170 -1.3923999853432178e-02</internalNodes>
          <leafValues>
            2.8606700897216797e-01 -2.9152700304985046e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2171 -1.5333999879658222e-02</internalNodes>
          <leafValues>
            -5.7414501905441284e-01 2.3063300549983978e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2172 -1.0239000432193279e-02</internalNodes>
          <leafValues>
            3.4479200839996338e-01 -2.6080399751663208e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2173 -5.0988998264074326e-02</internalNodes>
          <leafValues>
            5.6154102087020874e-01 6.1218999326229095e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2174 3.0689999461174011e-02</internalNodes>
          <leafValues>
            -1.4772799611091614e-01 1.6378489732742310e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2175 -1.1223999783396721e-02</internalNodes>
          <leafValues>
            2.4006199836730957e-01 -4.4864898920059204e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2176 -6.2899999320507050e-03</internalNodes>
          <leafValues>
            4.3119499087333679e-01 -2.3808999359607697e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2177 7.8590996563434601e-02</internalNodes>
          <leafValues>
            1.9865000620484352e-02 8.0853801965713501e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2178 -1.0178999975323677e-02</internalNodes>
          <leafValues>
            1.8193200230598450e-01 -3.2877799868583679e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2179 3.1227000057697296e-02</internalNodes>
          <leafValues>
            1.4973899722099304e-01 -1.4180339574813843e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2180 4.0196999907493591e-02</internalNodes>
          <leafValues>
            -1.9760499894618988e-01 5.8508199453353882e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2181 1.6138000413775444e-02</internalNodes>
          <leafValues>
            5.0000002374872565e-04 3.9050000905990601e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2182 -4.5519001781940460e-02</internalNodes>
          <leafValues>
            1.2646820545196533e+00 -1.5632599592208862e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2183 -1.8130000680685043e-02</internalNodes>
          <leafValues>
            6.5148502588272095e-01 1.0235999710857868e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2184 -1.4001999981701374e-02</internalNodes>
          <leafValues>
            -1.0344820022583008e+00 -3.2182998955249786e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2185 -3.8816001266241074e-02</internalNodes>
          <leafValues>
            -4.7874298691749573e-01 1.6290700435638428e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2186 3.1656000763177872e-02</internalNodes>
          <leafValues>
            -2.0983399450778961e-01 5.4575902223587036e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2187 -1.0839999653398991e-02</internalNodes>
          <leafValues>
            5.1898801326751709e-01 -1.5080000273883343e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2188 1.2032999657094479e-02</internalNodes>
          <leafValues>
            -2.1107600629329681e-01 7.5937002897262573e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2189 7.0772998034954071e-02</internalNodes>
          <leafValues>
            1.8048800528049469e-01 -7.4048501253128052e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2190 5.3139799833297729e-01</internalNodes>
          <leafValues>
            -1.4491699635982513e-01 1.5360039472579956e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2191 -1.4774000272154808e-02</internalNodes>
          <leafValues>
            -2.8153699636459351e-01 2.0407299697399139e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2192 -2.2410000674426556e-03</internalNodes>
          <leafValues>
            -4.4876301288604736e-01 5.3989000618457794e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2193 4.9968000501394272e-02</internalNodes>
          <leafValues>
            4.1514001786708832e-02 2.9417100548744202e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2194 -4.7701999545097351e-02</internalNodes>
          <leafValues>
            3.9674299955368042e-01 -2.8301799297332764e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2195 -9.1311000287532806e-02</internalNodes>
          <leafValues>
            2.1994259357452393e+00 8.7964996695518494e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2196 3.8070000708103180e-02</internalNodes>
          <leafValues>
            -2.8025600314140320e-01 2.5156199932098389e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2197 -1.5538999810814857e-02</internalNodes>
          <leafValues>
            3.4157499670982361e-01 1.7924999818205833e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2198 -1.5445999801158905e-02</internalNodes>
          <leafValues>
            2.8680199384689331e-01 -2.5135898590087891e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2199 -5.7388000190258026e-02</internalNodes>
          <leafValues>
            6.3830000162124634e-01 8.8597998023033142e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2200 -5.9440000914037228e-03</internalNodes>
          <leafValues>
            7.9016998410224915e-02 -4.0774899721145630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2201 -6.9968998432159424e-02</internalNodes>
          <leafValues>
            -4.4644200801849365e-01 1.7219600081443787e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2202 -2.5064999237656593e-02</internalNodes>
          <leafValues>
            -9.8270201683044434e-01 -3.5388000309467316e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2203 1.7216000705957413e-02</internalNodes>
          <leafValues>
            2.2705900669097900e-01 -8.0550098419189453e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2204 -4.4279001653194427e-02</internalNodes>
          <leafValues>
            8.3951997756958008e-01 -1.7429600656032562e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2205 4.3988998979330063e-02</internalNodes>
          <leafValues>
            1.1557199805974960e-01 -1.9666889905929565e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2206 1.5907000750303268e-02</internalNodes>
          <leafValues>
            -3.7576001137495041e-02 -1.0311100482940674e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2207 -9.2754997313022614e-02</internalNodes>
          <leafValues>
            -1.3530019521713257e+00 1.2141299992799759e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2208 7.1037001907825470e-02</internalNodes>
          <leafValues>
            -1.7684300243854523e-01 7.4485200643539429e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2209 5.7762000709772110e-02</internalNodes>
          <leafValues>
            1.2835599482059479e-01 -4.4444200396537781e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2210 -1.6432000324130058e-02</internalNodes>
          <leafValues>
            8.0152702331542969e-01 -1.7491699755191803e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2211 2.3939000442624092e-02</internalNodes>
          <leafValues>
            1.6144999861717224e-01 -1.2364500015974045e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2212 1.2636000290513039e-02</internalNodes>
          <leafValues>
            1.5411999821662903e-01 -3.3293798565864563e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2213 -5.4347999393939972e-02</internalNodes>
          <leafValues>
            -1.8400700092315674e+00 1.4835999906063080e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2214 -1.3261999934911728e-02</internalNodes>
          <leafValues>
            -8.0838799476623535e-01 -2.7726000174880028e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2215 6.1340001411736012e-03</internalNodes>
          <leafValues>
            -1.3785000145435333e-01 3.2858499884605408e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2216 2.8991000726819038e-02</internalNodes>
          <leafValues>
            -2.5516999885439873e-02 -8.3387202024459839e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2217 -2.1986000239849091e-02</internalNodes>
          <leafValues>
            -7.3739999532699585e-01 1.7887100577354431e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2218 5.3269998170435429e-03</internalNodes>
          <leafValues>
            -4.5449298620223999e-01 6.8791002035140991e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2219 8.6047999560832977e-02</internalNodes>
          <leafValues>
            2.1008500456809998e-01 -3.7808901071548462e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2220 -8.5549997165799141e-03</internalNodes>
          <leafValues>
            4.0134999155998230e-01 -2.1074099838733673e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2221 6.7790001630783081e-03</internalNodes>
          <leafValues>
            -2.1648999303579330e-02 4.5421499013900757e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2222 -6.3959998078644276e-03</internalNodes>
          <leafValues>
            -4.9818599224090576e-01 7.5907997786998749e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2223 8.9469999074935913e-03</internalNodes>
          <leafValues>
            1.7857700586318970e-01 -2.8454899787902832e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2224 3.2589999027550220e-03</internalNodes>
          <leafValues>
            4.6624999493360519e-02 -5.5206298828125000e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2225 4.1476998478174210e-02</internalNodes>
          <leafValues>
            1.7550499737262726e-01 -2.0703999698162079e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2226 -6.7449999041855335e-03</internalNodes>
          <leafValues>
            -4.6392598748207092e-01 6.9303996860980988e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2227 3.0564999207854271e-02</internalNodes>
          <leafValues>
            5.1734998822212219e-02 7.5550502538681030e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2228 -7.4780001305043697e-03</internalNodes>
          <leafValues>
            1.4893899857997894e-01 -3.1906801462173462e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2229 8.9088998734951019e-02</internalNodes>
          <leafValues>
            1.3738800585269928e-01 -1.1379710435867310e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2230 7.3230001144111156e-03</internalNodes>
          <leafValues>
            -2.8829199075698853e-01 1.9088600575923920e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2231 -1.8205000087618828e-02</internalNodes>
          <leafValues>
            -3.0178600549697876e-01 1.6795800626277924e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2232 -2.5828000158071518e-02</internalNodes>
          <leafValues>
            -9.8137998580932617e-01 -1.9860999658703804e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2233 1.0936199873685837e-01</internalNodes>
          <leafValues>
            4.8790000379085541e-02 5.3118300437927246e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2234 -1.1424999684095383e-02</internalNodes>
          <leafValues>
            2.3705999553203583e-01 -2.7925300598144531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2235 -5.7565998286008835e-02</internalNodes>
          <leafValues>
            4.7255399823188782e-01 6.5171003341674805e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2236 1.0278300195932388e-01</internalNodes>
          <leafValues>
            -2.0765100419521332e-01 5.0947701930999756e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2237 2.7041999623179436e-02</internalNodes>
          <leafValues>
            1.6421200335025787e-01 -1.4508620500564575e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2238 -1.3635000213980675e-02</internalNodes>
          <leafValues>
            -5.6543898582458496e-01 2.3788999766111374e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2239 -3.2158198952674866e-01</internalNodes>
          <leafValues>
            -3.5602829456329346e+00 1.1801300197839737e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2240 2.0458100736141205e-01</internalNodes>
          <leafValues>
            -3.7016000598669052e-02 -1.0225499868392944e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2241 -7.0347003638744354e-02</internalNodes>
          <leafValues>
            -5.6491899490356445e-01 1.8525199592113495e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2242 3.7831000983715057e-02</internalNodes>
          <leafValues>
            -2.9901999980211258e-02 -8.2921499013900757e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2243 -7.0298001170158386e-02</internalNodes>
          <leafValues>
            -5.3172302246093750e-01 1.4430199563503265e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2244 6.3221000134944916e-02</internalNodes>
          <leafValues>
            -2.2041200101375580e-01 4.7952198982238770e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2245 3.6393001675605774e-02</internalNodes>
          <leafValues>
            1.4222699403762817e-01 -6.1193901300430298e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2246 4.0099998004734516e-03</internalNodes>
          <leafValues>
            -3.4560799598693848e-01 1.1738699674606323e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2247 -4.9106001853942871e-02</internalNodes>
          <leafValues>
            9.5984101295471191e-01 6.4934998750686646e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2248 -7.1583002805709839e-02</internalNodes>
          <leafValues>
            1.7385669946670532e+00 -1.4252899587154388e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2249 -3.8008999079465866e-02</internalNodes>
          <leafValues>
            1.3872820138931274e+00 6.6188000142574310e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2250 -3.1570000573992729e-03</internalNodes>
          <leafValues>
            5.3677000105381012e-02 -5.4048001766204834e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2251 1.9458999857306480e-02</internalNodes>
          <leafValues>
            -9.3620002269744873e-02 3.9131000638008118e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2252 1.1293999850749969e-02</internalNodes>
          <leafValues>
            3.7223998457193375e-02 -5.4251801967620850e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2253 -3.3495001494884491e-02</internalNodes>
          <leafValues>
            9.5307898521423340e-01 3.7696998566389084e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2254 9.2035003006458282e-02</internalNodes>
          <leafValues>
            -1.3488399982452393e-01 2.2897069454193115e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2255 3.7529999390244484e-03</internalNodes>
          <leafValues>
            2.2824199497699738e-01 -5.9983700513839722e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2256 1.2848000042140484e-02</internalNodes>
          <leafValues>
            -2.2005200386047363e-01 3.7221899628639221e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2257 -1.4316199719905853e-01</internalNodes>
          <leafValues>
            1.2855789661407471e+00 4.7237001359462738e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2258 -9.6879996359348297e-02</internalNodes>
          <leafValues>
            -3.9550929069519043e+00 -7.2903998196125031e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2259 -8.8459998369216919e-03</internalNodes>
          <leafValues>
            3.7674999237060547e-01 -4.6484000980854034e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2260 1.5900000929832458e-02</internalNodes>
          <leafValues>
            -2.4457000195980072e-02 -8.0034798383712769e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2261 7.0372000336647034e-02</internalNodes>
          <leafValues>
            1.7019000649452209e-01 -6.3068997859954834e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2262 -3.7953998893499374e-02</internalNodes>
          <leafValues>
            -9.3667197227478027e-01 -4.1214000433683395e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2263 5.1597899198532104e-01</internalNodes>
          <leafValues>
            1.3080599904060364e-01 -1.5802290439605713e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2264 -3.2843001186847687e-02</internalNodes>
          <leafValues>
            -1.1441620588302612e+00 -4.9173999577760696e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2265 -3.6357000470161438e-02</internalNodes>
          <leafValues>
            4.9606400728225708e-01 -3.4458998590707779e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2266 6.8080001510679722e-03</internalNodes>
          <leafValues>
            -3.0997800827026367e-01 1.7054800689220428e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2267 -1.6114000231027603e-02</internalNodes>
          <leafValues>
            -3.7904599308967590e-01 1.6078999638557434e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2268 8.4530003368854523e-03</internalNodes>
          <leafValues>
            -1.8655499815940857e-01 5.6367701292037964e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2269 -1.3752399384975433e-01</internalNodes>
          <leafValues>
            -5.8989900350570679e-01 1.1749500036239624e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2270 1.7688000202178955e-01</internalNodes>
          <leafValues>
            -1.5424899756908417e-01 9.2911100387573242e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2271 7.9309996217489243e-03</internalNodes>
          <leafValues>
            3.2190701365470886e-01 -1.6392600536346436e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2272 1.0971800237894058e-01</internalNodes>
          <leafValues>
            -1.5876500308513641e-01 1.0186259746551514e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2273 -3.0293000862002373e-02</internalNodes>
          <leafValues>
            7.5587302446365356e-01 3.1794998794794083e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2274 -2.3118000477552414e-02</internalNodes>
          <leafValues>
            -8.8451498746871948e-01 -9.5039997249841690e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2275 -3.0900000128895044e-03</internalNodes>
          <leafValues>
            2.3838299512863159e-01 -1.1606200039386749e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2276 -3.3392000943422318e-02</internalNodes>
          <leafValues>
            -1.8738139867782593e+00 -6.8502999842166901e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2277 1.3190000317990780e-02</internalNodes>
          <leafValues>
            1.2919899821281433e-01 -6.7512202262878418e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2278 1.4661000110208988e-02</internalNodes>
          <leafValues>
            -2.4829000234603882e-02 -7.4396800994873047e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2279 -1.3248000293970108e-02</internalNodes>
          <leafValues>
            4.6820199489593506e-01 -2.4165000766515732e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2280 -1.6218999400734901e-02</internalNodes>
          <leafValues>
            4.0083798766136169e-01 -2.1255700290203094e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2281 -2.9052000492811203e-02</internalNodes>
          <leafValues>
            -1.5650019645690918e+00 1.4375899732112885e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2282 -1.0153199732303619e-01</internalNodes>
          <leafValues>
            -1.9220689535140991e+00 -6.9559998810291290e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2283 3.7753999233245850e-02</internalNodes>
          <leafValues>
            1.3396799564361572e-01 -2.2639141082763672e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2284 -2.8555598855018616e-01</internalNodes>
          <leafValues>
            1.0215270519256592e+00 -1.5232199430465698e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2285 1.5360699594020844e-01</internalNodes>
          <leafValues>
            -9.7409002482891083e-02 4.1662400960922241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2286 -2.1199999901000410e-04</internalNodes>
          <leafValues>
            1.1271899938583374e-01 -4.1653999686241150e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2287 -2.0597999915480614e-02</internalNodes>
          <leafValues>
            6.0540497303009033e-01 6.2467999756336212e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2288 3.7353999912738800e-02</internalNodes>
          <leafValues>
            -1.8919000029563904e-01 4.6464699506759644e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2289 5.7275000959634781e-02</internalNodes>
          <leafValues>
            1.1565300077199936e-01 -1.3213009834289551e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2290 5.1029999740421772e-03</internalNodes>
          <leafValues>
            -2.8061500191688538e-01 1.9313399493694305e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2291 -5.4644998162984848e-02</internalNodes>
          <leafValues>
            7.2428500652313232e-01 7.5447998940944672e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2292 2.5349000468850136e-02</internalNodes>
          <leafValues>
            -1.9481800496578217e-01 4.6032801270484924e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2293 2.4311000481247902e-02</internalNodes>
          <leafValues>
            1.5564100444316864e-01 -4.9913901090621948e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2294 3.5962000489234924e-02</internalNodes>
          <leafValues>
            -5.8573000133037567e-02 -1.5418399572372437e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2295 -1.0000699758529663e-01</internalNodes>
          <leafValues>
            -1.6100039482116699e+00 1.1450500041246414e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2296 8.4435999393463135e-02</internalNodes>
          <leafValues>
            -6.1406999826431274e-02 -1.4673349857330322e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2297 1.5947999432682991e-02</internalNodes>
          <leafValues>
            1.6287900507450104e-01 -1.1026400327682495e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2298 3.3824000507593155e-02</internalNodes>
          <leafValues>
            -1.7932699620723724e-01 5.7218402624130249e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2299 -6.1996001750230789e-02</internalNodes>
          <leafValues>
            4.6511812210083008e+00 9.4534002244472504e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2300 6.9876998662948608e-02</internalNodes>
          <leafValues>
            -1.6985900700092316e-01 8.7028998136520386e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2301 -2.7916999533772469e-02</internalNodes>
          <leafValues>
            9.1042500734329224e-01 5.6827001273632050e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2302 -1.2764000333845615e-02</internalNodes>
          <leafValues>
            2.2066700458526611e-01 -2.7769100666046143e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>199</maxWeakCount>
      <stageThreshold>-3.2573320865631104e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 2303 2.1662000566720963e-02</internalNodes>
          <leafValues>
            -8.9868897199630737e-01 2.9436299204826355e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2304 1.0044500231742859e-01</internalNodes>
          <leafValues>
            -3.7659201025962830e-01 6.0891002416610718e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2305 2.6003999635577202e-02</internalNodes>
          <leafValues>
            -3.8128501176834106e-01 3.9217400550842285e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2306 2.8441000729799271e-02</internalNodes>
          <leafValues>
            -1.8182300031185150e-01 5.8927202224731445e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2307 3.8612000644207001e-02</internalNodes>
          <leafValues>
            -2.2399599850177765e-01 6.3779997825622559e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2308 -4.6594999730587006e-02</internalNodes>
          <leafValues>
            7.0812201499938965e-01 -1.4666199684143066e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2309 -4.2791999876499176e-02</internalNodes>
          <leafValues>
            4.7680398821830750e-01 -2.9233199357986450e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2310 3.7960000336170197e-03</internalNodes>
          <leafValues>
            -1.8510299921035767e-01 5.2626699209213257e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2311 4.2348999530076981e-02</internalNodes>
          <leafValues>
            3.9244998246431351e-02 -8.9197701215744019e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2312 1.9598999992012978e-02</internalNodes>
          <leafValues>
            -2.3358400166034698e-01 4.4146499037742615e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2313 8.7400001939386129e-04</internalNodes>
          <leafValues>
            -4.6063598990440369e-01 1.7689600586891174e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2314 -4.3629999272525311e-03</internalNodes>
          <leafValues>
            3.3493199944496155e-01 -2.9893401265144348e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2315 1.6973000019788742e-02</internalNodes>
          <leafValues>
            -1.6408699750900269e-01 1.5993679761886597e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2316 3.6063998937606812e-02</internalNodes>
          <leafValues>
            2.2601699829101562e-01 -5.3186100721359253e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2317 -7.0864997804164886e-02</internalNodes>
          <leafValues>
            1.5220500528812408e-01 -4.1914600133895874e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2318 -6.3075996935367584e-02</internalNodes>
          <leafValues>
            -1.4874019622802734e+00 1.2953700125217438e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2319 2.9670000076293945e-02</internalNodes>
          <leafValues>
            -1.9145900011062622e-01 9.8184901475906372e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2320 3.7873998284339905e-02</internalNodes>
          <leafValues>
            1.3459500670433044e-01 -5.6316298246383667e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2321 -3.3289000391960144e-02</internalNodes>
          <leafValues>
            -1.0828030109405518e+00 -1.1504000052809715e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2322 -3.1608998775482178e-02</internalNodes>
          <leafValues>
            -5.9224498271942139e-01 1.3394799828529358e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2323 1.0740000288933516e-03</internalNodes>
          <leafValues>
            -4.9185800552368164e-01 9.4446003437042236e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2324 -7.1556001901626587e-02</internalNodes>
          <leafValues>
            5.9710198640823364e-01 -3.9553001523017883e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2325 -8.1170000135898590e-02</internalNodes>
          <leafValues>
            -1.1817820072174072e+00 -2.8254000470042229e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2326 4.4860001653432846e-03</internalNodes>
          <leafValues>
            -6.1028099060058594e-01 2.2619099915027618e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2327 -4.2176000773906708e-02</internalNodes>
          <leafValues>
            -1.1435619592666626e+00 -2.9001999646425247e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2328 -6.5640002489089966e-02</internalNodes>
          <leafValues>
            -1.6470279693603516e+00 1.2810300290584564e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2329 1.8188999965786934e-02</internalNodes>
          <leafValues>
            -3.1149399280548096e-01 2.5739601254463196e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2330 -5.1520001143217087e-02</internalNodes>
          <leafValues>
            -6.9206899404525757e-01 1.5270799398422241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2331 -4.7150999307632446e-02</internalNodes>
          <leafValues>
            -7.1868300437927246e-01 2.6879999786615372e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2332 1.7488999292254448e-02</internalNodes>
          <leafValues>
            2.2371199727058411e-01 -5.5381798744201660e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2333 -2.5264000520110130e-02</internalNodes>
          <leafValues>
            1.0319819450378418e+00 -1.7496499419212341e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2334 -4.0745001286268234e-02</internalNodes>
          <leafValues>
            4.4961598515510559e-01 3.9349000900983810e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2335 -3.7666998803615570e-02</internalNodes>
          <leafValues>
            -8.5475701093673706e-01 -1.2463999912142754e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2336 -1.3411000370979309e-02</internalNodes>
          <leafValues>
            5.7845598459243774e-01 -1.7467999830842018e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2337 -7.8999997640494257e-05</internalNodes>
          <leafValues>
            -3.7749201059341431e-01 1.3961799442768097e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2338 -1.1415000073611736e-02</internalNodes>
          <leafValues>
            -2.6186600327491760e-01 2.3712499439716339e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2339 3.7200000137090683e-02</internalNodes>
          <leafValues>
            -2.8626000508666039e-02 -1.2945239543914795e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2340 3.4050000831484795e-03</internalNodes>
          <leafValues>
            2.0531399548053741e-01 -1.8747499585151672e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2341 -2.2483000531792641e-02</internalNodes>
          <leafValues>
            6.7027199268341064e-01 -1.9594000279903412e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2342 2.3274999111890793e-02</internalNodes>
          <leafValues>
            1.7405399680137634e-01 -3.2746300101280212e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2343 -1.3917000032961369e-02</internalNodes>
          <leafValues>
            -8.3954298496246338e-01 -6.3760001212358475e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2344 7.5429999269545078e-03</internalNodes>
          <leafValues>
            -3.4194998443126678e-02 5.8998197317123413e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2345 -1.1539000086486340e-02</internalNodes>
          <leafValues>
            4.2142799496650696e-01 -2.3510499298572540e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2346 5.2501998841762543e-02</internalNodes>
          <leafValues>
            6.9303996860980988e-02 7.3226499557495117e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2347 5.2715998142957687e-02</internalNodes>
          <leafValues>
            -1.5688100457191467e-01 1.0907289981842041e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2348 -1.1726000346243382e-02</internalNodes>
          <leafValues>
            -7.0934301614761353e-01 1.6828800737857819e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2349 9.5945999026298523e-02</internalNodes>
          <leafValues>
            -1.6192899644374847e-01 1.0072519779205322e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2350 -1.5871999785304070e-02</internalNodes>
          <leafValues>
            3.9008399844169617e-01 -5.3777001798152924e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2351 3.4818001091480255e-02</internalNodes>
          <leafValues>
            1.7179999500513077e-02 -9.3941801786422729e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2352 3.4791998565196991e-02</internalNodes>
          <leafValues>
            5.0462998449802399e-02 5.4465699195861816e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2353 1.6284000128507614e-02</internalNodes>
          <leafValues>
            -2.6981300115585327e-01 4.0365299582481384e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2354 -4.4319000095129013e-02</internalNodes>
          <leafValues>
            8.4399998188018799e-01 3.2882999628782272e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2355 -5.5689997971057892e-03</internalNodes>
          <leafValues>
            1.5309399366378784e-01 -3.4959799051284790e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2356 -6.5842002630233765e-02</internalNodes>
          <leafValues>
            -9.2711198329925537e-01 1.6800999641418457e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2357 -7.3337003588676453e-02</internalNodes>
          <leafValues>
            5.1614499092102051e-01 -2.0236000418663025e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2358 1.6450000926852226e-02</internalNodes>
          <leafValues>
            1.3950599730014801e-01 -4.9301299452781677e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2359 -9.2630004510283470e-03</internalNodes>
          <leafValues>
            -9.0101999044418335e-01 -1.6116000711917877e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2360 5.9139998629689217e-03</internalNodes>
          <leafValues>
            1.9858199357986450e-01 -1.6731299459934235e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2361 -8.4699998842552304e-04</internalNodes>
          <leafValues>
            9.4005003571510315e-02 -4.1570898890495300e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2362 2.0532900094985962e-01</internalNodes>
          <leafValues>
            -6.0022000223398209e-02 7.0993602275848389e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2363 -1.6883000731468201e-02</internalNodes>
          <leafValues>
            2.4392199516296387e-01 -3.0551800131797791e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2364 -1.9111000001430511e-02</internalNodes>
          <leafValues>
            6.1229902505874634e-01 2.4252999573945999e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2365 -2.5962999090552330e-02</internalNodes>
          <leafValues>
            9.0764999389648438e-01 -1.6722099483013153e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2366 -2.1762000396847725e-02</internalNodes>
          <leafValues>
            -3.1384700536727905e-01 2.0134599506855011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2367 -2.4119999259710312e-02</internalNodes>
          <leafValues>
            -6.6588401794433594e-01 7.4559999629855156e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2368 4.7129999846220016e-02</internalNodes>
          <leafValues>
            5.9533998370170593e-02 8.7804502248764038e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2369 -4.5984998345375061e-02</internalNodes>
          <leafValues>
            8.0067998170852661e-01 -1.7252300679683685e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2370 2.6507999747991562e-02</internalNodes>
          <leafValues>
            1.8774099647998810e-01 -6.0850602388381958e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2371 -4.8615001142024994e-02</internalNodes>
          <leafValues>
            5.8644098043441772e-01 -1.9427700340747833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2372 -1.8562000244855881e-02</internalNodes>
          <leafValues>
            -2.5587901473045349e-01 1.6326199471950531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2373 1.2678000144660473e-02</internalNodes>
          <leafValues>
            -1.4228000305593014e-02 -7.6738101243972778e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2374 -1.1919999960809946e-03</internalNodes>
          <leafValues>
            2.0495000481605530e-01 -1.1404299736022949e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2375 -4.9088999629020691e-02</internalNodes>
          <leafValues>
            -1.0740849971771240e+00 -3.8940999656915665e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2376 -1.7436999827623367e-02</internalNodes>
          <leafValues>
            -5.7973802089691162e-01 1.8584500253200531e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2377 -1.4770000241696835e-02</internalNodes>
          <leafValues>
            -6.6150301694869995e-01 5.3119999356567860e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2378 -2.2905200719833374e-01</internalNodes>
          <leafValues>
            -4.8305100202560425e-01 1.2326399981975555e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2379 -1.2707099318504333e-01</internalNodes>
          <leafValues>
            5.7452601194381714e-01 -1.9420400261878967e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2380 1.0339000262320042e-02</internalNodes>
          <leafValues>
            -5.4641999304294586e-02 2.4501800537109375e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2381 6.9010001607239246e-03</internalNodes>
          <leafValues>
            1.2180600315332413e-01 -3.8797399401664734e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2382 2.9025399684906006e-01</internalNodes>
          <leafValues>
            1.0966199636459351e-01 -30.</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2383 -2.3804999887943268e-01</internalNodes>
          <leafValues>
            -1.7352679967880249e+00 -6.3809998333454132e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2384 6.2481001019477844e-02</internalNodes>
          <leafValues>
            1.3523000478744507e-01 -7.0301097631454468e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2385 4.7109997831285000e-03</internalNodes>
          <leafValues>
            -4.6984100341796875e-01 6.0341998934745789e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2386 -2.7815999463200569e-02</internalNodes>
          <leafValues>
            6.9807600975036621e-01 1.3719999697059393e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2387 -1.7020000144839287e-02</internalNodes>
          <leafValues>
            1.6870440244674683e+00 -1.4314800500869751e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2388 -4.9754999577999115e-02</internalNodes>
          <leafValues>
            7.9497700929641724e-01 7.7199999941512942e-04</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2389 -7.4732996523380280e-02</internalNodes>
          <leafValues>
            -1.0132360458374023e+00 -1.9388999789953232e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2390 3.2009001821279526e-02</internalNodes>
          <leafValues>
            1.4412100613117218e-01 -4.2139101028442383e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2391 -9.4463996589183807e-02</internalNodes>
          <leafValues>
            5.0682598352432251e-01 -2.0478899776935577e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2392 -1.5426999889314175e-02</internalNodes>
          <leafValues>
            -1.5811300277709961e-01 1.7806899547576904e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2393 -4.0540001355111599e-03</internalNodes>
          <leafValues>
            -5.4366701841354370e-01 3.1235000118613243e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2394 3.0080000869929790e-03</internalNodes>
          <leafValues>
            -1.7376799881458282e-01 3.0441701412200928e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2395 -1.0091999545693398e-02</internalNodes>
          <leafValues>
            2.5103801488876343e-01 -2.6224100589752197e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2396 -3.8818001747131348e-02</internalNodes>
          <leafValues>
            9.3226701021194458e-01 7.2659999132156372e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2397 3.4651998430490494e-02</internalNodes>
          <leafValues>
            -3.3934999257326126e-02 -8.5707902908325195e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2398 -4.6729999594390392e-03</internalNodes>
          <leafValues>
            3.4969300031661987e-01 -4.8517998307943344e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2399 6.8499997723847628e-04</internalNodes>
          <leafValues>
            6.6573001444339752e-02 -4.4973799586296082e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2400 3.5317000001668930e-02</internalNodes>
          <leafValues>
            1.4275799691677094e-01 -4.6726399660110474e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2401 -2.3569999262690544e-02</internalNodes>
          <leafValues>
            -1.0286079645156860e+00 -4.5288000255823135e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2402 -1.9109999993816018e-03</internalNodes>
          <leafValues>
            -1.9652199745178223e-01 2.8661000728607178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2403 -1.6659000888466835e-02</internalNodes>
          <leafValues>
            -7.7532202005386353e-01 -8.3280000835657120e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2404 6.6062200069427490e-01</internalNodes>
          <leafValues>
            1.3232499361038208e-01 -3.5266680717468262e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2405 1.0970599949359894e-01</internalNodes>
          <leafValues>
            -1.5547199547290802e-01 1.4674140214920044e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2406 1.3500999659299850e-02</internalNodes>
          <leafValues>
            1.5233400464057922e-01 -1.3020930290222168e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2407 -2.2871999070048332e-02</internalNodes>
          <leafValues>
            -7.1325999498367310e-01 -8.7040001526474953e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2408 -8.1821002066135406e-02</internalNodes>
          <leafValues>
            1.1127580404281616e+00 8.3219997584819794e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2409 -5.2728001028299332e-02</internalNodes>
          <leafValues>
            9.3165099620819092e-01 -1.7103999853134155e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2410 -2.5242000818252563e-02</internalNodes>
          <leafValues>
            -1.9733799993991852e-01 2.5359401106834412e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2411 -4.3818999081850052e-02</internalNodes>
          <leafValues>
            4.1815200448036194e-01 -2.4585500359535217e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2412 -1.8188999965786934e-02</internalNodes>
          <leafValues>
            -5.1743197441101074e-01 2.0174199342727661e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2413 2.3466000333428383e-02</internalNodes>
          <leafValues>
            -4.3071001768112183e-02 -1.0636579990386963e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2414 3.4216001629829407e-02</internalNodes>
          <leafValues>
            5.3780999034643173e-02 4.9707201123237610e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2415 2.5692999362945557e-02</internalNodes>
          <leafValues>
            -2.3800100386142731e-01 4.1651499271392822e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2416 -2.6565000414848328e-02</internalNodes>
          <leafValues>
            -8.8574802875518799e-01 1.3365900516510010e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2417 6.0942001640796661e-02</internalNodes>
          <leafValues>
            -2.0669700205326080e-01 5.8309000730514526e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2418 1.4474500715732574e-01</internalNodes>
          <leafValues>
            1.3282300531864166e-01 -3.1449348926544189e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2419 5.3410999476909637e-02</internalNodes>
          <leafValues>
            -1.7325200140476227e-01 6.9190698862075806e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2420 1.1408000253140926e-02</internalNodes>
          <leafValues>
            5.4822001606225967e-02 3.0240398645401001e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2421 -2.3179999552667141e-03</internalNodes>
          <leafValues>
            1.5820899605751038e-01 -3.1973201036453247e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2422 -2.9695000499486923e-02</internalNodes>
          <leafValues>
            7.1274799108505249e-01 5.8136001229286194e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2423 2.7249999344348907e-02</internalNodes>
          <leafValues>
            -1.5754100680351257e-01 9.2143797874450684e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2424 -3.6200000904500484e-03</internalNodes>
          <leafValues>
            -3.4548398852348328e-01 2.0220999419689178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2425 -1.2578999623656273e-02</internalNodes>
          <leafValues>
            -5.5650299787521362e-01 2.0388999953866005e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2426 -8.8849000632762909e-02</internalNodes>
          <leafValues>
            -3.6100010871887207e+00 1.3164199888706207e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2427 -1.9256999716162682e-02</internalNodes>
          <leafValues>
            5.1908999681472778e-01 -1.9284300506114960e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2428 -1.6666999086737633e-02</internalNodes>
          <leafValues>
            -8.7499998509883881e-02 1.5812499821186066e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2429 1.2931999750435352e-02</internalNodes>
          <leafValues>
            2.7405999600887299e-02 -5.5123901367187500e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2430 -1.3431999832391739e-02</internalNodes>
          <leafValues>
            2.3457799851894379e-01 -4.3235000222921371e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2431 1.8810000270605087e-02</internalNodes>
          <leafValues>
            -3.9680998772382736e-02 -9.4373297691345215e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2432 -6.4349998719990253e-03</internalNodes>
          <leafValues>
            4.5703700184822083e-01 -4.0520001202821732e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2433 -2.4249000474810600e-02</internalNodes>
          <leafValues>
            -7.6248002052307129e-01 -1.9857000559568405e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2434 -2.9667999595403671e-02</internalNodes>
          <leafValues>
            -3.7412509918212891e+00 1.1250600218772888e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2435 5.1150000654160976e-03</internalNodes>
          <leafValues>
            -6.3781797885894775e-01 1.1223999783396721e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2436 -5.7819997891783714e-03</internalNodes>
          <leafValues>
            1.9374400377273560e-01 -8.2042001187801361e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2437 1.6606999561190605e-02</internalNodes>
          <leafValues>
            -1.6192099452018738e-01 1.1334990262985229e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2438 3.8228001445531845e-02</internalNodes>
          <leafValues>
            2.1105000749230385e-02 7.6264202594757080e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2439 -5.7094000279903412e-02</internalNodes>
          <leafValues>
            -1.6974929571151733e+00 -5.9762001037597656e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2440 -5.3883001208305359e-02</internalNodes>
          <leafValues>
            1.1850190162658691e+00 9.0966999530792236e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2441 -2.6110000908374786e-03</internalNodes>
          <leafValues>
            -4.0941199660301208e-01 8.3820998668670654e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2442 2.9714399576187134e-01</internalNodes>
          <leafValues>
            1.5529899299144745e-01 -1.0995409488677979e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2443 -8.9063003659248352e-02</internalNodes>
          <leafValues>
            4.8947200179100037e-01 -2.0041200518608093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2444 -5.6193001568317413e-02</internalNodes>
          <leafValues>
            -2.4581399559974670e-01 1.4365500211715698e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2445 3.7004999816417694e-02</internalNodes>
          <leafValues>
            -4.8168998211622238e-02 -1.2310709953308105e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2446 -8.4840003401041031e-03</internalNodes>
          <leafValues>
            4.3372601270675659e-01 1.3779999688267708e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2447 -2.4379999376833439e-03</internalNodes>
          <leafValues>
            1.8949699401855469e-01 -3.2294198870658875e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2448 -7.1639999747276306e-02</internalNodes>
          <leafValues>
            -4.3979001045227051e-01 2.2730199992656708e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2449 5.2260002121329308e-03</internalNodes>
          <leafValues>
            -2.0548400282859802e-01 5.0933301448822021e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2450 -6.1360001564025879e-03</internalNodes>
          <leafValues>
            3.1157198548316956e-01 7.0680998265743256e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2451 1.5595000237226486e-02</internalNodes>
          <leafValues>
            -3.0934798717498779e-01 1.5627700090408325e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2452 2.5995999574661255e-02</internalNodes>
          <leafValues>
            1.3821600377559662e-01 -1.7616599798202515e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2453 -1.2085000053048134e-02</internalNodes>
          <leafValues>
            -5.1070201396942139e-01 5.8440998196601868e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2454 -6.7836001515388489e-02</internalNodes>
          <leafValues>
            4.7757101058959961e-01 -7.1446001529693604e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2455 -1.4715000055730343e-02</internalNodes>
          <leafValues>
            4.5238900184631348e-01 -1.9861400127410889e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2456 2.5118999183177948e-02</internalNodes>
          <leafValues>
            1.2954899668693542e-01 -8.6266398429870605e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2457 1.8826000392436981e-02</internalNodes>
          <leafValues>
            -4.1570000350475311e-02 -1.1354700326919556e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2458 -2.1263999864459038e-02</internalNodes>
          <leafValues>
            -3.4738001227378845e-01 1.5779499709606171e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2459 9.4609996303915977e-03</internalNodes>
          <leafValues>
            4.8639997839927673e-03 -6.1654800176620483e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2460 2.2957700490951538e-01</internalNodes>
          <leafValues>
            8.1372998654842377e-02 6.9841402769088745e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2461 -3.8061998784542084e-02</internalNodes>
          <leafValues>
            1.1616369485855103e+00 -1.4976699650287628e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2462 -1.3484999537467957e-02</internalNodes>
          <leafValues>
            -3.2036399841308594e-01 1.7365099489688873e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2463 3.6238998174667358e-02</internalNodes>
          <leafValues>
            -1.8158499896526337e-01 6.1956697702407837e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2464 6.7210001870989799e-03</internalNodes>
          <leafValues>
            7.9600000753998756e-04 4.2441400885581970e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2465 9.6525996923446655e-02</internalNodes>
          <leafValues>
            -1.4696800708770752e-01 1.2525680065155029e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2466 -3.5656999796628952e-02</internalNodes>
          <leafValues>
            -3.9781698584556580e-01 1.4191399514675140e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2467 1.0772000066936016e-02</internalNodes>
          <leafValues>
            -1.8194000422954559e-01 5.9762197732925415e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2468 7.9279996454715729e-02</internalNodes>
          <leafValues>
            1.4642499387264252e-01 -7.8836899995803833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2469 3.2841000705957413e-02</internalNodes>
          <leafValues>
            -6.2408000230789185e-02 -1.4227490425109863e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2470 -2.7781000360846519e-02</internalNodes>
          <leafValues>
            3.4033098816871643e-01 3.0670000240206718e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2471 -4.0339999832212925e-03</internalNodes>
          <leafValues>
            3.1084701418876648e-01 -2.2595700621604919e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2472 7.4260002002120018e-03</internalNodes>
          <leafValues>
            -3.8936998695135117e-02 3.1702101230621338e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2473 1.1213999986648560e-01</internalNodes>
          <leafValues>
            -1.7578299343585968e-01 6.5056598186492920e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2474 -1.1878100037574768e-01</internalNodes>
          <leafValues>
            -1.0092990398406982e+00 1.1069700121879578e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2475 -4.1584998369216919e-02</internalNodes>
          <leafValues>
            -5.3806400299072266e-01 1.9905000925064087e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2476 -2.7966000139713287e-02</internalNodes>
          <leafValues>
            4.8143199086189270e-01 3.3590998500585556e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2477 -1.2506400048732758e-01</internalNodes>
          <leafValues>
            2.6352199912071228e-01 -2.5737899541854858e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2478 2.3666900396347046e-01</internalNodes>
          <leafValues>
            3.6508001387119293e-02 9.0655601024627686e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2479 -2.9475999996066093e-02</internalNodes>
          <leafValues>
            -6.0048800706863403e-01 9.5880003646016121e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2480 3.7792999297380447e-02</internalNodes>
          <leafValues>
            1.5506200492382050e-01 -9.5733499526977539e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2481 7.2044000029563904e-02</internalNodes>
          <leafValues>
            -1.4525899291038513e-01 1.3676730394363403e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2482 9.7759999334812164e-03</internalNodes>
          <leafValues>
            1.2915999628603458e-02 2.1640899777412415e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2483 5.2154000848531723e-02</internalNodes>
          <leafValues>
            -1.6359999775886536e-02 -8.8356298208236694e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2484 -4.3790999799966812e-02</internalNodes>
          <leafValues>
            3.5829600691795349e-01 6.5131001174449921e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2485 -3.8378998637199402e-02</internalNodes>
          <leafValues>
            1.1961040496826172e+00 -1.4971500635147095e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2486 -9.8838999867439270e-02</internalNodes>
          <leafValues>
            -6.1834001541137695e-01 1.2786200642585754e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2487 -1.2190700322389603e-01</internalNodes>
          <leafValues>
            -1.8276120424270630e+00 -6.4862996339797974e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2488 -1.1981700360774994e-01</internalNodes>
          <leafValues>
            -30. 1.1323300004005432e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2489 3.0910000205039978e-02</internalNodes>
          <leafValues>
            -2.3934000730514526e-01 3.6332899332046509e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2490 1.0800999589264393e-02</internalNodes>
          <leafValues>
            -3.5140000283718109e-02 2.7707898616790771e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2491 5.6844998151063919e-02</internalNodes>
          <leafValues>
            -1.5524299442768097e-01 1.0802700519561768e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2492 1.0280000278726220e-03</internalNodes>
          <leafValues>
            -6.1202999204397202e-02 2.0508000254631042e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2493 -2.8273999691009521e-02</internalNodes>
          <leafValues>
            -6.4778000116348267e-01 2.3917000740766525e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2494 -1.6013599932193756e-01</internalNodes>
          <leafValues>
            1.0892050266265869e+00 5.8389000594615936e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2495 4.9629998393356800e-03</internalNodes>
          <leafValues>
            -2.5806298851966858e-01 2.0834599435329437e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2496 4.6937000006437302e-02</internalNodes>
          <leafValues>
            1.3886299729347229e-01 -1.5662620067596436e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2497 2.4286000058054924e-02</internalNodes>
          <leafValues>
            -2.0728300511837006e-01 5.2430999279022217e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2498 7.0202000439167023e-02</internalNodes>
          <leafValues>
            1.4796899259090424e-01 -1.3095090389251709e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2499 9.8120002076029778e-03</internalNodes>
          <leafValues>
            2.7906000614166260e-02 -5.0864601135253906e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2500 -5.6200999766588211e-02</internalNodes>
          <leafValues>
            1.2618130445480347e+00 6.3801996409893036e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2501 1.0982800275087357e-01</internalNodes>
          <leafValues>
            -1.2850099802017212e-01 3.0776169300079346e+00</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>211</maxWeakCount>
      <stageThreshold>-3.3703000545501709e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 2502 2.0910000428557396e-02</internalNodes>
          <leafValues>
            -6.8559402227401733e-01 3.8984298706054688e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2503 3.5032000392675400e-02</internalNodes>
          <leafValues>
            -4.7724398970603943e-01 4.5027199387550354e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2504 3.9799001067876816e-02</internalNodes>
          <leafValues>
            -4.7011101245880127e-01 4.2702499032020569e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2505 -4.8409998416900635e-03</internalNodes>
          <leafValues>
            2.5614300370216370e-01 -6.6556298732757568e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2506 2.3439999204128981e-03</internalNodes>
          <leafValues>
            -4.8083499073982239e-01 2.8013798594474792e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2507 2.5312999263405800e-02</internalNodes>
          <leafValues>
            -2.3948200047016144e-01 4.4191798567771912e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2508 -3.2193001359701157e-02</internalNodes>
          <leafValues>
            7.6086699962615967e-01 -2.5059100985527039e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2509 7.5409002602100372e-02</internalNodes>
          <leafValues>
            -3.4974598884582520e-01 3.4380298852920532e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2510 -1.8469000235199928e-02</internalNodes>
          <leafValues>
            -7.9085600376129150e-01 3.4788001328706741e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2511 -1.2802000157535076e-02</internalNodes>
          <leafValues>
            4.7107800841331482e-01 -6.0006000101566315e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2512 -2.6598000898957253e-02</internalNodes>
          <leafValues>
            6.7116099596023560e-01 -2.4257500469684601e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2513 2.1988999098539352e-02</internalNodes>
          <leafValues>
            2.4717499315738678e-01 -4.8301699757575989e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2514 1.4654099941253662e-01</internalNodes>
          <leafValues>
            -2.1504099667072296e-01 7.2055900096893311e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2515 3.5310001112520695e-03</internalNodes>
          <leafValues>
            2.7930998802185059e-01 -3.4339898824691772e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2516 9.4010001048445702e-03</internalNodes>
          <leafValues>
            5.5861998349428177e-02 -8.2143598794937134e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2517 -8.6390003561973572e-03</internalNodes>
          <leafValues>
            -9.9620598554611206e-01 1.8874999880790710e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2518 -3.9193000644445419e-02</internalNodes>
          <leafValues>
            -1.1945559978485107e+00 -2.9198000207543373e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2519 2.4855000898241997e-02</internalNodes>
          <leafValues>
            1.4987599849700928e-01 -5.4137802124023438e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2520 -3.4995000809431076e-02</internalNodes>
          <leafValues>
            -1.4210180044174194e+00 -4.2314000427722931e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2521 -1.8378999084234238e-02</internalNodes>
          <leafValues>
            -2.8242599964141846e-01 1.5581800043582916e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2522 -1.3592000119388103e-02</internalNodes>
          <leafValues>
            4.7317099571228027e-01 -2.1937200427055359e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2523 6.2629999592900276e-03</internalNodes>
          <leafValues>
            -5.9714000672101974e-02 6.0625898838043213e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2524 -1.8478000536561012e-02</internalNodes>
          <leafValues>
            -8.5647201538085938e-01 -1.3783999718725681e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2525 1.4236000366508961e-02</internalNodes>
          <leafValues>
            1.6654799878597260e-01 -2.7713999152183533e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2526 -3.2547000795602798e-02</internalNodes>
          <leafValues>
            -1.1728240251541138e+00 -4.0185000747442245e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2527 -2.6410000864416361e-03</internalNodes>
          <leafValues>
            2.6514300704002380e-01 -5.6343000382184982e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2528 -8.7799999164417386e-04</internalNodes>
          <leafValues>
            3.6556001752614975e-02 -5.5075198411941528e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2529 4.7371998429298401e-02</internalNodes>
          <leafValues>
            -4.2614001780748367e-02 4.8194900155067444e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2530 -7.0790001191198826e-03</internalNodes>
          <leafValues>
            2.8698998689651489e-01 -3.2923001050949097e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2531 -4.3145999312400818e-02</internalNodes>
          <leafValues>
            -1.4065419435501099e+00 1.2836399674415588e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2532 2.0592000335454941e-02</internalNodes>
          <leafValues>
            -2.1435299515724182e-01 5.3981798887252808e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2533 -2.2367000579833984e-02</internalNodes>
          <leafValues>
            3.3718299865722656e-01 4.5212000608444214e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2534 5.0039999186992645e-02</internalNodes>
          <leafValues>
            -2.5121700763702393e-01 4.1750499606132507e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2535 6.1794999986886978e-02</internalNodes>
          <leafValues>
            4.0084999054670334e-02 6.8779802322387695e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2536 -4.1861999779939651e-02</internalNodes>
          <leafValues>
            5.3027397394180298e-01 -2.2901999950408936e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2537 -3.1959998887032270e-03</internalNodes>
          <leafValues>
            2.5161498785018921e-01 -2.1514600515365601e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2538 2.4255000054836273e-02</internalNodes>
          <leafValues>
            7.2320001199841499e-03 -7.2519099712371826e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2539 -1.7303999513387680e-02</internalNodes>
          <leafValues>
            -4.9958199262619019e-01 1.8394500017166138e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2540 -4.1470001451671124e-03</internalNodes>
          <leafValues>
            8.5211999714374542e-02 -4.6364700794219971e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2541 -1.4369999989867210e-02</internalNodes>
          <leafValues>
            -5.2258902788162231e-01 2.3892599344253540e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2542 -9.0399999171495438e-03</internalNodes>
          <leafValues>
            -6.3250398635864258e-01 3.2551001757383347e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2543 -1.2373100221157074e-01</internalNodes>
          <leafValues>
            1.2856210470199585e+00 7.6545000076293945e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2544 -8.2221999764442444e-02</internalNodes>
          <leafValues>
            8.3208197355270386e-01 -1.8590599298477173e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2545 6.5659001469612122e-02</internalNodes>
          <leafValues>
            1.1298800259828568e-01 -30.</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2546 -3.1582999974489212e-02</internalNodes>
          <leafValues>
            -1.3485900163650513e+00 -4.7097001224756241e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2547 -7.9636000096797943e-02</internalNodes>
          <leafValues>
            -1.3533639907836914e+00 1.5668800473213196e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2548 -1.8880000337958336e-02</internalNodes>
          <leafValues>
            4.0300300717353821e-01 -2.5148901343345642e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2549 -5.0149997696280479e-03</internalNodes>
          <leafValues>
            -2.6287099719047546e-01 1.8582500517368317e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2550 -1.2218000367283821e-02</internalNodes>
          <leafValues>
            5.8692401647567749e-01 -1.9427700340747833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2551 1.2710000155493617e-03</internalNodes>
          <leafValues>
            -1.6688999533653259e-01 2.3006899654865265e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2552 2.9743999242782593e-02</internalNodes>
          <leafValues>
            1.2520000338554382e-02 -6.6723597049713135e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2553 2.8175000101327896e-02</internalNodes>
          <leafValues>
            -1.7060000449419022e-02 6.4579397439956665e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2554 3.0345000326633453e-02</internalNodes>
          <leafValues>
            -2.4178700149059296e-01 3.4878900647163391e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2555 -1.7325999215245247e-02</internalNodes>
          <leafValues>
            -5.3599399328231812e-01 2.0995999872684479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2556 -8.4178000688552856e-02</internalNodes>
          <leafValues>
            7.5093299150466919e-01 -1.7593200504779816e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2557 7.4950000271201134e-03</internalNodes>
          <leafValues>
            -1.6188099980354309e-01 3.0657500028610229e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2558 5.6494999676942825e-02</internalNodes>
          <leafValues>
            -1.7318800091743469e-01 1.0016150474548340e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2559 -5.2939997985959053e-03</internalNodes>
          <leafValues>
            2.3417599499225616e-01 -6.5347000956535339e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2560 -1.4945000410079956e-02</internalNodes>
          <leafValues>
            2.5018900632858276e-01 -3.0591198801994324e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2561 5.4919000715017319e-02</internalNodes>
          <leafValues>
            1.3121999800205231e-01 -9.3765097856521606e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2562 -1.9721999764442444e-02</internalNodes>
          <leafValues>
            -8.3978497982025146e-01 -2.3473000153899193e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2563 -6.7158997058868408e-02</internalNodes>
          <leafValues>
            2.3586840629577637e+00 8.2970999181270599e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2564 -1.4325999654829502e-02</internalNodes>
          <leafValues>
            1.8814499676227570e-01 -3.1221601366996765e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2565 2.9841000214219093e-02</internalNodes>
          <leafValues>
            1.4825099706649780e-01 -8.4681701660156250e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2566 5.1883000880479813e-02</internalNodes>
          <leafValues>
            -4.3731000274419785e-02 -1.3366169929504395e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2567 4.1127000004053116e-02</internalNodes>
          <leafValues>
            1.7660099267959595e-01 -6.0904097557067871e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2568 -1.2865099310874939e-01</internalNodes>
          <leafValues>
            -9.8701000213623047e-01 -3.7785001099109650e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2569 2.4170000106096268e-03</internalNodes>
          <leafValues>
            -1.6119599342346191e-01 3.2675701379776001e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2570 7.7030002139508724e-03</internalNodes>
          <leafValues>
            -2.3841500282287598e-01 2.9319399595260620e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2571 4.5520000159740448e-02</internalNodes>
          <leafValues>
            1.4424599707126617e-01 -1.5010160207748413e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2572 -7.8700996935367584e-02</internalNodes>
          <leafValues>
            -1.0394560098648071e+00 -4.5375999063253403e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2573 7.8619997948408127e-03</internalNodes>
          <leafValues>
            1.9633600115776062e-01 -1.4472399652004242e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2574 -1.3458999805152416e-02</internalNodes>
          <leafValues>
            -9.0634697675704956e-01 -3.8049001246690750e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2575 2.8827000409364700e-02</internalNodes>
          <leafValues>
            -2.9473999515175819e-02 6.0058397054672241e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2576 -2.7365999296307564e-02</internalNodes>
          <leafValues>
            -9.9804002046585083e-01 -3.8653001189231873e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2577 -7.2917997837066650e-02</internalNodes>
          <leafValues>
            7.3361498117446899e-01 5.7440001517534256e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2578 -1.3988999649882317e-02</internalNodes>
          <leafValues>
            2.7892601490020752e-01 -2.6516300439834595e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2579 4.3242998421192169e-02</internalNodes>
          <leafValues>
            4.7760000452399254e-03 3.5925900936126709e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2580 2.9533000662922859e-02</internalNodes>
          <leafValues>
            -2.0083999633789062e-01 5.1202899217605591e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2581 -3.1897000968456268e-02</internalNodes>
          <leafValues>
            6.4721697568893433e-01 -1.3760000001639128e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2582 3.7868998944759369e-02</internalNodes>
          <leafValues>
            -1.8363800644874573e-01 6.1343097686767578e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2583 -2.2417999804019928e-02</internalNodes>
          <leafValues>
            -2.9187899827957153e-01 1.8194800615310669e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2584 5.8958999812602997e-02</internalNodes>
          <leafValues>
            -6.6451996564865112e-02 -1.9290030002593994e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2585 3.1222999095916748e-02</internalNodes>
          <leafValues>
            -1.2732000090181828e-02 6.1560797691345215e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2586 3.7484999746084213e-02</internalNodes>
          <leafValues>
            -2.0856900513172150e-01 4.4363999366760254e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2587 -2.0966000854969025e-02</internalNodes>
          <leafValues>
            -3.5712799429893494e-01 2.4252200126647949e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2588 -2.5477999821305275e-02</internalNodes>
          <leafValues>
            1.0846560001373291e+00 -1.5054400265216827e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2589 -7.2570000775158405e-03</internalNodes>
          <leafValues>
            2.1302600204944611e-01 -1.8308199942111969e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2590 -5.0983000546693802e-02</internalNodes>
          <leafValues>
            5.1736801862716675e-01 -1.8833099305629730e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2591 -2.0640000700950623e-02</internalNodes>
          <leafValues>
            -4.4030201435089111e-01 2.2745999693870544e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2592 1.0672999545931816e-02</internalNodes>
          <leafValues>
            3.5059999674558640e-02 -5.1665002107620239e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2593 3.1895998865365982e-02</internalNodes>
          <leafValues>
            1.3228000141680241e-02 3.4915199875831604e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2594 -2.3824999108910561e-02</internalNodes>
          <leafValues>
            3.4118801355361938e-01 -2.1510200202465057e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2595 -6.0680001042783260e-03</internalNodes>
          <leafValues>
            3.2937398552894592e-01 -2.8523799777030945e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2596 2.3881999775767326e-02</internalNodes>
          <leafValues>
            -2.5333800911903381e-01 2.6296100020408630e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2597 2.7966000139713287e-02</internalNodes>
          <leafValues>
            1.4049099385738373e-01 -4.9887099862098694e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2598 1.4603000134229660e-02</internalNodes>
          <leafValues>
            -1.5395999886095524e-02 -7.6958000659942627e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2599 1.0872399806976318e-01</internalNodes>
          <leafValues>
            1.9069600105285645e-01 -3.2393100857734680e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2600 -1.4038000255823135e-02</internalNodes>
          <leafValues>
            3.4924700856208801e-01 -2.2358700633049011e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2601 4.0440000593662262e-03</internalNodes>
          <leafValues>
            -3.8329001516103745e-02 5.1177299022674561e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2602 -4.9769999459385872e-03</internalNodes>
          <leafValues>
            -4.2888298630714417e-01 4.9173999577760696e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2603 -8.5183002054691315e-02</internalNodes>
          <leafValues>
            6.6624599695205688e-01 7.8079998493194580e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2604 2.1559998858720064e-03</internalNodes>
          <leafValues>
            -4.9135199189186096e-01 6.9555997848510742e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2605 3.6384499073028564e-01</internalNodes>
          <leafValues>
            1.2997099757194519e-01 -1.8949509859085083e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2606 2.2082500159740448e-01</internalNodes>
          <leafValues>
            -5.7211998850107193e-02 -1.4281120300292969e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2607 -1.6140000894665718e-02</internalNodes>
          <leafValues>
            -5.7589399814605713e-01 1.8062500655651093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2608 -4.8330001533031464e-02</internalNodes>
          <leafValues>
            9.7308498620986938e-01 -1.6513000428676605e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2609 1.7529999837279320e-02</internalNodes>
          <leafValues>
            1.7932699620723724e-01 -2.7948901057243347e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2610 -3.4309998154640198e-02</internalNodes>
          <leafValues>
            -8.1072497367858887e-01 -1.6596000641584396e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2611 -4.5830002054572105e-03</internalNodes>
          <leafValues>
            2.7908998727798462e-01 -7.4519999325275421e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2612 1.2896400690078735e-01</internalNodes>
          <leafValues>
            -1.3508500158786774e-01 2.5411539077758789e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2613 3.0361000448465347e-02</internalNodes>
          <leafValues>
            -6.8419001996517181e-02 2.8734099864959717e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2614 4.4086001813411713e-02</internalNodes>
          <leafValues>
            -1.8135899305343628e-01 6.5413200855255127e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2615 3.0159999150782824e-03</internalNodes>
          <leafValues>
            -1.5690499544143677e-01 2.6963800191879272e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2616 -2.6336999610066414e-02</internalNodes>
          <leafValues>
            2.9175600409507751e-01 -2.5274100899696350e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2617 -2.7866000309586525e-02</internalNodes>
          <leafValues>
            4.4387501478195190e-01 5.5038001388311386e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2618 1.1725000105798244e-02</internalNodes>
          <leafValues>
            -1.9346499443054199e-01 4.6656700968742371e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2619 1.5689999563619494e-03</internalNodes>
          <leafValues>
            -8.2360003143548965e-03 2.5700899958610535e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2620 -3.5550000611692667e-03</internalNodes>
          <leafValues>
            -4.2430898547172546e-01 7.1174003183841705e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2621 -3.1695000827312469e-02</internalNodes>
          <leafValues>
            -8.5393500328063965e-01 1.6916200518608093e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2622 -3.2097000628709793e-02</internalNodes>
          <leafValues>
            8.3784902095794678e-01 -1.7597299814224243e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2623 1.5544199943542480e-01</internalNodes>
          <leafValues>
            9.9550001323223114e-02 2.3873300552368164e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2624 8.8045999407768250e-02</internalNodes>
          <leafValues>
            -1.8725299835205078e-01 6.2384301424026489e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2625 -1.6720000421628356e-03</internalNodes>
          <leafValues>
            2.5008699297904968e-01 -6.5118998289108276e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2626 9.3409996479749680e-03</internalNodes>
          <leafValues>
            -3.5378900170326233e-01 1.0715000331401825e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2627 3.7138000130653381e-02</internalNodes>
          <leafValues>
            1.6387000679969788e-01 -9.1718399524688721e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2628 8.0183997750282288e-02</internalNodes>
          <leafValues>
            -1.4812999963760376e-01 1.4895190000534058e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2629 -7.9100002767518163e-04</internalNodes>
          <leafValues>
            -2.1326899528503418e-01 1.9676400721073151e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2630 -5.0400001928210258e-03</internalNodes>
          <leafValues>
            -7.1318697929382324e-01 1.8240000354126096e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2631 1.1962399631738663e-01</internalNodes>
          <leafValues>
            3.3098999410867691e-02 1.0441709756851196e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2632 -4.5280000194907188e-03</internalNodes>
          <leafValues>
            -2.7308499813079834e-01 2.7229800820350647e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2633 -2.9639000073075294e-02</internalNodes>
          <leafValues>
            3.6225798726081848e-01 5.6795001029968262e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2634 2.6650000363588333e-02</internalNodes>
          <leafValues>
            -4.8041000962257385e-02 -9.6723502874374390e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2635 4.4422000646591187e-02</internalNodes>
          <leafValues>
            1.3052900135517120e-01 -3.5077300667762756e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2636 -2.4359999224543571e-02</internalNodes>
          <leafValues>
            -1.0766899585723877e+00 -5.1222998648881912e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2637 1.9734999164938927e-02</internalNodes>
          <leafValues>
            2.6238000020384789e-02 2.8070500493049622e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2638 5.4930001497268677e-03</internalNodes>
          <leafValues>
            -2.6111298799514771e-01 2.1011400222778320e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2639 -2.3200300335884094e-01</internalNodes>
          <leafValues>
            -1.7748440504074097e+00 1.1482600122690201e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2640 -2.5614000856876373e-02</internalNodes>
          <leafValues>
            2.9900801181793213e-01 -2.2502499818801880e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2641 -6.4949998632073402e-03</internalNodes>
          <leafValues>
            1.9563800096511841e-01 -9.9762998521327972e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2642 3.9840000681579113e-03</internalNodes>
          <leafValues>
            -4.3021500110626221e-01 8.1261001527309418e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2643 -3.5813000053167343e-02</internalNodes>
          <leafValues>
            -5.0987398624420166e-01 1.6345900297164917e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2644 -1.4169000089168549e-02</internalNodes>
          <leafValues>
            7.7978098392486572e-01 -1.7476299405097961e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2645 -1.2642100453376770e-01</internalNodes>
          <leafValues>
            -6.3047897815704346e-01 1.2728300690650940e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2646 6.8677999079227448e-02</internalNodes>
          <leafValues>
            -4.6447999775409698e-02 -1.1128979921340942e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2647 8.5864998400211334e-02</internalNodes>
          <leafValues>
            1.1835400015115738e-01 -4.8235158920288086e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2648 1.5511999838054180e-02</internalNodes>
          <leafValues>
            -1.7467999830842018e-02 -6.3693398237228394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2649 8.1091001629829407e-02</internalNodes>
          <leafValues>
            8.6133003234863281e-02 2.4559431076049805e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2650 1.8495000898838043e-02</internalNodes>
          <leafValues>
            4.0229000151157379e-02 -5.0858199596405029e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2651 -8.6320996284484863e-02</internalNodes>
          <leafValues>
            -1.9006760120391846e+00 1.1019100248813629e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2652 7.2355002164840698e-02</internalNodes>
          <leafValues>
            -6.2111999839544296e-02 -1.4165179729461670e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2653 -7.8179001808166504e-02</internalNodes>
          <leafValues>
            8.8849300146102905e-01 4.2369998991489410e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2654 9.6681997179985046e-02</internalNodes>
          <leafValues>
            -2.2094200551509857e-01 3.3575099706649780e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2655 -3.9875999093055725e-02</internalNodes>
          <leafValues>
            5.7804799079895020e-01 4.5347999781370163e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2656 -9.5349997282028198e-03</internalNodes>
          <leafValues>
            -5.4175698757171631e-01 3.2399999909102917e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2657 4.0600000647827983e-04</internalNodes>
          <leafValues>
            -8.1549003720283508e-02 3.5837900638580322e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2658 1.2107999995350838e-02</internalNodes>
          <leafValues>
            -2.0280399918556213e-01 4.3768000602722168e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2659 -2.0873999223113060e-02</internalNodes>
          <leafValues>
            4.1469898819923401e-01 -4.5568000525236130e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2660 5.7888001203536987e-02</internalNodes>
          <leafValues>
            -2.9009999707341194e-02 -9.1822302341461182e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2661 1.3200000103097409e-04</internalNodes>
          <leafValues>
            -1.1772400140762329e-01 2.0000000298023224e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2662 -1.7137000337243080e-02</internalNodes>
          <leafValues>
            3.3004799485206604e-01 -2.3055200278759003e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2663 3.0655000358819962e-02</internalNodes>
          <leafValues>
            -2.1545000374317169e-02 2.6878198981285095e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2664 -7.8699999721720815e-04</internalNodes>
          <leafValues>
            -4.4100698828697205e-01 4.9157999455928802e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2665 8.8036999106407166e-02</internalNodes>
          <leafValues>
            1.1782000213861465e-01 -2.8293309211730957e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2666 -3.9028998464345932e-02</internalNodes>
          <leafValues>
            9.1777199506759644e-01 -1.5827399492263794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2667 8.0105997622013092e-02</internalNodes>
          <leafValues>
            1.1289200186729431e-01 -1.9937280416488647e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2668 3.9538998156785965e-02</internalNodes>
          <leafValues>
            -1.4357399940490723e-01 1.3085240125656128e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2669 2.0684000104665756e-02</internalNodes>
          <leafValues>
            2.0048099756240845e-01 -4.4186998158693314e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2670 -6.7037999629974365e-02</internalNodes>
          <leafValues>
            3.2618600130081177e-01 -2.0550400018692017e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2671 4.6815000474452972e-02</internalNodes>
          <leafValues>
            1.5825299918651581e-01 -9.5535099506378174e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2672 7.8443996608257294e-02</internalNodes>
          <leafValues>
            -7.4651002883911133e-02 -2.1161499023437500e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2673 6.6380001604557037e-02</internalNodes>
          <leafValues>
            1.1641900241374969e-01 -1.6113519668579102e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2674 3.0053999274969101e-02</internalNodes>
          <leafValues>
            -1.6562600433826447e-01 7.0025402307510376e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2675 1.7119999974966049e-02</internalNodes>
          <leafValues>
            2.2627699375152588e-01 -4.0114998817443848e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2676 2.0073000341653824e-02</internalNodes>
          <leafValues>
            -1.9389699399471283e-01 4.4420298933982849e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2677 3.3101998269557953e-02</internalNodes>
          <leafValues>
            1.1637499928474426e-01 -1.5771679878234863e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2678 -1.4882000163197517e-02</internalNodes>
          <leafValues>
            -8.9680302143096924e-01 -4.2010001838207245e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2679 -1.0281000286340714e-02</internalNodes>
          <leafValues>
            3.5602998733520508e-01 -1.3124000281095505e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2680 -2.8695000335574150e-02</internalNodes>
          <leafValues>
            -4.6039599180221558e-01 2.6801999658346176e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2681 -4.7189998440444469e-03</internalNodes>
          <leafValues>
            2.3788799345493317e-01 -6.5518997609615326e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2682 3.2201600074768066e-01</internalNodes>
          <leafValues>
            -2.8489999473094940e-02 -8.4234601259231567e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2683 -1.7045000568032265e-02</internalNodes>
          <leafValues>
            -5.0938802957534790e-01 1.6057600080966949e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2684 -7.3469998314976692e-03</internalNodes>
          <leafValues>
            -5.4154998064041138e-01 4.7320001758635044e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2685 -3.0001999810338020e-02</internalNodes>
          <leafValues>
            -8.8785797357559204e-01 1.3621799647808075e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2686 -1.1292999610304832e-02</internalNodes>
          <leafValues>
            8.0615198612213135e-01 -1.6159500181674957e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2687 4.7749998047947884e-03</internalNodes>
          <leafValues>
            1.2968000024557114e-02 5.5079901218414307e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2688 5.0710001960396767e-03</internalNodes>
          <leafValues>
            -4.5728001743555069e-02 -1.0766259431838989e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2689 1.9344100356101990e-01</internalNodes>
          <leafValues>
            7.1262001991271973e-02 1.1694519519805908e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2690 5.3750001825392246e-03</internalNodes>
          <leafValues>
            -1.9736200571060181e-01 3.8206899166107178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2691 -6.8276003003120422e-02</internalNodes>
          <leafValues>
            -5.4372339248657227e+00 1.1151900142431259e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2692 -3.4933000802993774e-02</internalNodes>
          <leafValues>
            4.4793400168418884e-01 -1.8657900393009186e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2693 5.1219998858869076e-03</internalNodes>
          <leafValues>
            -1.4871999621391296e-02 1.8413899838924408e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2694 9.5311999320983887e-02</internalNodes>
          <leafValues>
            -1.5117099881172180e-01 9.4991499185562134e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2695 -6.2849000096321106e-02</internalNodes>
          <leafValues>
            4.6473601460456848e-01 3.8405001163482666e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2696 -1.7040699720382690e-01</internalNodes>
          <leafValues>
            -1.6499999761581421e+00 -6.3236996531486511e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2697 1.0583999566733837e-02</internalNodes>
          <leafValues>
            -3.8348998874425888e-02 4.1913801431655884e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2698 -4.1579000651836395e-02</internalNodes>
          <leafValues>
            3.4461900591850281e-01 -2.1187700331211090e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2699 1.2718600034713745e-01</internalNodes>
          <leafValues>
            1.2398199737071991e-01 -2.1254889965057373e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2700 8.2557000219821930e-02</internalNodes>
          <leafValues>
            -6.2024001032114029e-02 -1.4875819683074951e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2701 8.5293002426624298e-02</internalNodes>
          <leafValues>
            1.7087999731302261e-02 3.2076600193977356e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2702 5.5544000118970871e-02</internalNodes>
          <leafValues>
            -2.7414000034332275e-01 1.8976399302482605e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2703 4.5650000683963299e-03</internalNodes>
          <leafValues>
            -1.7920200526714325e-01 2.7967301011085510e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2704 1.2997999787330627e-02</internalNodes>
          <leafValues>
            -3.2297500967979431e-01 2.6941800117492676e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2705 5.7891998440027237e-02</internalNodes>
          <leafValues>
            1.2644399702548981e-01 -6.0713499784469604e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2706 -2.2824000567197800e-02</internalNodes>
          <leafValues>
            -4.9682098627090454e-01 2.2376999258995056e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2707 4.8312000930309296e-02</internalNodes>
          <leafValues>
            4.3607000261545181e-02 4.8537799715995789e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2708 2.5714000687003136e-02</internalNodes>
          <leafValues>
            -4.2950998991727829e-02 -9.3023502826690674e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2709 6.9269998930394650e-03</internalNodes>
          <leafValues>
            -2.9680000152438879e-03 3.4296301007270813e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2710 -3.4446999430656433e-02</internalNodes>
          <leafValues>
            -1.5299769639968872e+00 -6.1014998704195023e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2711 2.9387999325990677e-02</internalNodes>
          <leafValues>
            3.7595998495817184e-02 6.4172399044036865e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2712 -2.4319998919963837e-03</internalNodes>
          <leafValues>
            9.9088996648788452e-02 -3.9688101410865784e-01</leafValues></_></weakClassifiers></_>
    <_>
      <maxWeakCount>200</maxWeakCount>
      <stageThreshold>-2.9928278923034668e+00</stageThreshold>
      <weakClassifiers>
        <_>
          <internalNodes>
            0 -1 2713 -9.5944002270698547e-02</internalNodes>
          <leafValues>
            6.2419098615646362e-01 -4.5875200629234314e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2714 1.6834000125527382e-02</internalNodes>
          <leafValues>
            -9.3072801828384399e-01 2.1563600003719330e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2715 2.6049999520182610e-02</internalNodes>
          <leafValues>
            -4.0532299876213074e-01 4.2256599664688110e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2716 3.6500001442618668e-04</internalNodes>
          <leafValues>
            9.5288001000881195e-02 -6.3298100233078003e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2717 -6.6940002143383026e-03</internalNodes>
          <leafValues>
            3.7243801355361938e-01 -3.0332401394844055e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2718 1.8874000757932663e-02</internalNodes>
          <leafValues>
            -2.3357200622558594e-01 4.0330699086189270e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2719 -1.6300000424962491e-04</internalNodes>
          <leafValues>
            4.2886998504400253e-02 -7.7796798944473267e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2720 -7.6259002089500427e-02</internalNodes>
          <leafValues>
            -4.9628499150276184e-01 1.6335399448871613e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2721 5.0149001181125641e-02</internalNodes>
          <leafValues>
            3.2747000455856323e-02 -8.0047899484634399e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2722 -2.9239999130368233e-03</internalNodes>
          <leafValues>
            -5.0002801418304443e-01 2.5480601191520691e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2723 1.6243999823927879e-02</internalNodes>
          <leafValues>
            3.8913000375032425e-02 -7.0724898576736450e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2724 3.7811998277902603e-02</internalNodes>
          <leafValues>
            -6.6267997026443481e-02 7.3868799209594727e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2725 -1.2319999746978283e-02</internalNodes>
          <leafValues>
            4.8696398735046387e-01 -2.4485599994659424e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2726 5.8003999292850494e-02</internalNodes>
          <leafValues>
            1.3459099829196930e-01 -1.3232100009918213e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2727 4.8630000092089176e-03</internalNodes>
          <leafValues>
            -4.4172900915145874e-01 1.4005599915981293e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2728 4.5690998435020447e-02</internalNodes>
          <leafValues>
            3.1217999756336212e-02 8.9818298816680908e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2729 2.1321000531315804e-02</internalNodes>
          <leafValues>
            1.2008000165224075e-02 -8.6066198348999023e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2730 1.5679100155830383e-01</internalNodes>
          <leafValues>
            1.4055999927222729e-02 8.5332900285720825e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2731 -1.0328999720513821e-02</internalNodes>
          <leafValues>
            2.9022800922393799e-01 -2.9478800296783447e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2732 2.4290001019835472e-03</internalNodes>
          <leafValues>
            -4.0439900755882263e-01 1.9400200247764587e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2733 -2.3338999599218369e-02</internalNodes>
          <leafValues>
            3.2945200800895691e-01 -2.5712698698043823e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2734 -6.8970001302659512e-03</internalNodes>
          <leafValues>
            -5.3352999687194824e-01 2.1635200083255768e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2735 -3.4403000026941299e-02</internalNodes>
          <leafValues>
            -1.4425489902496338e+00 -4.4682998210191727e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2736 -2.1235000342130661e-02</internalNodes>
          <leafValues>
            -7.9017502069473267e-01 1.9084100425243378e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2737 2.0620001014322042e-03</internalNodes>
          <leafValues>
            -2.6931199431419373e-01 3.1488001346588135e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2738 -4.2190002277493477e-03</internalNodes>
          <leafValues>
            -5.4464399814605713e-01 1.6574600338935852e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2739 -1.4334999956190586e-02</internalNodes>
          <leafValues>
            2.2105000913143158e-02 -6.2342500686645508e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2740 -8.2120001316070557e-03</internalNodes>
          <leafValues>
            -4.9884998798370361e-01 1.9237099587917328e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2741 -9.3350000679492950e-03</internalNodes>
          <leafValues>
            -7.9131197929382324e-01 -1.4143999665975571e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2742 -3.7937998771667480e-02</internalNodes>
          <leafValues>
            7.9841297864913940e-01 -3.3799000084400177e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2743 4.7059999778866768e-03</internalNodes>
          <leafValues>
            -3.3163401484489441e-01 2.0726299285888672e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2744 -4.4499998912215233e-03</internalNodes>
          <leafValues>
            -2.7256301045417786e-01 1.8402199447154999e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2745 5.2189999260008335e-03</internalNodes>
          <leafValues>
            -5.3096002340316772e-01 5.2607998251914978e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2746 -9.5399999991059303e-03</internalNodes>
          <leafValues>
            -5.6485402584075928e-01 1.9269399344921112e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2747 4.4969998300075531e-02</internalNodes>
          <leafValues>
            -1.7411500215530396e-01 9.5382601022720337e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2748 1.4209000393748283e-02</internalNodes>
          <leafValues>
            -9.1949000954627991e-02 2.4836100637912750e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2749 1.6380199790000916e-01</internalNodes>
          <leafValues>
            -5.8497000485658646e-02 -1.6404409408569336e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2750 2.5579999200999737e-03</internalNodes>
          <leafValues>
            2.3447999358177185e-01 -9.2734001576900482e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2751 -3.8499999791383743e-03</internalNodes>
          <leafValues>
            1.7880700528621674e-01 -3.5844099521636963e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2752 -2.5221999734640121e-02</internalNodes>
          <leafValues>
            -4.2903000116348267e-01 2.0244500041007996e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2753 -1.9415000453591347e-02</internalNodes>
          <leafValues>
            5.8016300201416016e-01 -1.8806399405002594e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2754 1.4419999904930592e-02</internalNodes>
          <leafValues>
            3.2846998423337936e-02 8.1980502605438232e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2755 5.1582999527454376e-02</internalNodes>
          <leafValues>
            6.9176003336906433e-02 -4.5866298675537109e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2756 -3.7960000336170197e-02</internalNodes>
          <leafValues>
            -1.2553000450134277e+00 1.4332899451255798e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2757 -2.9560999944806099e-02</internalNodes>
          <leafValues>
            5.3151798248291016e-01 -2.0596499741077423e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2758 -3.9110999554395676e-02</internalNodes>
          <leafValues>
            1.1658719778060913e+00 5.3897000849246979e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2759 -2.9159000143408775e-02</internalNodes>
          <leafValues>
            3.9307600259780884e-01 -2.2184500098228455e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2760 -8.3617001771926880e-02</internalNodes>
          <leafValues>
            -7.3744499683380127e-01 1.4268200099468231e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2761 4.2004001140594482e-01</internalNodes>
          <leafValues>
            -1.4277400076389313e-01 1.7894840240478516e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2762 6.0005001723766327e-02</internalNodes>
          <leafValues>
            1.1976700276136398e-01 -1.8886189460754395e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2763 -1.8981000408530235e-02</internalNodes>
          <leafValues>
            -1.4148449897766113e+00 -5.6522998958826065e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2764 -6.0049998573958874e-03</internalNodes>
          <leafValues>
            4.4170799851417542e-01 -1.0200800001621246e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2765 -5.8214001357555389e-02</internalNodes>
          <leafValues>
            -1.3918470144271851e+00 -4.8268999904394150e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2766 -1.2271000072360039e-02</internalNodes>
          <leafValues>
            5.1317697763442993e-01 -9.3696996569633484e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2767 4.6585999429225922e-02</internalNodes>
          <leafValues>
            -5.7484000921249390e-02 -1.4283169507980347e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2768 1.2110000243410468e-03</internalNodes>
          <leafValues>
            -8.0891996622085571e-02 3.2333201169967651e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2769 -8.8642001152038574e-02</internalNodes>
          <leafValues>
            -8.6449098587036133e-01 -3.3146999776363373e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2770 -2.3184999823570251e-02</internalNodes>
          <leafValues>
            5.2162200212478638e-01 -1.6168000176548958e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2771 4.3090000748634338e-02</internalNodes>
          <leafValues>
            -1.6153800487518311e-01 1.0915000438690186e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2772 2.0599999697878957e-04</internalNodes>
          <leafValues>
            -1.7091499269008636e-01 3.1236699223518372e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2773 8.9159999042749405e-03</internalNodes>
          <leafValues>
            -6.7039998248219490e-03 -6.8810397386550903e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2774 -1.7752999439835548e-02</internalNodes>
          <leafValues>
            6.3292801380157471e-01 -4.2360001243650913e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2775 6.2299999408423901e-03</internalNodes>
          <leafValues>
            -3.3637198805809021e-01 1.2790599465370178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2776 2.2770000621676445e-02</internalNodes>
          <leafValues>
            -3.4703999757766724e-02 3.9141800999641418e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2777 -2.1534999832510948e-02</internalNodes>
          <leafValues>
            6.4765101671218872e-01 -2.0097799599170685e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2778 6.1758998781442642e-02</internalNodes>
          <leafValues>
            5.4297000169754028e-02 9.0700101852416992e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2779 -7.8069999814033508e-02</internalNodes>
          <leafValues>
            6.5523397922515869e-01 -1.9754399359226227e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2780 1.1315000243484974e-02</internalNodes>
          <leafValues>
            1.9385300576686859e-01 -5.1707297563552856e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2781 -2.5590000674128532e-02</internalNodes>
          <leafValues>
            -9.3096500635147095e-01 -3.1546998769044876e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2782 -3.8058999925851822e-02</internalNodes>
          <leafValues>
            -6.8326902389526367e-01 1.2709100544452667e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2783 9.7970003262162209e-03</internalNodes>
          <leafValues>
            1.5523999929428101e-02 -6.3347899913787842e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2784 -1.3841999694705009e-02</internalNodes>
          <leafValues>
            1.0060529708862305e+00 6.2812998890876770e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2785 8.3459997549653053e-03</internalNodes>
          <leafValues>
            -2.3383200168609619e-01 3.0982699990272522e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2786 -7.1439996361732483e-02</internalNodes>
          <leafValues>
            -7.2505402565002441e-01 1.7148299515247345e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2787 1.0006000287830830e-02</internalNodes>
          <leafValues>
            -2.2071999311447144e-01 3.5266199707984924e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2788 1.1005300283432007e-01</internalNodes>
          <leafValues>
            1.6662000119686127e-01 -7.4318999052047729e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2789 3.5310998558998108e-02</internalNodes>
          <leafValues>
            -2.3982700705528259e-01 4.1435998678207397e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2790 -1.1174699664115906e-01</internalNodes>
          <leafValues>
            5.1045399904251099e-01 2.2319999989122152e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2791 -1.1367800086736679e-01</internalNodes>
          <leafValues>
            9.0475201606750488e-01 -1.6615299880504608e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2792 1.6667999327182770e-02</internalNodes>
          <leafValues>
            1.4024500548839569e-01 -5.2178502082824707e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2793 -8.0340001732110977e-03</internalNodes>
          <leafValues>
            -6.6178399324417114e-01 3.7640000227838755e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2794 -3.3096998929977417e-02</internalNodes>
          <leafValues>
            8.0185902118682861e-01 5.9385001659393311e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2795 1.2547999620437622e-02</internalNodes>
          <leafValues>
            -3.3545500040054321e-01 1.4578600227832794e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2796 -4.2073998600244522e-02</internalNodes>
          <leafValues>
            -5.5509102344512939e-01 1.3266600668430328e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2797 2.5221999734640121e-02</internalNodes>
          <leafValues>
            -6.1631999909877777e-02 -1.3678770065307617e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2798 -2.4268999695777893e-02</internalNodes>
          <leafValues>
            3.4185099601745605e-01 -7.4160001240670681e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2799 -1.2280000373721123e-02</internalNodes>
          <leafValues>
            2.7745801210403442e-01 -3.1033900380134583e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2800 -1.1377099901437759e-01</internalNodes>
          <leafValues>
            1.1719540357589722e+00 8.3681002259254456e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2801 -8.4771998226642609e-02</internalNodes>
          <leafValues>
            8.1694799661636353e-01 -1.7837500572204590e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2802 -2.4552000686526299e-02</internalNodes>
          <leafValues>
            -1.8627299368381500e-01 1.4340099692344666e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2803 -9.0269995853304863e-03</internalNodes>
          <leafValues>
            3.2659199833869934e-01 -2.3541299998760223e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2804 1.1177999898791313e-02</internalNodes>
          <leafValues>
            1.9761200249195099e-01 -2.1701000630855560e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2805 -2.9366999864578247e-02</internalNodes>
          <leafValues>
            -9.3414801359176636e-01 -2.1704999729990959e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2806 6.3640000298619270e-03</internalNodes>
          <leafValues>
            2.5573000311851501e-02 4.6412798762321472e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2807 1.4026000164449215e-02</internalNodes>
          <leafValues>
            -2.1228599548339844e-01 4.0078800916671753e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2808 -1.3341999612748623e-02</internalNodes>
          <leafValues>
            7.4202698469161987e-01 2.9001999646425247e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2809 2.8422799706459045e-01</internalNodes>
          <leafValues>
            -1.9243599474430084e-01 4.3631199002265930e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2810 -2.3724000155925751e-01</internalNodes>
          <leafValues>
            6.9736397266387939e-01 6.9307997822761536e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2811 -1.1169700324535370e-01</internalNodes>
          <leafValues>
            3.9147201180458069e-01 -2.0922000706195831e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2812 1.2787500023841858e-01</internalNodes>
          <leafValues>
            -7.2555996477603912e-02 3.6088201403617859e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2813 -6.2900997698307037e-02</internalNodes>
          <leafValues>
            9.5424997806549072e-01 -1.5402799844741821e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2814 1.7439000308513641e-02</internalNodes>
          <leafValues>
            -5.1134999841451645e-02 2.7750301361083984e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2815 1.2319999514147639e-03</internalNodes>
          <leafValues>
            7.5627997517585754e-02 -3.6456099152565002e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2816 2.7495000511407852e-02</internalNodes>
          <leafValues>
            5.1844000816345215e-02 4.1562598943710327e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2817 -4.3543998152017593e-02</internalNodes>
          <leafValues>
            7.1969997882843018e-01 -1.7132200300693512e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2818 1.1025999672710896e-02</internalNodes>
          <leafValues>
            1.4354600012302399e-01 -6.5403002500534058e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2819 2.0865999162197113e-02</internalNodes>
          <leafValues>
            4.0089000016450882e-02 -4.5743298530578613e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2820 -2.2304000332951546e-02</internalNodes>
          <leafValues>
            5.3855001926422119e-01 7.1662999689579010e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2821 3.2492000609636307e-02</internalNodes>
          <leafValues>
            -4.5991998165845871e-02 -1.0047069787979126e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2822 1.2269999831914902e-02</internalNodes>
          <leafValues>
            3.4334998577833176e-02 4.2431798577308655e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2823 8.3820000290870667e-03</internalNodes>
          <leafValues>
            -2.5850600004196167e-01 2.6263499259948730e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2824 3.7353999912738800e-02</internalNodes>
          <leafValues>
            1.5692499279975891e-01 -1.0429090261459351e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2825 -1.4111000113189220e-02</internalNodes>
          <leafValues>
            -7.3177701234817505e-01 -2.0276999101042747e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2826 5.7066999375820160e-02</internalNodes>
          <leafValues>
            8.3360001444816589e-02 1.5661499500274658e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2827 4.9680001102387905e-03</internalNodes>
          <leafValues>
            -3.5318198800086975e-01 1.4698399603366852e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2828 -2.4492999538779259e-02</internalNodes>
          <leafValues>
            2.8325900435447693e-01 -3.4640000667423010e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2829 -1.1254999786615372e-02</internalNodes>
          <leafValues>
            -8.4017497301101685e-01 -3.6251999437808990e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2830 3.4533001482486725e-02</internalNodes>
          <leafValues>
            1.4998500049114227e-01 -8.7367099523544312e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2831 2.4303000420331955e-02</internalNodes>
          <leafValues>
            -1.8787500262260437e-01 5.9483999013900757e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2832 -7.8790001571178436e-03</internalNodes>
          <leafValues>
            4.4315698742866516e-01 -5.6570999324321747e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2833 3.5142000764608383e-02</internalNodes>
          <leafValues>
            -5.6494999676942825e-02 -1.3617190122604370e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2834 4.6259998343884945e-03</internalNodes>
          <leafValues>
            -3.1161698698997498e-01 2.5447699427604675e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2835 -8.3131000399589539e-02</internalNodes>
          <leafValues>
            1.6424349546432495e+00 -1.4429399371147156e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2836 -1.4015999622642994e-02</internalNodes>
          <leafValues>
            -7.7819502353668213e-01 1.7173300683498383e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2837 1.2450000504031777e-03</internalNodes>
          <leafValues>
            -2.3191399872303009e-01 2.8527900576591492e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2838 -1.6803000122308731e-02</internalNodes>
          <leafValues>
            -3.5965099930763245e-01 2.0412999391555786e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2839 -7.6747998595237732e-02</internalNodes>
          <leafValues>
            7.8050500154495239e-01 -1.5612800419330597e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2840 -2.3671999573707581e-01</internalNodes>
          <leafValues>
            1.1813700199127197e+00 7.8111998736858368e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2841 -1.0057400166988373e-01</internalNodes>
          <leafValues>
            -4.7104099392890930e-01 7.9172998666763306e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2842 1.3239999534562230e-03</internalNodes>
          <leafValues>
            2.2262699902057648e-01 -3.7099799513816833e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2843 2.2152999415993690e-02</internalNodes>
          <leafValues>
            -3.8649000227451324e-02 -9.2274999618530273e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2844 -1.1246199905872345e-01</internalNodes>
          <leafValues>
            4.1899600625038147e-01 8.0411002039909363e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2845 1.6481000930070877e-02</internalNodes>
          <leafValues>
            -1.6756699979305267e-01 7.1842402219772339e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2846 6.8113997578620911e-02</internalNodes>
          <leafValues>
            1.5719899535179138e-01 -8.7681102752685547e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2847 1.6011999920010567e-02</internalNodes>
          <leafValues>
            -4.1600000113248825e-03 -5.9327799081802368e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2848 4.6640001237392426e-03</internalNodes>
          <leafValues>
            -3.0153999105095863e-02 4.8345300555229187e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2849 6.7579997703433037e-03</internalNodes>
          <leafValues>
            -2.2667400538921356e-01 3.3662301301956177e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2850 4.7289999201893806e-03</internalNodes>
          <leafValues>
            -6.0373999178409576e-02 3.1458100676536560e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2851 2.5869999080896378e-03</internalNodes>
          <leafValues>
            -2.9872599244117737e-01 1.7787499725818634e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2852 2.8989999555051327e-03</internalNodes>
          <leafValues>
            2.1890200674533844e-01 -2.9567098617553711e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2853 -3.0053999274969101e-02</internalNodes>
          <leafValues>
            1.2150429487228394e+00 -1.4354999363422394e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2854 1.4181000180542469e-02</internalNodes>
          <leafValues>
            1.2451999820768833e-02 5.5490100383758545e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2855 -6.0527000576257706e-02</internalNodes>
          <leafValues>
            -1.4933999776840210e+00 -6.5227001905441284e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2856 -1.9882999360561371e-02</internalNodes>
          <leafValues>
            -3.8526400923728943e-01 1.9761200249195099e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2857 3.1218999996781349e-02</internalNodes>
          <leafValues>
            -2.1281200647354126e-01 2.9446500539779663e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2858 1.8271999433636665e-02</internalNodes>
          <leafValues>
            9.7200000891461968e-04 6.6814202070236206e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2859 1.1089999461546540e-03</internalNodes>
          <leafValues>
            -6.2467902898788452e-01 -1.6599999507889152e-03</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2860 -3.6713998764753342e-02</internalNodes>
          <leafValues>
            -4.2333900928497314e-01 1.2084700167179108e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2861 1.2044000439345837e-02</internalNodes>
          <leafValues>
            2.5882000103592873e-02 -5.0732398033142090e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2862 7.4749000370502472e-02</internalNodes>
          <leafValues>
            1.3184699416160583e-01 -2.1739600598812103e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2863 -2.3473200201988220e-01</internalNodes>
          <leafValues>
            1.1775610446929932e+00 -1.5114699304103851e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2864 1.4096499979496002e-01</internalNodes>
          <leafValues>
            3.3991001546382904e-02 3.9923098683357239e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2865 6.1789997853338718e-03</internalNodes>
          <leafValues>
            -3.1806701421737671e-01 1.1681699752807617e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2866 -5.7216998189687729e-02</internalNodes>
          <leafValues>
            8.4399098157882690e-01 8.3889000117778778e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2867 -5.5227000266313553e-02</internalNodes>
          <leafValues>
            3.6888301372528076e-01 -1.8913400173187256e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2868 -2.1583000198006630e-02</internalNodes>
          <leafValues>
            -5.2161800861358643e-01 1.5772600471973419e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2869 2.5747999548912048e-02</internalNodes>
          <leafValues>
            -5.9921998530626297e-02 -1.0674990415573120e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2870 -1.3098999857902527e-02</internalNodes>
          <leafValues>
            7.8958398103713989e-01 5.2099999040365219e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2871 2.2799998987466097e-03</internalNodes>
          <leafValues>
            -1.1704430580139160e+00 -5.9356998652219772e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2872 8.8060004636645317e-03</internalNodes>
          <leafValues>
            4.1717998683452606e-02 6.6352599859237671e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2873 -8.9699998497962952e-03</internalNodes>
          <leafValues>
            -3.5862699151039124e-01 6.0458000749349594e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2874 4.0230001322925091e-03</internalNodes>
          <leafValues>
            2.0979399979114532e-01 -2.4806000292301178e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2875 2.5017000734806061e-02</internalNodes>
          <leafValues>
            -1.8795900046825409e-01 3.9547100663185120e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2876 -5.9009999968111515e-03</internalNodes>
          <leafValues>
            2.5663900375366211e-01 -9.4919003546237946e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2877 4.3850000947713852e-03</internalNodes>
          <leafValues>
            3.3139001578092575e-02 -4.6075400710105896e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2878 -3.3771999180316925e-02</internalNodes>
          <leafValues>
            -9.8881602287292480e-01 1.4636899530887604e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2879 4.4523000717163086e-02</internalNodes>
          <leafValues>
            -1.3286699354648590e-01 1.5796790122985840e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2880 -4.0929000824689865e-02</internalNodes>
          <leafValues>
            3.3877098560333252e-01 7.4970997869968414e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2881 3.9351999759674072e-02</internalNodes>
          <leafValues>
            -1.8327899277210236e-01 4.6980699896812439e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2882 -7.0322997868061066e-02</internalNodes>
          <leafValues>
            -9.8322701454162598e-01 1.1808100342750549e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2883 3.5743001848459244e-02</internalNodes>
          <leafValues>
            -3.3050999045372009e-02 -8.3610898256301880e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2884 -4.2961999773979187e-02</internalNodes>
          <leafValues>
            1.1670809984207153e+00 8.0692000687122345e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2885 -2.1007999777793884e-02</internalNodes>
          <leafValues>
            6.3869798183441162e-01 -1.7626300454139709e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2886 -1.5742200613021851e-01</internalNodes>
          <leafValues>
            -2.3302499949932098e-01 1.2517499923706055e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2887 7.8659998252987862e-03</internalNodes>
          <leafValues>
            -2.2037999331951141e-01 2.7196800708770752e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2888 2.3622000589966774e-02</internalNodes>
          <leafValues>
            1.6127300262451172e-01 -4.3329000473022461e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2889 7.4692003428936005e-02</internalNodes>
          <leafValues>
            -1.6991999745368958e-01 5.8884900808334351e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2890 -6.4799998654052615e-04</internalNodes>
          <leafValues>
            2.5842899084091187e-01 -3.5911999642848969e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2891 -1.6290999948978424e-02</internalNodes>
          <leafValues>
            -7.6764398813247681e-01 -2.0472999662160873e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2892 -3.3133998513221741e-02</internalNodes>
          <leafValues>
            -2.7180099487304688e-01 1.4325700700283051e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2893 4.8797998577356339e-02</internalNodes>
          <leafValues>
            7.6408997178077698e-02 -4.1445198655128479e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2894 2.2869999520480633e-03</internalNodes>
          <leafValues>
            -3.8628999143838882e-02 2.0753799378871918e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2895 4.5304000377655029e-02</internalNodes>
          <leafValues>
            -1.7777900397777557e-01 6.3461399078369141e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2896 1.0705800354480743e-01</internalNodes>
          <leafValues>
            1.8972299993038177e-01 -5.1236200332641602e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2897 -4.0525000542402267e-02</internalNodes>
          <leafValues>
            7.0614999532699585e-01 -1.7803299427032471e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2898 3.1968999654054642e-02</internalNodes>
          <leafValues>
            6.8149998784065247e-02 6.8733102083206177e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2899 -5.7617001235485077e-02</internalNodes>
          <leafValues>
            7.5170499086380005e-01 -1.5764999389648438e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2900 1.3593999668955803e-02</internalNodes>
          <leafValues>
            1.9411900639533997e-01 -2.4561899900436401e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2901 7.1396000683307648e-02</internalNodes>
          <leafValues>
            -4.6881001442670822e-02 -8.8198298215866089e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2902 -1.4895999804139137e-02</internalNodes>
          <leafValues>
            -4.4532400369644165e-01 1.7679899930953979e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2903 -1.0026000440120697e-02</internalNodes>
          <leafValues>
            6.5122699737548828e-01 -1.6709999740123749e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2904 3.7589999847114086e-03</internalNodes>
          <leafValues>
            -5.8301001787185669e-02 3.4483298659324646e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2905 1.6263000667095184e-02</internalNodes>
          <leafValues>
            -1.5581500530242920e-01 8.6432701349258423e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2906 -4.0176000446081161e-02</internalNodes>
          <leafValues>
            -6.1028599739074707e-01 1.1796399950981140e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2907 2.7080999687314034e-02</internalNodes>
          <leafValues>
            -4.9601998180150986e-02 -8.9990001916885376e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2908 5.2420001477003098e-02</internalNodes>
          <leafValues>
            1.1297199875116348e-01 -1.0833640098571777e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2909 -1.9160000607371330e-02</internalNodes>
          <leafValues>
            -7.9880100488662720e-01 -3.4079000353813171e-02</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2910 -3.7730000913143158e-03</internalNodes>
          <leafValues>
            -1.9124099612236023e-01 2.1535199880599976e-01</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2911 7.5762003660202026e-02</internalNodes>
          <leafValues>
            -1.3421699404716492e-01 1.6807060241699219e+00</leafValues></_>
        <_>
          <internalNodes>
            0 -1 2912 -2.2173000499606133e-02</internalNodes>
          <leafValues>
            4.8600998520851135e-01 3.6160000599920750e-03</leafValues></_></weakClassifiers></_></stages>
  <features>
    <_>
      <rects>
        <_>
          6 4 12 9 -1.</_>
        <_>
          6 7 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 7 -1.</_>
        <_>
          10 4 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 9 -1.</_>
        <_>
          3 12 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 9 6 -1.</_>
        <_>
          8 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 4 19 -1.</_>
        <_>
          5 5 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 16 -1.</_>
        <_>
          6 13 12 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 12 6 -1.</_>
        <_>
          5 11 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 4 10 -1.</_>
        <_>
          11 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 7 6 -1.</_>
        <_>
          4 3 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 6 -1.</_>
        <_>
          6 8 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 7 -1.</_>
        <_>
          10 4 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 19 12 -1.</_>
        <_>
          1 12 19 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 3 -1.</_>
        <_>
          8 2 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 15 -1.</_>
        <_>
          9 14 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 10 -1.</_>
        <_>
          5 11 14 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 14 9 -1.</_>
        <_>
          5 3 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 9 6 -1.</_>
        <_>
          16 11 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 10 -1.</_>
        <_>
          9 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 6 10 -1.</_>
        <_>
          12 8 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 4 9 -1.</_>
        <_>
          4 5 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 11 -1.</_>
        <_>
          20 0 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 13 -1.</_>
        <_>
          8 6 8 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 18 10 6 -1.</_>
        <_>
          7 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 14 12 -1.</_>
        <_>
          5 13 14 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 3 -1.</_>
        <_>
          8 3 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 15 6 -1.</_>
        <_>
          5 11 15 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 5 14 -1.</_>
        <_>
          9 13 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 6 10 -1.</_>
        <_>
          11 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 3 12 -1.</_>
        <_>
          6 12 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          9 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 13 6 -1.</_>
        <_>
          5 8 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 15 -1.</_>
        <_>
          18 1 3 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 6 15 -1.</_>
        <_>
          4 1 3 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 24 15 -1.</_>
        <_>
          8 8 8 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 12 -1.</_>
        <_>
          5 6 7 6 2.</_>
        <_>
          12 12 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 21 12 -1.</_>
        <_>
          2 16 21 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 4 10 -1.</_>
        <_>
          10 1 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 20 10 -1.</_>
        <_>
          2 13 10 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 13 -1.</_>
        <_>
          2 1 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 2 4 13 -1.</_>
        <_>
          20 2 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 22 19 -1.</_>
        <_>
          11 5 11 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 9 -1.</_>
        <_>
          20 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 11 -1.</_>
        <_>
          2 3 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 4 9 -1.</_>
        <_>
          12 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 19 3 -1.</_>
        <_>
          0 7 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 4 9 -1.</_>
        <_>
          12 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 4 9 -1.</_>
        <_>
          10 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 14 14 -1.</_>
        <_>
          12 5 7 7 2.</_>
        <_>
          5 12 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 2 -1.</_>
        <_>
          1 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 13 4 11 -1.</_>
        <_>
          17 13 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 6 9 -1.</_>
        <_>
          0 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 9 -1.</_>
        <_>
          6 7 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 6 -1.</_>
        <_>
          10 5 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 5 -1.</_>
        <_>
          8 1 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 18 6 -1.</_>
        <_>
          4 12 18 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 17 12 6 -1.</_>
        <_>
          2 17 6 3 2.</_>
        <_>
          8 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 3 4 13 -1.</_>
        <_>
          19 3 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 4 13 -1.</_>
        <_>
          3 3 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 23 -1.</_>
        <_>
          8 1 8 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 8 12 -1.</_>
        <_>
          1 11 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 7 3 14 -1.</_>
        <_>
          14 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 16 6 -1.</_>
        <_>
          3 12 8 3 2.</_>
        <_>
          11 15 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 6 -1.</_>
        <_>
          6 8 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 6 12 -1.</_>
        <_>
          8 13 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 18 3 -1.</_>
        <_>
          1 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 16 12 -1.</_>
        <_>
          4 10 16 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 4 20 -1.</_>
        <_>
          2 1 2 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 2 -1.</_>
        <_>
          3 1 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 20 14 -1.</_>
        <_>
          1 5 10 7 2.</_>
        <_>
          11 12 10 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 14 12 -1.</_>
        <_>
          5 12 14 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 7 9 -1.</_>
        <_>
          3 17 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 9 6 -1.</_>
        <_>
          14 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 9 6 -1.</_>
        <_>
          1 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 8 10 -1.</_>
        <_>
          15 6 4 5 2.</_>
        <_>
          11 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 14 14 -1.</_>
        <_>
          5 5 7 7 2.</_>
        <_>
          12 12 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 5 -1.</_>
        <_>
          10 0 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          9 3 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 18 4 -1.</_>
        <_>
          9 8 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 9 -1.</_>
        <_>
          6 3 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 6 -1.</_>
        <_>
          8 0 8 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 16 12 -1.</_>
        <_>
          4 11 16 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 6 6 -1.</_>
        <_>
          11 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 24 3 -1.</_>
        <_>
          8 20 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 15 4 -1.</_>
        <_>
          9 13 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 12 -1.</_>
        <_>
          9 18 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 22 18 2 -1.</_>
        <_>
          1 23 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 4 10 -1.</_>
        <_>
          10 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 8 10 -1.</_>
        <_>
          6 12 8 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 10 4 -1.</_>
        <_>
          0 16 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 2 -1.</_>
        <_>
          6 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 22 3 -1.</_>
        <_>
          1 2 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 6 15 -1.</_>
        <_>
          5 4 3 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 4 4 10 -1.</_>
        <_>
          20 4 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 4 10 -1.</_>
        <_>
          2 4 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 20 6 -1.</_>
        <_>
          12 16 10 3 2.</_>
        <_>
          2 19 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 8 9 -1.</_>
        <_>
          4 12 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 6 6 -1.</_>
        <_>
          8 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 8 12 6 -1.</_>
        <_>
          17 8 6 3 2.</_>
        <_>
          11 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 12 6 -1.</_>
        <_>
          0 8 6 3 2.</_>
        <_>
          6 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 9 6 -1.</_>
        <_>
          8 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 6 -1.</_>
        <_>
          0 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 6 10 -1.</_>
        <_>
          12 8 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 19 12 3 -1.</_>
        <_>
          9 19 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 20 2 -1.</_>
        <_>
          2 11 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 18 12 -1.</_>
        <_>
          2 9 9 6 2.</_>
        <_>
          11 15 9 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 24 -1.</_>
        <_>
          3 0 9 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 10 -1.</_>
        <_>
          5 6 7 5 2.</_>
        <_>
          12 11 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 10 12 -1.</_>
        <_>
          14 5 5 6 2.</_>
        <_>
          9 11 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 12 12 -1.</_>
        <_>
          4 5 6 6 2.</_>
        <_>
          10 11 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 18 3 -1.</_>
        <_>
          4 15 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 13 8 8 -1.</_>
        <_>
          6 17 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 18 6 -1.</_>
        <_>
          3 19 18 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 6 -1.</_>
        <_>
          3 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 18 -1.</_>
        <_>
          10 6 4 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 4 14 -1.</_>
        <_>
          8 1 2 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 19 2 -1.</_>
        <_>
          3 3 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 22 13 -1.</_>
        <_>
          12 8 11 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 11 4 -1.</_>
        <_>
          8 11 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 15 10 -1.</_>
        <_>
          5 12 5 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 12 6 -1.</_>
        <_>
          16 16 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 12 6 -1.</_>
        <_>
          4 16 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 1 5 12 -1.</_>
        <_>
          19 5 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          8 2 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 4 -1.</_>
        <_>
          6 10 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 9 6 -1.</_>
        <_>
          10 5 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 17 6 6 -1.</_>
        <_>
          9 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 22 15 -1.</_>
        <_>
          0 12 22 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 17 9 -1.</_>
        <_>
          4 4 17 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 10 -1.</_>
        <_>
          9 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 8 -1.</_>
        <_>
          18 1 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 7 -1.</_>
        <_>
          3 1 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 22 -1.</_>
        <_>
          18 0 3 22 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 22 -1.</_>
        <_>
          3 0 3 22 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 7 8 16 -1.</_>
        <_>
          16 7 4 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 19 6 -1.</_>
        <_>
          2 12 19 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 12 -1.</_>
        <_>
          9 13 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 17 6 -1.</_>
        <_>
          2 17 17 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 7 3 14 -1.</_>
        <_>
          14 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 8 10 -1.</_>
        <_>
          5 6 4 5 2.</_>
        <_>
          9 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 9 11 -1.</_>
        <_>
          18 8 3 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 9 11 -1.</_>
        <_>
          3 8 3 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 10 18 -1.</_>
        <_>
          8 15 10 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 3 14 -1.</_>
        <_>
          7 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 24 8 -1.</_>
        <_>
          8 14 8 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 14 -1.</_>
        <_>
          10 10 9 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 6 6 -1.</_>
        <_>
          14 15 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 16 -1.</_>
        <_>
          7 0 5 8 2.</_>
        <_>
          12 8 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 9 6 -1.</_>
        <_>
          13 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 4 -1.</_>
        <_>
          12 3 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 9 6 -1.</_>
        <_>
          13 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 20 4 -1.</_>
        <_>
          1 1 10 2 2.</_>
        <_>
          11 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 9 6 -1.</_>
        <_>
          13 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 9 6 -1.</_>
        <_>
          8 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 10 6 -1.</_>
        <_>
          8 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 6 9 -1.</_>
        <_>
          8 3 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 12 6 -1.</_>
        <_>
          7 5 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 18 3 -1.</_>
        <_>
          0 11 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 22 3 -1.</_>
        <_>
          1 11 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 8 8 -1.</_>
        <_>
          9 11 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 11 6 6 -1.</_>
        <_>
          12 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 6 6 -1.</_>
        <_>
          9 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 10 11 6 -1.</_>
        <_>
          7 12 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 24 4 -1.</_>
        <_>
          0 13 12 2 2.</_>
        <_>
          12 15 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 22 12 -1.</_>
        <_>
          13 4 11 6 2.</_>
        <_>
          2 10 11 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 20 17 -1.</_>
        <_>
          12 0 10 17 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 2 24 -1.</_>
        <_>
          14 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 2 24 -1.</_>
        <_>
          9 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 1 2 22 -1.</_>
        <_>
          14 1 1 22 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 2 22 -1.</_>
        <_>
          9 1 1 22 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 6 3 18 -1.</_>
        <_>
          18 6 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 14 9 6 -1.</_>
        <_>
          6 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 14 9 4 -1.</_>
        <_>
          13 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 18 3 -1.</_>
        <_>
          3 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 8 18 -1.</_>
        <_>
          13 4 4 9 2.</_>
        <_>
          9 13 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 12 4 -1.</_>
        <_>
          6 2 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 14 6 -1.</_>
        <_>
          6 11 14 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 6 -1.</_>
        <_>
          10 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 6 16 -1.</_>
        <_>
          10 13 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 9 16 -1.</_>
        <_>
          4 4 3 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 18 9 -1.</_>
        <_>
          5 3 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 5 8 -1.</_>
        <_>
          9 19 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 0 4 9 -1.</_>
        <_>
          20 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 18 3 -1.</_>
        <_>
          2 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 22 19 2 -1.</_>
        <_>
          5 23 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 4 9 -1.</_>
        <_>
          2 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 19 18 -1.</_>
        <_>
          5 12 19 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 9 -1.</_>
        <_>
          2 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 14 12 -1.</_>
        <_>
          13 5 7 6 2.</_>
        <_>
          6 11 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 20 2 -1.</_>
        <_>
          0 2 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 3 -1.</_>
        <_>
          1 3 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 7 9 -1.</_>
        <_>
          2 11 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 22 4 -1.</_>
        <_>
          13 12 11 2 2.</_>
        <_>
          2 14 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 22 4 -1.</_>
        <_>
          0 12 11 2 2.</_>
        <_>
          11 14 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 6 11 -1.</_>
        <_>
          11 7 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 9 6 -1.</_>
        <_>
          10 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 4 10 -1.</_>
        <_>
          11 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 12 -1.</_>
        <_>
          6 10 12 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 15 -1.</_>
        <_>
          18 6 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 18 3 -1.</_>
        <_>
          3 16 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 5 6 9 -1.</_>
        <_>
          18 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 16 6 -1.</_>
        <_>
          1 5 8 3 2.</_>
        <_>
          9 8 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 24 14 -1.</_>
        <_>
          0 4 12 7 2.</_>
        <_>
          12 11 12 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 13 -1.</_>
        <_>
          13 0 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 13 -1.</_>
        <_>
          9 0 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 6 9 -1.</_>
        <_>
          13 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 6 9 -1.</_>
        <_>
          10 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 17 9 6 -1.</_>
        <_>
          13 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 14 6 -1.</_>
        <_>
          2 18 7 3 2.</_>
        <_>
          9 21 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 18 4 -1.</_>
        <_>
          12 18 9 2 2.</_>
        <_>
          3 20 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 15 4 -1.</_>
        <_>
          5 20 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 15 9 -1.</_>
        <_>
          14 15 5 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 16 4 -1.</_>
        <_>
          4 6 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 15 10 -1.</_>
        <_>
          5 14 5 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 10 14 -1.</_>
        <_>
          12 9 5 7 2.</_>
        <_>
          7 16 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 6 9 -1.</_>
        <_>
          9 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 3 -1.</_>
        <_>
          3 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 18 3 -1.</_>
        <_>
          0 11 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 18 4 -1.</_>
        <_>
          12 16 9 2 2.</_>
        <_>
          3 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 14 6 -1.</_>
        <_>
          4 6 7 3 2.</_>
        <_>
          11 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 2 18 -1.</_>
        <_>
          13 0 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 2 18 -1.</_>
        <_>
          10 0 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 15 10 -1.</_>
        <_>
          10 7 5 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 21 4 -1.</_>
        <_>
          8 20 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 5 18 -1.</_>
        <_>
          10 14 5 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 6 -1.</_>
        <_>
          0 2 12 3 2.</_>
        <_>
          12 5 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 22 8 -1.</_>
        <_>
          12 1 11 4 2.</_>
        <_>
          1 5 11 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 15 9 -1.</_>
        <_>
          4 3 15 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 19 -1.</_>
        <_>
          8 0 8 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 21 18 3 -1.</_>
        <_>
          11 21 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 10 4 -1.</_>
        <_>
          9 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 10 4 -1.</_>
        <_>
          10 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 8 6 16 -1.</_>
        <_>
          20 8 3 8 2.</_>
        <_>
          17 16 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 20 4 -1.</_>
        <_>
          1 15 10 2 2.</_>
        <_>
          11 17 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 10 6 -1.</_>
        <_>
          14 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 16 9 -1.</_>
        <_>
          3 3 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 7 15 -1.</_>
        <_>
          15 11 7 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 13 -1.</_>
        <_>
          11 1 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 2 6 14 -1.</_>
        <_>
          17 2 3 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 12 10 -1.</_>
        <_>
          3 14 6 5 2.</_>
        <_>
          9 19 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 6 14 -1.</_>
        <_>
          4 2 3 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 4 5 12 -1.</_>
        <_>
          10 8 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 24 5 -1.</_>
        <_>
          8 17 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 7 5 12 -1.</_>
        <_>
          15 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 6 12 -1.</_>
        <_>
          3 1 3 6 2.</_>
        <_>
          6 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 13 6 6 -1.</_>
        <_>
          12 16 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 13 6 6 -1.</_>
        <_>
          6 16 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 6 3 16 -1.</_>
        <_>
          14 14 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 13 6 -1.</_>
        <_>
          1 14 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 4 9 -1.</_>
        <_>
          13 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 9 6 -1.</_>
        <_>
          10 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 6 9 -1.</_>
        <_>
          12 2 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 6 9 -1.</_>
        <_>
          9 2 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 12 6 -1.</_>
        <_>
          6 20 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 6 9 -1.</_>
        <_>
          9 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 12 3 -1.</_>
        <_>
          7 7 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 8 21 -1.</_>
        <_>
          8 10 8 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 10 12 -1.</_>
        <_>
          7 8 10 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 9 -1.</_>
        <_>
          0 4 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 2 2 20 -1.</_>
        <_>
          15 2 1 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          0 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 3 2 21 -1.</_>
        <_>
          15 3 1 21 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 2 23 -1.</_>
        <_>
          8 0 1 23 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 9 4 -1.</_>
        <_>
          15 10 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 9 4 -1.</_>
        <_>
          0 10 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 9 6 -1.</_>
        <_>
          8 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 6 -1.</_>
        <_>
          0 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 18 4 -1.</_>
        <_>
          9 10 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 19 -1.</_>
        <_>
          8 0 8 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 8 12 -1.</_>
        <_>
          9 7 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 10 -1.</_>
        <_>
          12 6 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 10 12 -1.</_>
        <_>
          12 9 5 6 2.</_>
        <_>
          7 15 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 3 19 -1.</_>
        <_>
          6 0 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 10 -1.</_>
        <_>
          16 0 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 6 12 -1.</_>
        <_>
          2 0 3 6 2.</_>
        <_>
          5 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 24 2 -1.</_>
        <_>
          0 12 24 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 9 13 4 -1.</_>
        <_>
          4 11 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 6 9 -1.</_>
        <_>
          9 11 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 16 4 -1.</_>
        <_>
          0 14 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 12 6 9 -1.</_>
        <_>
          18 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 6 9 -1.</_>
        <_>
          0 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 10 4 -1.</_>
        <_>
          8 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 6 9 -1.</_>
        <_>
          10 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 3 6 15 -1.</_>
        <_>
          14 3 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 6 15 -1.</_>
        <_>
          8 3 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 2 9 4 -1.</_>
        <_>
          15 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 6 7 -1.</_>
        <_>
          8 10 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 6 10 -1.</_>
        <_>
          9 19 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 5 8 -1.</_>
        <_>
          7 17 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 3 16 -1.</_>
        <_>
          14 13 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 17 18 3 -1.</_>
        <_>
          2 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 18 19 3 -1.</_>
        <_>
          5 19 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 4 3 18 -1.</_>
        <_>
          13 4 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 3 18 -1.</_>
        <_>
          10 4 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 18 9 -1.</_>
        <_>
          9 3 6 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 6 14 -1.</_>
        <_>
          8 1 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 9 6 -1.</_>
        <_>
          12 19 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 20 16 -1.</_>
        <_>
          1 3 10 8 2.</_>
        <_>
          11 11 10 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 5 6 12 -1.</_>
        <_>
          15 5 3 6 2.</_>
        <_>
          12 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 16 -1.</_>
        <_>
          1 2 11 8 2.</_>
        <_>
          12 10 11 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 5 10 -1.</_>
        <_>
          10 19 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          3 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 6 10 -1.</_>
        <_>
          12 14 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          8 2 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 9 -1.</_>
        <_>
          6 7 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 5 -1.</_>
        <_>
          10 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 14 12 -1.</_>
        <_>
          5 12 14 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 8 10 -1.</_>
        <_>
          4 14 4 5 2.</_>
        <_>
          8 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 5 14 -1.</_>
        <_>
          11 13 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 3 16 -1.</_>
        <_>
          7 14 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 8 -1.</_>
        <_>
          9 7 6 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 20 2 -1.</_>
        <_>
          2 4 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 19 6 -1.</_>
        <_>
          3 14 19 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 6 6 14 -1.</_>
        <_>
          16 6 3 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 6 12 -1.</_>
        <_>
          9 9 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 6 6 18 -1.</_>
        <_>
          21 6 3 9 2.</_>
        <_>
          18 15 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 6 18 -1.</_>
        <_>
          0 6 3 9 2.</_>
        <_>
          3 15 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          18 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 15 6 -1.</_>
        <_>
          3 20 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          18 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 9 -1.</_>
        <_>
          0 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 18 2 -1.</_>
        <_>
          5 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 6 -1.</_>
        <_>
          6 2 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 9 -1.</_>
        <_>
          12 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 6 9 -1.</_>
        <_>
          10 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 13 6 -1.</_>
        <_>
          3 8 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 6 15 -1.</_>
        <_>
          5 5 3 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 9 6 -1.</_>
        <_>
          11 8 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 3 14 -1.</_>
        <_>
          8 13 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 10 4 -1.</_>
        <_>
          9 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 4 19 -1.</_>
        <_>
          13 1 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 4 19 -1.</_>
        <_>
          9 1 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 9 6 9 -1.</_>
        <_>
          18 12 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 21 18 3 -1.</_>
        <_>
          1 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 10 9 -1.</_>
        <_>
          14 16 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 22 4 -1.</_>
        <_>
          1 13 11 2 2.</_>
        <_>
          12 15 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 16 6 -1.</_>
        <_>
          12 6 8 3 2.</_>
        <_>
          4 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 18 22 -1.</_>
        <_>
          1 0 9 11 2.</_>
        <_>
          10 11 9 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 8 14 -1.</_>
        <_>
          14 7 4 7 2.</_>
        <_>
          10 14 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 6 20 -1.</_>
        <_>
          0 4 3 10 2.</_>
        <_>
          3 14 3 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 9 -1.</_>
        <_>
          17 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 9 -1.</_>
        <_>
          5 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 6 12 -1.</_>
        <_>
          18 12 3 6 2.</_>
        <_>
          15 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 6 12 -1.</_>
        <_>
          3 12 3 6 2.</_>
        <_>
          6 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 9 6 -1.</_>
        <_>
          0 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 19 3 -1.</_>
        <_>
          4 15 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 19 3 -1.</_>
        <_>
          2 14 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 10 6 -1.</_>
        <_>
          14 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 10 12 -1.</_>
        <_>
          6 0 5 6 2.</_>
        <_>
          11 6 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 1 6 12 -1.</_>
        <_>
          20 1 3 6 2.</_>
        <_>
          17 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 6 12 -1.</_>
        <_>
          1 1 3 6 2.</_>
        <_>
          4 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 14 6 9 -1.</_>
        <_>
          16 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 9 12 -1.</_>
        <_>
          7 9 9 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 4 12 -1.</_>
        <_>
          12 7 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 14 8 -1.</_>
        <_>
          4 4 14 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 18 3 -1.</_>
        <_>
          8 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 21 23 -1.</_>
        <_>
          7 1 7 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 17 4 -1.</_>
        <_>
          6 11 17 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 11 18 -1.</_>
        <_>
          1 6 11 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 15 13 6 -1.</_>
        <_>
          6 17 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 6 -1.</_>
        <_>
          0 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 15 4 -1.</_>
        <_>
          13 7 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 9 -1.</_>
        <_>
          9 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 18 3 -1.</_>
        <_>
          12 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 24 4 -1.</_>
        <_>
          8 14 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 10 3 12 -1.</_>
        <_>
          16 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 3 -1.</_>
        <_>
          0 4 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 17 10 6 -1.</_>
        <_>
          14 19 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 18 3 -1.</_>
        <_>
          7 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 18 9 -1.</_>
        <_>
          5 3 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 9 -1.</_>
        <_>
          4 6 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 5 3 12 -1.</_>
        <_>
          16 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 18 4 -1.</_>
        <_>
          6 7 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 6 10 -1.</_>
        <_>
          11 8 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 6 9 -1.</_>
        <_>
          11 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 21 -1.</_>
        <_>
          12 1 9 21 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 7 -1.</_>
        <_>
          6 8 6 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 6 9 -1.</_>
        <_>
          10 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          8 2 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 7 5 12 -1.</_>
        <_>
          14 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 5 12 -1.</_>
        <_>
          5 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 17 -1.</_>
        <_>
          3 1 3 17 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 19 9 -1.</_>
        <_>
          3 4 19 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 12 6 -1.</_>
        <_>
          3 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 4 4 19 -1.</_>
        <_>
          20 4 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 10 7 -1.</_>
        <_>
          5 16 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 10 12 -1.</_>
        <_>
          13 7 5 6 2.</_>
        <_>
          8 13 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 12 -1.</_>
        <_>
          6 7 5 6 2.</_>
        <_>
          11 13 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 9 6 -1.</_>
        <_>
          12 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 21 4 -1.</_>
        <_>
          8 20 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 9 6 -1.</_>
        <_>
          9 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 9 6 -1.</_>
        <_>
          10 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 14 -1.</_>
        <_>
          13 0 2 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 14 -1.</_>
        <_>
          9 0 2 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 9 6 -1.</_>
        <_>
          14 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 18 5 -1.</_>
        <_>
          8 8 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 3 6 11 -1.</_>
        <_>
          20 3 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 11 14 -1.</_>
        <_>
          6 12 11 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 9 -1.</_>
        <_>
          18 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 9 6 -1.</_>
        <_>
          7 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 9 -1.</_>
        <_>
          18 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 6 9 -1.</_>
        <_>
          0 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 9 4 -1.</_>
        <_>
          9 6 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 22 19 2 -1.</_>
        <_>
          0 23 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 14 6 9 -1.</_>
        <_>
          17 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 14 6 9 -1.</_>
        <_>
          1 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 11 4 9 -1.</_>
        <_>
          14 11 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 4 9 -1.</_>
        <_>
          8 11 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 7 -1.</_>
        <_>
          9 9 6 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 10 -1.</_>
        <_>
          9 17 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 17 18 3 -1.</_>
        <_>
          6 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 18 3 -1.</_>
        <_>
          1 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 11 12 -1.</_>
        <_>
          10 12 11 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          5 6 7 3 2.</_>
        <_>
          12 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 15 4 -1.</_>
        <_>
          5 6 15 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 22 2 -1.</_>
        <_>
          0 1 22 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 24 -1.</_>
        <_>
          8 0 8 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 18 4 -1.</_>
        <_>
          10 15 9 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 9 -1.</_>
        <_>
          6 11 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 7 12 -1.</_>
        <_>
          4 16 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 6 -1.</_>
        <_>
          12 2 11 3 2.</_>
        <_>
          1 5 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 20 14 3 -1.</_>
        <_>
          12 20 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 16 -1.</_>
        <_>
          12 0 12 8 2.</_>
        <_>
          0 8 12 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 13 18 4 -1.</_>
        <_>
          3 13 9 2 2.</_>
        <_>
          12 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 22 2 -1.</_>
        <_>
          2 11 22 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 11 8 -1.</_>
        <_>
          6 7 11 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 6 6 -1.</_>
        <_>
          14 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 24 6 -1.</_>
        <_>
          0 9 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 10 10 -1.</_>
        <_>
          19 0 5 5 2.</_>
        <_>
          14 5 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 10 10 -1.</_>
        <_>
          0 0 5 5 2.</_>
        <_>
          5 5 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 4 -1.</_>
        <_>
          12 1 12 2 2.</_>
        <_>
          0 3 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 15 16 6 -1.</_>
        <_>
          13 15 8 3 2.</_>
        <_>
          5 18 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 16 6 -1.</_>
        <_>
          3 15 8 3 2.</_>
        <_>
          11 18 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 21 10 -1.</_>
        <_>
          0 18 21 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 24 -1.</_>
        <_>
          15 0 2 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 6 11 -1.</_>
        <_>
          9 4 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 9 6 -1.</_>
        <_>
          12 5 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 2 20 -1.</_>
        <_>
          1 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 24 -1.</_>
        <_>
          15 0 2 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 24 -1.</_>
        <_>
          7 0 2 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 7 6 14 -1.</_>
        <_>
          19 7 3 7 2.</_>
        <_>
          16 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 4 12 -1.</_>
        <_>
          6 7 2 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 24 14 -1.</_>
        <_>
          8 5 8 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 13 10 6 -1.</_>
        <_>
          5 15 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 6 14 -1.</_>
        <_>
          2 7 3 7 2.</_>
        <_>
          5 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 2 9 15 -1.</_>
        <_>
          18 2 3 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 9 -1.</_>
        <_>
          2 2 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 10 14 -1.</_>
        <_>
          17 2 5 7 2.</_>
        <_>
          12 9 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 2 18 -1.</_>
        <_>
          12 6 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 15 6 -1.</_>
        <_>
          14 5 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 10 -1.</_>
        <_>
          10 6 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 9 7 -1.</_>
        <_>
          6 3 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 14 3 -1.</_>
        <_>
          6 7 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 8 6 -1.</_>
        <_>
          11 7 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 7 7 12 -1.</_>
        <_>
          12 13 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          10 6 2 9 2.</_>
        <_>
          12 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 14 6 9 -1.</_>
        <_>
          16 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 6 13 -1.</_>
        <_>
          6 0 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 21 3 -1.</_>
        <_>
          9 2 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 5 12 -1.</_>
        <_>
          5 8 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 3 4 10 -1.</_>
        <_>
          10 8 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 5 8 -1.</_>
        <_>
          8 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 11 9 -1.</_>
        <_>
          6 3 11 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 5 -1.</_>
        <_>
          10 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 5 -1.</_>
        <_>
          8 0 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 23 6 -1.</_>
        <_>
          1 12 23 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          9 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 21 6 -1.</_>
        <_>
          3 8 21 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 6 12 -1.</_>
        <_>
          2 5 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 8 10 -1.</_>
        <_>
          8 12 8 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 15 12 -1.</_>
        <_>
          10 7 5 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 10 6 -1.</_>
        <_>
          0 19 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 18 9 6 -1.</_>
        <_>
          14 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 16 -1.</_>
        <_>
          9 14 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 18 9 6 -1.</_>
        <_>
          14 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 9 6 -1.</_>
        <_>
          1 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 9 9 6 -1.</_>
        <_>
          15 11 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 9 6 -1.</_>
        <_>
          0 11 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 6 9 -1.</_>
        <_>
          19 3 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 17 18 3 -1.</_>
        <_>
          2 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 21 6 -1.</_>
        <_>
          3 17 21 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 17 6 6 -1.</_>
        <_>
          9 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 3 6 9 -1.</_>
        <_>
          18 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          0 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 16 10 -1.</_>
        <_>
          12 0 8 5 2.</_>
        <_>
          4 5 8 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 10 16 -1.</_>
        <_>
          2 0 5 8 2.</_>
        <_>
          7 8 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 10 5 -1.</_>
        <_>
          14 0 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 10 5 -1.</_>
        <_>
          5 0 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 3 6 10 -1.</_>
        <_>
          18 3 3 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 12 6 -1.</_>
        <_>
          5 11 6 3 2.</_>
        <_>
          11 14 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          21 0 3 18 -1.</_>
        <_>
          22 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 9 7 -1.</_>
        <_>
          11 8 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 12 8 10 -1.</_>
        <_>
          7 12 4 5 2.</_>
        <_>
          11 17 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          21 0 3 18 -1.</_>
        <_>
          22 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          12 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 9 6 -1.</_>
        <_>
          15 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 3 -1.</_>
        <_>
          0 3 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 7 6 9 -1.</_>
        <_>
          13 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 6 10 -1.</_>
        <_>
          9 6 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 6 12 -1.</_>
        <_>
          14 1 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 12 -1.</_>
        <_>
          6 10 12 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 3 2 21 -1.</_>
        <_>
          14 3 1 21 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 12 8 -1.</_>
        <_>
          6 5 12 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 8 -1.</_>
        <_>
          3 4 18 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 3 -1.</_>
        <_>
          3 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 24 4 -1.</_>
        <_>
          12 13 12 2 2.</_>
        <_>
          0 15 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 9 -1.</_>
        <_>
          12 5 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 6 9 -1.</_>
        <_>
          13 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 6 22 -1.</_>
        <_>
          8 2 2 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 10 8 14 -1.</_>
        <_>
          20 10 4 7 2.</_>
        <_>
          16 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 16 15 -1.</_>
        <_>
          3 9 16 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 10 8 14 -1.</_>
        <_>
          20 10 4 7 2.</_>
        <_>
          16 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 8 14 -1.</_>
        <_>
          0 10 4 7 2.</_>
        <_>
          4 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 11 6 -1.</_>
        <_>
          10 17 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 24 9 -1.</_>
        <_>
          8 7 8 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 4 16 -1.</_>
        <_>
          13 1 2 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 4 16 -1.</_>
        <_>
          9 1 2 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 16 8 -1.</_>
        <_>
          13 5 8 4 2.</_>
        <_>
          5 9 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 6 9 -1.</_>
        <_>
          0 12 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 6 9 -1.</_>
        <_>
          3 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 9 6 -1.</_>
        <_>
          8 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 8 10 -1.</_>
        <_>
          2 13 4 5 2.</_>
        <_>
          6 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 5 3 18 -1.</_>
        <_>
          15 11 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 18 3 -1.</_>
        <_>
          3 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 5 6 11 -1.</_>
        <_>
          19 5 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 6 11 -1.</_>
        <_>
          3 5 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 1 4 9 -1.</_>
        <_>
          19 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 4 9 -1.</_>
        <_>
          3 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 15 18 9 -1.</_>
        <_>
          4 15 9 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 12 4 -1.</_>
        <_>
          6 11 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 2 9 6 -1.</_>
        <_>
          15 4 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 9 6 -1.</_>
        <_>
          0 4 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 17 -1.</_>
        <_>
          17 0 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 17 -1.</_>
        <_>
          5 0 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 17 9 4 -1.</_>
        <_>
          8 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 3 18 -1.</_>
        <_>
          6 11 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 14 12 -1.</_>
        <_>
          5 8 14 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 3 12 -1.</_>
        <_>
          10 8 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 14 15 -1.</_>
        <_>
          10 12 14 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 14 15 -1.</_>
        <_>
          0 12 14 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 9 6 -1.</_>
        <_>
          15 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 9 6 -1.</_>
        <_>
          0 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 6 14 -1.</_>
        <_>
          14 6 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 6 9 -1.</_>
        <_>
          11 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 6 15 -1.</_>
        <_>
          14 6 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 6 15 -1.</_>
        <_>
          8 6 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 3 8 9 -1.</_>
        <_>
          15 3 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 9 21 -1.</_>
        <_>
          3 0 3 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 8 12 -1.</_>
        <_>
          11 13 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 12 -1.</_>
        <_>
          6 7 5 6 2.</_>
        <_>
          11 13 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          12 6 2 9 2.</_>
        <_>
          10 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 9 -1.</_>
        <_>
          0 3 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 18 3 -1.</_>
        <_>
          3 15 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 8 10 -1.</_>
        <_>
          3 14 4 5 2.</_>
        <_>
          7 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 24 4 -1.</_>
        <_>
          12 12 12 2 2.</_>
        <_>
          0 14 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 3 20 -1.</_>
        <_>
          1 2 1 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 10 8 -1.</_>
        <_>
          17 16 5 4 2.</_>
        <_>
          12 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 10 8 -1.</_>
        <_>
          2 16 5 4 2.</_>
        <_>
          7 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 9 -1.</_>
        <_>
          7 3 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 3 -1.</_>
        <_>
          8 0 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 15 4 -1.</_>
        <_>
          3 10 15 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 6 -1.</_>
        <_>
          10 5 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 13 14 6 -1.</_>
        <_>
          5 16 14 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 4 10 -1.</_>
        <_>
          11 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 6 7 -1.</_>
        <_>
          3 6 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 6 -1.</_>
        <_>
          18 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 3 -1.</_>
        <_>
          3 2 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 14 18 -1.</_>
        <_>
          9 12 14 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 6 -1.</_>
        <_>
          3 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 6 -1.</_>
        <_>
          13 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 24 3 -1.</_>
        <_>
          8 20 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 7 -1.</_>
        <_>
          13 11 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 10 6 -1.</_>
        <_>
          4 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 6 -1.</_>
        <_>
          13 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 7 -1.</_>
        <_>
          8 11 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 11 12 -1.</_>
        <_>
          7 8 11 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 15 10 4 -1.</_>
        <_>
          6 17 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 9 -1.</_>
        <_>
          16 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 6 9 -1.</_>
        <_>
          6 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 4 15 -1.</_>
        <_>
          11 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 20 3 -1.</_>
        <_>
          0 1 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 18 10 6 -1.</_>
        <_>
          13 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 6 11 -1.</_>
        <_>
          5 7 3 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 10 9 -1.</_>
        <_>
          10 17 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 4 9 -1.</_>
        <_>
          10 2 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 3 10 4 -1.</_>
        <_>
          14 3 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 6 -1.</_>
        <_>
          6 6 6 3 2.</_>
        <_>
          12 9 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 8 10 -1.</_>
        <_>
          12 8 4 5 2.</_>
        <_>
          8 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 4 16 -1.</_>
        <_>
          7 12 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 9 4 -1.</_>
        <_>
          8 10 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 14 9 -1.</_>
        <_>
          5 5 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 19 8 -1.</_>
        <_>
          3 20 19 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 10 8 -1.</_>
        <_>
          5 0 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 16 18 -1.</_>
        <_>
          5 2 8 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 24 11 -1.</_>
        <_>
          8 11 8 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 18 5 -1.</_>
        <_>
          3 3 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 18 3 -1.</_>
        <_>
          1 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 17 18 3 -1.</_>
        <_>
          5 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 9 6 -1.</_>
        <_>
          1 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 23 10 -1.</_>
        <_>
          1 14 23 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 3 -1.</_>
        <_>
          3 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 3 -1.</_>
        <_>
          6 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 3 22 -1.</_>
        <_>
          7 2 1 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 17 10 6 -1.</_>
        <_>
          14 19 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 10 6 -1.</_>
        <_>
          1 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 3 6 12 -1.</_>
        <_>
          13 3 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          12 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 9 6 -1.</_>
        <_>
          15 10 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 6 9 -1.</_>
        <_>
          5 11 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 3 19 -1.</_>
        <_>
          15 5 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 9 6 -1.</_>
        <_>
          6 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 3 19 -1.</_>
        <_>
          15 5 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          0 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 21 18 3 -1.</_>
        <_>
          5 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 4 -1.</_>
        <_>
          7 10 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 4 8 10 -1.</_>
        <_>
          17 4 4 5 2.</_>
        <_>
          13 9 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 9 6 -1.</_>
        <_>
          10 8 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 9 9 8 -1.</_>
        <_>
          15 9 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 5 12 -1.</_>
        <_>
          0 10 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 14 6 -1.</_>
        <_>
          14 6 7 3 2.</_>
        <_>
          7 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 3 19 -1.</_>
        <_>
          8 5 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 15 20 -1.</_>
        <_>
          13 4 5 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 15 20 -1.</_>
        <_>
          6 4 5 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 6 6 -1.</_>
        <_>
          13 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 6 6 -1.</_>
        <_>
          8 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 2 6 14 -1.</_>
        <_>
          17 2 3 7 2.</_>
        <_>
          14 9 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 6 14 -1.</_>
        <_>
          4 2 3 7 2.</_>
        <_>
          7 9 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 4 6 7 -1.</_>
        <_>
          12 4 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 6 9 -1.</_>
        <_>
          11 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 4 8 10 -1.</_>
        <_>
          11 4 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 8 10 -1.</_>
        <_>
          9 4 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 10 6 -1.</_>
        <_>
          8 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 21 6 -1.</_>
        <_>
          1 20 21 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 12 6 -1.</_>
        <_>
          9 2 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 12 6 -1.</_>
        <_>
          9 2 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 5 12 6 -1.</_>
        <_>
          18 5 6 3 2.</_>
        <_>
          12 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 6 9 -1.</_>
        <_>
          8 11 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 20 6 -1.</_>
        <_>
          2 9 20 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 12 6 -1.</_>
        <_>
          0 5 6 3 2.</_>
        <_>
          6 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 8 10 -1.</_>
        <_>
          18 14 4 5 2.</_>
        <_>
          14 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 14 8 10 -1.</_>
        <_>
          2 14 4 5 2.</_>
        <_>
          6 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 20 13 -1.</_>
        <_>
          2 11 10 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 12 5 -1.</_>
        <_>
          12 9 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 16 6 -1.</_>
        <_>
          13 6 8 3 2.</_>
        <_>
          5 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 19 9 4 -1.</_>
        <_>
          1 21 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 12 5 -1.</_>
        <_>
          11 5 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 14 12 -1.</_>
        <_>
          3 5 7 6 2.</_>
        <_>
          10 11 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 9 6 -1.</_>
        <_>
          12 4 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 6 19 3 -1.</_>
        <_>
          2 7 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 10 6 9 -1.</_>
        <_>
          18 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 2 -1.</_>
        <_>
          3 8 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 2 4 18 -1.</_>
        <_>
          22 2 2 9 2.</_>
        <_>
          20 11 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 20 3 -1.</_>
        <_>
          2 19 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 22 3 -1.</_>
        <_>
          1 10 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 4 18 -1.</_>
        <_>
          0 2 2 9 2.</_>
        <_>
          2 11 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 0 4 23 -1.</_>
        <_>
          19 0 2 23 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 19 -1.</_>
        <_>
          3 3 3 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          20 2 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 10 6 -1.</_>
        <_>
          0 7 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 12 12 -1.</_>
        <_>
          13 0 6 6 2.</_>
        <_>
          7 6 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 6 -1.</_>
        <_>
          0 3 12 3 2.</_>
        <_>
          12 6 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 4 10 -1.</_>
        <_>
          10 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 4 15 -1.</_>
        <_>
          8 14 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 11 17 6 -1.</_>
        <_>
          4 14 17 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 18 8 -1.</_>
        <_>
          2 5 9 4 2.</_>
        <_>
          11 9 9 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 14 6 -1.</_>
        <_>
          14 6 7 3 2.</_>
        <_>
          7 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 14 6 -1.</_>
        <_>
          3 6 7 3 2.</_>
        <_>
          10 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 5 3 18 -1.</_>
        <_>
          17 5 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 3 18 -1.</_>
        <_>
          6 5 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 10 14 4 -1.</_>
        <_>
          10 12 14 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 9 4 -1.</_>
        <_>
          4 12 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 18 9 -1.</_>
        <_>
          2 3 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 12 8 -1.</_>
        <_>
          10 3 4 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 8 5 -1.</_>
        <_>
          5 1 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 7 7 8 -1.</_>
        <_>
          12 11 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 22 4 -1.</_>
        <_>
          0 14 22 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 4 15 -1.</_>
        <_>
          15 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 7 8 -1.</_>
        <_>
          5 11 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 9 4 -1.</_>
        <_>
          8 20 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 4 -1.</_>
        <_>
          1 4 22 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 6 17 -1.</_>
        <_>
          19 3 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 8 18 -1.</_>
        <_>
          8 11 8 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 6 12 -1.</_>
        <_>
          20 0 3 6 2.</_>
        <_>
          17 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 5 9 12 -1.</_>
        <_>
          15 11 9 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 22 18 2 -1.</_>
        <_>
          2 23 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 10 12 6 -1.</_>
        <_>
          16 10 6 3 2.</_>
        <_>
          10 13 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 4 11 -1.</_>
        <_>
          2 1 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 0 4 10 -1.</_>
        <_>
          20 0 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 6 17 -1.</_>
        <_>
          3 3 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 8 9 -1.</_>
        <_>
          0 16 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 8 6 12 -1.</_>
        <_>
          16 12 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 6 12 -1.</_>
        <_>
          2 12 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 19 3 -1.</_>
        <_>
          1 6 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 8 9 7 -1.</_>
        <_>
          14 8 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 12 9 -1.</_>
        <_>
          3 11 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 3 -1.</_>
        <_>
          3 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 4 12 -1.</_>
        <_>
          10 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 14 -1.</_>
        <_>
          3 9 9 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 4 9 -1.</_>
        <_>
          2 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 5 4 18 -1.</_>
        <_>
          12 5 2 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 4 18 -1.</_>
        <_>
          10 5 2 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 6 10 -1.</_>
        <_>
          12 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 4 11 -1.</_>
        <_>
          11 4 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 16 18 3 -1.</_>
        <_>
          4 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 20 3 -1.</_>
        <_>
          0 17 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 12 -1.</_>
        <_>
          9 13 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 13 8 8 -1.</_>
        <_>
          8 17 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 3 12 -1.</_>
        <_>
          13 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 14 14 -1.</_>
        <_>
          5 9 7 7 2.</_>
        <_>
          12 16 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 10 -1.</_>
        <_>
          12 0 12 5 2.</_>
        <_>
          0 5 12 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 11 18 2 -1.</_>
        <_>
          1 12 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 5 5 12 -1.</_>
        <_>
          19 9 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 5 12 -1.</_>
        <_>
          0 9 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 6 8 18 -1.</_>
        <_>
          20 6 4 9 2.</_>
        <_>
          16 15 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 8 18 -1.</_>
        <_>
          0 6 4 9 2.</_>
        <_>
          4 15 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 5 12 12 -1.</_>
        <_>
          18 5 6 6 2.</_>
        <_>
          12 11 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 6 9 -1.</_>
        <_>
          9 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 13 6 11 -1.</_>
        <_>
          11 13 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 12 12 -1.</_>
        <_>
          0 5 6 6 2.</_>
        <_>
          6 11 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 23 3 -1.</_>
        <_>
          1 3 23 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 19 3 -1.</_>
        <_>
          1 16 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 17 11 4 -1.</_>
        <_>
          13 19 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 8 5 -1.</_>
        <_>
          4 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 10 4 -1.</_>
        <_>
          12 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 9 9 -1.</_>
        <_>
          4 9 9 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 9 6 -1.</_>
        <_>
          15 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 9 6 -1.</_>
        <_>
          1 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 20 8 -1.</_>
        <_>
          13 10 10 4 2.</_>
        <_>
          3 14 10 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 9 18 -1.</_>
        <_>
          5 0 3 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 9 10 -1.</_>
        <_>
          16 11 3 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 8 5 -1.</_>
        <_>
          5 2 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 21 6 -1.</_>
        <_>
          10 4 7 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 14 -1.</_>
        <_>
          7 0 5 7 2.</_>
        <_>
          12 7 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 12 4 -1.</_>
        <_>
          12 19 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 23 4 -1.</_>
        <_>
          0 8 23 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 8 10 -1.</_>
        <_>
          17 10 4 5 2.</_>
        <_>
          13 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 16 9 4 -1.</_>
        <_>
          15 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 4 -1.</_>
        <_>
          0 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 6 -1.</_>
        <_>
          13 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 6 -1.</_>
        <_>
          8 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 6 -1.</_>
        <_>
          12 3 12 3 2.</_>
        <_>
          0 6 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 18 3 -1.</_>
        <_>
          2 5 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 4 -1.</_>
        <_>
          12 0 12 2 2.</_>
        <_>
          0 2 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 18 3 -1.</_>
        <_>
          1 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 6 -1.</_>
        <_>
          0 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 17 18 3 -1.</_>
        <_>
          6 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 6 10 -1.</_>
        <_>
          10 8 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 5 8 -1.</_>
        <_>
          8 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 6 8 -1.</_>
        <_>
          12 12 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 6 11 -1.</_>
        <_>
          8 5 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 6 8 9 -1.</_>
        <_>
          13 9 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 21 6 -1.</_>
        <_>
          1 9 21 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 5 3 12 -1.</_>
        <_>
          15 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 11 12 -1.</_>
        <_>
          6 13 11 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 8 10 8 -1.</_>
        <_>
          18 8 5 4 2.</_>
        <_>
          13 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 12 3 -1.</_>
        <_>
          11 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 18 4 -1.</_>
        <_>
          12 11 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 22 22 -1.</_>
        <_>
          0 11 22 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 6 8 -1.</_>
        <_>
          11 6 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 9 -1.</_>
        <_>
          12 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 6 14 -1.</_>
        <_>
          8 3 3 7 2.</_>
        <_>
          11 10 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 18 8 -1.</_>
        <_>
          9 10 6 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 3 14 -1.</_>
        <_>
          10 7 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 20 -1.</_>
        <_>
          4 13 16 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 6 10 -1.</_>
        <_>
          11 4 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 16 4 -1.</_>
        <_>
          5 2 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 18 4 -1.</_>
        <_>
          8 5 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 9 -1.</_>
        <_>
          15 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 8 5 -1.</_>
        <_>
          12 4 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 10 4 -1.</_>
        <_>
          12 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 10 4 -1.</_>
        <_>
          7 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 11 12 5 -1.</_>
        <_>
          11 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 8 10 -1.</_>
        <_>
          3 10 4 5 2.</_>
        <_>
          7 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 9 8 -1.</_>
        <_>
          14 12 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 21 24 3 -1.</_>
        <_>
          8 21 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 20 18 4 -1.</_>
        <_>
          9 20 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 9 6 -1.</_>
        <_>
          1 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 17 10 4 -1.</_>
        <_>
          11 19 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 4 12 -1.</_>
        <_>
          9 18 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 9 6 -1.</_>
        <_>
          12 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 6 9 -1.</_>
        <_>
          1 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 12 4 -1.</_>
        <_>
          6 18 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 20 3 -1.</_>
        <_>
          1 6 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 9 9 -1.</_>
        <_>
          8 4 9 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 19 9 4 -1.</_>
        <_>
          2 21 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 18 -1.</_>
        <_>
          11 7 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 8 12 -1.</_>
        <_>
          7 2 4 6 2.</_>
        <_>
          11 8 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 10 9 8 -1.</_>
        <_>
          14 10 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 12 5 -1.</_>
        <_>
          9 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 9 6 -1.</_>
        <_>
          14 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 6 9 -1.</_>
        <_>
          7 10 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 5 12 -1.</_>
        <_>
          4 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 21 6 -1.</_>
        <_>
          9 0 7 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 15 -1.</_>
        <_>
          11 0 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 18 2 -1.</_>
        <_>
          2 3 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 17 8 6 -1.</_>
        <_>
          8 20 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 2 -1.</_>
        <_>
          3 1 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 9 6 -1.</_>
        <_>
          11 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 5 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          2 3 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 2 4 9 -1.</_>
        <_>
          20 2 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 4 9 -1.</_>
        <_>
          2 2 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 4 -1.</_>
        <_>
          12 1 12 2 2.</_>
        <_>
          0 3 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 6 -1.</_>
        <_>
          0 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 9 6 -1.</_>
        <_>
          14 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 19 3 -1.</_>
        <_>
          0 16 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 22 12 -1.</_>
        <_>
          12 5 11 6 2.</_>
        <_>
          1 11 11 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 13 6 6 -1.</_>
        <_>
          8 13 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 20 3 -1.</_>
        <_>
          4 3 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 6 10 -1.</_>
        <_>
          10 14 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 12 16 6 -1.</_>
        <_>
          14 12 8 3 2.</_>
        <_>
          6 15 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 8 9 -1.</_>
        <_>
          2 16 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 8 6 14 -1.</_>
        <_>
          14 8 3 7 2.</_>
        <_>
          11 15 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 16 6 -1.</_>
        <_>
          2 12 8 3 2.</_>
        <_>
          10 15 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 16 16 8 -1.</_>
        <_>
          5 20 16 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 4 12 -1.</_>
        <_>
          9 7 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 8 10 -1.</_>
        <_>
          12 2 4 5 2.</_>
        <_>
          8 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 6 -1.</_>
        <_>
          6 6 6 3 2.</_>
        <_>
          12 9 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 6 9 -1.</_>
        <_>
          12 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 8 12 -1.</_>
        <_>
          0 0 4 6 2.</_>
        <_>
          4 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 6 9 -1.</_>
        <_>
          18 11 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 6 6 -1.</_>
        <_>
          5 12 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 21 3 -1.</_>
        <_>
          10 21 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 16 6 -1.</_>
        <_>
          2 3 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 6 7 6 -1.</_>
        <_>
          13 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 4 14 -1.</_>
        <_>
          6 11 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 6 9 -1.</_>
        <_>
          11 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 6 14 -1.</_>
        <_>
          7 8 3 7 2.</_>
        <_>
          10 15 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 4 16 -1.</_>
        <_>
          18 16 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 6 10 -1.</_>
        <_>
          11 14 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 12 5 -1.</_>
        <_>
          10 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 23 3 -1.</_>
        <_>
          0 13 23 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 12 -1.</_>
        <_>
          15 0 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 12 5 -1.</_>
        <_>
          4 10 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 2 10 4 -1.</_>
        <_>
          13 4 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 12 -1.</_>
        <_>
          7 0 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 9 6 -1.</_>
        <_>
          14 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 9 6 -1.</_>
        <_>
          7 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 18 13 -1.</_>
        <_>
          12 11 6 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 18 13 -1.</_>
        <_>
          6 11 6 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 12 6 -1.</_>
        <_>
          16 16 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 21 3 -1.</_>
        <_>
          0 7 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 12 6 -1.</_>
        <_>
          16 16 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 6 14 -1.</_>
        <_>
          5 14 6 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 19 2 -1.</_>
        <_>
          5 11 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 14 4 -1.</_>
        <_>
          5 6 14 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 18 4 -1.</_>
        <_>
          9 18 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 9 -1.</_>
        <_>
          9 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 3 11 4 -1.</_>
        <_>
          13 5 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 9 6 -1.</_>
        <_>
          5 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 1 4 23 -1.</_>
        <_>
          19 1 2 23 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 4 23 -1.</_>
        <_>
          3 1 2 23 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 16 18 3 -1.</_>
        <_>
          5 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 11 4 -1.</_>
        <_>
          0 5 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 20 3 -1.</_>
        <_>
          2 17 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 3 13 4 -1.</_>
        <_>
          5 5 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 22 15 -1.</_>
        <_>
          1 9 11 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 14 3 -1.</_>
        <_>
          10 4 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 10 4 -1.</_>
        <_>
          8 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 4 -1.</_>
        <_>
          11 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 4 6 9 -1.</_>
        <_>
          12 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 9 6 -1.</_>
        <_>
          4 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 8 10 -1.</_>
        <_>
          12 3 4 5 2.</_>
        <_>
          8 8 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 16 6 -1.</_>
        <_>
          3 6 8 3 2.</_>
        <_>
          11 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          5 9 14 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 9 6 -1.</_>
        <_>
          4 5 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 18 2 -1.</_>
        <_>
          6 4 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 9 6 -1.</_>
        <_>
          10 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 3 -1.</_>
        <_>
          0 2 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 10 6 -1.</_>
        <_>
          0 19 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 18 3 -1.</_>
        <_>
          3 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 6 16 -1.</_>
        <_>
          2 5 3 8 2.</_>
        <_>
          5 13 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 11 6 -1.</_>
        <_>
          7 8 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 12 22 -1.</_>
        <_>
          5 13 12 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 4 10 -1.</_>
        <_>
          10 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 4 18 -1.</_>
        <_>
          9 6 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 6 9 -1.</_>
        <_>
          18 11 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 15 10 -1.</_>
        <_>
          9 7 5 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 6 9 -1.</_>
        <_>
          12 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 10 -1.</_>
        <_>
          11 9 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 6 10 -1.</_>
        <_>
          13 14 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 14 6 10 -1.</_>
        <_>
          9 14 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 8 16 9 -1.</_>
        <_>
          4 11 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 20 3 -1.</_>
        <_>
          2 12 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 13 -1.</_>
        <_>
          13 0 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 13 -1.</_>
        <_>
          9 0 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 7 -1.</_>
        <_>
          9 1 6 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 11 6 9 -1.</_>
        <_>
          1 14 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 9 6 -1.</_>
        <_>
          8 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 15 6 -1.</_>
        <_>
          3 11 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 19 2 -1.</_>
        <_>
          5 11 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 7 16 -1.</_>
        <_>
          8 14 7 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 9 6 -1.</_>
        <_>
          9 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 8 12 -1.</_>
        <_>
          0 11 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 18 3 -1.</_>
        <_>
          6 5 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 12 6 -1.</_>
        <_>
          4 16 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 13 9 4 -1.</_>
        <_>
          13 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 14 14 -1.</_>
        <_>
          5 8 7 7 2.</_>
        <_>
          12 15 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 22 6 -1.</_>
        <_>
          12 16 11 3 2.</_>
        <_>
          1 19 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 10 10 -1.</_>
        <_>
          14 5 5 5 2.</_>
        <_>
          9 10 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 10 10 -1.</_>
        <_>
          5 5 5 5 2.</_>
        <_>
          10 10 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 16 6 -1.</_>
        <_>
          12 6 8 3 2.</_>
        <_>
          4 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 6 9 -1.</_>
        <_>
          0 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 10 8 14 -1.</_>
        <_>
          20 10 4 7 2.</_>
        <_>
          16 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 12 -1.</_>
        <_>
          9 18 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 10 8 12 -1.</_>
        <_>
          12 10 4 6 2.</_>
        <_>
          8 16 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 4 9 -1.</_>
        <_>
          10 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 4 8 16 -1.</_>
        <_>
          14 4 4 8 2.</_>
        <_>
          10 12 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 10 10 6 -1.</_>
        <_>
          7 12 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 14 -1.</_>
        <_>
          12 6 7 7 2.</_>
        <_>
          5 13 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 20 2 -1.</_>
        <_>
          2 12 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 4 16 -1.</_>
        <_>
          18 16 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 11 12 10 -1.</_>
        <_>
          1 11 6 5 2.</_>
        <_>
          7 16 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 12 4 -1.</_>
        <_>
          6 11 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 7 -1.</_>
        <_>
          12 12 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 4 8 16 -1.</_>
        <_>
          14 4 4 8 2.</_>
        <_>
          10 12 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 8 16 -1.</_>
        <_>
          6 4 4 8 2.</_>
        <_>
          10 12 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 9 6 -1.</_>
        <_>
          11 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 16 12 -1.</_>
        <_>
          1 5 8 6 2.</_>
        <_>
          9 11 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 8 -1.</_>
        <_>
          9 9 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 3 18 -1.</_>
        <_>
          7 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 9 5 14 -1.</_>
        <_>
          17 16 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 5 14 -1.</_>
        <_>
          2 16 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 10 6 -1.</_>
        <_>
          7 7 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 23 18 -1.</_>
        <_>
          1 9 23 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 21 3 -1.</_>
        <_>
          8 1 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 12 6 -1.</_>
        <_>
          3 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 8 8 16 -1.</_>
        <_>
          20 8 4 8 2.</_>
        <_>
          16 16 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 19 24 4 -1.</_>
        <_>
          8 19 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 8 8 16 -1.</_>
        <_>
          20 8 4 8 2.</_>
        <_>
          16 16 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 8 16 -1.</_>
        <_>
          0 8 4 8 2.</_>
        <_>
          4 16 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 12 8 10 -1.</_>
        <_>
          8 17 8 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 5 8 -1.</_>
        <_>
          5 11 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 19 2 -1.</_>
        <_>
          4 2 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 24 9 -1.</_>
        <_>
          8 12 8 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 13 8 -1.</_>
        <_>
          6 4 13 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 3 -1.</_>
        <_>
          0 1 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 3 4 11 -1.</_>
        <_>
          20 3 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 12 8 -1.</_>
        <_>
          12 11 6 4 2.</_>
        <_>
          6 15 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 12 6 -1.</_>
        <_>
          0 8 6 3 2.</_>
        <_>
          6 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 17 18 3 -1.</_>
        <_>
          6 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 6 -1.</_>
        <_>
          0 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 3 4 9 -1.</_>
        <_>
          20 3 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 4 9 -1.</_>
        <_>
          2 3 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 9 19 -1.</_>
        <_>
          18 0 3 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 9 19 -1.</_>
        <_>
          3 0 3 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 8 -1.</_>
        <_>
          13 11 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 8 -1.</_>
        <_>
          8 11 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 19 3 -1.</_>
        <_>
          5 12 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 20 18 4 -1.</_>
        <_>
          9 20 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 16 6 -1.</_>
        <_>
          6 8 16 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 9 6 -1.</_>
        <_>
          9 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 3 4 14 -1.</_>
        <_>
          10 10 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 15 12 -1.</_>
        <_>
          1 11 15 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 8 5 -1.</_>
        <_>
          11 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 9 -1.</_>
        <_>
          7 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 12 8 -1.</_>
        <_>
          5 5 6 4 2.</_>
        <_>
          11 9 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 12 11 6 -1.</_>
        <_>
          13 14 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 21 3 -1.</_>
        <_>
          0 14 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 8 12 -1.</_>
        <_>
          12 1 4 6 2.</_>
        <_>
          8 7 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 6 12 -1.</_>
        <_>
          1 0 3 6 2.</_>
        <_>
          4 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 21 2 -1.</_>
        <_>
          2 3 21 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 19 3 -1.</_>
        <_>
          2 3 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 10 6 14 -1.</_>
        <_>
          20 10 3 7 2.</_>
        <_>
          17 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 6 14 -1.</_>
        <_>
          1 10 3 7 2.</_>
        <_>
          4 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 14 14 -1.</_>
        <_>
          14 6 7 7 2.</_>
        <_>
          7 13 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 9 6 -1.</_>
        <_>
          0 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 8 9 -1.</_>
        <_>
          15 17 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 22 4 -1.</_>
        <_>
          1 1 11 2 2.</_>
        <_>
          12 3 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 9 6 -1.</_>
        <_>
          9 13 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 18 3 -1.</_>
        <_>
          0 16 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 14 7 9 -1.</_>
        <_>
          16 17 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 4 -1.</_>
        <_>
          12 3 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 5 -1.</_>
        <_>
          7 6 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 4 10 -1.</_>
        <_>
          12 1 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 4 10 -1.</_>
        <_>
          10 1 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 6 9 -1.</_>
        <_>
          15 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 6 9 -1.</_>
        <_>
          3 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 3 19 -1.</_>
        <_>
          16 1 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 6 9 -1.</_>
        <_>
          3 3 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 3 19 -1.</_>
        <_>
          16 0 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 12 4 -1.</_>
        <_>
          12 3 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 9 -1.</_>
        <_>
          10 5 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 3 19 -1.</_>
        <_>
          7 0 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 3 12 -1.</_>
        <_>
          11 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 5 -1.</_>
        <_>
          11 7 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 3 3 18 -1.</_>
        <_>
          12 3 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 3 6 12 -1.</_>
        <_>
          11 3 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 19 3 -1.</_>
        <_>
          3 8 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 18 3 -1.</_>
        <_>
          2 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 13 18 4 -1.</_>
        <_>
          12 13 9 2 2.</_>
        <_>
          3 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 6 9 -1.</_>
        <_>
          5 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 20 4 -1.</_>
        <_>
          14 1 10 2 2.</_>
        <_>
          4 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 20 4 -1.</_>
        <_>
          0 1 10 2 2.</_>
        <_>
          10 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 6 -1.</_>
        <_>
          10 15 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 8 -1.</_>
        <_>
          8 2 8 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 18 3 -1.</_>
        <_>
          5 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 6 6 -1.</_>
        <_>
          11 15 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 8 5 -1.</_>
        <_>
          11 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 12 8 5 -1.</_>
        <_>
          9 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 14 6 -1.</_>
        <_>
          5 2 14 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 5 12 -1.</_>
        <_>
          10 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 8 14 -1.</_>
        <_>
          7 9 4 7 2.</_>
        <_>
          11 16 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 22 6 -1.</_>
        <_>
          12 5 11 3 2.</_>
        <_>
          1 8 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 6 6 -1.</_>
        <_>
          0 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 4 -1.</_>
        <_>
          12 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 19 3 -1.</_>
        <_>
          2 19 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 4 -1.</_>
        <_>
          12 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 18 3 -1.</_>
        <_>
          1 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 4 -1.</_>
        <_>
          12 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 3 -1.</_>
        <_>
          0 1 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 14 4 -1.</_>
        <_>
          5 2 14 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 14 9 6 -1.</_>
        <_>
          6 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 6 9 -1.</_>
        <_>
          14 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 20 13 4 -1.</_>
        <_>
          5 22 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 12 -1.</_>
        <_>
          9 13 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 21 3 -1.</_>
        <_>
          8 10 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 9 6 -1.</_>
        <_>
          11 8 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 9 7 -1.</_>
        <_>
          6 10 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 10 8 -1.</_>
        <_>
          17 10 5 4 2.</_>
        <_>
          12 14 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 24 3 -1.</_>
        <_>
          8 15 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 9 6 -1.</_>
        <_>
          8 7 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 6 9 -1.</_>
        <_>
          4 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 4 -1.</_>
        <_>
          12 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 6 -1.</_>
        <_>
          9 15 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 14 10 -1.</_>
        <_>
          16 9 7 5 2.</_>
        <_>
          9 14 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 14 10 -1.</_>
        <_>
          1 9 7 5 2.</_>
        <_>
          8 14 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 9 17 -1.</_>
        <_>
          11 7 3 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 6 20 -1.</_>
        <_>
          3 4 3 10 2.</_>
        <_>
          6 14 3 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 4 9 -1.</_>
        <_>
          12 7 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 9 -1.</_>
        <_>
          12 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 6 16 -1.</_>
        <_>
          3 8 3 8 2.</_>
        <_>
          6 16 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 4 -1.</_>
        <_>
          12 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 9 4 -1.</_>
        <_>
          3 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 9 6 -1.</_>
        <_>
          13 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 4 10 -1.</_>
        <_>
          5 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 12 6 -1.</_>
        <_>
          11 5 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 9 8 -1.</_>
        <_>
          9 4 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 10 8 -1.</_>
        <_>
          17 16 5 4 2.</_>
        <_>
          12 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 10 8 -1.</_>
        <_>
          2 16 5 4 2.</_>
        <_>
          7 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 4 -1.</_>
        <_>
          12 0 12 2 2.</_>
        <_>
          0 2 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 9 6 -1.</_>
        <_>
          0 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 24 6 -1.</_>
        <_>
          12 4 12 3 2.</_>
        <_>
          0 7 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 11 4 -1.</_>
        <_>
          5 2 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 22 4 -1.</_>
        <_>
          12 1 11 2 2.</_>
        <_>
          1 3 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 18 -1.</_>
        <_>
          9 15 6 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 20 4 -1.</_>
        <_>
          2 11 20 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 14 14 -1.</_>
        <_>
          5 9 14 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 16 6 -1.</_>
        <_>
          4 5 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 19 3 -1.</_>
        <_>
          2 4 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 10 4 -1.</_>
        <_>
          7 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 4 15 -1.</_>
        <_>
          0 14 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 21 3 -1.</_>
        <_>
          2 11 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 6 -1.</_>
        <_>
          6 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 14 9 -1.</_>
        <_>
          6 7 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 9 -1.</_>
        <_>
          11 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 9 9 -1.</_>
        <_>
          15 11 9 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 4 21 -1.</_>
        <_>
          8 7 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 22 19 2 -1.</_>
        <_>
          3 23 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 20 3 -1.</_>
        <_>
          2 16 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 0 4 13 -1.</_>
        <_>
          19 0 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 8 8 -1.</_>
        <_>
          1 11 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 6 9 -1.</_>
        <_>
          14 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 6 9 -1.</_>
        <_>
          4 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 4 10 -1.</_>
        <_>
          14 5 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 4 10 -1.</_>
        <_>
          8 5 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 6 6 -1.</_>
        <_>
          14 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 6 6 -1.</_>
        <_>
          4 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 21 -1.</_>
        <_>
          8 2 8 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 6 13 -1.</_>
        <_>
          3 2 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 0 4 21 -1.</_>
        <_>
          20 0 2 21 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 4 20 -1.</_>
        <_>
          2 4 2 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 16 9 6 -1.</_>
        <_>
          8 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 12 7 9 -1.</_>
        <_>
          16 15 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 21 14 3 -1.</_>
        <_>
          12 21 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 5 6 9 -1.</_>
        <_>
          11 5 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 10 -1.</_>
        <_>
          12 5 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 9 -1.</_>
        <_>
          10 5 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 10 4 -1.</_>
        <_>
          14 16 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 14 14 -1.</_>
        <_>
          5 5 7 7 2.</_>
        <_>
          12 12 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 12 6 -1.</_>
        <_>
          18 8 6 3 2.</_>
        <_>
          12 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 12 -1.</_>
        <_>
          6 6 6 6 2.</_>
        <_>
          12 12 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 13 6 10 -1.</_>
        <_>
          13 13 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 20 8 -1.</_>
        <_>
          1 10 10 4 2.</_>
        <_>
          11 14 10 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 13 9 6 -1.</_>
        <_>
          15 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          9 3 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 5 14 -1.</_>
        <_>
          10 8 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 16 6 -1.</_>
        <_>
          3 6 16 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 3 8 9 -1.</_>
        <_>
          16 6 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 6 10 -1.</_>
        <_>
          9 13 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 13 9 6 -1.</_>
        <_>
          15 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 9 6 -1.</_>
        <_>
          0 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 16 9 6 -1.</_>
        <_>
          13 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 9 6 -1.</_>
        <_>
          2 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 16 18 3 -1.</_>
        <_>
          5 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 18 3 -1.</_>
        <_>
          1 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 18 3 -1.</_>
        <_>
          5 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 19 2 -1.</_>
        <_>
          1 2 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 2 6 11 -1.</_>
        <_>
          16 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 15 15 6 -1.</_>
        <_>
          9 15 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 2 6 11 -1.</_>
        <_>
          16 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 6 11 -1.</_>
        <_>
          6 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          18 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 4 -1.</_>
        <_>
          1 2 11 2 2.</_>
        <_>
          12 4 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 21 12 -1.</_>
        <_>
          9 0 7 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 18 3 -1.</_>
        <_>
          0 13 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 6 9 -1.</_>
        <_>
          14 2 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 18 3 -1.</_>
        <_>
          3 11 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 3 8 9 -1.</_>
        <_>
          16 6 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 3 -1.</_>
        <_>
          3 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 6 9 -1.</_>
        <_>
          11 11 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 6 9 -1.</_>
        <_>
          11 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 2 18 -1.</_>
        <_>
          15 0 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 2 18 -1.</_>
        <_>
          8 0 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 7 9 -1.</_>
        <_>
          17 6 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 9 6 -1.</_>
        <_>
          3 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 21 3 -1.</_>
        <_>
          3 19 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 7 9 -1.</_>
        <_>
          0 6 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 22 3 -1.</_>
        <_>
          2 8 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 16 -1.</_>
        <_>
          0 3 12 8 2.</_>
        <_>
          12 11 12 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 17 9 4 -1.</_>
        <_>
          13 19 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 12 8 -1.</_>
        <_>
          5 5 6 4 2.</_>
        <_>
          11 9 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          12 6 7 3 2.</_>
        <_>
          5 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 16 14 6 -1.</_>
        <_>
          5 16 7 3 2.</_>
        <_>
          12 19 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          18 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 9 -1.</_>
        <_>
          0 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 20 10 -1.</_>
        <_>
          13 4 10 5 2.</_>
        <_>
          3 9 10 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 9 8 -1.</_>
        <_>
          5 13 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 21 15 -1.</_>
        <_>
          9 1 7 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 12 14 8 -1.</_>
        <_>
          12 12 7 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 4 -1.</_>
        <_>
          6 7 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 9 6 -1.</_>
        <_>
          9 5 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 6 -1.</_>
        <_>
          13 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 6 -1.</_>
        <_>
          8 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 18 2 -1.</_>
        <_>
          6 5 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 11 -1.</_>
        <_>
          2 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 15 -1.</_>
        <_>
          20 0 2 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 13 -1.</_>
        <_>
          2 0 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          8 2 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 13 18 4 -1.</_>
        <_>
          12 13 9 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 10 4 -1.</_>
        <_>
          9 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 12 3 -1.</_>
        <_>
          11 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 19 3 -1.</_>
        <_>
          4 15 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 4 20 -1.</_>
        <_>
          10 10 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 9 6 -1.</_>
        <_>
          8 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 15 4 -1.</_>
        <_>
          7 9 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 12 7 -1.</_>
        <_>
          12 4 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 6 9 -1.</_>
        <_>
          0 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 5 6 9 -1.</_>
        <_>
          18 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 16 6 -1.</_>
        <_>
          0 18 8 3 2.</_>
        <_>
          8 21 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 18 14 6 -1.</_>
        <_>
          16 18 7 3 2.</_>
        <_>
          9 21 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 20 4 -1.</_>
        <_>
          1 20 10 2 2.</_>
        <_>
          11 22 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 20 6 -1.</_>
        <_>
          12 8 10 3 2.</_>
        <_>
          2 11 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 6 9 -1.</_>
        <_>
          9 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 12 8 -1.</_>
        <_>
          12 5 4 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 12 8 -1.</_>
        <_>
          8 5 4 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 6 16 -1.</_>
        <_>
          4 0 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 4 6 12 -1.</_>
        <_>
          15 8 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 6 12 -1.</_>
        <_>
          3 8 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 15 22 -1.</_>
        <_>
          4 11 15 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 9 6 -1.</_>
        <_>
          0 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 6 -1.</_>
        <_>
          0 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 8 10 -1.</_>
        <_>
          14 0 4 5 2.</_>
        <_>
          10 5 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 4 16 -1.</_>
        <_>
          3 0 2 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 12 4 10 -1.</_>
        <_>
          10 17 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 10 6 -1.</_>
        <_>
          8 6 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 22 18 2 -1.</_>
        <_>
          12 22 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 11 6 -1.</_>
        <_>
          7 9 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 12 10 -1.</_>
        <_>
          0 0 6 5 2.</_>
        <_>
          6 5 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 12 6 -1.</_>
        <_>
          16 1 6 3 2.</_>
        <_>
          10 4 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 16 9 4 -1.</_>
        <_>
          7 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 15 16 -1.</_>
        <_>
          10 7 5 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 12 13 -1.</_>
        <_>
          11 10 6 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 12 6 -1.</_>
        <_>
          12 2 6 3 2.</_>
        <_>
          6 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 12 9 -1.</_>
        <_>
          3 12 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 2 8 6 -1.</_>
        <_>
          16 5 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 8 6 -1.</_>
        <_>
          0 5 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 11 -1.</_>
        <_>
          0 3 12 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 8 10 -1.</_>
        <_>
          0 13 4 5 2.</_>
        <_>
          4 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 4 10 -1.</_>
        <_>
          10 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 21 -1.</_>
        <_>
          10 9 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 15 9 -1.</_>
        <_>
          4 7 15 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 6 -1.</_>
        <_>
          8 1 8 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 5 16 -1.</_>
        <_>
          9 14 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          9 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 3 12 -1.</_>
        <_>
          6 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 9 8 -1.</_>
        <_>
          8 6 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 20 2 -1.</_>
        <_>
          4 4 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 18 3 -1.</_>
        <_>
          8 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 15 10 6 -1.</_>
        <_>
          7 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 4 18 -1.</_>
        <_>
          1 4 2 9 2.</_>
        <_>
          3 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 9 -1.</_>
        <_>
          15 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 9 -1.</_>
        <_>
          7 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 9 6 -1.</_>
        <_>
          9 7 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 2 -1.</_>
        <_>
          3 1 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 20 4 -1.</_>
        <_>
          0 10 10 2 2.</_>
        <_>
          10 12 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 12 -1.</_>
        <_>
          10 8 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 6 12 -1.</_>
        <_>
          6 5 3 6 2.</_>
        <_>
          9 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 22 -1.</_>
        <_>
          15 0 9 11 2.</_>
        <_>
          6 11 9 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 18 22 -1.</_>
        <_>
          0 0 9 11 2.</_>
        <_>
          9 11 9 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 11 -1.</_>
        <_>
          20 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 11 -1.</_>
        <_>
          2 2 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 20 3 -1.</_>
        <_>
          0 1 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 20 2 -1.</_>
        <_>
          2 3 20 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 2 -1.</_>
        <_>
          1 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 7 6 9 -1.</_>
        <_>
          18 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 22 9 -1.</_>
        <_>
          0 3 22 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 6 9 -1.</_>
        <_>
          17 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 6 9 -1.</_>
        <_>
          0 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 6 -1.</_>
        <_>
          0 8 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 10 -1.</_>
        <_>
          2 2 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 9 -1.</_>
        <_>
          17 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 9 -1.</_>
        <_>
          5 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 9 6 -1.</_>
        <_>
          15 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 23 6 -1.</_>
        <_>
          0 17 23 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 15 18 3 -1.</_>
        <_>
          5 16 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 6 -1.</_>
        <_>
          0 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 8 10 -1.</_>
        <_>
          13 8 4 5 2.</_>
        <_>
          9 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 15 6 -1.</_>
        <_>
          8 7 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 8 10 -1.</_>
        <_>
          13 8 4 5 2.</_>
        <_>
          9 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 12 -1.</_>
        <_>
          8 0 3 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 8 10 -1.</_>
        <_>
          13 8 4 5 2.</_>
        <_>
          9 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 6 9 -1.</_>
        <_>
          10 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          12 6 2 9 2.</_>
        <_>
          10 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 12 4 -1.</_>
        <_>
          11 7 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 8 10 -1.</_>
        <_>
          13 8 4 5 2.</_>
        <_>
          9 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 8 10 -1.</_>
        <_>
          7 8 4 5 2.</_>
        <_>
          11 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 10 6 14 -1.</_>
        <_>
          14 10 3 7 2.</_>
        <_>
          11 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 6 19 -1.</_>
        <_>
          12 5 3 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 12 12 6 -1.</_>
        <_>
          12 12 6 3 2.</_>
        <_>
          6 15 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 18 6 -1.</_>
        <_>
          1 9 9 3 2.</_>
        <_>
          10 12 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 14 8 10 -1.</_>
        <_>
          20 14 4 5 2.</_>
        <_>
          16 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 22 8 -1.</_>
        <_>
          0 9 11 4 2.</_>
        <_>
          11 13 11 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 12 6 -1.</_>
        <_>
          14 18 6 3 2.</_>
        <_>
          8 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 20 18 -1.</_>
        <_>
          0 6 10 9 2.</_>
        <_>
          10 15 10 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 20 12 -1.</_>
        <_>
          13 6 10 6 2.</_>
        <_>
          3 12 10 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 10 8 -1.</_>
        <_>
          0 16 5 4 2.</_>
        <_>
          5 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 19 3 -1.</_>
        <_>
          0 12 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 6 6 9 -1.</_>
        <_>
          14 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 22 4 -1.</_>
        <_>
          1 7 11 2 2.</_>
        <_>
          12 9 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 6 7 12 -1.</_>
        <_>
          13 10 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 11 9 -1.</_>
        <_>
          4 10 11 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 10 8 -1.</_>
        <_>
          17 10 5 4 2.</_>
        <_>
          12 14 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 9 7 -1.</_>
        <_>
          5 12 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 14 6 9 -1.</_>
        <_>
          16 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 6 12 -1.</_>
        <_>
          3 16 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 6 6 -1.</_>
        <_>
          14 16 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 6 9 -1.</_>
        <_>
          10 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 23 -1.</_>
        <_>
          11 1 2 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 6 -1.</_>
        <_>
          0 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 17 18 3 -1.</_>
        <_>
          4 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 13 14 -1.</_>
        <_>
          5 9 13 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 8 12 -1.</_>
        <_>
          19 0 4 6 2.</_>
        <_>
          15 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 8 12 -1.</_>
        <_>
          0 0 4 6 2.</_>
        <_>
          4 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 8 7 -1.</_>
        <_>
          8 2 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 6 9 -1.</_>
        <_>
          3 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 8 6 12 -1.</_>
        <_>
          17 8 3 6 2.</_>
        <_>
          14 14 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 8 6 12 -1.</_>
        <_>
          4 8 3 6 2.</_>
        <_>
          7 14 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 5 5 15 -1.</_>
        <_>
          16 10 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 5 15 -1.</_>
        <_>
          3 10 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 9 -1.</_>
        <_>
          18 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 6 15 -1.</_>
        <_>
          1 12 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 15 12 8 -1.</_>
        <_>
          17 15 6 4 2.</_>
        <_>
          11 19 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          0 2 12 2 2.</_>
        <_>
          12 4 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 2 19 -1.</_>
        <_>
          15 1 1 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 2 19 -1.</_>
        <_>
          8 1 1 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          22 1 2 20 -1.</_>
        <_>
          22 1 1 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 2 20 -1.</_>
        <_>
          1 1 1 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 11 6 12 -1.</_>
        <_>
          20 11 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 6 12 -1.</_>
        <_>
          2 11 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 14 -1.</_>
        <_>
          3 13 18 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 10 7 8 -1.</_>
        <_>
          6 14 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 12 12 -1.</_>
        <_>
          7 13 12 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 18 5 -1.</_>
        <_>
          11 18 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 21 20 3 -1.</_>
        <_>
          4 22 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 12 -1.</_>
        <_>
          9 12 3 6 2.</_>
        <_>
          12 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 18 3 -1.</_>
        <_>
          4 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 3 -1.</_>
        <_>
          3 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 9 -1.</_>
        <_>
          18 7 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 9 6 -1.</_>
        <_>
          2 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 18 4 -1.</_>
        <_>
          13 14 9 2 2.</_>
        <_>
          4 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 6 14 -1.</_>
        <_>
          7 7 3 7 2.</_>
        <_>
          10 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 12 6 -1.</_>
        <_>
          13 13 6 3 2.</_>
        <_>
          7 16 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 9 -1.</_>
        <_>
          10 7 4 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 12 6 6 -1.</_>
        <_>
          12 12 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 4 10 -1.</_>
        <_>
          0 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 9 6 -1.</_>
        <_>
          11 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 12 6 -1.</_>
        <_>
          2 12 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 6 9 -1.</_>
        <_>
          13 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 6 9 -1.</_>
        <_>
          5 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 9 6 -1.</_>
        <_>
          9 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 16 12 6 -1.</_>
        <_>
          5 19 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 20 3 -1.</_>
        <_>
          3 3 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 12 6 -1.</_>
        <_>
          6 5 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 3 24 -1.</_>
        <_>
          12 0 1 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 15 4 -1.</_>
        <_>
          8 16 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 12 -1.</_>
        <_>
          9 18 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 12 8 -1.</_>
        <_>
          1 15 6 4 2.</_>
        <_>
          7 19 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 10 8 14 -1.</_>
        <_>
          19 10 4 7 2.</_>
        <_>
          15 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 8 14 -1.</_>
        <_>
          1 9 4 7 2.</_>
        <_>
          5 16 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 9 10 -1.</_>
        <_>
          9 16 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 6 -1.</_>
        <_>
          6 9 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 9 -1.</_>
        <_>
          12 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 9 7 -1.</_>
        <_>
          10 8 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 4 8 10 -1.</_>
        <_>
          14 4 4 5 2.</_>
        <_>
          10 9 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 6 9 -1.</_>
        <_>
          4 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 12 -1.</_>
        <_>
          8 6 8 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 6 14 -1.</_>
        <_>
          6 7 3 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 8 5 8 -1.</_>
        <_>
          19 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 5 8 -1.</_>
        <_>
          0 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 6 6 -1.</_>
        <_>
          17 6 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 6 6 -1.</_>
        <_>
          1 6 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 9 -1.</_>
        <_>
          18 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 9 -1.</_>
        <_>
          0 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 18 6 -1.</_>
        <_>
          3 5 18 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 9 6 -1.</_>
        <_>
          2 5 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 3 10 8 -1.</_>
        <_>
          14 3 5 4 2.</_>
        <_>
          9 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 3 10 8 -1.</_>
        <_>
          5 3 5 4 2.</_>
        <_>
          10 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 11 6 12 -1.</_>
        <_>
          10 11 3 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 11 6 11 -1.</_>
        <_>
          11 11 3 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 7 -1.</_>
        <_>
          12 6 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 18 18 3 -1.</_>
        <_>
          5 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 6 9 -1.</_>
        <_>
          10 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 9 7 -1.</_>
        <_>
          11 1 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 6 6 -1.</_>
        <_>
          9 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 4 11 -1.</_>
        <_>
          14 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 12 4 11 -1.</_>
        <_>
          8 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 12 18 -1.</_>
        <_>
          12 0 4 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 10 5 -1.</_>
        <_>
          7 12 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 20 22 3 -1.</_>
        <_>
          2 21 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 2 20 -1.</_>
        <_>
          1 4 1 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 4 -1.</_>
        <_>
          8 2 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 10 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 8 10 -1.</_>
        <_>
          6 7 4 5 2.</_>
        <_>
          10 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 14 -1.</_>
        <_>
          17 0 3 7 2.</_>
        <_>
          14 7 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 11 5 8 -1.</_>
        <_>
          4 15 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 20 9 -1.</_>
        <_>
          2 3 20 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 8 -1.</_>
        <_>
          6 7 6 4 2.</_>
        <_>
          12 11 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 17 6 6 -1.</_>
        <_>
          9 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 10 10 4 -1.</_>
        <_>
          7 12 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 9 -1.</_>
        <_>
          10 5 4 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 8 -1.</_>
        <_>
          8 11 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 4 17 -1.</_>
        <_>
          18 4 2 17 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 6 -1.</_>
        <_>
          3 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 4 17 -1.</_>
        <_>
          18 4 2 17 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 4 17 -1.</_>
        <_>
          4 4 2 17 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 18 19 3 -1.</_>
        <_>
          5 19 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 2 18 -1.</_>
        <_>
          11 9 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 4 2 18 -1.</_>
        <_>
          15 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 2 18 -1.</_>
        <_>
          7 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 11 10 8 -1.</_>
        <_>
          12 11 5 4 2.</_>
        <_>
          7 15 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          12 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 9 -1.</_>
        <_>
          12 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 16 8 -1.</_>
        <_>
          2 9 8 4 2.</_>
        <_>
          10 13 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 6 9 -1.</_>
        <_>
          14 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 6 9 -1.</_>
        <_>
          10 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 6 9 -1.</_>
        <_>
          14 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 12 6 -1.</_>
        <_>
          3 14 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 9 6 -1.</_>
        <_>
          14 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 9 6 -1.</_>
        <_>
          1 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 3 -1.</_>
        <_>
          3 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 22 6 -1.</_>
        <_>
          1 9 22 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 4 6 6 -1.</_>
        <_>
          18 7 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 6 6 -1.</_>
        <_>
          0 7 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 16 6 -1.</_>
        <_>
          5 14 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 9 4 -1.</_>
        <_>
          6 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 6 9 -1.</_>
        <_>
          14 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 15 6 9 -1.</_>
        <_>
          4 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 6 23 -1.</_>
        <_>
          17 1 2 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 21 24 3 -1.</_>
        <_>
          8 21 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 24 4 -1.</_>
        <_>
          8 20 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 6 23 -1.</_>
        <_>
          5 1 2 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 18 3 -1.</_>
        <_>
          3 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 22 4 -1.</_>
        <_>
          12 16 11 2 2.</_>
        <_>
          1 18 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 6 -1.</_>
        <_>
          0 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 21 3 -1.</_>
        <_>
          9 10 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 12 6 -1.</_>
        <_>
          2 18 6 3 2.</_>
        <_>
          8 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 24 4 -1.</_>
        <_>
          0 7 24 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 6 12 -1.</_>
        <_>
          10 13 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 6 9 -1.</_>
        <_>
          8 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 6 9 -1.</_>
        <_>
          11 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 20 3 -1.</_>
        <_>
          2 2 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 12 6 -1.</_>
        <_>
          1 18 6 3 2.</_>
        <_>
          7 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 2 4 13 -1.</_>
        <_>
          13 2 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 4 -1.</_>
        <_>
          12 7 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 4 13 -1.</_>
        <_>
          10 1 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 3 18 -1.</_>
        <_>
          7 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 3 10 5 -1.</_>
        <_>
          14 3 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 15 12 8 -1.</_>
        <_>
          10 15 4 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 9 -1.</_>
        <_>
          11 10 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 4 9 -1.</_>
        <_>
          10 3 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 6 14 -1.</_>
        <_>
          20 0 3 7 2.</_>
        <_>
          17 7 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 6 14 -1.</_>
        <_>
          1 0 3 7 2.</_>
        <_>
          4 7 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 16 -1.</_>
        <_>
          17 0 3 8 2.</_>
        <_>
          14 8 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 4 10 -1.</_>
        <_>
          9 4 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 18 6 -1.</_>
        <_>
          12 17 9 3 2.</_>
        <_>
          3 20 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 22 4 -1.</_>
        <_>
          12 20 11 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 3 10 5 -1.</_>
        <_>
          14 3 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 10 5 -1.</_>
        <_>
          5 3 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 12 16 -1.</_>
        <_>
          16 6 4 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 12 16 -1.</_>
        <_>
          4 6 4 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 9 5 15 -1.</_>
        <_>
          10 14 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 21 2 -1.</_>
        <_>
          1 19 21 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 9 6 -1.</_>
        <_>
          15 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 12 4 -1.</_>
        <_>
          12 1 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 12 -1.</_>
        <_>
          12 0 6 6 2.</_>
        <_>
          6 6 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 10 8 12 -1.</_>
        <_>
          8 10 4 6 2.</_>
        <_>
          12 16 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 16 10 8 -1.</_>
        <_>
          19 16 5 4 2.</_>
        <_>
          14 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 10 8 -1.</_>
        <_>
          0 16 5 4 2.</_>
        <_>
          5 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 12 12 5 -1.</_>
        <_>
          14 12 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 10 8 -1.</_>
        <_>
          6 16 5 4 2.</_>
        <_>
          11 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 6 -1.</_>
        <_>
          13 6 6 3 2.</_>
        <_>
          7 9 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 4 18 -1.</_>
        <_>
          9 6 2 9 2.</_>
        <_>
          11 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 9 6 14 -1.</_>
        <_>
          13 9 3 7 2.</_>
        <_>
          10 16 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 6 14 -1.</_>
        <_>
          8 9 3 7 2.</_>
        <_>
          11 16 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 11 12 -1.</_>
        <_>
          7 10 11 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 8 6 16 -1.</_>
        <_>
          4 8 3 8 2.</_>
        <_>
          7 16 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 3 4 21 -1.</_>
        <_>
          17 10 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 4 21 -1.</_>
        <_>
          3 10 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 8 18 -1.</_>
        <_>
          14 1 4 9 2.</_>
        <_>
          10 10 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 16 8 -1.</_>
        <_>
          2 5 8 4 2.</_>
        <_>
          10 9 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 12 -1.</_>
        <_>
          3 10 18 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 16 12 -1.</_>
        <_>
          4 14 16 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 4 8 20 -1.</_>
        <_>
          19 4 4 10 2.</_>
        <_>
          15 14 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 9 6 -1.</_>
        <_>
          10 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 4 8 20 -1.</_>
        <_>
          19 4 4 10 2.</_>
        <_>
          15 14 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 8 20 -1.</_>
        <_>
          1 4 4 10 2.</_>
        <_>
          5 14 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 8 8 14 -1.</_>
        <_>
          15 8 4 7 2.</_>
        <_>
          11 15 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 8 14 -1.</_>
        <_>
          5 8 4 7 2.</_>
        <_>
          9 15 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 13 5 8 -1.</_>
        <_>
          10 17 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 7 9 -1.</_>
        <_>
          4 16 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 24 10 -1.</_>
        <_>
          0 18 24 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 8 11 -1.</_>
        <_>
          8 2 4 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 8 16 -1.</_>
        <_>
          14 2 4 8 2.</_>
        <_>
          10 10 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 6 -1.</_>
        <_>
          0 2 12 3 2.</_>
        <_>
          12 5 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 9 -1.</_>
        <_>
          6 3 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 12 12 -1.</_>
        <_>
          1 2 6 6 2.</_>
        <_>
          7 8 6 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 5 6 9 -1.</_>
        <_>
          18 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 8 10 -1.</_>
        <_>
          4 3 4 5 2.</_>
        <_>
          8 8 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 21 18 3 -1.</_>
        <_>
          6 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 2 -1.</_>
        <_>
          1 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 22 3 -1.</_>
        <_>
          1 11 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 12 9 -1.</_>
        <_>
          2 11 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 12 6 -1.</_>
        <_>
          18 8 6 3 2.</_>
        <_>
          12 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 12 6 -1.</_>
        <_>
          0 8 6 3 2.</_>
        <_>
          6 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 9 -1.</_>
        <_>
          12 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 9 6 -1.</_>
        <_>
          7 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 7 12 -1.</_>
        <_>
          9 14 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 9 6 -1.</_>
        <_>
          7 13 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 15 18 4 -1.</_>
        <_>
          12 15 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 4 16 -1.</_>
        <_>
          7 4 2 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 9 -1.</_>
        <_>
          12 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 6 9 -1.</_>
        <_>
          10 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 12 10 -1.</_>
        <_>
          15 11 6 5 2.</_>
        <_>
          9 16 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 14 6 -1.</_>
        <_>
          3 8 14 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 17 8 -1.</_>
        <_>
          4 6 17 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 12 21 -1.</_>
        <_>
          6 9 12 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 9 9 -1.</_>
        <_>
          8 4 9 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 24 3 -1.</_>
        <_>
          12 7 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 9 10 -1.</_>
        <_>
          11 11 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 18 3 -1.</_>
        <_>
          2 12 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 16 9 4 -1.</_>
        <_>
          8 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 9 6 -1.</_>
        <_>
          0 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 24 6 -1.</_>
        <_>
          0 13 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 9 20 6 -1.</_>
        <_>
          2 12 20 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 16 12 -1.</_>
        <_>
          12 5 8 6 2.</_>
        <_>
          4 11 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 10 4 -1.</_>
        <_>
          7 5 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 6 8 -1.</_>
        <_>
          9 19 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 7 10 -1.</_>
        <_>
          17 5 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 7 10 -1.</_>
        <_>
          0 5 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 1 6 12 -1.</_>
        <_>
          19 1 3 6 2.</_>
        <_>
          16 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 19 8 -1.</_>
        <_>
          1 4 19 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 9 4 -1.</_>
        <_>
          12 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 9 4 -1.</_>
        <_>
          3 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 10 6 -1.</_>
        <_>
          12 4 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 18 2 -1.</_>
        <_>
          12 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 4 9 -1.</_>
        <_>
          12 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 4 9 -1.</_>
        <_>
          10 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 8 10 -1.</_>
        <_>
          14 5 4 5 2.</_>
        <_>
          10 10 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 13 -1.</_>
        <_>
          10 4 4 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 5 6 6 -1.</_>
        <_>
          13 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 12 3 -1.</_>
        <_>
          7 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 10 6 -1.</_>
        <_>
          7 7 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 21 5 -1.</_>
        <_>
          9 0 7 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 9 9 -1.</_>
        <_>
          0 11 9 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 7 -1.</_>
        <_>
          3 3 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 18 12 6 -1.</_>
        <_>
          15 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 20 6 -1.</_>
        <_>
          2 8 10 3 2.</_>
        <_>
          12 11 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 2 10 4 -1.</_>
        <_>
          13 4 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 5 18 -1.</_>
        <_>
          4 11 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 4 4 9 -1.</_>
        <_>
          20 4 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 8 14 -1.</_>
        <_>
          8 13 8 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 6 -1.</_>
        <_>
          12 1 12 3 2.</_>
        <_>
          0 4 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 4 9 -1.</_>
        <_>
          2 4 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 3 -1.</_>
        <_>
          3 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 16 6 -1.</_>
        <_>
          3 19 16 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 6 6 9 -1.</_>
        <_>
          13 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          5 6 7 3 2.</_>
        <_>
          12 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 5 8 10 -1.</_>
        <_>
          17 5 4 5 2.</_>
        <_>
          13 10 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 20 3 -1.</_>
        <_>
          2 3 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 9 6 -1.</_>
        <_>
          12 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 3 4 11 -1.</_>
        <_>
          12 3 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 4 11 -1.</_>
        <_>
          10 3 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 8 10 -1.</_>
        <_>
          12 3 4 5 2.</_>
        <_>
          8 8 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 2 18 -1.</_>
        <_>
          12 1 1 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 9 6 -1.</_>
        <_>
          12 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 19 3 -1.</_>
        <_>
          0 3 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 9 6 -1.</_>
        <_>
          9 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 18 5 -1.</_>
        <_>
          7 8 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 6 4 15 -1.</_>
        <_>
          13 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 18 3 -1.</_>
        <_>
          1 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 14 6 -1.</_>
        <_>
          9 9 14 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 18 3 -1.</_>
        <_>
          2 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 12 6 -1.</_>
        <_>
          0 8 6 3 2.</_>
        <_>
          6 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 13 7 8 -1.</_>
        <_>
          9 17 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 17 20 3 -1.</_>
        <_>
          2 18 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 15 4 -1.</_>
        <_>
          4 2 15 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 2 6 6 -1.</_>
        <_>
          17 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          0 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 9 6 -1.</_>
        <_>
          0 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 18 12 6 -1.</_>
        <_>
          15 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 6 9 -1.</_>
        <_>
          3 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 13 8 10 -1.</_>
        <_>
          20 13 4 5 2.</_>
        <_>
          16 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 24 4 -1.</_>
        <_>
          8 14 8 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 18 6 6 -1.</_>
        <_>
          13 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 8 10 -1.</_>
        <_>
          0 13 4 5 2.</_>
        <_>
          4 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 24 6 -1.</_>
        <_>
          0 17 24 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 12 8 -1.</_>
        <_>
          5 2 6 4 2.</_>
        <_>
          11 6 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 9 6 -1.</_>
        <_>
          11 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 4 -1.</_>
        <_>
          4 5 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 10 -1.</_>
        <_>
          10 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 4 5 8 -1.</_>
        <_>
          8 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 5 9 12 -1.</_>
        <_>
          11 9 9 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 9 12 -1.</_>
        <_>
          4 9 9 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 6 6 9 -1.</_>
        <_>
          14 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 20 12 -1.</_>
        <_>
          2 8 20 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 17 16 -1.</_>
        <_>
          4 12 17 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 7 6 -1.</_>
        <_>
          8 10 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 23 2 -1.</_>
        <_>
          1 10 23 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 3 4 9 -1.</_>
        <_>
          13 3 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 6 13 -1.</_>
        <_>
          10 1 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 22 18 2 -1.</_>
        <_>
          4 23 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 9 6 -1.</_>
        <_>
          6 10 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 2 24 -1.</_>
        <_>
          14 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 2 24 -1.</_>
        <_>
          9 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 18 10 -1.</_>
        <_>
          9 2 6 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 15 6 -1.</_>
        <_>
          9 13 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          9 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 4 11 -1.</_>
        <_>
          11 1 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 10 4 -1.</_>
        <_>
          9 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 18 -1.</_>
        <_>
          12 0 5 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 6 16 -1.</_>
        <_>
          14 1 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 6 16 -1.</_>
        <_>
          8 1 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 6 -1.</_>
        <_>
          18 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 18 2 -1.</_>
        <_>
          3 6 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 6 -1.</_>
        <_>
          18 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 6 -1.</_>
        <_>
          0 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 11 6 -1.</_>
        <_>
          13 13 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 10 4 -1.</_>
        <_>
          10 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 10 7 -1.</_>
        <_>
          11 9 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 10 7 -1.</_>
        <_>
          8 9 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 4 6 6 -1.</_>
        <_>
          16 4 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 10 8 -1.</_>
        <_>
          5 6 5 4 2.</_>
        <_>
          10 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 21 16 3 -1.</_>
        <_>
          7 21 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 21 16 3 -1.</_>
        <_>
          9 21 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 22 14 -1.</_>
        <_>
          13 5 11 7 2.</_>
        <_>
          2 12 11 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 8 10 -1.</_>
        <_>
          3 10 4 5 2.</_>
        <_>
          7 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 6 12 -1.</_>
        <_>
          20 0 3 6 2.</_>
        <_>
          17 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 6 18 -1.</_>
        <_>
          7 2 2 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 9 -1.</_>
        <_>
          15 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 7 9 -1.</_>
        <_>
          0 15 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 13 8 10 -1.</_>
        <_>
          19 13 4 5 2.</_>
        <_>
          15 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 6 12 -1.</_>
        <_>
          1 0 3 6 2.</_>
        <_>
          4 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 8 10 -1.</_>
        <_>
          1 13 4 5 2.</_>
        <_>
          5 18 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 19 2 -1.</_>
        <_>
          3 22 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 4 13 -1.</_>
        <_>
          8 3 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 18 3 -1.</_>
        <_>
          5 11 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 3 5 12 -1.</_>
        <_>
          9 7 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 4 15 -1.</_>
        <_>
          11 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 16 4 -1.</_>
        <_>
          4 3 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 3 -1.</_>
        <_>
          6 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 10 8 -1.</_>
        <_>
          5 1 5 4 2.</_>
        <_>
          10 5 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 18 12 6 -1.</_>
        <_>
          17 18 6 3 2.</_>
        <_>
          11 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 15 12 3 -1.</_>
        <_>
          11 15 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 22 4 -1.</_>
        <_>
          1 10 11 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 9 6 -1.</_>
        <_>
          10 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 12 5 -1.</_>
        <_>
          10 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 7 -1.</_>
        <_>
          11 7 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 8 10 -1.</_>
        <_>
          11 2 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 8 10 -1.</_>
        <_>
          9 2 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 18 6 -1.</_>
        <_>
          15 4 9 3 2.</_>
        <_>
          6 7 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 10 9 -1.</_>
        <_>
          0 8 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 21 6 -1.</_>
        <_>
          2 9 21 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 22 16 -1.</_>
        <_>
          0 4 11 8 2.</_>
        <_>
          11 12 11 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 22 -1.</_>
        <_>
          9 11 6 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 3 12 -1.</_>
        <_>
          9 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 12 18 -1.</_>
        <_>
          18 0 6 9 2.</_>
        <_>
          12 9 6 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 12 18 -1.</_>
        <_>
          0 0 6 9 2.</_>
        <_>
          6 9 6 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 22 4 -1.</_>
        <_>
          12 1 11 2 2.</_>
        <_>
          1 3 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 4 -1.</_>
        <_>
          3 2 18 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 22 6 -1.</_>
        <_>
          2 7 22 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 9 -1.</_>
        <_>
          5 3 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 6 9 -1.</_>
        <_>
          12 14 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 6 9 -1.</_>
        <_>
          10 14 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 18 18 3 -1.</_>
        <_>
          5 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 13 -1.</_>
        <_>
          9 0 3 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 4 12 4 -1.</_>
        <_>
          7 4 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 12 6 -1.</_>
        <_>
          9 2 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 18 3 -1.</_>
        <_>
          4 2 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 6 12 -1.</_>
        <_>
          0 12 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 6 9 -1.</_>
        <_>
          11 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 13 -1.</_>
        <_>
          11 10 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 17 18 2 -1.</_>
        <_>
          6 18 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 6 9 -1.</_>
        <_>
          11 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 9 -1.</_>
        <_>
          12 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 10 8 -1.</_>
        <_>
          5 6 5 4 2.</_>
        <_>
          10 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 9 5 8 -1.</_>
        <_>
          14 13 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 5 8 -1.</_>
        <_>
          5 13 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 11 9 6 -1.</_>
        <_>
          14 13 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 23 15 -1.</_>
        <_>
          0 7 23 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 8 12 -1.</_>
        <_>
          16 6 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 15 6 9 -1.</_>
        <_>
          4 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 9 4 -1.</_>
        <_>
          8 20 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 11 6 -1.</_>
        <_>
          13 13 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 11 6 -1.</_>
        <_>
          0 13 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 24 6 -1.</_>
        <_>
          12 9 12 3 2.</_>
        <_>
          0 12 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 8 8 -1.</_>
        <_>
          6 20 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 16 14 6 -1.</_>
        <_>
          10 18 14 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 21 3 -1.</_>
        <_>
          1 2 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 3 -1.</_>
        <_>
          0 2 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 8 5 -1.</_>
        <_>
          6 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 21 3 -1.</_>
        <_>
          9 11 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 12 6 -1.</_>
        <_>
          1 18 6 3 2.</_>
        <_>
          7 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 4 10 -1.</_>
        <_>
          10 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 4 10 -1.</_>
        <_>
          7 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 6 12 -1.</_>
        <_>
          9 12 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 9 6 -1.</_>
        <_>
          10 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 19 2 -1.</_>
        <_>
          3 15 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 10 10 -1.</_>
        <_>
          7 7 5 5 2.</_>
        <_>
          12 12 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 18 12 -1.</_>
        <_>
          3 12 9 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 6 12 -1.</_>
        <_>
          10 0 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 17 9 -1.</_>
        <_>
          3 3 17 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 11 -1.</_>
        <_>
          10 0 4 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 6 13 -1.</_>
        <_>
          4 0 3 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 16 6 -1.</_>
        <_>
          5 11 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 5 12 -1.</_>
        <_>
          8 14 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          9 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 6 -1.</_>
        <_>
          3 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 20 3 -1.</_>
        <_>
          2 1 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 15 10 -1.</_>
        <_>
          9 6 5 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 9 -1.</_>
        <_>
          16 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 16 9 6 -1.</_>
        <_>
          7 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 9 -1.</_>
        <_>
          16 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 6 9 -1.</_>
        <_>
          6 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 1 6 16 -1.</_>
        <_>
          19 1 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 6 16 -1.</_>
        <_>
          3 1 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 6 9 -1.</_>
        <_>
          14 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 9 -1.</_>
        <_>
          0 3 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 6 6 -1.</_>
        <_>
          9 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 9 6 -1.</_>
        <_>
          6 10 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 7 3 16 -1.</_>
        <_>
          14 15 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 14 12 -1.</_>
        <_>
          4 10 7 6 2.</_>
        <_>
          11 16 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 6 -1.</_>
        <_>
          7 8 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 4 20 -1.</_>
        <_>
          9 2 2 20 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 6 9 -1.</_>
        <_>
          14 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          12 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 6 9 -1.</_>
        <_>
          14 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 20 14 4 -1.</_>
        <_>
          5 22 14 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 16 12 -1.</_>
        <_>
          4 10 16 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 21 4 -1.</_>
        <_>
          3 2 21 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 6 9 -1.</_>
        <_>
          4 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 16 5 8 -1.</_>
        <_>
          16 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 16 16 -1.</_>
        <_>
          4 0 8 8 2.</_>
        <_>
          12 8 8 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 14 6 -1.</_>
        <_>
          13 6 7 3 2.</_>
        <_>
          6 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 15 -1.</_>
        <_>
          10 10 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 12 8 -1.</_>
        <_>
          15 15 6 4 2.</_>
        <_>
          9 19 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 4 -1.</_>
        <_>
          12 7 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          12 6 7 3 2.</_>
        <_>
          5 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 10 -1.</_>
        <_>
          3 6 9 5 2.</_>
        <_>
          12 11 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 21 -1.</_>
        <_>
          12 0 6 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 21 -1.</_>
        <_>
          8 0 8 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 3 -1.</_>
        <_>
          6 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 6 -1.</_>
        <_>
          0 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 19 2 -1.</_>
        <_>
          4 4 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 2 -1.</_>
        <_>
          0 4 24 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 9 4 -1.</_>
        <_>
          15 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 4 -1.</_>
        <_>
          0 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 15 18 2 -1.</_>
        <_>
          6 16 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 18 3 -1.</_>
        <_>
          3 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 3 23 -1.</_>
        <_>
          13 0 1 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 8 6 -1.</_>
        <_>
          6 3 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 3 23 -1.</_>
        <_>
          10 0 1 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 4 10 -1.</_>
        <_>
          10 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 12 -1.</_>
        <_>
          7 12 10 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 9 6 14 -1.</_>
        <_>
          17 9 3 7 2.</_>
        <_>
          14 16 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 10 9 -1.</_>
        <_>
          2 3 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 5 12 -1.</_>
        <_>
          11 7 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 12 10 -1.</_>
        <_>
          1 4 6 5 2.</_>
        <_>
          7 9 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 9 4 -1.</_>
        <_>
          15 3 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 8 10 -1.</_>
        <_>
          1 2 4 5 2.</_>
        <_>
          5 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 5 12 -1.</_>
        <_>
          10 5 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 14 24 -1.</_>
        <_>
          11 0 7 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 17 10 4 -1.</_>
        <_>
          7 19 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 4 10 -1.</_>
        <_>
          10 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 15 6 9 -1.</_>
        <_>
          15 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          3 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 15 6 9 -1.</_>
        <_>
          15 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 15 6 9 -1.</_>
        <_>
          7 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          12 6 2 9 2.</_>
        <_>
          10 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 6 11 -1.</_>
        <_>
          9 3 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 9 4 -1.</_>
        <_>
          15 3 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 14 8 -1.</_>
        <_>
          5 8 14 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 15 9 -1.</_>
        <_>
          8 4 15 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 8 10 -1.</_>
        <_>
          7 2 4 5 2.</_>
        <_>
          11 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 6 12 -1.</_>
        <_>
          12 2 3 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 6 12 -1.</_>
        <_>
          9 2 3 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 12 4 -1.</_>
        <_>
          7 7 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 12 10 -1.</_>
        <_>
          10 3 4 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 16 6 -1.</_>
        <_>
          13 6 8 3 2.</_>
        <_>
          5 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 9 -1.</_>
        <_>
          9 1 6 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 18 5 -1.</_>
        <_>
          9 8 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 22 -1.</_>
        <_>
          0 0 12 11 2.</_>
        <_>
          12 11 12 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 16 9 6 -1.</_>
        <_>
          14 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 24 8 -1.</_>
        <_>
          0 20 24 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 19 22 4 -1.</_>
        <_>
          12 19 11 2 2.</_>
        <_>
          1 21 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 9 6 -1.</_>
        <_>
          1 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 6 9 -1.</_>
        <_>
          11 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 18 12 6 -1.</_>
        <_>
          16 18 6 3 2.</_>
        <_>
          10 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 12 6 -1.</_>
        <_>
          2 18 6 3 2.</_>
        <_>
          8 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 16 9 -1.</_>
        <_>
          8 6 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 10 6 -1.</_>
        <_>
          0 7 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 18 3 -1.</_>
        <_>
          5 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 6 9 6 -1.</_>
        <_>
          2 9 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 2 10 9 -1.</_>
        <_>
          14 5 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 3 -1.</_>
        <_>
          3 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 15 6 -1.</_>
        <_>
          9 4 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 8 15 6 -1.</_>
        <_>
          4 10 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 24 4 -1.</_>
        <_>
          12 5 12 2 2.</_>
        <_>
          0 7 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 6 12 -1.</_>
        <_>
          9 8 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 6 12 -1.</_>
        <_>
          0 12 3 6 2.</_>
        <_>
          3 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 10 6 -1.</_>
        <_>
          14 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 18 9 -1.</_>
        <_>
          2 10 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 10 9 -1.</_>
        <_>
          11 17 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 8 -1.</_>
        <_>
          7 6 5 4 2.</_>
        <_>
          12 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 14 6 -1.</_>
        <_>
          13 6 7 3 2.</_>
        <_>
          6 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 9 7 -1.</_>
        <_>
          7 13 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 10 6 12 -1.</_>
        <_>
          17 10 3 6 2.</_>
        <_>
          14 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 6 12 -1.</_>
        <_>
          4 10 3 6 2.</_>
        <_>
          7 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 9 8 6 -1.</_>
        <_>
          13 9 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 4 14 -1.</_>
        <_>
          10 3 2 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 3 18 -1.</_>
        <_>
          18 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 16 12 -1.</_>
        <_>
          12 12 8 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 14 -1.</_>
        <_>
          17 0 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 14 -1.</_>
        <_>
          5 0 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 12 20 -1.</_>
        <_>
          16 2 4 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 12 20 -1.</_>
        <_>
          4 2 4 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 6 17 -1.</_>
        <_>
          18 0 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 6 17 -1.</_>
        <_>
          4 0 2 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 9 6 -1.</_>
        <_>
          15 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 9 6 -1.</_>
        <_>
          0 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 13 -1.</_>
        <_>
          20 1 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 13 -1.</_>
        <_>
          2 1 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 4 9 -1.</_>
        <_>
          16 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 12 7 -1.</_>
        <_>
          9 10 4 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 9 12 6 -1.</_>
        <_>
          12 11 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 12 6 -1.</_>
        <_>
          0 11 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 14 9 -1.</_>
        <_>
          5 10 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 20 3 -1.</_>
        <_>
          0 16 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 10 8 10 -1.</_>
        <_>
          12 10 4 5 2.</_>
        <_>
          8 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 13 9 -1.</_>
        <_>
          5 7 13 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 6 18 -1.</_>
        <_>
          10 8 6 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 12 4 -1.</_>
        <_>
          6 11 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 15 12 -1.</_>
        <_>
          3 6 15 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 12 5 -1.</_>
        <_>
          16 0 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 18 3 -1.</_>
        <_>
          6 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 24 5 -1.</_>
        <_>
          8 14 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 3 18 -1.</_>
        <_>
          6 1 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 4 14 -1.</_>
        <_>
          10 0 2 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 3 4 9 -1.</_>
        <_>
          11 3 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 12 6 -1.</_>
        <_>
          14 2 6 3 2.</_>
        <_>
          8 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 17 4 -1.</_>
        <_>
          0 6 17 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 16 5 8 -1.</_>
        <_>
          16 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 5 8 -1.</_>
        <_>
          3 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 2 -1.</_>
        <_>
          6 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 12 5 -1.</_>
        <_>
          4 0 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 3 6 12 -1.</_>
        <_>
          17 3 3 6 2.</_>
        <_>
          14 9 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 6 12 -1.</_>
        <_>
          2 12 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 21 3 -1.</_>
        <_>
          2 4 21 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 6 12 -1.</_>
        <_>
          4 3 3 6 2.</_>
        <_>
          7 9 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 12 6 -1.</_>
        <_>
          18 8 6 3 2.</_>
        <_>
          12 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 16 9 -1.</_>
        <_>
          8 15 8 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 13 18 5 -1.</_>
        <_>
          6 13 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 15 6 -1.</_>
        <_>
          6 6 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 9 6 -1.</_>
        <_>
          14 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 15 11 -1.</_>
        <_>
          8 0 5 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 3 3 18 -1.</_>
        <_>
          15 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 3 18 -1.</_>
        <_>
          6 9 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 10 8 -1.</_>
        <_>
          14 5 5 4 2.</_>
        <_>
          9 9 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 16 8 -1.</_>
        <_>
          4 4 8 4 2.</_>
        <_>
          12 8 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 12 3 -1.</_>
        <_>
          7 7 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 9 13 -1.</_>
        <_>
          8 0 3 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 10 9 -1.</_>
        <_>
          8 4 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 18 2 -1.</_>
        <_>
          0 3 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 13 14 6 -1.</_>
        <_>
          17 13 7 3 2.</_>
        <_>
          10 16 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 14 6 -1.</_>
        <_>
          0 13 7 3 2.</_>
        <_>
          7 16 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 2 3 21 -1.</_>
        <_>
          21 2 1 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 5 12 -1.</_>
        <_>
          0 13 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 12 6 -1.</_>
        <_>
          12 8 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 20 3 -1.</_>
        <_>
          1 9 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 19 3 -1.</_>
        <_>
          5 8 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 9 6 -1.</_>
        <_>
          1 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 10 14 12 -1.</_>
        <_>
          6 14 14 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 18 -1.</_>
        <_>
          5 12 14 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 9 7 -1.</_>
        <_>
          14 12 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 18 4 -1.</_>
        <_>
          1 17 18 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 6 9 -1.</_>
        <_>
          11 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 18 4 -1.</_>
        <_>
          0 8 9 2 2.</_>
        <_>
          9 10 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 20 6 -1.</_>
        <_>
          13 10 10 3 2.</_>
        <_>
          3 13 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 20 6 -1.</_>
        <_>
          1 10 10 3 2.</_>
        <_>
          11 13 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 24 2 -1.</_>
        <_>
          0 9 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 20 8 -1.</_>
        <_>
          1 12 10 4 2.</_>
        <_>
          11 16 10 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 9 7 -1.</_>
        <_>
          14 12 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 9 7 -1.</_>
        <_>
          7 12 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 12 8 5 -1.</_>
        <_>
          12 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 8 5 -1.</_>
        <_>
          8 12 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 4 10 -1.</_>
        <_>
          13 10 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 20 2 -1.</_>
        <_>
          11 15 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 6 -1.</_>
        <_>
          9 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 21 3 -1.</_>
        <_>
          7 1 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 13 9 -1.</_>
        <_>
          6 7 13 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 5 -1.</_>
        <_>
          10 5 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 10 10 6 -1.</_>
        <_>
          10 12 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 12 5 8 -1.</_>
        <_>
          6 16 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 9 -1.</_>
        <_>
          15 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 10 18 6 -1.</_>
        <_>
          8 10 6 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 9 4 -1.</_>
        <_>
          11 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 21 3 -1.</_>
        <_>
          8 20 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 22 2 -1.</_>
        <_>
          1 11 22 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 9 -1.</_>
        <_>
          15 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 9 -1.</_>
        <_>
          7 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 20 -1.</_>
        <_>
          20 2 2 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 20 -1.</_>
        <_>
          2 2 2 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 7 6 14 -1.</_>
        <_>
          14 7 3 7 2.</_>
        <_>
          11 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 4 9 -1.</_>
        <_>
          2 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 14 9 4 -1.</_>
        <_>
          12 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 9 4 -1.</_>
        <_>
          1 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 15 6 -1.</_>
        <_>
          7 8 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 3 18 -1.</_>
        <_>
          8 8 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 6 -1.</_>
        <_>
          12 6 6 3 2.</_>
        <_>
          6 9 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 19 20 4 -1.</_>
        <_>
          2 19 10 2 2.</_>
        <_>
          12 21 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 6 9 -1.</_>
        <_>
          14 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 18 14 -1.</_>
        <_>
          3 5 9 7 2.</_>
        <_>
          12 12 9 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 4 18 -1.</_>
        <_>
          17 6 2 9 2.</_>
        <_>
          15 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 4 18 -1.</_>
        <_>
          5 6 2 9 2.</_>
        <_>
          7 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 6 9 -1.</_>
        <_>
          13 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 5 6 9 -1.</_>
        <_>
          13 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 6 6 -1.</_>
        <_>
          12 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 16 6 -1.</_>
        <_>
          12 1 8 3 2.</_>
        <_>
          4 4 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 13 6 11 -1.</_>
        <_>
          11 13 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 1 6 12 -1.</_>
        <_>
          20 1 3 6 2.</_>
        <_>
          17 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 18 3 -1.</_>
        <_>
          1 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 10 8 -1.</_>
        <_>
          7 17 10 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 10 6 -1.</_>
        <_>
          6 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 9 4 -1.</_>
        <_>
          9 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 6 12 -1.</_>
        <_>
          1 1 3 6 2.</_>
        <_>
          4 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 4 5 12 -1.</_>
        <_>
          19 8 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 8 8 -1.</_>
        <_>
          4 0 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 19 3 -1.</_>
        <_>
          3 6 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 12 6 -1.</_>
        <_>
          1 5 6 3 2.</_>
        <_>
          7 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 21 8 -1.</_>
        <_>
          9 1 7 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 16 8 -1.</_>
        <_>
          4 5 16 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 3 -1.</_>
        <_>
          6 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 10 14 -1.</_>
        <_>
          4 11 10 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 4 10 -1.</_>
        <_>
          15 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 18 3 -1.</_>
        <_>
          9 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 12 6 -1.</_>
        <_>
          12 18 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 6 9 -1.</_>
        <_>
          6 15 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 7 6 8 -1.</_>
        <_>
          15 11 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 6 8 -1.</_>
        <_>
          3 11 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 18 6 -1.</_>
        <_>
          14 9 9 3 2.</_>
        <_>
          5 12 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 12 6 -1.</_>
        <_>
          1 15 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 10 6 -1.</_>
        <_>
          14 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 10 6 -1.</_>
        <_>
          0 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 13 6 9 -1.</_>
        <_>
          15 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 13 6 9 -1.</_>
        <_>
          3 16 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 8 8 -1.</_>
        <_>
          9 5 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 18 12 6 -1.</_>
        <_>
          1 18 6 3 2.</_>
        <_>
          7 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 19 10 4 -1.</_>
        <_>
          13 21 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 19 10 4 -1.</_>
        <_>
          1 21 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 19 18 3 -1.</_>
        <_>
          6 20 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 4 10 -1.</_>
        <_>
          8 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 6 -1.</_>
        <_>
          0 2 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 9 -1.</_>
        <_>
          0 4 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 9 20 6 -1.</_>
        <_>
          14 9 10 3 2.</_>
        <_>
          4 12 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 15 19 8 -1.</_>
        <_>
          1 19 19 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 10 6 -1.</_>
        <_>
          14 2 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 21 14 -1.</_>
        <_>
          8 10 7 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 10 8 8 -1.</_>
        <_>
          10 10 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 10 4 -1.</_>
        <_>
          11 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 9 -1.</_>
        <_>
          10 5 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 10 -1.</_>
        <_>
          9 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 4 4 13 -1.</_>
        <_>
          14 4 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 4 13 -1.</_>
        <_>
          8 4 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 9 6 -1.</_>
        <_>
          11 7 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 16 6 -1.</_>
        <_>
          3 6 8 3 2.</_>
        <_>
          11 9 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 16 14 -1.</_>
        <_>
          13 4 8 7 2.</_>
        <_>
          5 11 8 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 4 -1.</_>
        <_>
          0 0 12 2 2.</_>
        <_>
          12 2 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 9 6 -1.</_>
        <_>
          12 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 14 4 -1.</_>
        <_>
          11 1 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 7 9 -1.</_>
        <_>
          10 17 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 3 8 10 -1.</_>
        <_>
          8 3 4 5 2.</_>
        <_>
          12 8 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 12 5 -1.</_>
        <_>
          11 3 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 4 13 -1.</_>
        <_>
          10 2 2 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 3 19 -1.</_>
        <_>
          12 2 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 9 6 -1.</_>
        <_>
          10 7 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 22 20 2 -1.</_>
        <_>
          4 22 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 24 4 -1.</_>
        <_>
          0 16 12 2 2.</_>
        <_>
          12 18 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 12 5 -1.</_>
        <_>
          11 3 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 8 14 -1.</_>
        <_>
          1 10 4 7 2.</_>
        <_>
          5 17 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 16 6 6 -1.</_>
        <_>
          11 19 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 10 24 -1.</_>
        <_>
          6 0 5 12 2.</_>
        <_>
          11 12 5 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 14 14 -1.</_>
        <_>
          14 5 7 7 2.</_>
        <_>
          7 12 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 8 -1.</_>
        <_>
          7 8 5 4 2.</_>
        <_>
          12 12 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 9 6 -1.</_>
        <_>
          12 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 3 -1.</_>
        <_>
          12 6 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 12 5 -1.</_>
        <_>
          11 3 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 22 4 -1.</_>
        <_>
          1 13 11 2 2.</_>
        <_>
          12 15 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 12 6 -1.</_>
        <_>
          9 14 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 9 6 -1.</_>
        <_>
          0 7 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 23 6 -1.</_>
        <_>
          1 7 23 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 19 12 -1.</_>
        <_>
          1 10 19 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 21 -1.</_>
        <_>
          9 8 6 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 19 18 3 -1.</_>
        <_>
          9 19 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 14 6 9 -1.</_>
        <_>
          11 14 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 4 12 -1.</_>
        <_>
          11 6 2 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 6 9 -1.</_>
        <_>
          18 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 6 9 -1.</_>
        <_>
          4 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 4 22 -1.</_>
        <_>
          15 1 2 11 2.</_>
        <_>
          13 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 8 12 -1.</_>
        <_>
          1 14 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 7 7 9 -1.</_>
        <_>
          14 10 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 18 4 -1.</_>
        <_>
          3 12 9 2 2.</_>
        <_>
          12 14 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 4 22 -1.</_>
        <_>
          15 1 2 11 2.</_>
        <_>
          13 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 4 22 -1.</_>
        <_>
          7 1 2 11 2.</_>
        <_>
          9 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 20 4 -1.</_>
        <_>
          14 7 10 2 2.</_>
        <_>
          4 9 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 7 -1.</_>
        <_>
          12 10 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 10 4 -1.</_>
        <_>
          7 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 4 15 -1.</_>
        <_>
          0 8 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 8 12 -1.</_>
        <_>
          19 0 4 6 2.</_>
        <_>
          15 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 8 12 -1.</_>
        <_>
          1 0 4 6 2.</_>
        <_>
          5 6 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 6 16 -1.</_>
        <_>
          16 5 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 6 16 -1.</_>
        <_>
          6 5 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 16 -1.</_>
        <_>
          17 0 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 16 -1.</_>
        <_>
          5 0 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 24 3 -1.</_>
        <_>
          0 3 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 10 4 -1.</_>
        <_>
          7 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 23 8 -1.</_>
        <_>
          1 4 23 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 19 3 -1.</_>
        <_>
          1 18 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 2 -1.</_>
        <_>
          6 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 17 9 6 -1.</_>
        <_>
          1 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 6 9 -1.</_>
        <_>
          15 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 6 9 -1.</_>
        <_>
          3 18 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 20 6 -1.</_>
        <_>
          4 17 20 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 6 14 -1.</_>
        <_>
          0 10 3 7 2.</_>
        <_>
          3 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 3 -1.</_>
        <_>
          6 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 9 7 -1.</_>
        <_>
          7 12 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 10 18 5 -1.</_>
        <_>
          12 10 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 18 5 -1.</_>
        <_>
          6 10 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 18 9 -1.</_>
        <_>
          9 2 6 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 10 10 -1.</_>
        <_>
          4 6 5 5 2.</_>
        <_>
          9 11 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 14 4 9 -1.</_>
        <_>
          20 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 4 9 -1.</_>
        <_>
          2 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 20 -1.</_>
        <_>
          13 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 21 12 3 -1.</_>
        <_>
          12 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 20 -1.</_>
        <_>
          13 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 16 10 8 -1.</_>
        <_>
          1 16 5 4 2.</_>
        <_>
          6 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 20 -1.</_>
        <_>
          13 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 3 19 -1.</_>
        <_>
          2 0 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 20 -1.</_>
        <_>
          13 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 9 -1.</_>
        <_>
          2 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 19 4 -1.</_>
        <_>
          3 9 19 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 14 9 6 -1.</_>
        <_>
          7 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 1 7 6 -1.</_>
        <_>
          17 4 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 14 8 -1.</_>
        <_>
          5 4 14 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 1 8 6 -1.</_>
        <_>
          16 4 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 8 6 -1.</_>
        <_>
          0 4 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 4 -1.</_>
        <_>
          15 0 9 2 2.</_>
        <_>
          6 2 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 6 -1.</_>
        <_>
          0 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 8 -1.</_>
        <_>
          9 7 6 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 6 9 -1.</_>
        <_>
          4 11 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 6 9 -1.</_>
        <_>
          12 5 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          10 6 2 9 2.</_>
        <_>
          12 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 1 4 20 -1.</_>
        <_>
          13 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 4 20 -1.</_>
        <_>
          9 1 2 10 2.</_>
        <_>
          11 11 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 18 6 -1.</_>
        <_>
          14 9 9 3 2.</_>
        <_>
          5 12 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 6 9 -1.</_>
        <_>
          8 4 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 16 8 6 -1.</_>
        <_>
          10 16 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 18 8 -1.</_>
        <_>
          0 0 9 4 2.</_>
        <_>
          9 4 9 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 14 12 -1.</_>
        <_>
          13 5 7 6 2.</_>
        <_>
          6 11 7 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 15 7 -1.</_>
        <_>
          9 3 5 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 10 6 -1.</_>
        <_>
          14 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 4 10 -1.</_>
        <_>
          0 16 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 22 3 -1.</_>
        <_>
          1 11 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 6 10 -1.</_>
        <_>
          10 9 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 2 6 12 -1.</_>
        <_>
          16 2 3 6 2.</_>
        <_>
          13 8 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          10 6 2 9 2.</_>
        <_>
          12 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 16 -1.</_>
        <_>
          12 8 5 8 2.</_>
        <_>
          7 16 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 8 12 -1.</_>
        <_>
          8 1 4 6 2.</_>
        <_>
          12 7 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 12 14 -1.</_>
        <_>
          13 1 6 7 2.</_>
        <_>
          7 8 6 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 14 12 6 -1.</_>
        <_>
          2 16 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 16 6 6 -1.</_>
        <_>
          11 19 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 16 6 6 -1.</_>
        <_>
          7 19 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 4 4 10 -1.</_>
        <_>
          13 4 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 19 19 3 -1.</_>
        <_>
          0 20 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 6 8 -1.</_>
        <_>
          12 12 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 8 22 -1.</_>
        <_>
          8 12 8 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 6 8 -1.</_>
        <_>
          12 12 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 6 8 -1.</_>
        <_>
          6 12 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 6 9 -1.</_>
        <_>
          14 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 4 -1.</_>
        <_>
          0 8 24 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 10 6 -1.</_>
        <_>
          14 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 10 6 -1.</_>
        <_>
          0 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 19 3 -1.</_>
        <_>
          4 7 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 19 3 -1.</_>
        <_>
          1 7 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 16 9 -1.</_>
        <_>
          4 3 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 5 -1.</_>
        <_>
          8 1 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 6 15 -1.</_>
        <_>
          3 11 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 22 18 2 -1.</_>
        <_>
          6 23 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 6 9 -1.</_>
        <_>
          2 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 12 6 9 -1.</_>
        <_>
          18 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 6 9 -1.</_>
        <_>
          0 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 4 10 -1.</_>
        <_>
          11 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 16 -1.</_>
        <_>
          9 14 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 10 10 -1.</_>
        <_>
          7 12 10 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 6 13 -1.</_>
        <_>
          3 3 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 13 -1.</_>
        <_>
          18 1 3 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 6 9 -1.</_>
        <_>
          7 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 2 6 11 -1.</_>
        <_>
          18 2 3 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 11 -1.</_>
        <_>
          3 2 3 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 15 6 -1.</_>
        <_>
          9 14 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 20 3 -1.</_>
        <_>
          2 3 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          10 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 12 14 -1.</_>
        <_>
          5 6 6 7 2.</_>
        <_>
          11 13 6 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 9 6 -1.</_>
        <_>
          10 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 9 -1.</_>
        <_>
          12 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 12 20 -1.</_>
        <_>
          4 1 6 10 2.</_>
        <_>
          10 11 6 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 18 3 -1.</_>
        <_>
          6 7 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 18 3 -1.</_>
        <_>
          9 7 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 20 18 3 -1.</_>
        <_>
          9 20 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 12 15 -1.</_>
        <_>
          10 2 4 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 18 3 -1.</_>
        <_>
          2 4 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 4 4 18 -1.</_>
        <_>
          21 4 2 9 2.</_>
        <_>
          19 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 19 3 -1.</_>
        <_>
          0 2 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 15 4 -1.</_>
        <_>
          5 2 15 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 14 5 -1.</_>
        <_>
          12 2 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 22 14 -1.</_>
        <_>
          1 2 11 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 6 9 -1.</_>
        <_>
          10 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 17 18 3 -1.</_>
        <_>
          6 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 3 18 -1.</_>
        <_>
          9 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 20 3 -1.</_>
        <_>
          2 1 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 5 12 -1.</_>
        <_>
          5 8 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 12 5 -1.</_>
        <_>
          12 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 12 -1.</_>
        <_>
          9 12 3 6 2.</_>
        <_>
          12 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 8 10 -1.</_>
        <_>
          18 14 4 5 2.</_>
        <_>
          14 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 14 8 10 -1.</_>
        <_>
          2 14 4 5 2.</_>
        <_>
          6 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 18 12 6 -1.</_>
        <_>
          16 18 6 3 2.</_>
        <_>
          10 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 6 9 -1.</_>
        <_>
          1 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 3 3 20 -1.</_>
        <_>
          12 3 1 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 14 6 -1.</_>
        <_>
          4 6 7 3 2.</_>
        <_>
          11 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 13 -1.</_>
        <_>
          10 5 4 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 4 15 -1.</_>
        <_>
          5 9 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 16 15 4 -1.</_>
        <_>
          14 16 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 6 14 -1.</_>
        <_>
          7 8 3 7 2.</_>
        <_>
          10 15 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 6 -1.</_>
        <_>
          7 8 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 18 3 -1.</_>
        <_>
          2 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 15 8 -1.</_>
        <_>
          5 5 15 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 8 18 -1.</_>
        <_>
          7 10 8 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 24 3 -1.</_>
        <_>
          0 11 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 13 -1.</_>
        <_>
          2 2 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 8 10 -1.</_>
        <_>
          20 0 4 5 2.</_>
        <_>
          16 5 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 10 9 -1.</_>
        <_>
          5 4 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 18 3 -1.</_>
        <_>
          5 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 3 -1.</_>
        <_>
          0 2 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 4 6 11 -1.</_>
        <_>
          13 4 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 8 10 -1.</_>
        <_>
          0 0 4 5 2.</_>
        <_>
          4 5 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 16 18 3 -1.</_>
        <_>
          4 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 18 3 -1.</_>
        <_>
          2 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 18 10 -1.</_>
        <_>
          12 0 9 5 2.</_>
        <_>
          3 5 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 3 20 21 -1.</_>
        <_>
          12 3 10 21 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 14 3 -1.</_>
        <_>
          6 7 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 12 6 -1.</_>
        <_>
          0 9 6 3 2.</_>
        <_>
          6 12 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 21 4 -1.</_>
        <_>
          10 14 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 21 4 -1.</_>
        <_>
          7 14 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 21 18 3 -1.</_>
        <_>
          11 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 21 18 3 -1.</_>
        <_>
          7 21 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 4 4 18 -1.</_>
        <_>
          21 4 2 9 2.</_>
        <_>
          19 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 7 18 3 -1.</_>
        <_>
          3 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 4 4 18 -1.</_>
        <_>
          21 4 2 9 2.</_>
        <_>
          19 13 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 15 10 6 -1.</_>
        <_>
          7 17 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 13 11 9 -1.</_>
        <_>
          9 16 11 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 4 10 -1.</_>
        <_>
          0 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 16 9 6 -1.</_>
        <_>
          15 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 4 18 -1.</_>
        <_>
          1 5 2 9 2.</_>
        <_>
          3 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 8 10 -1.</_>
        <_>
          13 8 4 5 2.</_>
        <_>
          9 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 8 10 -1.</_>
        <_>
          7 8 4 5 2.</_>
        <_>
          11 13 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 12 5 -1.</_>
        <_>
          13 8 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 9 7 -1.</_>
        <_>
          10 8 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 12 5 -1.</_>
        <_>
          13 8 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 9 7 -1.</_>
        <_>
          10 6 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 12 5 -1.</_>
        <_>
          13 8 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 18 -1.</_>
        <_>
          10 11 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 14 12 -1.</_>
        <_>
          5 11 14 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 11 4 -1.</_>
        <_>
          0 3 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 10 -1.</_>
        <_>
          11 10 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 17 11 6 -1.</_>
        <_>
          2 19 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 16 9 6 -1.</_>
        <_>
          15 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 18 2 -1.</_>
        <_>
          1 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 13 -1.</_>
        <_>
          10 4 4 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 18 3 -1.</_>
        <_>
          0 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 18 3 -1.</_>
        <_>
          6 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 9 6 -1.</_>
        <_>
          0 18 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 15 9 6 -1.</_>
        <_>
          13 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 9 6 -1.</_>
        <_>
          2 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 1 6 16 -1.</_>
        <_>
          13 1 3 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 6 16 -1.</_>
        <_>
          8 1 3 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 5 6 10 -1.</_>
        <_>
          13 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 10 -1.</_>
        <_>
          9 5 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 24 -1.</_>
        <_>
          12 0 2 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 4 20 -1.</_>
        <_>
          3 4 2 10 2.</_>
        <_>
          5 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 9 -1.</_>
        <_>
          16 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 6 9 -1.</_>
        <_>
          6 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 18 5 -1.</_>
        <_>
          10 5 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 6 9 -1.</_>
        <_>
          7 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 15 8 -1.</_>
        <_>
          12 2 5 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 15 8 -1.</_>
        <_>
          7 2 5 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 4 9 -1.</_>
        <_>
          10 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 6 12 -1.</_>
        <_>
          3 4 3 6 2.</_>
        <_>
          6 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 8 18 -1.</_>
        <_>
          16 0 4 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 8 18 -1.</_>
        <_>
          4 0 4 18 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 24 6 -1.</_>
        <_>
          0 9 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 14 3 -1.</_>
        <_>
          11 7 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 8 15 -1.</_>
        <_>
          10 8 4 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 14 -1.</_>
        <_>
          12 0 5 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 10 8 10 -1.</_>
        <_>
          17 10 4 5 2.</_>
        <_>
          13 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 4 9 -1.</_>
        <_>
          5 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 1 6 8 -1.</_>
        <_>
          16 1 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 6 8 -1.</_>
        <_>
          5 1 3 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 12 -1.</_>
        <_>
          3 10 18 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 16 4 -1.</_>
        <_>
          4 14 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 9 16 15 -1.</_>
        <_>
          4 14 16 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 8 10 -1.</_>
        <_>
          3 10 4 5 2.</_>
        <_>
          7 15 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 16 6 -1.</_>
        <_>
          16 18 8 3 2.</_>
        <_>
          8 21 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 12 5 -1.</_>
        <_>
          6 16 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 9 4 -1.</_>
        <_>
          14 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 14 9 6 -1.</_>
        <_>
          7 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 16 12 -1.</_>
        <_>
          4 14 16 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 19 6 -1.</_>
        <_>
          0 15 19 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 13 9 6 -1.</_>
        <_>
          10 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 3 23 -1.</_>
        <_>
          6 0 1 23 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 24 6 -1.</_>
        <_>
          0 10 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 5 12 -1.</_>
        <_>
          0 9 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 19 18 -1.</_>
        <_>
          3 9 19 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 6 12 -1.</_>
        <_>
          9 11 3 6 2.</_>
        <_>
          12 17 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 24 8 -1.</_>
        <_>
          12 5 12 4 2.</_>
        <_>
          0 9 12 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 18 9 4 -1.</_>
        <_>
          6 20 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 10 6 -1.</_>
        <_>
          8 10 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 20 3 -1.</_>
        <_>
          2 8 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 7 20 -1.</_>
        <_>
          12 10 7 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 7 20 -1.</_>
        <_>
          5 10 7 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 2 2 18 -1.</_>
        <_>
          14 11 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 10 12 -1.</_>
        <_>
          10 8 5 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 12 8 -1.</_>
        <_>
          12 9 6 4 2.</_>
        <_>
          6 13 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 3 14 -1.</_>
        <_>
          7 14 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 12 16 -1.</_>
        <_>
          17 2 6 8 2.</_>
        <_>
          11 10 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 6 9 -1.</_>
        <_>
          9 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 14 9 4 -1.</_>
        <_>
          13 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 22 4 -1.</_>
        <_>
          0 12 11 2 2.</_>
        <_>
          11 14 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 22 6 -1.</_>
        <_>
          12 12 11 3 2.</_>
        <_>
          1 15 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 9 6 -1.</_>
        <_>
          9 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 4 9 -1.</_>
        <_>
          10 0 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 18 7 -1.</_>
        <_>
          9 8 6 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 6 -1.</_>
        <_>
          0 8 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 24 10 -1.</_>
        <_>
          8 11 8 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 18 21 -1.</_>
        <_>
          9 3 6 21 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 12 4 10 -1.</_>
        <_>
          9 12 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 16 10 8 -1.</_>
        <_>
          15 16 5 4 2.</_>
        <_>
          10 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 6 12 -1.</_>
        <_>
          15 10 3 6 2.</_>
        <_>
          12 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 10 6 12 -1.</_>
        <_>
          6 10 3 6 2.</_>
        <_>
          9 16 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 12 6 12 -1.</_>
        <_>
          19 12 3 6 2.</_>
        <_>
          16 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 6 12 -1.</_>
        <_>
          2 12 3 6 2.</_>
        <_>
          5 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 6 9 -1.</_>
        <_>
          12 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 6 9 -1.</_>
        <_>
          10 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 20 10 4 -1.</_>
        <_>
          14 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 10 4 -1.</_>
        <_>
          5 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 17 9 6 -1.</_>
        <_>
          11 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 14 4 -1.</_>
        <_>
          3 4 14 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 10 4 -1.</_>
        <_>
          10 3 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 10 4 -1.</_>
        <_>
          5 15 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 2 3 19 -1.</_>
        <_>
          20 2 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 9 8 -1.</_>
        <_>
          7 12 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 5 12 -1.</_>
        <_>
          4 11 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 3 -1.</_>
        <_>
          8 1 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 4 -1.</_>
        <_>
          6 10 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 3 4 10 -1.</_>
        <_>
          19 3 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 9 6 -1.</_>
        <_>
          3 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 22 -1.</_>
        <_>
          20 0 2 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 22 -1.</_>
        <_>
          2 0 2 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 15 19 3 -1.</_>
        <_>
          5 16 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 7 4 15 -1.</_>
        <_>
          10 12 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 21 18 3 -1.</_>
        <_>
          0 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 10 15 -1.</_>
        <_>
          7 8 10 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 18 3 -1.</_>
        <_>
          1 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 9 6 -1.</_>
        <_>
          11 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 24 14 -1.</_>
        <_>
          0 17 24 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 9 8 10 -1.</_>
        <_>
          17 9 4 5 2.</_>
        <_>
          13 14 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 4 9 -1.</_>
        <_>
          12 5 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 9 8 10 -1.</_>
        <_>
          17 9 4 5 2.</_>
        <_>
          13 14 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 11 10 10 -1.</_>
        <_>
          7 11 5 5 2.</_>
        <_>
          12 16 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 18 4 -1.</_>
        <_>
          13 13 9 2 2.</_>
        <_>
          4 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 19 2 -1.</_>
        <_>
          0 1 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 24 6 -1.</_>
        <_>
          8 18 8 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 8 16 -1.</_>
        <_>
          6 12 8 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 10 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 9 -1.</_>
        <_>
          0 6 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 15 7 9 -1.</_>
        <_>
          13 18 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 12 6 -1.</_>
        <_>
          3 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 14 6 9 -1.</_>
        <_>
          12 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 15 8 -1.</_>
        <_>
          2 19 15 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 16 -1.</_>
        <_>
          9 14 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 7 12 -1.</_>
        <_>
          6 10 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 6 6 9 -1.</_>
        <_>
          14 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 14 6 9 -1.</_>
        <_>
          5 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 6 9 -1.</_>
        <_>
          12 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 4 18 -1.</_>
        <_>
          6 6 2 9 2.</_>
        <_>
          8 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 9 6 12 -1.</_>
        <_>
          17 9 3 6 2.</_>
        <_>
          14 15 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 9 6 12 -1.</_>
        <_>
          4 9 3 6 2.</_>
        <_>
          7 15 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 15 9 6 -1.</_>
        <_>
          14 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 18 4 -1.</_>
        <_>
          0 20 9 2 2.</_>
        <_>
          9 22 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 18 9 6 -1.</_>
        <_>
          13 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 9 6 -1.</_>
        <_>
          2 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 2 4 22 -1.</_>
        <_>
          21 2 2 11 2.</_>
        <_>
          19 13 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 2 4 22 -1.</_>
        <_>
          1 2 2 11 2.</_>
        <_>
          3 13 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 2 24 -1.</_>
        <_>
          15 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 20 16 4 -1.</_>
        <_>
          11 20 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 4 18 -1.</_>
        <_>
          13 6 2 9 2.</_>
        <_>
          11 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 10 14 -1.</_>
        <_>
          7 9 5 7 2.</_>
        <_>
          12 16 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 6 6 9 -1.</_>
        <_>
          14 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 7 9 -1.</_>
        <_>
          3 9 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 4 4 20 -1.</_>
        <_>
          22 4 2 10 2.</_>
        <_>
          20 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 6 9 -1.</_>
        <_>
          7 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 14 -1.</_>
        <_>
          12 0 5 7 2.</_>
        <_>
          7 7 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 18 6 -1.</_>
        <_>
          11 1 9 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 2 24 -1.</_>
        <_>
          15 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 2 24 -1.</_>
        <_>
          8 0 1 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 12 6 7 -1.</_>
        <_>
          13 12 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 12 6 7 -1.</_>
        <_>
          8 12 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 18 19 -1.</_>
        <_>
          9 5 6 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 9 6 -1.</_>
        <_>
          8 6 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 9 6 -1.</_>
        <_>
          12 5 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 10 8 -1.</_>
        <_>
          3 16 5 4 2.</_>
        <_>
          8 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 8 5 15 -1.</_>
        <_>
          19 13 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 5 15 -1.</_>
        <_>
          0 13 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          20 4 4 20 -1.</_>
        <_>
          22 4 2 10 2.</_>
        <_>
          20 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 4 20 -1.</_>
        <_>
          0 4 2 10 2.</_>
        <_>
          2 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 10 4 -1.</_>
        <_>
          7 7 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 19 14 4 -1.</_>
        <_>
          11 19 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 11 12 3 -1.</_>
        <_>
          10 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 3 -1.</_>
        <_>
          0 2 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 14 20 -1.</_>
        <_>
          14 2 7 10 2.</_>
        <_>
          7 12 7 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 6 9 -1.</_>
        <_>
          2 13 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 19 -1.</_>
        <_>
          13 0 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 11 14 3 -1.</_>
        <_>
          8 11 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 16 20 -1.</_>
        <_>
          15 1 8 10 2.</_>
        <_>
          7 11 8 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 21 9 -1.</_>
        <_>
          7 10 7 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 19 15 5 -1.</_>
        <_>
          11 19 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 10 6 6 -1.</_>
        <_>
          11 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 1 16 20 -1.</_>
        <_>
          15 1 8 10 2.</_>
        <_>
          7 11 8 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 16 20 -1.</_>
        <_>
          1 1 8 10 2.</_>
        <_>
          9 11 8 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 4 3 12 -1.</_>
        <_>
          16 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 3 12 -1.</_>
        <_>
          5 10 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 8 -1.</_>
        <_>
          12 6 5 4 2.</_>
        <_>
          7 10 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 9 6 6 -1.</_>
        <_>
          4 12 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 4 -1.</_>
        <_>
          6 7 12 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 5 15 -1.</_>
        <_>
          9 7 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 9 6 -1.</_>
        <_>
          15 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 11 10 -1.</_>
        <_>
          6 5 11 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 7 4 12 -1.</_>
        <_>
          12 13 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 9 4 -1.</_>
        <_>
          7 4 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 13 6 -1.</_>
        <_>
          6 2 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          10 6 2 9 2.</_>
        <_>
          12 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 6 9 -1.</_>
        <_>
          12 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 18 10 6 -1.</_>
        <_>
          3 20 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 20 3 -1.</_>
        <_>
          4 15 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 9 6 -1.</_>
        <_>
          2 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 19 -1.</_>
        <_>
          13 0 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 19 -1.</_>
        <_>
          9 0 2 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 22 2 -1.</_>
        <_>
          1 5 22 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 9 6 -1.</_>
        <_>
          0 2 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 24 18 -1.</_>
        <_>
          0 9 24 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 16 8 -1.</_>
        <_>
          3 6 16 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 6 18 6 -1.</_>
        <_>
          3 8 18 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 6 10 -1.</_>
        <_>
          5 1 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 9 6 -1.</_>
        <_>
          16 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 9 6 -1.</_>
        <_>
          5 0 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 7 10 -1.</_>
        <_>
          6 5 7 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 20 4 -1.</_>
        <_>
          12 2 10 2 2.</_>
        <_>
          2 4 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 19 3 -1.</_>
        <_>
          2 12 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 8 6 9 -1.</_>
        <_>
          12 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 6 9 -1.</_>
        <_>
          10 8 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 8 4 9 -1.</_>
        <_>
          13 8 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 11 9 9 -1.</_>
        <_>
          6 11 3 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 5 -1.</_>
        <_>
          9 9 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 2 20 -1.</_>
        <_>
          2 14 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 17 8 6 -1.</_>
        <_>
          14 20 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 2 -1.</_>
        <_>
          3 22 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 15 6 -1.</_>
        <_>
          10 4 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 15 12 6 -1.</_>
        <_>
          2 17 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 8 6 9 -1.</_>
        <_>
          17 11 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 20 4 -1.</_>
        <_>
          2 12 10 2 2.</_>
        <_>
          12 14 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 24 6 -1.</_>
        <_>
          0 19 24 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 16 9 4 -1.</_>
        <_>
          7 18 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 4 22 -1.</_>
        <_>
          17 1 2 11 2.</_>
        <_>
          15 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 4 22 -1.</_>
        <_>
          5 1 2 11 2.</_>
        <_>
          7 12 2 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 13 8 9 -1.</_>
        <_>
          11 16 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 6 9 -1.</_>
        <_>
          8 1 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 4 3 18 -1.</_>
        <_>
          11 10 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 12 6 -1.</_>
        <_>
          5 8 6 3 2.</_>
        <_>
          11 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 7 5 8 -1.</_>
        <_>
          15 11 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 5 8 -1.</_>
        <_>
          4 11 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 6 12 -1.</_>
        <_>
          15 6 3 6 2.</_>
        <_>
          12 12 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 6 12 -1.</_>
        <_>
          6 6 3 6 2.</_>
        <_>
          9 12 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 14 8 -1.</_>
        <_>
          12 9 7 4 2.</_>
        <_>
          5 13 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 3 14 -1.</_>
        <_>
          9 8 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 6 12 -1.</_>
        <_>
          12 10 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 5 4 18 -1.</_>
        <_>
          4 5 2 9 2.</_>
        <_>
          6 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 16 18 -1.</_>
        <_>
          4 12 16 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 7 20 -1.</_>
        <_>
          5 14 7 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 8 8 12 -1.</_>
        <_>
          14 14 8 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 6 14 -1.</_>
        <_>
          9 10 3 7 2.</_>
        <_>
          12 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 9 6 -1.</_>
        <_>
          12 5 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 4 3 18 -1.</_>
        <_>
          10 4 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 22 14 -1.</_>
        <_>
          12 4 11 7 2.</_>
        <_>
          1 11 11 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 18 2 -1.</_>
        <_>
          2 8 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 6 12 -1.</_>
        <_>
          12 10 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 9 7 -1.</_>
        <_>
          9 5 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 7 4 12 -1.</_>
        <_>
          12 13 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 4 12 -1.</_>
        <_>
          8 13 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 2 10 22 -1.</_>
        <_>
          7 13 10 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 3 20 -1.</_>
        <_>
          1 1 1 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 18 4 -1.</_>
        <_>
          13 13 9 2 2.</_>
        <_>
          4 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 13 18 4 -1.</_>
        <_>
          2 13 9 2 2.</_>
        <_>
          11 15 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 6 -1.</_>
        <_>
          15 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 6 -1.</_>
        <_>
          0 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 24 -1.</_>
        <_>
          15 0 9 12 2.</_>
        <_>
          6 12 9 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 6 12 -1.</_>
        <_>
          6 10 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 10 4 -1.</_>
        <_>
          8 9 10 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 9 18 6 -1.</_>
        <_>
          1 9 9 3 2.</_>
        <_>
          10 12 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 18 3 -1.</_>
        <_>
          6 7 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 9 8 -1.</_>
        <_>
          10 7 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 12 6 12 -1.</_>
        <_>
          12 12 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 18 3 -1.</_>
        <_>
          3 15 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 7 -1.</_>
        <_>
          18 17 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 10 6 -1.</_>
        <_>
          1 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 7 -1.</_>
        <_>
          18 17 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 3 3 19 -1.</_>
        <_>
          11 3 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 7 -1.</_>
        <_>
          18 17 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 11 9 -1.</_>
        <_>
          6 4 11 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 7 -1.</_>
        <_>
          18 17 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 11 6 -1.</_>
        <_>
          6 8 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 7 8 5 -1.</_>
        <_>
          16 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 20 19 -1.</_>
        <_>
          12 4 10 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 21 6 -1.</_>
        <_>
          9 1 7 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 14 -1.</_>
        <_>
          6 5 6 7 2.</_>
        <_>
          12 12 6 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 8 5 -1.</_>
        <_>
          6 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 7 8 5 -1.</_>
        <_>
          16 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 8 5 -1.</_>
        <_>
          4 7 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 7 -1.</_>
        <_>
          18 17 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 8 10 -1.</_>
        <_>
          8 6 4 5 2.</_>
        <_>
          12 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 15 9 9 -1.</_>
        <_>
          18 15 3 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 9 9 -1.</_>
        <_>
          3 15 3 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 10 9 7 -1.</_>
        <_>
          15 10 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 9 7 -1.</_>
        <_>
          6 10 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 15 10 8 -1.</_>
        <_>
          18 15 5 4 2.</_>
        <_>
          13 19 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 12 -1.</_>
        <_>
          0 1 3 6 2.</_>
        <_>
          3 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 12 -1.</_>
        <_>
          13 0 3 6 2.</_>
        <_>
          10 6 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 10 12 -1.</_>
        <_>
          7 0 5 6 2.</_>
        <_>
          12 6 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 1 16 8 -1.</_>
        <_>
          4 1 8 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 21 19 3 -1.</_>
        <_>
          0 22 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 18 4 -1.</_>
        <_>
          15 9 9 2 2.</_>
        <_>
          6 11 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 9 6 -1.</_>
        <_>
          3 6 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 15 -1.</_>
        <_>
          9 6 6 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 6 6 -1.</_>
        <_>
          8 9 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 1 14 9 -1.</_>
        <_>
          5 4 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 8 20 -1.</_>
        <_>
          3 0 4 10 2.</_>
        <_>
          7 10 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 7 9 -1.</_>
        <_>
          5 3 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 12 5 -1.</_>
        <_>
          10 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 8 14 -1.</_>
        <_>
          4 1 4 14 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 12 22 4 -1.</_>
        <_>
          2 14 22 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 17 6 6 -1.</_>
        <_>
          8 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 7 -1.</_>
        <_>
          18 1 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 6 -1.</_>
        <_>
          3 0 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 17 18 -1.</_>
        <_>
          4 12 17 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 12 6 -1.</_>
        <_>
          6 0 6 3 2.</_>
        <_>
          12 3 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 18 4 -1.</_>
        <_>
          13 7 9 2 2.</_>
        <_>
          4 9 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 10 6 -1.</_>
        <_>
          4 14 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 10 12 -1.</_>
        <_>
          12 9 5 6 2.</_>
        <_>
          7 15 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 3 -1.</_>
        <_>
          8 1 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 6 6 -1.</_>
        <_>
          13 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 6 6 -1.</_>
        <_>
          8 11 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 19 3 -1.</_>
        <_>
          3 11 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 9 -1.</_>
        <_>
          0 5 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 16 10 6 -1.</_>
        <_>
          14 18 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 10 6 -1.</_>
        <_>
          0 18 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 9 6 -1.</_>
        <_>
          14 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 9 6 -1.</_>
        <_>
          0 20 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 9 6 -1.</_>
        <_>
          14 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 6 9 -1.</_>
        <_>
          8 2 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 4 12 -1.</_>
        <_>
          15 8 2 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 13 8 8 -1.</_>
        <_>
          8 17 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 20 18 3 -1.</_>
        <_>
          10 20 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 4 12 -1.</_>
        <_>
          7 8 2 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 12 3 -1.</_>
        <_>
          7 7 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 9 -1.</_>
        <_>
          12 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 20 18 3 -1.</_>
        <_>
          11 20 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 18 3 -1.</_>
        <_>
          7 20 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 1 6 20 -1.</_>
        <_>
          21 1 3 10 2.</_>
        <_>
          18 11 3 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 6 20 -1.</_>
        <_>
          0 1 3 10 2.</_>
        <_>
          3 11 3 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 3 4 18 -1.</_>
        <_>
          15 3 2 9 2.</_>
        <_>
          13 12 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 6 12 -1.</_>
        <_>
          0 6 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 9 12 6 -1.</_>
        <_>
          18 9 6 3 2.</_>
        <_>
          12 12 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 3 4 18 -1.</_>
        <_>
          7 3 2 9 2.</_>
        <_>
          9 12 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 6 9 -1.</_>
        <_>
          16 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 12 6 -1.</_>
        <_>
          0 9 6 3 2.</_>
        <_>
          6 12 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 4 8 20 -1.</_>
        <_>
          18 4 4 10 2.</_>
        <_>
          14 14 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 8 20 -1.</_>
        <_>
          2 4 4 10 2.</_>
        <_>
          6 14 4 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 13 9 6 -1.</_>
        <_>
          14 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 9 6 -1.</_>
        <_>
          1 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 18 3 -1.</_>
        <_>
          9 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 13 9 6 -1.</_>
        <_>
          5 15 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 18 3 -1.</_>
        <_>
          5 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 6 7 -1.</_>
        <_>
          11 2 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 9 6 -1.</_>
        <_>
          12 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 9 6 -1.</_>
        <_>
          9 1 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 14 6 -1.</_>
        <_>
          12 6 7 3 2.</_>
        <_>
          5 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 6 13 -1.</_>
        <_>
          10 2 2 13 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 12 6 -1.</_>
        <_>
          12 11 6 3 2.</_>
        <_>
          6 14 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 15 -1.</_>
        <_>
          9 1 6 15 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 6 7 -1.</_>
        <_>
          13 0 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 16 6 -1.</_>
        <_>
          3 6 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 6 9 -1.</_>
        <_>
          9 7 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 0 4 24 -1.</_>
        <_>
          13 0 2 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 4 24 -1.</_>
        <_>
          9 0 2 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 5 12 -1.</_>
        <_>
          11 13 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 15 9 6 -1.</_>
        <_>
          7 17 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 7 18 6 -1.</_>
        <_>
          5 9 18 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 5 12 -1.</_>
        <_>
          8 13 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 17 17 6 -1.</_>
        <_>
          4 19 17 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 18 14 -1.</_>
        <_>
          0 3 9 7 2.</_>
        <_>
          9 10 9 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 24 2 -1.</_>
        <_>
          0 2 24 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 15 18 3 -1.</_>
        <_>
          0 16 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 9 -1.</_>
        <_>
          11 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 3 14 12 -1.</_>
        <_>
          3 9 14 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 6 9 -1.</_>
        <_>
          10 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 6 10 -1.</_>
        <_>
          12 6 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 6 9 -1.</_>
        <_>
          7 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 21 7 -1.</_>
        <_>
          9 0 7 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 12 5 -1.</_>
        <_>
          10 11 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 9 8 -1.</_>
        <_>
          11 7 3 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 18 -1.</_>
        <_>
          9 6 3 9 2.</_>
        <_>
          12 15 3 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 8 10 -1.</_>
        <_>
          19 14 4 5 2.</_>
        <_>
          15 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 14 8 10 -1.</_>
        <_>
          1 14 4 5 2.</_>
        <_>
          5 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 0 8 10 -1.</_>
        <_>
          15 0 4 5 2.</_>
        <_>
          11 5 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 8 10 -1.</_>
        <_>
          5 0 4 5 2.</_>
        <_>
          9 5 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 12 5 -1.</_>
        <_>
          6 1 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 12 18 2 -1.</_>
        <_>
          10 12 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 20 6 -1.</_>
        <_>
          12 8 10 3 2.</_>
        <_>
          2 11 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 9 7 -1.</_>
        <_>
          10 6 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 8 16 -1.</_>
        <_>
          14 5 4 8 2.</_>
        <_>
          10 13 4 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 16 8 -1.</_>
        <_>
          3 9 8 4 2.</_>
        <_>
          11 13 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 10 4 -1.</_>
        <_>
          7 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 12 10 8 -1.</_>
        <_>
          7 12 5 4 2.</_>
        <_>
          12 16 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 19 15 4 -1.</_>
        <_>
          14 19 5 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 18 9 -1.</_>
        <_>
          7 0 6 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 4 10 8 -1.</_>
        <_>
          18 4 5 4 2.</_>
        <_>
          13 8 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 16 18 4 -1.</_>
        <_>
          9 16 6 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 7 10 12 -1.</_>
        <_>
          13 7 5 6 2.</_>
        <_>
          8 13 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 10 12 -1.</_>
        <_>
          6 7 5 6 2.</_>
        <_>
          11 13 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 18 7 -1.</_>
        <_>
          10 6 6 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 18 3 -1.</_>
        <_>
          0 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 18 3 -1.</_>
        <_>
          3 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 4 6 10 -1.</_>
        <_>
          4 4 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 8 24 -1.</_>
        <_>
          16 0 4 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 8 15 -1.</_>
        <_>
          8 0 4 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 8 24 -1.</_>
        <_>
          16 0 4 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 4 18 9 -1.</_>
        <_>
          7 4 6 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 12 9 6 -1.</_>
        <_>
          15 14 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 6 -1.</_>
        <_>
          3 9 9 3 2.</_>
        <_>
          12 12 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 5 6 9 -1.</_>
        <_>
          18 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 6 9 -1.</_>
        <_>
          0 8 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 18 4 -1.</_>
        <_>
          13 7 9 2 2.</_>
        <_>
          4 9 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 12 20 -1.</_>
        <_>
          2 1 6 10 2.</_>
        <_>
          8 11 6 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 6 23 -1.</_>
        <_>
          17 0 3 23 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 2 18 -1.</_>
        <_>
          1 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 8 10 6 -1.</_>
        <_>
          8 10 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 20 6 -1.</_>
        <_>
          0 6 10 3 2.</_>
        <_>
          10 9 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 12 12 5 -1.</_>
        <_>
          15 12 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 3 19 -1.</_>
        <_>
          1 4 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 1 3 18 -1.</_>
        <_>
          20 1 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 3 18 -1.</_>
        <_>
          3 1 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 18 3 -1.</_>
        <_>
          9 10 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 10 9 -1.</_>
        <_>
          9 4 5 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 13 14 7 -1.</_>
        <_>
          7 13 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 13 14 7 -1.</_>
        <_>
          10 13 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 9 6 -1.</_>
        <_>
          11 15 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 14 8 10 -1.</_>
        <_>
          4 14 4 5 2.</_>
        <_>
          8 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 14 4 10 -1.</_>
        <_>
          10 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 5 16 -1.</_>
        <_>
          3 16 5 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 10 9 6 -1.</_>
        <_>
          15 12 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 10 9 6 -1.</_>
        <_>
          0 12 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 12 9 -1.</_>
        <_>
          6 10 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 10 5 8 -1.</_>
        <_>
          9 14 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 15 6 9 -1.</_>
        <_>
          10 15 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 6 7 6 -1.</_>
        <_>
          16 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 1 4 22 -1.</_>
        <_>
          10 1 2 22 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 14 3 -1.</_>
        <_>
          6 6 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 19 3 -1.</_>
        <_>
          0 19 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 6 24 -1.</_>
        <_>
          17 0 3 24 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 15 6 -1.</_>
        <_>
          5 13 5 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 10 14 -1.</_>
        <_>
          14 6 5 7 2.</_>
        <_>
          9 13 5 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 8 10 -1.</_>
        <_>
          1 6 4 5 2.</_>
        <_>
          5 11 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 5 -1.</_>
        <_>
          7 6 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 9 6 -1.</_>
        <_>
          10 7 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 8 14 14 -1.</_>
        <_>
          14 8 7 7 2.</_>
        <_>
          7 15 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 14 14 -1.</_>
        <_>
          3 8 7 7 2.</_>
        <_>
          10 15 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 13 4 -1.</_>
        <_>
          9 10 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 6 12 -1.</_>
        <_>
          3 2 3 6 2.</_>
        <_>
          6 8 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 10 17 6 -1.</_>
        <_>
          6 13 17 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 17 6 -1.</_>
        <_>
          1 13 17 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 7 8 9 -1.</_>
        <_>
          16 10 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 7 8 9 -1.</_>
        <_>
          0 10 8 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 9 24 10 -1.</_>
        <_>
          12 9 12 5 2.</_>
        <_>
          0 14 12 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 15 8 -1.</_>
        <_>
          8 2 5 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 18 8 -1.</_>
        <_>
          10 2 6 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 1 18 4 -1.</_>
        <_>
          0 1 9 2 2.</_>
        <_>
          9 3 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          20 2 3 18 -1.</_>
        <_>
          21 2 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 3 3 19 -1.</_>
        <_>
          2 3 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 6 16 -1.</_>
        <_>
          20 8 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 6 16 -1.</_>
        <_>
          2 8 2 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 18 11 6 -1.</_>
        <_>
          8 20 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 12 5 -1.</_>
        <_>
          8 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 5 -1.</_>
        <_>
          11 6 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 9 6 -1.</_>
        <_>
          9 3 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 12 5 -1.</_>
        <_>
          7 6 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 8 6 7 -1.</_>
        <_>
          12 8 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 9 6 -1.</_>
        <_>
          11 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 6 9 -1.</_>
        <_>
          8 17 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 2 9 6 -1.</_>
        <_>
          11 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 16 20 -1.</_>
        <_>
          4 3 8 10 2.</_>
        <_>
          12 13 8 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 10 12 -1.</_>
        <_>
          12 6 5 6 2.</_>
        <_>
          7 12 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 7 12 -1.</_>
        <_>
          0 6 7 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 11 6 -1.</_>
        <_>
          12 19 11 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 12 8 -1.</_>
        <_>
          4 7 6 4 2.</_>
        <_>
          10 11 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 11 8 10 -1.</_>
        <_>
          12 11 4 5 2.</_>
        <_>
          8 16 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 4 9 -1.</_>
        <_>
          11 1 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 3 22 -1.</_>
        <_>
          15 0 1 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 0 3 22 -1.</_>
        <_>
          8 0 1 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 7 18 4 -1.</_>
        <_>
          13 7 9 2 2.</_>
        <_>
          4 9 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 4 15 -1.</_>
        <_>
          10 7 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 18 13 -1.</_>
        <_>
          9 0 9 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 0 3 24 -1.</_>
        <_>
          17 0 1 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 3 24 -1.</_>
        <_>
          6 0 1 24 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 15 5 8 -1.</_>
        <_>
          10 19 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 18 18 2 -1.</_>
        <_>
          2 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 8 20 3 -1.</_>
        <_>
          2 9 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 6 9 6 -1.</_>
        <_>
          7 8 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 19 10 -1.</_>
        <_>
          3 7 19 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 7 19 3 -1.</_>
        <_>
          2 8 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 6 9 4 -1.</_>
        <_>
          15 8 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 2 18 8 -1.</_>
        <_>
          8 2 6 8 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 9 14 4 -1.</_>
        <_>
          10 9 7 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 6 16 -1.</_>
        <_>
          7 4 3 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 9 16 -1.</_>
        <_>
          18 8 3 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 9 16 -1.</_>
        <_>
          3 8 3 16 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 0 6 14 -1.</_>
        <_>
          20 0 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 6 14 -1.</_>
        <_>
          2 0 2 14 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 0 6 22 -1.</_>
        <_>
          17 0 2 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 6 22 -1.</_>
        <_>
          5 0 2 22 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 2 12 20 -1.</_>
        <_>
          16 2 4 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 2 12 20 -1.</_>
        <_>
          4 2 4 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 4 9 -1.</_>
        <_>
          11 6 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 6 16 -1.</_>
        <_>
          12 0 3 16 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 1 3 12 -1.</_>
        <_>
          12 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 4 18 6 -1.</_>
        <_>
          3 4 9 3 2.</_>
        <_>
          12 7 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 5 16 8 -1.</_>
        <_>
          13 5 8 4 2.</_>
        <_>
          5 9 8 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 10 6 -1.</_>
        <_>
          0 15 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 9 6 -1.</_>
        <_>
          8 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 2 9 6 -1.</_>
        <_>
          9 2 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 1 10 8 -1.</_>
        <_>
          19 1 5 4 2.</_>
        <_>
          14 5 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 3 12 -1.</_>
        <_>
          9 7 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 9 -1.</_>
        <_>
          6 7 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 12 6 -1.</_>
        <_>
          10 5 4 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 1 8 5 -1.</_>
        <_>
          5 1 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 12 6 8 -1.</_>
        <_>
          12 16 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 12 12 6 -1.</_>
        <_>
          3 14 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 18 12 6 -1.</_>
        <_>
          15 18 6 3 2.</_>
        <_>
          9 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 13 6 6 -1.</_>
        <_>
          4 16 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 3 7 18 -1.</_>
        <_>
          11 12 7 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 18 3 -1.</_>
        <_>
          9 9 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 3 19 2 -1.</_>
        <_>
          5 4 19 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 2 12 6 -1.</_>
        <_>
          4 2 6 3 2.</_>
        <_>
          10 5 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 6 9 -1.</_>
        <_>
          11 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 6 9 -1.</_>
        <_>
          10 6 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 9 5 15 -1.</_>
        <_>
          16 14 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 9 5 15 -1.</_>
        <_>
          3 14 5 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 6 14 6 -1.</_>
        <_>
          13 6 7 3 2.</_>
        <_>
          6 9 7 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 3 14 -1.</_>
        <_>
          8 13 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 24 5 -1.</_>
        <_>
          8 16 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 20 20 3 -1.</_>
        <_>
          10 20 10 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 10 18 2 -1.</_>
        <_>
          5 11 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 6 10 -1.</_>
        <_>
          2 6 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 1 20 3 -1.</_>
        <_>
          2 2 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 13 6 11 -1.</_>
        <_>
          11 13 2 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 15 6 8 -1.</_>
        <_>
          9 19 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 9 -1.</_>
        <_>
          9 15 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 18 2 -1.</_>
        <_>
          5 12 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 6 15 6 -1.</_>
        <_>
          2 8 15 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 18 3 -1.</_>
        <_>
          6 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 3 18 -1.</_>
        <_>
          6 0 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          18 3 6 10 -1.</_>
        <_>
          20 3 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 6 10 -1.</_>
        <_>
          2 3 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 8 9 -1.</_>
        <_>
          10 5 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 5 8 9 -1.</_>
        <_>
          10 5 4 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 2 20 3 -1.</_>
        <_>
          3 3 20 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 13 4 -1.</_>
        <_>
          5 4 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          17 0 7 14 -1.</_>
        <_>
          17 7 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 7 14 -1.</_>
        <_>
          0 7 7 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 11 10 6 -1.</_>
        <_>
          9 11 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 10 6 -1.</_>
        <_>
          10 11 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 6 3 18 -1.</_>
        <_>
          11 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 16 18 3 -1.</_>
        <_>
          6 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 9 10 -1.</_>
        <_>
          4 11 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 7 15 4 -1.</_>
        <_>
          9 9 15 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 6 12 6 -1.</_>
        <_>
          5 6 6 3 2.</_>
        <_>
          11 9 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 1 12 9 -1.</_>
        <_>
          6 4 12 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 9 6 12 -1.</_>
        <_>
          7 9 3 6 2.</_>
        <_>
          10 15 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 5 13 6 -1.</_>
        <_>
          11 7 13 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 11 22 13 -1.</_>
        <_>
          12 11 11 13 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 8 6 6 -1.</_>
        <_>
          18 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 6 6 -1.</_>
        <_>
          0 11 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 6 24 3 -1.</_>
        <_>
          0 7 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 10 6 -1.</_>
        <_>
          0 7 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 7 18 3 -1.</_>
        <_>
          6 8 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 10 6 -1.</_>
        <_>
          0 2 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          19 0 3 19 -1.</_>
        <_>
          20 0 1 19 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 6 12 16 -1.</_>
        <_>
          4 6 6 8 2.</_>
        <_>
          10 14 6 8 2.</_></rects></_>
    <_>
      <rects>
        <_>
          19 6 4 18 -1.</_>
        <_>
          21 6 2 9 2.</_>
        <_>
          19 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 4 18 -1.</_>
        <_>
          1 6 2 9 2.</_>
        <_>
          3 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 21 18 3 -1.</_>
        <_>
          3 22 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 19 9 4 -1.</_>
        <_>
          0 21 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 18 12 6 -1.</_>
        <_>
          18 18 6 3 2.</_>
        <_>
          12 21 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 18 9 4 -1.</_>
        <_>
          7 20 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 16 10 8 -1.</_>
        <_>
          17 16 5 4 2.</_>
        <_>
          12 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 16 10 8 -1.</_>
        <_>
          2 16 5 4 2.</_>
        <_>
          7 20 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 0 10 12 -1.</_>
        <_>
          19 0 5 6 2.</_>
        <_>
          14 6 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 10 12 -1.</_>
        <_>
          0 0 5 6 2.</_>
        <_>
          5 6 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 14 9 6 -1.</_>
        <_>
          15 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 9 6 -1.</_>
        <_>
          0 16 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 14 10 6 -1.</_>
        <_>
          14 16 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 10 6 -1.</_>
        <_>
          0 16 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 18 18 2 -1.</_>
        <_>
          5 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 18 3 -1.</_>
        <_>
          0 19 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 5 18 12 -1.</_>
        <_>
          12 5 9 6 2.</_>
        <_>
          3 11 9 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 3 7 9 -1.</_>
        <_>
          5 6 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 19 15 -1.</_>
        <_>
          4 5 19 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 0 16 4 -1.</_>
        <_>
          3 2 16 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 12 16 12 -1.</_>
        <_>
          4 12 8 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 3 12 15 -1.</_>
        <_>
          10 3 6 15 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 4 2 19 -1.</_>
        <_>
          16 4 1 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 2 19 -1.</_>
        <_>
          7 4 1 19 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 14 8 10 -1.</_>
        <_>
          17 14 4 5 2.</_>
        <_>
          13 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 14 8 10 -1.</_>
        <_>
          3 14 4 5 2.</_>
        <_>
          7 19 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 3 18 -1.</_>
        <_>
          12 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 11 12 6 -1.</_>
        <_>
          5 11 6 3 2.</_>
        <_>
          11 14 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 8 10 -1.</_>
        <_>
          14 5 4 5 2.</_>
        <_>
          10 10 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 4 12 10 -1.</_>
        <_>
          6 4 6 5 2.</_>
        <_>
          12 9 6 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 18 10 -1.</_>
        <_>
          15 8 9 5 2.</_>
        <_>
          6 13 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 18 10 -1.</_>
        <_>
          0 8 9 5 2.</_>
        <_>
          9 13 9 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 3 18 -1.</_>
        <_>
          12 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 14 18 3 -1.</_>
        <_>
          0 15 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 6 3 18 -1.</_>
        <_>
          12 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 6 3 18 -1.</_>
        <_>
          9 12 3 6 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 14 18 3 -1.</_>
        <_>
          6 15 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 5 18 3 -1.</_>
        <_>
          0 6 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 5 22 3 -1.</_>
        <_>
          2 6 22 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 21 10 -1.</_>
        <_>
          7 0 7 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 3 18 17 -1.</_>
        <_>
          12 3 6 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 18 17 -1.</_>
        <_>
          6 3 6 17 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 24 11 -1.</_>
        <_>
          8 12 8 11 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 10 16 6 -1.</_>
        <_>
          4 13 16 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 8 6 8 -1.</_>
        <_>
          12 12 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 14 8 7 -1.</_>
        <_>
          10 14 4 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 10 6 14 -1.</_>
        <_>
          18 10 3 7 2.</_>
        <_>
          15 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 6 14 -1.</_>
        <_>
          3 10 3 7 2.</_>
        <_>
          6 17 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 12 18 2 -1.</_>
        <_>
          6 13 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 10 6 -1.</_>
        <_>
          5 10 10 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 11 9 4 -1.</_>
        <_>
          12 13 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 11 9 6 -1.</_>
        <_>
          0 13 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 2 3 18 -1.</_>
        <_>
          12 2 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 2 3 18 -1.</_>
        <_>
          11 2 1 18 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 12 6 10 -1.</_>
        <_>
          11 12 2 10 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 10 6 9 -1.</_>
        <_>
          1 13 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 16 6 -1.</_>
        <_>
          14 9 8 3 2.</_>
        <_>
          6 12 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 8 9 6 -1.</_>
        <_>
          1 10 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          7 7 16 6 -1.</_>
        <_>
          7 9 16 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 0 18 3 -1.</_>
        <_>
          0 1 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 0 6 9 -1.</_>
        <_>
          12 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 5 6 6 -1.</_>
        <_>
          12 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          12 6 2 9 2.</_>
        <_>
          10 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 0 6 9 -1.</_>
        <_>
          10 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 9 -1.</_>
        <_>
          9 4 6 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 0 18 9 -1.</_>
        <_>
          1 3 18 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 3 24 3 -1.</_>
        <_>
          0 4 24 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 14 9 4 -1.</_>
        <_>
          6 16 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 9 8 10 -1.</_>
        <_>
          12 9 4 5 2.</_>
        <_>
          8 14 4 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 13 9 -1.</_>
        <_>
          5 5 13 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 16 9 -1.</_>
        <_>
          4 7 16 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          4 4 14 9 -1.</_>
        <_>
          4 7 14 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 9 6 -1.</_>
        <_>
          8 7 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 7 16 6 -1.</_>
        <_>
          1 9 16 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 5 13 9 -1.</_>
        <_>
          10 8 13 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 5 13 9 -1.</_>
        <_>
          1 8 13 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 4 24 6 -1.</_>
        <_>
          12 4 12 3 2.</_>
        <_>
          0 7 12 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 14 10 9 -1.</_>
        <_>
          1 17 10 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 17 18 3 -1.</_>
        <_>
          5 18 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 18 3 -1.</_>
        <_>
          0 17 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 17 9 6 -1.</_>
        <_>
          9 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 20 22 4 -1.</_>
        <_>
          1 20 11 2 2.</_>
        <_>
          12 22 11 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 14 8 6 -1.</_>
        <_>
          8 17 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 6 8 15 -1.</_>
        <_>
          8 11 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 4 18 3 -1.</_>
        <_>
          5 5 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 3 5 10 -1.</_>
        <_>
          9 8 5 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 8 12 3 -1.</_>
        <_>
          6 8 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          2 6 18 6 -1.</_>
        <_>
          2 6 9 3 2.</_>
        <_>
          11 9 9 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          10 6 4 18 -1.</_>
        <_>
          12 6 2 9 2.</_>
        <_>
          10 15 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          7 5 6 6 -1.</_>
        <_>
          10 5 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          14 5 2 18 -1.</_>
        <_>
          14 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          8 5 2 18 -1.</_>
        <_>
          8 14 2 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 2 10 6 -1.</_>
        <_>
          9 2 5 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 18 12 -1.</_>
        <_>
          12 1 9 12 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 2 17 22 -1.</_>
        <_>
          5 13 17 11 2.</_></rects></_>
    <_>
      <rects>
        <_>
          4 0 12 6 -1.</_>
        <_>
          4 2 12 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 9 16 6 -1.</_>
        <_>
          14 9 8 3 2.</_>
        <_>
          6 12 8 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 0 5 18 -1.</_>
        <_>
          9 9 5 9 2.</_></rects></_>
    <_>
      <rects>
        <_>
          12 0 6 9 -1.</_>
        <_>
          14 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 0 6 9 -1.</_>
        <_>
          8 0 2 9 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 12 -1.</_>
        <_>
          11 1 2 12 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 9 13 4 -1.</_>
        <_>
          5 11 13 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 19 3 -1.</_>
        <_>
          5 9 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          9 9 6 8 -1.</_>
        <_>
          9 13 6 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          11 9 4 15 -1.</_>
        <_>
          11 14 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 0 6 14 -1.</_>
        <_>
          2 0 3 7 2.</_>
        <_>
          5 7 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 1 6 14 -1.</_>
        <_>
          18 1 3 7 2.</_>
        <_>
          15 8 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 1 6 14 -1.</_>
        <_>
          3 1 3 7 2.</_>
        <_>
          6 8 3 7 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 20 18 4 -1.</_>
        <_>
          12 20 9 2 2.</_>
        <_>
          3 22 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          5 0 4 20 -1.</_>
        <_>
          5 0 2 10 2.</_>
        <_>
          7 10 2 10 2.</_></rects></_>
    <_>
      <rects>
        <_>
          16 8 8 12 -1.</_>
        <_>
          20 8 4 6 2.</_>
        <_>
          16 14 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 8 8 12 -1.</_>
        <_>
          0 8 4 6 2.</_>
        <_>
          4 14 4 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          13 13 10 8 -1.</_>
        <_>
          18 13 5 4 2.</_>
        <_>
          13 17 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          1 13 10 8 -1.</_>
        <_>
          1 13 5 4 2.</_>
        <_>
          6 17 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 8 4 15 -1.</_>
        <_>
          15 13 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          5 8 4 15 -1.</_>
        <_>
          5 13 4 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          6 11 16 12 -1.</_>
        <_>
          6 15 16 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          2 11 16 12 -1.</_>
        <_>
          2 15 16 4 3.</_></rects></_>
    <_>
      <rects>
        <_>
          14 12 7 9 -1.</_>
        <_>
          14 15 7 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          10 1 3 21 -1.</_>
        <_>
          10 8 3 7 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 11 9 4 -1.</_>
        <_>
          13 13 9 2 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 10 17 9 -1.</_>
        <_>
          3 13 17 3 3.</_></rects></_>
    <_>
      <rects>
        <_>
          13 8 8 15 -1.</_>
        <_>
          13 13 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 8 8 15 -1.</_>
        <_>
          3 13 8 5 3.</_></rects></_>
    <_>
      <rects>
        <_>
          11 14 10 8 -1.</_>
        <_>
          16 14 5 4 2.</_>
        <_>
          11 18 5 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 22 6 -1.</_>
        <_>
          0 18 11 3 2.</_>
        <_>
          11 21 11 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 16 24 4 -1.</_>
        <_>
          0 16 12 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          6 20 12 3 -1.</_>
        <_>
          12 20 6 3 2.</_></rects></_>
    <_>
      <rects>
        <_>
          18 12 6 12 -1.</_>
        <_>
          21 12 3 6 2.</_>
        <_>
          18 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          0 12 6 12 -1.</_>
        <_>
          0 12 3 6 2.</_>
        <_>
          3 18 3 6 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          1 6 22 10 -1.</_>
        <_>
          1 6 11 5 2.</_>
        <_>
          12 11 11 5 2.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 18 18 2 -1.</_>
        <_>
          0 19 18 1 2.</_></rects></_>
    <_>
      <rects>
        <_>
          3 15 19 3 -1.</_>
        <_>
          3 16 19 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 18 3 -1.</_>
        <_>
          0 14 18 1 3.</_></rects></_>
    <_>
      <rects>
        <_>
          15 17 9 6 -1.</_>
        <_>
          15 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 17 9 6 -1.</_>
        <_>
          0 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          12 17 9 6 -1.</_>
        <_>
          12 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          3 17 9 6 -1.</_>
        <_>
          3 19 9 2 3.</_></rects></_>
    <_>
      <rects>
        <_>
          16 2 3 20 -1.</_>
        <_>
          17 2 1 20 3.</_></rects></_>
    <_>
      <rects>
        <_>
          0 13 24 8 -1.</_>
        <_>
          0 17 24 4 2.</_></rects></_>
    <_>
      <rects>
        <_>
          9 1 6 22 -1.</_>
        <_>
          12 1 3 11 2.</_>
        <_>
          9 12 3 11 2.</_></rects></_></features></cascade>
</opencv_storage>
`
