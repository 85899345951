import './CameraComponent.css'

import { useEffect, useRef, useState } from 'react'
import { startAutoFaceCheck } from './auto-check'
import { startCamera, stopCamera } from './camera'
import { captureImageFromVideo } from './parser'

type CameraComponentProps = {
  onImageCaptured?:
    | ((args: {
        image: string | null
        userId: string
        type: string
      }) => void | Promise<void>)
    | null
}

function CameraComponent({ onImageCaptured }: CameraComponentProps) {
  const videoElementRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isCameraStarted, setIsCameraStarted] = useState(true)
  const [image, setImage] = useState<string | null>(null)
  const [userId, setUserId] = useState<string>('')

  const type = useRef<string>('')
  const camWidth = useRef<string>('600px')
  const camHeight = useRef<string>('400px')
  const roiWidth = useRef<number>(0.75)
  const roiHeight = useRef<number>(1)
  const minFaceSize = useRef<Array<number>>([10,10])
  const maxFaceSize = useRef<Array<number>>([1000,1000])
  const cameraChecking = useRef<boolean>(false)

  const [showControls, setShowControls] = useState(type.current === 'register')

  let mediaStream: MediaStream

  const resume = async () => {
    await videoElementRef.current?.play()
  }

  const callStartCamera = async () => {
    await startCamera({
      videoEl: videoElementRef.current!,
      canvas: canvasRef.current!,
      faceRecParams: {
        camWidth: camWidth.current,
        camHeight: camHeight.current,
        minFaceSize: minFaceSize.current,
        maxFaceSize: maxFaceSize.current,
        roiWidth: roiWidth.current,
        roiHeight: roiHeight.current,
      },
      onCameraStart: () => setIsCameraStarted(true),
      onStream(stream) {
        mediaStream = stream
      },
    })

    if (type.current === 'verify' && !cameraChecking.current){
      startAutoFaceCheck({
        videoEl: videoElementRef.current!,
        onCameraStop: () => {
          setIsCameraStarted(false)
          cameraChecking.current = false
        },
        onImageCaptured: async (image) => {
          setImage(image)
        },
        roiWidth: roiWidth.current,
        roiHeight: roiHeight.current,
        minFaceSize: minFaceSize.current,
        maxFaceSize: maxFaceSize.current
      })

      cameraChecking.current = true
    }
  }

  const reset = async () => {
    videoElementRef.current?.pause()
    setImage(null)
    await stopCamera(mediaStream)
    setIsCameraStarted(false)
    await callStartCamera()
  }

  const submitImage = async () => {
    if (type.current === 'register' && !userId) {
      console.error('No user id provided')
      if (window && window.parent) {
        window.parent.postMessage(
          { type: 'error', data: 'No user id provided' },
          '*',
        )
      }
      return
    }

    try {
      setIsCameraStarted(false)
      await onImageCaptured?.({ image, userId, type: type.current })
      setImage(null)
    } catch(e){
      setIsCameraStarted(true)
    }
  }

  const cancelCapture = async () => {
    await resume()
    setImage(null)
  }

  const callCapture = async () => {
    const image = await captureImageFromVideo(videoElementRef.current)
    setImage(image)
  }

  const callClose = () => {
    window.parent.postMessage({ type: 'close' }, '*')
  }

  useEffect(() => {
    if (type.current === 'verify' && image) submitImage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, type.current])

  const performAction = (action: string) => {
    switch (action) {
      case 'cancelCapture':
        cancelCapture()
        break;

      case 'takePicture':
        callCapture()
        break;

      case 'submitPicture':
        submitImage()
        break;

      case 'hideButtons':
        setShowControls(false)
        break;

      case 'showButtons':
        setShowControls(true)
        break;

      default:
        return false
    }

    return true
  }

  useEffect(() => {
    if (!window || !window.parent) return
    const handler = (event: MessageEvent) => {
      const { data, action } = event.data || {}

      if (performAction(action)) return

      if (typeof data?.userId === 'string') setUserId(data.userId)

      if (typeof data?.type === 'string') {
        setShowControls(data.type === 'register')
        type.current = data.type
        reset()
      }

      if (typeof data?.camWidth === 'string') {
        camWidth.current = data.camWidth
        reset()
      }

      if (typeof data?.camHeight === 'string') {
        camHeight.current = data.camHeight
        reset()
      }

      if (typeof data?.roiWidth === 'number') {
        roiWidth.current = data.roiWidth
        reset()
      }

      if (typeof data?.roiHeight === 'number') {
        roiHeight.current = data.roiHeight
        reset()
      }

      if (Array.isArray(data?.minFaceSize) && data.minFaceSize.length === 2) {
        minFaceSize.current = data.minFaceSize
        reset()
      }

      if (Array.isArray(data?.maxFaceSize) && data.maxFaceSize.length === 2) {
        maxFaceSize.current = data.maxFaceSize
        reset()
      }
    }
    window.addEventListener('message', handler)
    window.parent.postMessage({ type: 'ready' }, '*')
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  return (
    <>
      <div className='camera-container'>
        <video
          className='camera-preview'
          height='320'
          width='240'
          style={{
            display: 'block',
            width: '100%',
            height: image ? camHeight.current : '100%',
            maxHeight: camHeight.current,
            maxWidth: camWidth.current,
          }}
          ref={videoElementRef}></video>
        <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, opacity: 0.1, backgroundColor: 'white' }} />

        {!isCameraStarted && <div className='camera-overlay'></div>}
      </div>

      {showControls && (
        <div id='controls'>
          {isCameraStarted && (
            <div>
              <button id='close-button' onClick={callClose}>
                Close
              </button>

              {!image && (
                <button id='capture-button' onClick={callCapture}>
                  Capture
                </button>
              )}

              {image && (
                <>
                  <button id='ok-button' onClick={submitImage}>
                    OK
                  </button>
                  <button id='cancel-button' onClick={cancelCapture}>
                    Cancel
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CameraComponent
