export async function captureImageFromVideo(video?: HTMLVideoElement | null) {
  if (!video) return null

  video.pause()

  const canvas = document.createElement('canvas')
  canvas.width = 360
  canvas.height = 480
  const context = canvas.getContext('2d')

  context!.translate(canvas.width / 2, canvas.height / 2)
  context!.drawImage(
    video,
    -canvas.height / 2,
    -canvas.width / 2,
    canvas.height,
    canvas.width,
  )

  return canvas.toDataURL('image/png')
}
